@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.new-label {
  font-weight: bold !important;
  color: $alert-color !important;
  font-style: normal !important;
}

.bpm-label {
  padding: 0.1rem 0.5rem !important;
  display: inline-block;
  vertical-align: middle;
  width: auto !important;
  @include fts11;

  :hover,
  :focus {
    color: $white-color;
    cursor: pointer;
  }
}

.dialog-labels {
  padding-top: 0.5rem;

  .label-icon {
    width: 4rem;
    display: block;
    float: left;
  }

  .label-checkbox {
    display: block;
    float: left;
    padding-top: 0.2rem;
  }
}

.dialog-label {
  clear: both;
}

.theme-prime,
.theme-primepro,
.theme-gethome {

  .prime-form-label {
    width: 100%;

    label {
      margin-bottom: 0.2rem;
      @include fts11;
      color: $investment-icon-color;
      text-transform: uppercase;
    }
  }

  .prime-form-field {
    width: 100%;
  }
}
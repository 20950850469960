@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

/* row = tile = listItem */

.bpm-grid.bpm-rowview {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin: 0 -1.5rem;

  .bpm-grid-head.bpm-grid-row {
    /* ukryj header w kaflach */
    display: none !important;
  }

  .bpm-grid-row,
  .sortable-placeholder {
    float: left;
    height: 30rem;
    margin: 1.5rem;
    padding: 5rem 1rem 1rem 1rem;
    width: 100%;
    background: $white-color;
    border: 1px solid $border-color;
    position: relative;
  }

  .bpm-grid-head .bpm-grid-cell i {
    display: inline-block;
    vertical-align: top;
    font-size: 1.7rem;
    padding-top: 0.1rem;
    color: transparent;
  }

  .bpm-grid-head .bpm-grid-actions {
    text-align: left;
  }

  .bpm-grid-cell {
    display: block;
    vertical-align: top;
    padding: 0;
    transition: all 0.3s ease 0s;
  }

  .bpm-grid-checkbox {
    position: absolute;
    left: 1rem;
    top: 1rem;
  }

  .bpm-grid-status {
    bottom: 1rem;
    left: 1rem;
    position: absolute;

    .bpm-grid-cell-label {
      display: none;
    }

    .bpm-grid-cell-value {
      width: 20rem;
    }
  }

  .bpm-grid-regular {
    margin-left: 25rem;
  }

  .bpm-grid-image {
    position: absolute;
    left: 1rem;
    top: 5rem;
    width: 20rem;

    img {
      width: 100%;
    }
  }

  .bpm-status-temp {
    bottom: 0.5rem;
    left: 21rem;
    position: absolute;
    text-align: left;
    width: 30rem;
  }

  .bpm-grid-actions {
    @include fts14;
    min-width: 18rem;
    text-align: left;
    width: 18rem;
    position: absolute;
    left: 4rem;
    top: 1rem;

    a {
      display: inline-block;
      padding: 0 0.4rem 0.2rem;
      vertical-align: middle;
      width: 2.1rem;
      margin-bottom: 0.3rem;

      &.bpm-submit {
        display: block;
        width: 100%;
        margin-bottom: 0;
        text-align: left;
        padding: 0.4rem 1rem;
        background: none;
        color: $main-color;
        border: none;
        @include fts14;

        &:hover {
          background-color: $table-bg-hover;
          color: $main-color;
        }
      }
    }
  }

  div:not(:hover).bpm-grid-row .bpm-grid-actions a {
    background-color: transparent;
    color: $table-border-hover;
  }

  .bpm-grid-row:hover div:not(:hover).bpm-grid-actions a {
    background-color: transparent;
    color: $primary-color;
  }

  .bpm-grid-cell-label {
    margin-right: 1rem;
    width: 15rem;
    display: inline-block;
    font-weight: 700;
  }

  .bpm-grid-cell-value {
    display: inline-block;
    width: 35rem;
  }
}

.bpm-grid-row-empty {
  margin: 7rem 2rem;
  text-align: center;

  .btn.btn-primary {
    margin-top: 6rem;
  }
}

.theme-prime,
.theme-primepro,
.theme-gethome {

  .bpm-grid.bpm-rowview .bpm-grid-row,
  .bpm-grid.bpm-rowview .sortable-placeholder {
    border-color: $prime-border-box;
  }
}
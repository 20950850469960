@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.side-kit {
  position: fixed;
  padding-top: 5rem;
  height: 100%;
  z-index: 100000;
  visibility: hidden;
  right: 0;
  background: url("../gfx/bg2.jpg") repeat scroll 0 0;
  box-shadow: $dialog-shadow;
  width: 30rem;
}

.kit-content {
  height: inherit;
  padding: 1rem;
  position: relative;

  .sortable-placeholder {
    height: 5rem;
    padding-bottom: 0 !important;
    background: $table-row-bg;
    border: 1px dashed $nav-active-color;
    position: relative;
  }

  .bpm-box {
    height: 15rem;
    background: $white-color;
    border: 1px solid $border-color;
    padding: 0.1rem;
  }

  .bpm-box-header {
    height: 3rem !important;
    overflow: hidden;
    position: relative;
    padding: 0 0.5rem;

    .buttons {
      position: absolute;
      top: 0.3rem;
      z-index: 1;
      right: 0.5rem;
      display: none;
    }
  }

  .bpm-box-part {
    height: 14.6rem;
  }

  h3 {
    @include fts14;
    margin: 0.6rem 0;
  }

  .bpm-checkbox span {
    @include fts13;
  }

  .bpm-grid-cell {
    @include fts12;
  }

  .bpm-grid-image {
    min-width: 4.5rem !important;
    width: 4.5rem !important;

    img {
      height: 3.5rem !important;
    }
  }

  .offer-short {

    .offer-short-location,
    .offer-short-area,
    .offer-short-price-old {
      @include fts12;
    }

    .offer-short-number,
    .offer-short-third,
    .offer-short-fourth {
      @include fts11;
    }

  }

  .seeker-short {
    .seeker-short-name,
    .seeker-short-type {
      @include fts12;
    }

    .seeker-short-status,
    .seeker-short-fourth {
      @include fts11;
    }
  }

}

.side-kit-buttons {
  position: absolute;
  top: 1.5rem;
  right: 1rem;
}

.kit-items {
  margin-top: 3rem;
}

.kit-item .box-collapsed,
.kit-sort .bpm-box {
  display: none;
}

.kit-item:hover .bpm-box-header .buttons {
  display: block;
}

.kit-item-wrapper {
  padding: 0.5rem;
  box-shadow: $box-shadow;
  background-color: $white-color;
  margin-bottom: 1rem;

  .bpm-box {
    border: none;
  }

  .bpm-grid-empty {
    @include fts12;
    color: $gray-color;
    padding-top: 5rem;
  }
}

.kit-sort .kit-item-wrapper {
  box-shadow: $kit-shadow;
}

.bpm-desktop-col-3 .kit-content .seeker-short-third {
  @include fts11;
}

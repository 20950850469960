@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.btn-link:active, .btn-link.active {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn i + span {
  margin-left: 0.5rem;
}

.btn span + i {
  margin-left: 0.5rem;
  margin-right: 0;
}

.watermark-add {
  padding-top: 5rem;
}

.btn-gray {
  @include status($disabled-color);

  i {
    color: $white-color !important;
  }

  &:hover {
    color: $white-color !important;
    filter: brightness(95%);
  }
}

.btn-darkblue {
  @include themify($themes) {
    color: themed('darkBlueBtnColor');
    background-color: themed('darkBlueBtnBg');
    border-color: themed('darkBlueBtnBg');
  }

  i {
    @include themify($themes) {
      color: themed('darkBlueBtnColor');
    }
  }

  &:hover {
    filter: brightness(95%);
  }
}

.btn-shortcut {
  border: 1px solid $border-color;
  @include themify($themes) {
    color: themed('shortcutColor');
    background-color: themed('shortcutBg');
    border-color: themed('shortcutBorder');
  }

  &:hover {
    @include themify($themes) {
      border-color: themed('shortcutColor');
    }
  }
}

.btn-violet {
  @include themify($themes) {
    color: $white-color;
    background-color: themed('eventAcquisitionBg');
    border-color: themed('eventAcquisitionBg');
  }

  i, span {
    color: $white-color !important;
  }
}

.bpm-button-status:before {
  content: " ";
  display: inline-block;
  line-height: 1;
  position: absolute;
  left: 2rem;
  padding: 0.2rem;
  width: 0.8rem;
  height: 1.7rem;
}

.bpm-button-status {
  padding-left: 2.5rem !important;
}

.bpm-button-status.bpm-submit {
  width: auto;
  padding: 0.4rem 0.8rem;
  border-radius: 50px !important;
}

a.graylink {
  color: $main-color;
}

.doka--root .doka--instructions-bubble {
  font-size: 18px !important;
  padding: 1em !important;
  max-width: 20em !important;
  background: url('../gfx/editor-bg.png') repeat 0 0 !important;
  filter: none !important;
}

.btn-action {
  border: 1px solid $border-color;
  background: $white-color;

  &:hover {
    border: 1px solid $disabled-color;
  }

  i {
    @include fts15;
  }
}

.btn-link.text-primary {
  @include themify($themes) {
    color: themed('linkColor');
  }

  &:hover {
    @include themify($themes) {
      color: themed('linkColor');
    }
  }
}

.btn-link.text-warning {
  color: $warning-color;

  &:hover {
    color: $warning-color;
  }
}

.btn-link.text-danger {
  @include themify($themes) {
    color: themed('alertColor');
  }

  &:hover {
    @include themify($themes) {
      color: themed('alertColor');
    }
  }
}

.btn-link.text-success {
  @include themify($themes) {
    color: themed('successColor');
  }

  &:hover {
    @include themify($themes) {
      color: themed('successColor');
    }
  }
}

.btn-link.text-info {
  color: #58bddb;

  &:hover {
    color: #58bddb;
  }
}

.btn-input {
  height: 3.8rem;
  padding: 0.8rem 0.8rem 0.4rem;

  i {
    @include fts16;
  }
}

button.btn-input {
  padding: 0.6rem 0.8rem 0.4rem;
}

.form-control:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;

  @include themify($themes) {
    border-color: themed('formControlFocusBorder');
  }
}

.bpm-selectbox .form-control {
  border-right: none;
}

.input-group-addon {
  padding: 0.6rem;
  @include fts14;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  background-color: $white-color;
  border-left: none;
  border-radius: 0;

  @include themify($themes) {
    color: themed('inputAddonColor');
  }
}

.theme-prime .input-group-addon,
.theme-primepro .input-group-addon,
.theme-gethome .input-group-addon {
  border-left: none;
}

.form-control {
  height: 4rem;
  padding: 0 1.6rem;
  color: $dark2;
  font-weight: 500;
  @include fts13;

  @include themify($themes) {
    border-color: themed('formControlBorder');
  }
}

.mce-window.mce-in {
  z-index: 999999999 !important;
}

.ukraine {
  display: inline-block;
  width: 2rem;
  height: 1.4rem;
  background: $ukraine-gradient;
}

.btn-ukraine {
  padding-left: 2.5rem !important;

  &:before {
    content: " ";
    display: inline-block;
    line-height: 1;
    position: absolute;
    left: 2rem;
    padding: 0.2rem;
    width: 0.8rem;
    height: 1.7rem;
    background: $ukraine-gradient;
  }
}

#more-search-options-button:hover {
  @include themify($themes) {
    color: themed('linkColor');
  }
}

.theme-gethome {
  .form-control:not(:has(~ span)) {
    border: 1px solid #bbb;
    border-radius: 4px;
  }

  .form-control:has(~ span) {
    border: 1px solid #bbb;
    border-right: none;
  }

  .input-group-addon {
    border: 1px solid #BBB;
  }

  input:focus ~ .input-group-addon.bpm-autocomplete-arrow {
    border: 1px solid $gethome-primary-color;
    border-left: none;
  }
}

@media (max-width: 780px) {

  .btn {
    margin-bottom: 0.3rem;
  }

  .btn-group,
  .btn-group-vertical,
  .input-group,
  .bpm-autocomplete {
    position: static;
  }

  .bpm-grid-actions-icons .btn-group,
  .bpm-grid-cell .btn-group,
  .bpm-grid-pagination .btn-group {
    position: relative;
  }

  .btn {
    font-weight: 500;
  }

  .btn, .btn-link, .btn-lg, .btn-sm, .fc-header .fc-button {
    border-radius: 0.4rem !important;
  }
}

.theme-prime,
.theme-primepro,
.theme-gethome {

  .btn,
  .btn.bpm-new-offers-import.btn-primary,
  .btn.bpm-new-contiguities-import.btn-primary,
  .bpm-dialog-body .btn,
  .bpm-page-admin-company-details .wizard-step-body .bpm-box-header .btn {
    padding: 1rem 1.6rem;
    height: 4rem;
    min-width: 8.8rem;
    @include fts14;

    i {
      font-size: 1.6rem;
    }

    i + span {
      margin-left: 0.4rem;
    }

    &.btn-view-config {
      height: auto;
      @include fts12;
    }

    &.application-dialog-trigger {
      height: auto;
    }
  }

  .btn-sm, .btn-xs {
    padding: 0.4rem 0.7rem;
    @include fts13;
    line-height: 1.5;
  }

  .btn-group .btn,
  .bpm-listview-filters .btn,
  .btn.btn-sm,
  .profile-buttons .btn,
  .btn.btn-xs,
  .bpm-controls-left .btn-mass-action .btn {
    height: 3rem;
    font-size: 1.3rem;
    padding: 0.5rem 0.8rem;

    i {
      font-size: 1.3rem;
    }
  }

  .bpm-listview-controls .bpm-listview-filters .btn-mass-action {

    display: flex;
    flex-direction: row;

    .btn-action-elipsys .bpm-dropdown-menu {
      right: 0;
      left: unset;
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.4rem;

      i {
        padding-right: 0.5rem;
        font-size: 1.4rem;
      }
    }

    i.fa-ellipsis-v {
      font-size: 2.8rem;
      padding: 0.1rem 1.5rem;
    }

    .bpm-dropdown-content .btn {
      justify-content: flex-start;
    }

  }

  .bpm-listview-controls .btn-mass-action {
    i.fa-ellipsis-v {
      font-size: 2.8rem;
      padding: 0.1rem 0.5rem;
    }
  }

  .btn-mass-action.nomargin .btn.bpm-dropdown.no-radius-right {
    min-width: unset;
  }

  .btn-group .btn.bpm-status {
    height: 2.5rem;
    padding: 0.3rem 1rem;
  }

  .btn-input {
    color: $prime-primary-color !important;
    background: none;
    border: 1px solid $prime-primary-color !important;

    span + i {
      margin-right: 0px;
      margin-left: 15px;
    }
  }
}

.theme-gethome .btn-input {
  color: $gethome-primary-color !important;
  border: 1px solid $gethome-primary-color !important;
}

.theme-gethome {
  .btn-link:hover,
  .btn-link:focus,
  a:hover, a:focus {
    color: $gethome-dark-color;
    background-color: transparent;
  }

  .btn-primary:hover {
    background-color: $gethome-primary-color;
  }

  .plupload_button.plupload_add:hover {
    background-color: $success-prime-color;
  }
}

@media (max-width: 780px) {

  .theme-prime,
  .theme-primepro,
  .theme-gethome {

    .btn-group,
    .btn-group-vertical,
    .input-group,
    .bpm-autocomplete {
      position: static;
    }

    .bpm-grid-actions-icons .btn-group,
    .bpm-grid-cell .btn-group,
    .bpm-grid-pagination .btn-group {
      position: relative;
    }

    .btn {
      margin-bottom: 0.3rem;
    }
  }

}

@media (min-width: 992px) and (max-width: 1024px) {

  .btn {
    padding: 0.5rem 0.7rem;
    @include fts13;
  }
}

.btn-offer-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 6rem;
  padding: 1.8rem 2.4rem;
  border-radius: 0.4rem;
  z-index: 10000;
  @include themify($themes) {
    background-color: themed('btnPrimaryBg');
  }

  span {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $white-color;
  }

  i {
    color: $white-color;
  }

  &.active {
    i {
      transform: rotate(180deg);
    }
  }
}

.nav-buttons-mobile {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.8rem;

  a, button {
    width: 2.8rem;
    height: 2.8rem;
  }
}

a.plupload_button {
  color: #ffffff;
}

a.plupload_disabled {
  background-color: #c5c5c5;
}

.chevron-rotate {
  &.active {
    i {
      transform: rotate(180deg);
    }
  }
}

.side-bar-search-footer .btn-success {
  margin-bottom: 0.6rem;
}

.mobile-content .btn-reset.btn.btn-xs.btn-link {
    display: block;
    padding: 0.1rem 0.5rem;
    height: unset;
}
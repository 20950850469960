@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-page-reservation-panel .bpm-grid-row {

  &:hover {
    background-color: $table-row-bg !important;
    cursor: pointer;

    .bpm-grid-cell {
      background-color: $table-row-bg !important;
      cursor: pointer;
    }
  }

  &.selected-row .bpm-grid-cell {
    font-weight: bold;
    background-color: $table-cell-selected;
    border-color: $table-cell-sorted-border;
  }
}

.bpm-page-reservation-panel {

  .withdrawn {
    opacity: 0.5;
  }

  .bpm-box-header .buttons {
    display: block;
    right: 3rem;
    top: 1rem;
  }

  h2:first-child {
    padding-right: 0 !important;
  }

  .bpm-box-list {
    height: 30rem;
  }

  .bpm-desktop-item-higher .bpm-box-list {
    height: 51rem;
  }

  .bpm-box-details {
    height: auto !important;
    padding-bottom: 2rem;
  }

  .buttons.form-input {
    margin-top: 0 !important;
    float: left;
  }

  .bpm-desktop .bpm-desktop-item.chosen-item {
    height: 34rem;
  }

  .bpm-desktop .bpm-desktop-item.chosen-item .bpm-box-list {
    height: 25rem;
  }

  .bpm-desktop .bpm-desktop-item.chosen-item .bpm-grid-empty {
    padding-top: 10rem;
  }
}

@media (max-width: 780px) {
  .bpm-page-reservation-panel .btn-group {
    position: relative !important;
  }
}

.reservation-note {
  margin-bottom: 1rem;
}

.reservation-note-content {
  @include fts13;
}

.reservation-note-info {
  @include fts11;
}
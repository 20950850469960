@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

body#client-zone > header, aside {
  background: $white-color;
}

body#client-zone > header {
  height: 6rem;
}

#client-zone {

  aside {
    box-shadow: 0 0 4rem 0 rgb(0 0 0 / 10%);
  }

  .shadow {
    box-shadow: inset 4rem 0 4rem -4rem rgba(0, 0, 0, 0.1);
    padding-left: 3rem;
  }

  header .logo {
    display: block;
    float: left;
  }

  a.logo {
    width: 23rem;
    text-align: center;
    height: 5.8rem;
    line-height: 3rem;
    margin: 0;
  }

  a.logo .companyname {
    display: block;
    width: 100%;
    padding-top: 1.4rem;
    @include fts16;
  }

  a.logo img {
    width: 12rem;
  }


  section.content {
    background: $main-bg-color;
    padding-top: 9rem;
    word-break: break-word;
  }

  .nav-top > .btn-group > a {
    line-height: 5.8rem;
    padding: 0 3rem 0 1rem;
  }


  .nav-top > .btn-group span {
    @include fts16;
  }

  .nav-top > .btn-group span, .menu-main > dl > dt > a, .menu-level-2 > dt > a, .menu-main > dl > dt > a > i, .search-bar-top .search-button a span {
    color: $primary-color;
  }

  .nav-top .nav-dropdown .list-group-item a span {
    @include fts14;
    padding-left: 0;
  }

  .blue, .nav-top > .btn-group > a:hover i, .bpm-autocomplete .bpm-autocomplete-suggestion strong {
    color: $zone-suggestions;
  }

  .menu-main {
    padding: 6rem 1rem 1rem 1rem;
  }

  .nav-top > .btn-group > .label {
    line-height: 5.8rem;
    padding: 0 3rem 0 1rem;
    @include fts16;
    color: $main-color;
  }

  .logo-image .menu-main {
    padding-top: 8rem;
  }

  .menu-main > dl > dt > a {
    padding: 0.8rem 1rem;
  }

  .menu-main > dl > dt > a.active, .menu-main > dl > dt > a.active-url {
    background-color: $primary-color;
    color: $item-bg-color;
  }

  .menu-main > dl > dt > a.active > i, .menu-main > dl > dt > a.active-url > i {
    color: $white-color;
  }

  .menu-main > dl > dt {
    border-bottom: 1px solid $border-color;
  }

  .menu-main > dl > dt > a:hover {
    background-color: $bg-hover-opacity;
  }

  .menu-level-2 {
    background: $item-bg-color;
  }

  .search-bar-top .form-control {
    background-color: $dropdown-foot-bg;
  }


  .menu-level-2 > dt > .separator hr {
    border-top: 1px solid $border-color;
  }

  .btn-primary {
    color: $white-color;
    background-color: $primary-color;
    border-color: $primary-color;
  }

  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary:active,
  .btn-primary.active,
  .open .dropdown-toggle.btn-primary {
    color: $white-color;
    background-color: $fc-btn-active;
    border-color: $zone-primary-border;
  }

  .btn-success {
    color: $white-color;
    background-color: $success-color;
    border-color: $success-color;
  }

  .btn-success:hover,
  .btn-success:focus,
  .btn-success:active,
  .btn-success.active,
  .open .dropdown-toggle.btn-success {
    color: $white-color;
    background-color: $status-active;
    border-color: $zone-success-border;
  }

  .btn-danger {
    color: $white-color;
    background-color: $alert-color;
    border-color: $alert-color;
  }

  .btn-danger:hover,
  .btn-danger:focus,
  .btn-danger:active,
  .btn-danger.active,
  .open .dropdown-toggle.btn-danger {
    color: $white-color;
    background-color: $zone-danger-bg;
    border-color: $zone-danger-border;
  }

  .btn-info {
    color: $white-color;
    background-color: $fc-contact-bg;
    border-color: $fc-contact-bg;
  }

  .btn-info:hover,
  .btn-info:focus,
  .btn-info:active,
  .btn-info.active,
  .open .dropdown-toggle.btn-info {
    color: $white-color;
    background-color: $zone-info-bg;
    border-color: $zone-info-border;
  }

  .text-primary {
    color: $wizard-step-color;
  }

  .text-primary:hover {
    color: $wizard-step-color;
  }

  .text-danger {
    color: $alert-color;
  }

  .text-danger:hover {
    color: $alert-color;
  }

  a, h3 {
    color: $wizard-step-color;
  }

  a:hover {
    color: $zone-link-hover;
  }

  .bpm-dialog {
    background-color: $main-bg-color;
    border-radius: 5px !important;
  }

  .bpm-dialog-header-title {
    font-weight: 500;
  }

  .bpm-dialog-header,
  .bpm-dialog-content {
    padding: 3rem 3.5rem 1rem;
  }

  .bpm-dialog-header {
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
  }

  .bpm-dialog-content {
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }

  .offer-box .offer-box-price {
    color: $success-color;
  }


  @media (max-width: 780px) {


    .search-bar-top {
      background: $white-color;
    }

  }

  header.login {
    background: $white-color !important;
  }

  header.login .biglogo {
    background: url(../gfx/mls-wspon-logo-big.png) no-repeat center center !important;
  }


  .hidemls {
    display: none !important;
  }

  .bpm-validation .required-activate-input {
    border-bottom: 2px solid $alert-color;
  }


  #localmlsgrupasaw header.login .biglogo, #mlsgrupasaw header.login .biglogo {
    background: url(../gfx/mls-grupasaw-logo-big.png) no-repeat center center !important;
  }


  #localmlssppon header.login .biglogo, #mlssppon header.login .biglogo {
    background: url(../gfx/mls-sppon-logo-big.png) no-repeat center center !important;
  }

  .wizard-summary .summary-header .btn, .wizard-estate .summary-header .btn {
    margin-bottom: 0.5rem;
  }

  .comment {
    padding-bottom: 2rem;
  }

  .report-info {
    padding: 0 0 2rem 0;
  }

  .report-info .lead {
    @include fts18;
    padding-bottom: 1rem;
  }

  .report-info .lead strong {
    color: $primary-color;
  }

  .report-info span.small {
    @include fts12;
  }

  .report-info ul {
    margin: 0 0 0.5rem 0;
    padding: 0 0 0.5rem 0;
    list-style: none;
  }

  .report-info li {
    margin-bottom: 0.5rem;
  }

  .report-info span {
    @include fts14;
    color: $main-color;
    text-align: left;
    display: inline-block;
    padding: 0;
    margin: 0;
    line-height: 1.2rem;
  }

  .report-info span.widelabel {
    width: 25rem;
  }

  .report-info span.label {
    width: 13rem;
  }

  .report-info span.percent {
    width: 10rem;
    margin-left: 0.5rem;
  }

  .report-info span.bar {
    background-color: $primary-color;
    height: 1.4rem;
  }

  .report-info span.value {
    color: $primary-color;
    width: 5rem;
    text-align: right;
  }

  .menu-mobile-wraper {
    display: none;
    padding: 1.5rem;
  }

  .menu-mobile-label {
    width: 100%;
    text-align: center;
    font-weight: bold;
  }

  .menu-mobile-button {
    display: block;
    position: absolute;
    right: 1rem;
    top: 1.5rem;
  }

  .menu-mobile span {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    padding: 0.5rem 1.5rem;
    @include fts14;
    line-height: 2.4rem;
    width: 100%;
  }

  .offer-box .profileimage {
    width: 100%;
  }

  .offer-box-buttons-div {
    clear: both;
    padding: 1rem 0;
  }

  .offer-box {
    height: auto;
  }

  .menu-top {
    display: none;
  }

  .logo-mobile {
    display: none;
  }


  .opinion-dropdown .bpm-dropdown-content {
    max-height: none;
  }

  @media (max-width: 768px) {

    section.content {
      padding: 8rem 2rem;
    }

    a.logo {
      width: 7rem;
      display: none;
    }

    a.logo img {
      width: auto;
      max-height: 5rem;
      margin-top: 0.5rem;
    }

    .logo-image .menu-main {
      padding-top: 0;
    }

    .logo-mobile {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 10rem;
      height: 6rem;

    }

    aside {
      padding-top: 7rem;
    }

    .menu-mobile-wraper {
      display: block;
    }


    .nav-top {
      display: none;
    }

    .offer-box .profileimage {
      margin-bottom: 2rem;
    }

    .menu-top {
      display: block;
    }
  }

}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-board {
  padding-right: 0;
  width: 55rem;
  margin: 0 auto 1.5rem;
  position: relative;

  .bpm-box-header {
    position: relative;
    width: 100%;
    overflow: visible;

    h2,
    h3 {
      float: none;
      padding-right: 15rem;
      font-size: 2.4rem;

      @include themify($themes) {
        font-family: themed('mainFont'), Helvetica, Arial, sans-serif;
      }
    }

    .bpm-board-filter,
    .bpm-board-quick-info {
      font-weight: 300;
      @include fts14;
      position: absolute;
      top: 1rem;
      right: 12rem;
    }

    .bpm-board-quick-help {
      font-weight: 300;
      @include fts14;
      position: absolute;
      top: 1rem;
      right: 0;
    }

    .bpm-board-back {
      font-weight: 300;
      @include fts13;
      position: absolute;
      top: 0.5rem;
      right: 0;
    }
  }

  &.bpm-board-all {
    padding-right: 0;
  }

  .bpm-board-post {
    background: $white-color;
    border: 1px solid $border-color;
    margin-bottom: 1rem;
  }

  .bpm-board-header {
    position: relative;
    padding: 1.5rem;

    .bpm-post-options {
      color: $autocomplete-color;
      font-weight: 300;
      @include fts13;
      position: absolute;
      top: 0;
      right: 0;
    }

    .bpm-user-date {
      color: $autocomplete-color;
      font-weight: 300;
      @include fts13;
    }

  }

  .bpm-board-new-post {
    position: relative;

    .bpm-new-post-content {
      float: left;
      width: 47rem;
    }

    textarea.form-control {
      padding: 0.5rem 1rem;
      margin-top: 0.2rem;
      @include fts13;
      color: $input-color;
      background-color: $white-color;
      border: none;
      border-bottom: 1px solid $autocomplete-border;
      width: 100%;
      height: 5rem;
      overflow-y: hidden;
    }

    .form-control:hover,
    .form-control:focus {
      border: none;
      box-shadow: none;
      border-bottom: 1px solid $autocomplete-border;
    }

    .bpm-new-post-tabs {
      padding-top: 0.5rem;
    }

    .new-post-content {
      display: none;
    }

    .bottom-actions {
      position: relative;
      height: 13rem;
    }

    .bottom-options {
      @include fts11;
      position: absolute;
      left: 0;
      top: 7rem;
      width: 46rem;
      color: $autocomplete-color;
    }

    .bottom-info {
      @include fts11;
      position: absolute;
      left: 1rem;
      top: 7rem;
      width: 46rem;
      color: $autocomplete-color;

      i {
        font-size: 6rem;
        position: absolute;
        right: 0;
        bottom: -1rem;
        color: $scrollbar-bg;
      }
    }

    .btn-publish {
      position: absolute;
      right: 0;
      top: 1.5rem;
      width: 10rem;
    }

    .new-post-ref {
      position: absolute;
      left: 0;
      top: 1.5rem;
      width: 30rem;
    }

    .new-post-ref .form-control,
    .bottom-options .form-control {
      padding: 0.5rem 1rem;
      line-height: 3.5rem;
      @include fts13;
      color: $input-color;
      background-color: $white-color;
      border: none;
      border-bottom: 1px solid $autocomplete-border;
      width: 100%;
    }
  }

  img.avatar {
    width: 3.5rem;
    float: left;
    display: block;
    margin-right: 1rem;
    border-radius: 50%;
    overflow: hidden;
  }

  .bpm-post-content-edit {

    .btn-publish,
    .btn-cancel {
      width: auto;
    }

    .bottom-actions {
      text-align: right;
      padding-top: 0.5rem;
    }

    textarea.form-control {
      padding: 0.5rem 1rem;
      margin-top: 0.2rem;
      @include fts13;
      color: $input-color;
      background-color: $white-color;
      border: none;
      width: 100%;
      height: 5rem;
      overflow-y: hidden;
    }
  }

  .bpm-board-user {
    position: relative;
    margin-bottom: 1rem;

    .bpm-user-data {
      float: left;
      width: 40rem;
    }

    .bpm-user-label {
      color: $main-color;
      @include fts14;
    }

    a {
      @include fts14;
    }

  }

  .bpm-post-open-day-header {
    @include fts15;
    padding: 0 1.5rem 1rem;
  }

  .bpm-post-content {
    @include fts13;
    padding: 0 1.5rem 1.5rem;
  }

  .bpm-post-content-edit {
    padding: 0 1.5rem 1.5rem;
    display: none;
  }

  .bpm-board-object {
    padding: 0 1.5rem 1.5rem;

    .object-image {
      display: block;
      width: 100%;
      height: 35rem;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border: 1px solid $autocomplete-border;

      .object-image-info {
        width: 100%;
        height: 10rem;
        background: url("../gfx/bg-white-transparent.png") repeat;
        margin-top: 12.5rem;
        padding: 2rem;
        font-size: 2rem;
        text-align: center;
      }
    }

    .object-content {
      color: $gray-color;
      @include fts12;
      padding-top: 1.5rem;

      .object-options-area {
        clear: both;
      }

      .object-option {
        background: none repeat scroll 0 0 $white-color;
        border: 1px solid $border-color;
        float: left;
        margin-bottom: 0.5rem;
        margin-right: 0.5rem;
        padding: 0.3rem 0.6rem;
        @include fts12;
      }

      .object-price {
        color: $success-color;
      }

      .object-price-old {
        color: $main-color;
        text-decoration: line-through;
      }

      h3 {
        margin-top: 0;
        margin-bottom: 0.8rem;
        @include fts15;
      }

      h4 {
        margin-top: 0;
        margin-bottom: 1rem;
        @include fts14;
        font-weight: 400;
      }

    }
  }

  .bpm-board-action {
    padding: 1rem 1.5rem;
    border-top: 1px solid $autocomplete-border;
  }

  .bpm-board-comments {
    background: $table-bg;
    padding: 0 1.5rem 1.5rem;
    border-top: 1px solid $border-color;
  }

  .bpm-board-recommend-summary {
    padding: 1rem 0;
    border-bottom: 1px solid $autocomplete-border;
    font-size: 13px;
  }

  .bpm-board-comment-show {
    margin-bottom: 1.5rem;
    display: block;
    @include fts13;

    &.comment-opened {
      display: none;
    }
  }

  .bpm-board-comments-section {
    padding: 1rem 0;
  }

  .bpm-board-comments-list {
    display: none;

    &.comment-opened {
      display: block;
    }
  }

  .bpm-board-comment {
    position: relative;
    margin-bottom: 1rem;

    .bpm-comment-content {
      float: left;
      width: 47rem;

      .form-control {
        padding: 0.5rem 1rem;
        margin-top: 0.2rem;
        line-height: 3.5rem;
        @include fts13;
        color: $input-color;
        background-color: $white-color;
      }
    }

    .bpm-comment-text {
      @include fts13;
      padding-bottom: 0.3rem;
    }

    .bpm-comment-info {
      color: $autocomplete-color;
      @include fts12;
    }

    &.bpm-board-comment-answer {
      padding-left: 4.5rem;
    }
  }

  .bpm-board-comment-answer .bpm-comment-content {
    float: left;
    width: 42.5rem;
  }

  .bpm-post-options {

    .bpm-hoverdown-content,
    .bpm-dropdown-content {
      @include reset;
    }
  }

  .bpm-autocomplete {

    .bpm-autocomplete-suggestion.richrow span.autocomplete-label {
      @include fts12;
      line-height: 1.8rem;
    }

    .bpm-dropdown-menu {
      width: 40rem;
    }
  }

  .fa-clock-o {
    margin-right: 0.3rem;
  }

  .bpm-board-filter-wrapper {
    display: none;
    padding: 1.5rem;
  }

  .bpm-board-more {
    text-align: center;
    padding: 1rem;
    background: $white-color;
    border: 1px solid $border-color;
    margin-bottom: 1rem;
    display: block;
  }
}

.bpm-board-left {
  width: 55rem;
  position: absolute;
  left: 0;
  top: 0;
}

.bpm-board-right {
  width: 18rem;
  right: 0;
  top: 6rem;
  position: absolute;
}

.bpm-dialog-body .bpm-board .bpm-board-new-post {

  .bottom-actions {
    display: block;
    height: 5rem;
  }

  .bottom-info {
    @include fts11;
    position: absolute;
    left: 1rem;
    top: 2.5rem;
    width: 36rem;
    color: $autocomplete-color;
  }
}

.bpm-post-options {

  .list-group {
    @include reset;
    border: none;

    .list-group-item {
      padding: 0.8rem 1rem;
      margin-bottom: -0.1rem;
      border: none;
      border-bottom: 1px solid $border-color;

      a {
        @include fts13;
        color: $main-color;
      }
    }
  }
}

@media(max-width: 780px) {

  .bpm-board {
    display: block;
    width: 100%;
    position: static;

    .bpm-board-left {
      width: 100%;
      position: static;
      display: block;
    }

    .bpm-board-header {
      position: static;
      padding: 1rem 1rem 0;

      .text-primary {
        @include fts18;
        color: $board-text-color;
      }

      .text-danger.fts16 {
        display: none;
      }

      .bpm-post-options {
        top: 1rem;
        right: 1rem;
      }

      .bpm-user-date {
        @include fts10;
        color: $board-text-color-light;

        i {
          display: none;
        }
      }
    }

    .bpm-board-post .bpm-board-header {
      position: relative;
    }

    .bpm-box-header {
      height: auto !important;
    }

    .bpm-board-user {
      position: static;
      display: flex;
      margin-bottom: 0;

      .bpm-user-data {
        width: 80%;
      }

      a {
        @include fts12;
        font-weight: 500;
        color: $board-primary-color;
      }
    }

    .bpm-board-new-post {

      .bpm-new-post-content {
        width: 100%;
        @include fts10;
        font-weight: 500;
        color: $board-text-color;
        padding-bottom: 1rem;
      }

      .bpm-new-post-tabs {
        padding-top: 1rem;
      }

      .bottom-info {
        width: 100%;
        left: 0.2rem;
      }

      .bottom-options {
        width: 100%;

        .form-control.bpm-datepicker,
        .form-control.bpm-timepicker {
          margin-bottom: 1.5rem;
        }
      }

      .bottom-actions {
        height: 26.5rem;
      }

      textarea.form-control {
        margin-top: 1rem;
        background: $white-color;
        border: 1px solid $menu-link-color !important;
        box-sizing: border-box;
        border-radius: 0.4rem !important;
        height: 10rem !important;
        @include fts12;
      }

      .bpm-dropdown-menu {
        width: auto !important;
        min-width: unset !important;
        left: 0 !important;
        right: unset !important;
      }

      .new-post-ref .form-control,
      .bottom-options .form-control {
        background: $white-color;
        border: 1px solid $menu-link-color !important;
        border-radius: 0.4rem !important;
        @include fts12;
        padding: 1rem 1.5rem;
      }

      .new-post-ref {
        width: 100%;
      }

      .btn-publish {
        bottom: 0;
        top: unset;
        width: 15rem;
        background: $board-btn-bg;
        border-color: $board-btn-bg;
        border-radius: 0.4rem !important;
        @include fts14;
      }
    }

    .bpm-board-quick-info,
    .bpm-board-quick-help {
      display: none;
    }

    .bpm-board-comment {

      .bpm-comment-content {
        width: 90%;

        .form-control::placeholder {
          color: transparent;
        }

        label {
          font-weight: 500;
          @include fts12;
          color: $board-text-color;
        }
      }

    }

    .bpm-board-post {
      border: 1px solid $board-post-border;
    }

    img.avatar {
      width: 4rem;
      height: 4rem;
    }

    .bpm-post-content {
      padding: 1rem;
      @include fts12;
      font-weight: 500;
      color: $board-text-color;
      letter-spacing: 0.03px;
    }

    .bpm-board-object {
      padding: 0 1rem;

      .object-image {
        height: 16.4rem;

        .object-image-info {
          height: 12rem;
          margin-top: 2rem;
        }
      }

      .object-content {
        padding-top: 0;

        h3 {
          margin: 1rem 0 0.4rem;
          @include fts12;
          font-weight: 500;
          color: $board-primary-color;
        }

        h4 {
          font-weight: 500;
          font-size: 10px;
          line-height: 1.5rem;
          letter-spacing: 0.03px;
          color: $board-text-color;
        }

        .object-price {
          color: $board-success-color;
        }

        .object-option {
          border: 1px solid $board-post-border;
          padding: 0.4rem 1rem;
          font-weight: 500;
          @include fts10;
          letter-spacing: 0.03px;
          color: $board-text-color;
        }
      }
    }

    .bpm-board-action {
      border-top: none;
      padding: 1rem;

      .btn-sm {
        @include fts10;
        border-radius: 0.4rem !important;
      }
    }

    .btn-danger {
      background-color: $board-danger-color;
    }

    .btn-primary {
      background-color: $board-btn-bg;
    }

    .btn-success {
      background-color: $board-success-color;
      border-color: $board-success-color;
    }

    .bpm-board-comment-show {
      @include fts12;
      font-weight: 500;
      padding: 1rem;
      background-color: $fc-content-bg;
      color: $board-primary-color;
      border-bottom: 1px solid $board-post-border;
    }

    .bpm-board-no-comment {
      width: 100%;
      display: block;
      @include fts12;
      font-weight: 500;
      padding: 1rem;
      background-color: $fc-content-bg;
      color: $board-primary-color;
      border-bottom: 1px solid $board-post-border;

    }

    .bpm-board-comments {
      padding: 0;
      background: $white-color;
      border-top: 1px solid $board-post-border;
    }

    .bpm-board-comments-section {
      padding: 0;
    }

    .bpm-board-recommend-summary {
      padding: 1rem;
      @include fts12;
      font-weight: 500;
      color: $board-text-color;
      border-bottom: 1px solid $board-post-border;
    }

    .bpm-board-comments-list img {
      width: 3.3rem;
      height: 3.3rem;
    }

    .bpm-post-content-edit {

      .btn-publish {
        background: $board-btn-bg;
        border-color: $board-btn-bg;
        border-radius: 0.4rem !important;
        @include fts14;
        font-weight: 500;
        padding: 0.8rem;
        width: 45%;
      }

      .bottom-actions {

        border-radius: 0.4rem !important;

        .btn-post-edit-cancel {
          background-color: transparent !important;
          border-color: transparent;
          color: $board-text-color-light !important;
          @include fts14;
          font-weight: 500;
          width: 45%;
          padding: 0.8rem;
        }
      }
    }

    .text-danger {
      color: $board-danger-color;
    }

    .text-success {
      color: $board-success-color;
    }

  }

  .bpm-board-add-post-title {
    @include fts14;
    margin-top: 1rem;
    line-height: 2rem;
    color: $board-text-color;
  }

  #bpm-new-post-announcement-button {
    width: 45%;
    background: $board-btn-bg;
    color: $white-color;
    border-radius: 0.4rem !important;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    margin-right: 0.8rem;
    @include fts12;
  }

  #bpm-new-post-open-day-button {
    width: 45%;
    background: $board-success-color;
    color: $white-color;
    border-radius: 0.4rem !important;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    @include fts12;
  }

  .bpm-board-comment-show i {
    display: none;
  }

  .bpm-board-comments-list {
    background-color: $fc-content-bg;
    border-bottom: 1px solid $board-post-border;
  }

  .bpm-comment-content .form-control {
    border-radius: 0.4rem !important;
  }

  .bpm-board-comment.add-comment-section {
    display: flex;
    padding: 1rem;
  }

  .bpm-board-comments-list {

    .bpm-board-comment {
      padding: 1rem;
      display: flex;

      .bpm-comment-info {
        @include fts10;
        color: $board-text-color-light;

        i {
          display: none;
        }
      }
    }

    .bpm-comment-content {

      .text-primary {
        @include fts12;
        font-weight: 500;
        color: $board-primary-color;
        padding-right: 0.5rem;
      }

      .bpm-comment-text {
        @include fts12;
        font-weight: 500;
        color: $board-text-color;
      }
    }
  }
}
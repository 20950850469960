@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

@import url(https://fonts.googleapis.com/css?family=Rubik&subset=latin,latin-ext);

html {
  font-size: 10px;
}

html, body {
  height: 100%;
}

body {
  background: $main-bg-color;
  //font-family: $main-font, Helvetica, Arial, sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;
  color: $main-color;
  -webkit-animation-delay: 0.1s;
  -webkit-animation-name: fontfix;
  -webkit-animation-duration: 0.1s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;

  &.theme-prime,
  &.theme-primepro,
  &.theme-gethome {
    font-size: 12px;
  }
}

@-webkit-keyframes fontfix {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

.desktop-hidden {
  display: none !important;
}

.mobile-hidden {
  display: none !important;
}

.mobile-detected .hide-in-mobile {
  display: none !important;
}

.mobile-hide {
  display: none !important;
}

.theme-prime .hidemls,
.theme-primepro .hidemls,
.theme-gethome .hidemls {
  display: none !important;
}

.theme-prime .row,
.theme-primepro .row,
.theme-gethome .row {
  border-spacing: 0;
}

@media (min-width: 780px) {

  .hide-in-desktop {
    display: none !important;
  }
}

@media (max-width: 780px) {

  .hide-in-mobile {
    display: none !important;
  }
}
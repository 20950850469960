@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";


.theme-gethome .bpm-page-application .row {
  margin: 0;
}

.theme-gethome .bpm-grid.bpm-rowview {
  margin: 0;
}

.bpm-grid.bpm-rowview .bpm-grid-row.bpm-grid-gethome {
  margin: 1.2rem 0;
  border: 1px solid #EEE8F6;

  .application-heading {

    h3 {
      color: $gethome-primary-color;
      font-size: 2rem;
      font-weight: 500;
      line-height: 3rem;
      margin: 0 0 1rem 0;

      i {
        position: static;
        color: $gethome-primary-color;
        font-size: 2rem;
        padding-right: 1.2rem;

        &.fa-phone {
          color: $board-success-color;
        }
      }

      img {
        height: 2rem;
        margin-bottom: 0.2rem;
      }
    }

    .application-subheading {

      display: flex;

      h4 {
        font-size: 1.4rem;
        line-height: 3.2rem;
        margin: 0 !important;

        &:first-child {
          padding-right: 4rem;
        }

        span {
          font-weight: bold;
        }
      }

    }

  }

  .bpm-grid-application-title i,
  .bpm-grid-toggler-title i {
    color: $gethome-secondary-color;
    position: static;
  }

  .bpm-grid-application-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    width: 100%;

    .application-buttons {
      display: flex;
      align-items: center;
    }


    .application-print-button {
      display: block;
      margin-right: 4rem;
    }
  }

  .bpm-grid-application-content {
    font-size: 1.4rem;
    line-height: 2.1rem;

    .bpm-collapse-content {
      padding: 1rem 2rem 2rem 2rem;

      .application-offer-data {
        display: flex;

        i {
          font-size: 1.6rem;
          line-height: 2.1rem;
          padding-right: 1.4rem;
          color: $gethome-secondary-color;
        }

        h5 {
          margin: 0;
          line-height: 2.1rem;
          font-weight: bold;
        }

        a {
          margin-bottom: 2.4rem;
          color: $gethome-accent-color;
          display: block;
        }
      }

      .application-offer-content {

        h5 {
          margin: 1.6rem 0 0.8rem;
          line-height: 2.1rem;
          font-weight: bold;
        }

        .application-content {
          border: 1px solid $gethome-gray3-color;
          border-radius: 4px;
          background-color: $fc-content-bg;
          color: $gethome-dark2-color;
          padding: 1rem 1.5rem;
          font-size: 1.4rem;
          line-height: 2.1rem;
        }
      }

      .application-footer {

        display: flex;
        justify-content: space-between;

        .application-footer-content {
          color: $gethome-primary-color;
          font-size: 1.8rem;
        }

      }
    }

  }
}

.theme-gethome {

  .side-bar-search-header {
    h2 {
      font-size: 1.8rem;
      line-height: 2.7rem;
    }
  }

  .side-bar-search-buttons {
    top: 1.8rem;
  }

  .side-bar-search-content {

    .form-group {
      margin-bottom: 2.2rem;
    }

    .form-control {
      font-size: 1.4rem;
    }

    .control-label label {
      text-transform: none;
      font-size: 1.2rem;
      margin-bottom: 0.8rem;
    }

    .input-group-addon {
      color: $gethome-turquise-primary;
    }
  }

  .bpm-status.status-gh-131,
  .bpm-status.status-gh-132 {
    background-color: $gethome-turquise-primary;
    border-color: $gethome-turquise-primary;
  }

  .bpm-status.status-gh-133,
  .bpm-status.status-gh-134,
  .bpm-status.status-gh-135 {
    background-color: $gethome-accent-color;
    border-color: $gethome-accent-color;
  }

  .date-input-gh {

    .col-sm-6 {

      &:first-child {
        padding-right: 0.5rem;
      }

      &:last-child {
        padding-left: 0.5rem;
      }
    }

    .col-sm-5 {

      &:first-child {
        padding-right: 0;
      }

      &:last-child {
        padding-left: 0;
      }

      .form-control {
        padding-left: 0.2rem;
      }

      .bpm-dropdown-menu {
        top: -25rem;
      }
    }

    .col-sm-2 {
      text-align: center;
      padding: 1rem 0 0 0;
    }
  }

}
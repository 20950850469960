@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-profile-mobile {
  .bpm-box-header {
    height: unset !important;

    .buttons {
      position: relative;
      top: unset;
      right: unset;
    }
  }

  .bpm-activity {
    height: unset;
    padding: 2rem 0 2rem 0;
  }

  .bpm-grid-empty {
    padding-top: unset;
  }

  .profile-header {
    * {
      margin: 0 !important;
      padding: 0 !important;
    }

    display: flex;
    gap: 0.8rem;

    a.back-btn {
      display: none;
    }

    .profile-image {
      min-width: 11.4rem;
      min-height: 11.4rem;
      max-width: 11.4rem;
      max-height: 11.4rem;

      img {
        width: 11.4rem;
        border-radius: 4px;
      }
    }

    .profile-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-basis: 100%;
      overflow: hidden;
    }

    .profile-name {
      line-height: 2.9rem;
      font-size: 1.9rem;
      letter-spacing: 0.15px;
      white-space: nowrap;
    }

    .profile-company {
      line-height: 1.9rem;
      font-size: 1.3rem;
      white-space: nowrap;
    }

    .profile-email {
      line-height: 1.3rem;
      font-size: 1.2rem;
      white-space: nowrap;
    }

    .profile-buttons {
      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 3.8rem;
        width: 100%;
        font-size: 1.4rem;
        background-color: $board-btn-bg;
        padding: 0 1.4rem 0 1.7rem !important;
      }
    }
  }

  .profile-content {
    .buttons {
      display: flex;
      gap: 0.8rem;
      width: 100%;
      margin-bottom: 2rem;

      * {
        margin: 0;
      }

      .btn-group {
        width: 50%;

        a, button {
          width: 100%;

          i {
            display: none;
          }
        }
      }
    }
  }
}
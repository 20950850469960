@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-grid-application,
.bpm-grid-toggler {
  padding: 0 !important;
  height: auto !important;
  border-radius: 0.1rem !important;
}

.bpm-grid-application-title,
.bpm-grid-toggler-title {
  padding: 2rem 8rem 1rem 4rem;
  position: relative;
  cursor: pointer;

  i {
    color: $investment-user-icon;
    line-height: inherit;
    font-size: 3rem;
    font-weight: bold;
    position: absolute;
    top: 3rem;
    right: 4rem;

    &.fa-angle-down {
      display: inline;
    }

    &.fa-angle-up {
      display: none;
    }
  }

  &.active i.fa-angle-down {
    display: none;
  }

  &.active i.fa-angle-up {
    display: inline;
  }

  h4 {
    font-size: 2.2rem;
    color: $investment-heading-color;
    line-height: 3.2rem;
    margin: 0 0 0.5rem 0 !important;
    font-weight: 500;
  }

  .additional-info {
    @include fts15;
    color: $prime-dark2-color;
    font-weight: 400;
    line-height: 3.2rem;
  }
}

.bpm-grid-application-status-cell {
  padding-top: 1.8rem;
  text-align: right;
}

.bpm-grid-toggler-title .bpm-toggler-content {
  padding: 1rem 8rem 2rem 4rem;
}

.bpm-grid-application-content {

  .bpm-collapse-content {
    padding: 1rem 8rem 2rem 4rem;
  }

  .application-investment-picture {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .application-price-wrapper {
    width: 100%;
    padding: 0.5rem;
    text-align: center;
    @include fts14;
    border: 1px solid $application-price-border;
  }

  .application-price-per-meter {
    color: $investment-span-color;
    @include fts13;
    margin-left: 0.5rem;
  }

  .application-content {
    background: $main-bg-color;
    padding: 1.5rem 2.5rem;
    @include fts16;
    margin-bottom: 1.5rem;
  }

  .application-client {
    background: $application-client-bg;
    padding: 1rem 2.5rem 1rem 5rem;
    @include fts14;
    margin-bottom: 1.5rem;
    position: relative;

    .fa-warning {
      color: $alert-color;
      @include fts16;
      margin-right: 1rem;
      position: absolute;
      left: 2rem;
      top: 1.2rem;
    }

    .application-client-more {
      margin-left: 1rem;
    }

  }
}

.application-client-difference .bpm-collapse-content {
  padding: 1.5rem 0 0 0;
}

.application-action-button {
  text-align: left;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  margin-left: 2.5rem;
}

.application-info-user {
  text-align: left;
  @include fts14;
  margin-left: 2.5rem;
  margin-bottom: 0.5rem;

  span {
    color: $investment-span-color;
  }

  i.fa-check {
    color: $success-color;
    margin-right: 1rem;
  }

  i.fa-times {
    color: $alert-color;
    margin-right: 1rem;
    margin-left: 0.2rem;
  }
}
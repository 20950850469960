@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.theme-prime,
.theme-primepro {
  .bpm-page-application {

    position: relative;

    .row {
      margin-left: 0;
      margin-right: 0;

      h3 {
        font-size: 3.3rem;
        letter-spacing: 0.25px;
        color: $prime-dark-color;
        margin: 0 0 1rem;
      }

    }

    p {
      font-size: 1.5rem;
      color: $dark2;
      margin-bottom: 1.6rem;
    }

    .bpm-listview-controls {
      position: static;
      margin-bottom: 0;
    }

    .bpm-listview-filters {
      top: 2rem;
      right: 2.4rem;

      .btn {
        background-color: $white-color;
        color: $prime-primary-color;
        font-weight: 500;

        &:hover {
          background-color: $prime-primary-color;
          color: $white-color;
        }
      }
    }

    .bpm-listview-addons {
      display: none;
    }

    .bpm-listview-addons.bpm-listview-addons-undertable {
      display: inline-block;
    }

    .bpm-grid.bpm-tableview {

      .bpm-grid-head .bpm-grid-cell {
        padding-top: 0;
        padding-bottom: 0;
    }

      .bpm-grid-actions-icons {
        display: flex;
        align-items: center;

        a.btn-success {
          text-transform: unset;
          height: auto;
        }
      }

      .bpm-grid-cell {

        h3, h4 {
          font-size: 1.5rem;
          font-weight: 500;
          margin: 0;
        }

        h5 {
          font-size: 1.2rem;
        }

        i {
          color: $prime-dark2-color;
        }

        .options-area {
          padding-top: 0;
        }

        .option {
          font-size: 1.2rem;
          color: $prime-text-secondary;
        }
      }
    }

  }

  .mobile-content .bpm-page-application .list-controls-mobile {
    position: static;
  }


}
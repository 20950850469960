@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

*:focus {
  outline: none;
}

i.icon {
  font-size: 14px;
}

.top-ad {
  display: none;
}

.remove-bottom {
  margin-bottom: 0 !important;
}

.half-bottom {
  margin-bottom: 10px !important;
}

.add-bottom {
  margin-bottom: 20px !important;
}

.blue {
  @include themify($themes) {
    color: themed('blueColor');
  }
}

.theme-gethome {
  .blue {
    color: #37474F;
  }
}

.green {
  color: #41a40f;
}

.red {
  color: #d02121;
}

.orange {
  color: #f2a200;
}

.grey {
  color: #999;
}

.white, a.white {
  color: #fff !important;
}

a.bodycolor {
  color: #444 !important;
}

a:hover.bodycolor {
  color: #444 !important;
}

.ds-lg {
  box-shadow: 4px 5px 25px rgba(0, 0, 0, 0.20);
}

.ds-lg2 {
  box-shadow: 7px 7px 25px rgba(0, 0, 0, 0.20);
}

.ds-lg3 {
  box-shadow: 7px 7px 25px rgba(0, 0, 0, 0.08);
}

.ds-md {
  box-shadow: 5px 6px 12px rgba(0, 0, 0, 0.05);
}

.ds-md2 {
  box-shadow: 4px 5px 12px rgba(0, 0, 0, 0.03);
}

.ds-md3 {
  box-shadow: 4px 5px 15px rgba(0, 0, 0, 0.15);
}

.ds-sm {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
}

.w100p {
  width: 100% !important;
}

.w40p {
  width: 40% !important;
}

.w45p {
  width: 45% !important;
}

.w50p {
  width: 48% !important;
}

.w66p {
  width: 66% !important;
}

.w60p {
  width: 60% !important;
}

.w35p {
  width: 35% !important;
}

.w33p {
  width: 32.3% !important;
}

.w30p {
  width: 30% !important;
}

.w25p {
  width: 25% !important;
}

.w20p {
  width: 20% !important;
}

.w15p {
  width: 15% !important;
}

.w10p {
  width: 10% !important;
}

.w5p {
  width: 5% !important;
}

.w10x {
  width: 10px !important;
}

.w20x {
  width: 20px !important;
}

.w30x {
  width: 30px !important;
}

.w40x {
  width: 40px !important;
}

.w50x {
  width: 50px !important;
}

.w100x {
  width: 100px !important;
}

.w150x {
  width: 150px !important;
}

.w200x {
  width: 200px !important;
}

.w300x {
  width: 300px !important;
}

.w400x {
  width: 400px !important;
}

.w500x {
  width: 500px !important;
}

.w600x {
  width: 600px !important;
}

.mh100x {
  min-height: 100px !important;
}

.mh200x {
  min-height: 200px !important;
}

.mh300x {
  min-height: 300px !important;
}

.mh350x {
  min-height: 350px !important;
}

.mh400x {
  min-height: 400px !important;
}

.mh450x {
  min-height: 450px !important;
}

.mh500x {
  min-height: 500px !important;
}

.hidd {
  display: none;
}

.dn {
  display: none;
}

.db {
  display: block !important;
}

.dib {
  display: inline-block !important;
}

.di {
  display: inline !important;
}

.fl {
  float: left !important;
}

.fr {
  float: right !important;
}

.cl {
  clear: left !important;
}

.cr {
  clear: right !important;
}

.cb {
  clear: both !important;
}

.pa {
  position: absolute !important;
}

.pr {
  position: relative !important;
}

.pf {
  position: fixed;
}

.op0 {
  opacity: 0;
}

.op100 {
  opacity: 1 !important;
  filter: Alpha(Opacity=100) !important;
}

.tar {
  text-align: right !important;
}

.tal {
  text-align: left !important;
}

.tac {
  text-align: center !important;
}

.taj {
  text-align: justify !important;
}

.vat {
  vertical-align: top !important;
}

.vh {
  visibility: hidden;
}

.oh {
  overflow: hidden;
}

.ox {
  overflow-x: scroll;
}

.oy {
  overflow-y: scroll;
}

.pl0 {
  padding-left: 0px !important;
}

.pb0 {
  padding-bottom: 0px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.p0 {
  padding: 0px !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.ml0 {
  margin-left: 0px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.mr0 {
  margin-right: 0px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.nomargin {
  margin: 0 !important;
}

.ws-nw {
  white-space: nowrap;
}

.border-bottom {
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.cf:before,
.cf:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.cf:after {
  clear: both;
}

.btn-xxs {
  font-size: 11px !important;
}

.control-label {
  padding-top: 10px;

  @include themify($themes) {
    color: themed('controlLabelColor');
  }
}

.hint {
  color: #666;
}

.tip {
  color: #999;
  font-size: 11px;
  position: absolute;
  left: 10px;
  bottom: -10px;
}

.hint.header {
  color: #333;
  font-size: 12px;
}

.tip.important {
  @include themify($themes) {
    color: themed('alertColor');
  }
}

.tip-button {
  color: #999 !important;
  font-size: 12px !important;
  font-style: normal;
}

.text-bold {
  font-weight: 600 !important;
}

.fts11 {
  font-size: 11px !important;
}

.fts12 {
  font-size: 12px !important;
}

.fts13 {
  font-size: 13px !important;
}

.fts14 {
  font-size: 14px !important;
}

.fts15 {
  font-size: 15px !important;
}

.fts16 {
  font-size: 16px !important;
}

.fts18 {
  font-size: 18px !important;
}

#bpm-span-trigger {
  display: none;
}

.ajax-loading {
  background: url("../gfx/loading.gif") no-repeat center center;
}

.fancybox-title {
  opacity: 0.7;
  font: normal 12px/18px "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.text-violet {
  @include themify($themes) {
    color: themed('eventAcquisitionBg');
  }
}

.avpw_is_fullscreen #avpw_controls {
  top: 60px !important;
  z-index: 4000000 !important;
}

.link-wrap {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

div.phpdebugbar {
  z-index: 1000001 !important;
}


.fancybox-overlay {
  z-index: 50000001 !important;
}

.fancybox-opened {
  z-index: 50000002 !important;
}

h1, h2, h4, h5 {
  @include themify($themes) {
    color: themed('mainColor');
  }
}

h3 {
  @include themify($themes) {
    color: themed('linkColor');
  }
}

.theme-prime,
.theme-primepro,
.theme-gethome {

  .btn-red {
    background-color: $dot-inactive-color;
    color: $white-color;
    border-color: $dot-inactive-color;
  }

  .btn-green {
    background-color: $success-prime-color;
    color: $white-color;
    border-color: $success-prime-color;
  }

  .btn-red-text {
    background: none;
    color: $dot-inactive-color;
    border: none;
  }

  .btn-green-text {
    background: none;
    color: $success-prime-color;
    border: none;
  }

  .btn-full {
    color: $prime-primary-color !important;
    background-color: $white-color !important;
    border-color: $prime-primary-color !important;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $prime-primary-color !important;
      -webkit-filter: brightness(95%);
      filter: brightness(95%);
    }
  }

  .btn-empty {
    color: $investment-span-color !important;
    background: none !important;
    border: 1px solid $investment-span-color !important;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $investment-span-color !important;
      -webkit-filter: brightness(95%);
      filter: brightness(95%);
    }
  }
}

.theme-gethome {

  .btn-full {
    color: $gethome-primary-color !important;
    background-color: $white-color !important;
    border-color: $gethome-primary-color !important;
  }

  .btn-empty {
    color: $gethome-icons-color !important;
    background: none !important;
    border: 1px solid $gethome-icons-color !important;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $gethome-icons-color !important;
    }
  }
}

@font-face {
  font-family: "pg-icons";
  src: url(../fonts/pg-icons.woff) format("woff"), url(../fonts/pg-icons.ttf) format("truetype"), url(../fonts/pg-icons.svg#pg-icons) format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "pg-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: auto;
}

.icon-star:before {
  content: "\e000";
}

.icon-star-o:before {
  content: "\e001";
}

.icon-heart:before {
  content: "\e002";
}

.icon-heart-o:before {
  content: "\e003";
}

.icon-bell:before {
  content: "\e004";
}

.icon-bell-o:before {
  content: "\e005";
}

.icon-check:before {
  content: "\e006";
}

.icon-delete:before {
  content: "\e007";
}

.icon-close:before {
  content: "\e007";
}

.icon-plus:before {
  content: "\e008";
}

.icon-minus:before {
  content: "\e009";
}

.icon-message-o:before {
  content: "\e00a";
}

.icon-message:before {
  content: "\e03b";
}

.icon-comment:before {
  content: "\e00b";
}

.icon-comment-o:before {
  content: "\e00c";
}

.icon-arrow-up:before {
  content: "\e00d";
}

.icon-arrow-right:before {
  content: "\e00e";
}

.icon-arrow-down:before {
  content: "\e00f";
}

.icon-arrow-left:before {
  content: "\e010";
}

.icon-home:before {
  content: "\e011";
}

.icon-trash-o:before {
  content: "\e012";
}

.icon-exclamation:before {
  content: "\e013";
}

.icon-question:before {
  content: "\e014";
}

.icon-users-n:before {
  content: "\e015";
}

.icon-search-n:before {
  content: "\e016";
}

.icon-search:before {
  content: "\e017";
}

.icon-info:before {
  content: "\e018";
}

.icon-counter:before {
  content: "\e019";
}

.icon-note:before {
  content: "\e01a";
}

.icon-calendar:before {
  content: "\e01b";
}

.icon-caret-left:before {
  content: "\e01c";
}

.icon-caret-right:before {
  content: "\e01d";
}

.icon-caret-down:before {
  content: "\e01e";
}

.icon-caret-up:before {
  content: "\e01f";
}

.icon-user:before {
  content: "\e020";
}

.icon-clock:before {
  content: "\e021";
}

.icon-users:before {
  content: "\e022";
}

.icon-phone:before {
  content: "\e023";
}

.icon-envelop:before {
  content: "\e024";
}

.icon-bookmark-o:before {
  content: "\e025";
}

.icon-user-o:before {
  content: "\e026";
}

.icon-pencil:before {
  content: "\e027";
}

.icon-dot:before {
  content: "\e028";
}

.icon-dot-o:before {
  content: "\e029";
}

.icon-import:before {
  content: "\e02a";
}

.icon-export:before {
  content: "\e02b";
}

.icon-box-o:before {
  content: "\e02f";
}

.icon-dart-down:before {
  content: "\e030";
}

.icon-plan:before {
  content: "\e02c";
}

.icon-flag:before {
  content: "\e02d";
}

.icon-trash:before {
  content: "\e032";
}

.icon-client:before {
  content: "\e033";
}

.icon-south:before {
  content: "\e02e";
}

.icon-east:before {
  content: "\e031";
}

.icon-west:before {
  content: "\e034";
}

.icon-north:before {
  content: "\e035";
}

.icon-no-offers:before {
  content: "\e036";
}

.icon-warning:before {
  content: "\e037";
}

.icon-client-potential:before {
  content: "\e038";
}

.icon-client-proceeded:before {
  content: "\e039";
}

.icon-client-closed:before {
  content: "\e03a";
}

.icon-chat:before {
  content: "\e03c";
}

.icon-lifebuoy:before {
  content: "\e03d";
}

.icon-envelop-open:before {
  content: "\e03e";
}

.icon-flame:before {
  content: "\e03f";
}

.icon-refresh:before {
  content: "\e040";
}

.icon-key:before {
  content: "\e041";
}

.icon-lock:before {
  content: "\e042";
}

.icon-dollar:before {
  content: "\e043";
}

.icon-envelop-open-n:before {
  content: "\e044";
}

.icon-offers:before {
  content: "\e045";
}

.icon-reload:before {
  content: "\e046";
}

.icon-map-marker:before {
  content: "\e047";
}

.icon-undo:before {
  content: "\e048";
}

.icon-offer:before {
  content: "\e049";
}

.icon-settings:before {
  content: "\e04a";
}

.icon-logout:before {
  content: "\e04b";
}

.icon-notifications:before {
  content: "\e04c";
}

.icon-dir:before {
  content: "\e04d";
}

.icon-whats-new:before {
  content: "\e04e";
}

.icon-key-o:before {
  content: "\e04f";
}

.icon-offer-alias:before {
  content: "\e050";
}

.icon-offer-publish:before {
  content: "\e051";
}

.icon-settings-bars:before {
  content: "\e052";
}

.icon-user-circle:before {
  content: "\e053";
}

.icon-earth:before {
  content: "\e054";
}

.icon-chart:before {
  content: "\e055";
}

.icon-ellipsis-v:before {
  content: "\e056";
}

.icon-eye:before {
  content: "\e057";
}

.icon-print:before {
  content: "\e058";
}

.icon-box:before {
  content: "\e059";
}

.icon-box-remove:before {
  content: "\e05a";
}

.icon-box-add:before {
  content: "\e05b";
}

.icon-introduce-offer:before {
  content: "\e05c";
}

.icon-verify-mobile:before {
  content: "\e05d";
}

.icon-pencil-big:before {
  content: "\e05e";
}

.icon-thumbs-o-up:before {
  content: "\e05f";
}

.icon-thumbs-o-down:before {
  content: "\e060";
}

.icon-bag:before {
  content: "\e061";
}

.icon-shopping-cart:before {
  content: "\e062";
}

.icon-notification:before {
  content: "\e063";
}

.icon-cost-per-lead:before {
  content: "\e064";
}

.icon-dart-up:before {
  content: "\e065";
}

.icon-call:before {
  content: "\e066";
}

.icon-missed-call:before {
  content: "\e067";
}

.icon-people:before {
  content: "\e068";
}

.icon-call-o:before {
  content: "\e069";
}

.icon-marker-o:before {
  content: "\e070";
}

.icon-thumbs-up-down:before {
  content: "\e071";
}

.material-symbols-rounded {
  font-variation-settings: 'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-calendar-controls .bpm-dropdown-menu {
  width: 20rem;
}

.fc-content {
  background: $fc-content-bg;
}

.fc-header .fc-button {
  border-radius: 0;
  vertical-align: middle;
  margin-bottom: 0;
  margin-right: 0.3rem;

  &:last-child {
    margin-right: 0;
  }
}

.fc-header-title h2 {
  text-align: center;
  padding-right: 0 !important;
}

.fc-header-right .fc-button.fc-state-active {
  @include themify($themes) {
    background: themed('fcStateActiveBg') !important;
    border-color: themed('fcStateActiveBg');
  }
}

.fc-state-default {
  text-shadow: none;
  box-shadow: none;
}

.fc-button {
  height: auto;
}

.fc-header h2 {
  font-size: 2.2rem;
}

.fc-event-vert .fc-event-bg {
  background: none;
}

.fc-event-vert:hover .fc-event-bg {
  background: $white-color;
  opacity: 0.1;
}

/* event types */

.fc-event-meeting {
  @include themify($themes) {
    color: themed('eventTextColor');
    background-color: themed('eventMeetingBg');
    border-color: themed('eventMeetingBg');
  }
}

.fc-event-acquisition {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('eventAcquisitionBg');
    border-color: themed('eventAcquisitionBg');
  }
}

.fc-event-contact {
  @include themify($themes) {
    color: themed('eventTextColor');
    background-color: themed('eventContactBg');
    border-color: themed('eventContactBg');
  }
}

.fc-event-presentation {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('eventPresentationBg');
    border-color: themed('eventPresentationBg');
  }
}

.fc-event-task {
  @include themify($themes) {
    color: themed('eventTextColor');
    background-color: themed('eventTaskBg');
    border-color: themed('eventTaskBg');
  }
}

.fc-event-vacation {
  @include themify($themes) {
    color: themed('eventTextColor');
    background-color: themed('eventVacationBg');
    border-color: themed('eventVacationBg');
  }
}

.fc-event-event {
  @include themify($themes) {
    color: themed('eventEventText');
    background-color: themed('eventEventBg');
    border-color: themed('eventEventBorder');
  }
}

.fc-event-text-meeting {
  @include themify($themes) {
    color: themed('eventMeetingTextColor');
  }
}

.fc-event-text-acquisition {
  @include themify($themes) {
    color: themed('eventAcquisitionBg');
  }
}

.fc-event-text-contact {
  @include themify($themes) {
    color: themed('eventContactText')
  }
}

.fc-event-text-presentation {
  @include themify($themes) {
    color: themed('eventPresentationBg');
  }
}

.fc-event-text-task {
  @include themify($themes) {
    color: themed('eventTaskText');
  }
}

.fc-event-text-vacation {
  @include themify($themes) {
    color: themed('eventVacationText');
  }
}

.fc-event-text-event {
  @include themify($themes) {
    color: themed('eventTextEvent');
  }
}

.fc-event-private {
  @include status-with-border($white-color, $menu-link-color, $main-color);
  border-style: dashed;
}

/* event inner */

.fc-status-checkbox {
  margin-right: 0;
  margin-bottom: 0 !important;
  padding-top: 0.1rem;
  z-index: 100;

  span {
    display: block;
    min-height: 1rem;
    position: absolute;
    left: 0.1rem;
    top: 0.2rem;
  }
}

.fc-status-checkbox input[type="checkbox"] + span:before,
.fc-status-checkbox input[type="radio"] + span:before {
  background-color: $white-color;
  @include fts11;
  line-height: 1;
  position: absolute;
  left: 0;
  padding: 0;
  width: 1.3rem;
  height: 1.3rem;
}


.fc-status-checkbox input[type="checkbox"]:checked + span:before,
.fc-status-checkbox input[type="radio"]:checked + span:before {
  background-color: $white-color;
  border: 1px solid $border-color;
  @include themify($themes) {
    color: themed('successColor');
  }
}

.fc-event-inner {
  padding-left: 1.6rem;
}

.fc-done {
  text-decoration: line-through;
}

.fc-cancelled {
  opacity: 0.2;
}

.fc-cancelled .fc-status-checkbox {
  display: none;
}

.fc-private {
  border-bottom: 3px solid $white-color !important;
}

.fc-loader {
  background: url('../gfx/load3.gif') no-repeat center 10rem $white-color;
  opacity: 0.4;
  position: absolute;
  z-index: 10000;
}

.bpm-add-event-contact,
.bpm-add-send-mail-contact,
.bpm-change-client-contact {
  width: 35rem;
}

.bpm-add-send-mail-contact .bpm-dropdown-content {
  max-height: 51rem;
}

.bpm-add-event-contact,
.bpm-add-connect-offer-contact,
.bpm-add-send-mail-contact,
.bpm-add-print-offer-contact,
.bpm-add-reservation-contact {
  width: 35rem;
}

.bpm-add-connect-offer-contact .bpm-dropdown-content {
  max-height: 51rem;
}

.bpm-add-print-offer-contact .bpm-dropdown-content {
  max-height: 51rem;
}

.bpm-add-reservation-contact .bpm-dropdown-content {
  max-height: 51rem;
}

.bpm-add-event-contact .bpm-dropdown-content {
  max-height: 41rem;
}

.bpm-change-client-contact .bpm-dropdown-content {
  max-height: 41rem;
}

.relation-details {
  @include fts11;
  display: block;
}

.control-label-link {
  @include fts11;
  display: block;
}

.fc-agenda-allday .fc-day-content {
  padding-bottom: 3.5rem;
}

.fc-grid .fc-day-content {
  padding-bottom: 3.5rem;
}

.fc-allday-link {
  @include fts13;
}

#company160 {

  .fc-event-contact {
    @include themify($themes) {
      color: themed('dangerBtnColor');
      background-color: themed('dangerBtnBg');
      border-color: themed('dangerBtnBg');
    }
  }

  .fc-event-inside {
    color: $white-color;
    @include themify($themes) {
      background-color: themed('successColor');
      border-color: themed('successColor');
    }
  }

  .fc-event-text-contact {
    @include themify($themes) {
      color: themed('alertColor');
    }
  }

  .fc-event-text-inside {
    @include themify($themes) {
      color: themed('successColor');
    }
  }
}

.theme-prime,
.theme-primepro {

  .fc-header-left {
    padding-top: 0.5rem !important;

    .fc-button {
      border-radius: 1px !important;
      color: $prime-primary-color !important;
      border: 2px solid rgba(46, 91, 255, 0.08) !important;
      padding: 1rem;
      background: $white-color !important;
      margin-right: 0.7rem;
      @include fts13;
    }

    .fc-header-space {
      padding: 0;
    }
  }

  .fc-header-right .fc-button {
    border: 1px solid $prime-primary-color;
    border-radius: 4px !important;
    color: $prime-primary-color !important;
    background: none !important;
    margin-right: 0.5rem;

    &.fc-state-active {
      background: $fc-state-active-bg !important;
    }
  }

  .bpm-calendar-event {

    .col-sm-21 {
      width: 100%;
    }

    .bpm-tabs-menu {
      border: none;

      > a {
        border: none;
        @include fts16;
        color: $prime-calendar-tabs;
        padding: 0.1rem 0 0;
        margin-right: 3rem;
        font-weight: 500;
        border-bottom: 0.3rem solid $white-color;

        &.active {
          border: none;
          background: none;
          box-shadow: none;
          @include fts16;
          color: $investment-span-color;
          padding: 0.1rem 0 0;
          border-bottom: 0.3rem solid $investment-span-color;
        }
      }
    }

    .date-options {
      padding-top: 1rem;

      .bpm-checkbox {
        @include fts13;
        margin-right: 2rem;
      }
    }
  }

  #bpm-calendar-event-wrap {
    min-width: 60rem;
    max-width: 60rem;
  }

  .fc-agenda-slots th {
    @include fts13;
  }

  .fc-widget-header {
    @include fts14;
  }

  .fc-header-center {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}

.theme-prime .bpm-calendar-controls .btn-primary.btn-full,
.theme-primepro .bpm-calendar-controls .btn-primary.btn-full {
  background-color: $prime-primary-color !important;
  border: 1px solid $prime-primary-color !important;
  color: $white-color !important;
}


@media (max-width: 780px) {

  .fc-header-right {
    display: none;
  }

  .fc-header-left {
    text-align: center;
    width: 100%;
    display: block;
    margin-bottom: 2rem;
  }

  .fc-header-center {
    display: none;
  }

  .fc-header,
  .fc-header tbody,
  .fc-header tr {
    display: block;
    width: 100%;
  }


  .theme-prime, .theme-primepro {
    #bpm-calendar-event-wrap {
      min-width: 100%;
      max-width: 100%;
    }
  }

}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/themes/_themes";
@import "./resources/sass-test/build/sass/utils/_mixins";

section.content {
  margin: 0 0 0 23rem;
  padding: 7.4rem 2.4rem 2.4rem 2.4rem;
  min-height: 80rem;

  @include themify($themes) {
    background: themed('mainBgColor');
    font-family: themed('mainFont'), Helvetica, Arial, sans-serif;
    color: themed('mainColor');
  }

  h2:first-child {
    margin-top: 0;
    margin-bottom: 1.6rem;
    padding-right: 35rem;
  }

  .bpm-wizard h2:first-child {
    padding-right: 0;
  }

  .bpm-page-calendar h2:first-child {
    padding-right: 45rem;
  }
}

.theme-prime,
.theme-primepro,
.theme-gethome {
  section.content {
    padding: 6.7rem 0 2.4rem 0;

    .bpm-page-container {
        padding: 2rem 2.4rem 2.4rem 2.4rem;
        position: relative;
    }
  }

  #bpm-page {
    margin: 0 auto;
  }
}

section.menu-hidden {
  margin-left: 1rem;
}

article.reload {
  opacity: 1;
}

article.text {
  font-weight: 300;
}

section.content.mobile-content {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  padding-top: 7rem;
  margin-left: 0;
  width: 100%;
}

.theme-prime,
.theme-primepro,
.theme-gethome {
  section.content.mobile-content .bpm-page-calendar h2:first-child {
    padding-right: 1rem;
  }
}

@media (max-width: 780px) {
  section.content h2:first-child {
    padding-right: 0;
  }
}


@media (min-width: 992px) and (max-width: 1199px) {

  section.content {
    margin-left: 20.5rem;
    padding: 8rem 3rem;
  }

  section.menu-hidden {
    margin-left: 1rem;
  }

}

@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-grid.bpm-tileview.bpm-tileview-list.bpm-mobileview-list {
  margin: 0;

  .bpm-grid-content-top {
    opacity: 1;
    height: max-content;
    position: relative;


    .bpm-grid-actions {
      width: 22rem;
      right: 1.6rem;
      top: 1.6rem;
    }

    .bpm-grid-actions-icons {
      display: flex;
      justify-content: flex-end;
      gap: 5px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $mobileIconsBorder;
        border-radius: 0 !important;
        width: 3.2rem;
        height: 3.2rem;
        padding: 0;
        margin: 0;
      }

      .btn-group.btn-actions-icon {
        width: 3.2rem;
      }

      .btn-send-icon,
      .btn-print-icon {
        display: none;
      }

    }
  }

  .nowrapColumn {
    white-space: unset;
  }

  .bpm-grid-row {
    min-height: unset;
    max-height: unset;
    height: auto;
    margin: 0 0 1.6rem;
    width: 100%;
    border: 2px solid #EEE8F6;
    border-radius: 8px;
    box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.07);
    position: relative;

    &.bpm-selected {
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
      @include themify($themes) {
        border-color: themed('primaryColor');
      }
    }

    .bpm-grid-cell-table {
      width: 100%;
      padding: 0 1.6rem 1.6rem 1.6rem;
      color: $board-text-color-light;
      display: flex;
      flex-direction: column;

      .cell-with-status {
        .bpm-grid-cell-label,
        .bpm-grid-cell-value {
          margin-bottom: 1.6rem;
        }
      }

      .bpm-grid-status, .btn-group {
        position: relative;
        top: unset;
        bottom: unset;
        right: unset;
        left: unset;
        width: 100%;
        opacity: 1 !important;
      }

      .bpm-grid-status {
        display: flex;

        .bpm-grid-cell-label {
          margin: 0;
        }
      }

      .bpm-grid-status .btn-group {
        width: calc(100% - 10rem);
      }

      .bpm-grid-cell-label,
      .bpm-grid-cell-value {
        border: none;
        padding: 0 0 1.6rem;
        font-weight: 500;
      }

      .bpm-grid-cell-label {
        padding-right: 1rem;
      }

      .bpm-grid-cell-value {
        font-weight: bold;
        width: calc(100% - 10rem);

        &.bpm-status {
          font-weight: normal;
          border: 1px solid;
          border-radius: 4px !important;
          width: 100%;
          padding: 0.4rem;
        }

      }
    }

    .bpm-grid-hide-mobile {
      display: none;
    }
  }

  .bpm-grid-content-center {
    opacity: 1;
    top: 60px;
    bottom: unset;
    padding: 0;
    position: static;
    height: auto;
    margin-top: 60px;

    &.small-margin-mobile {
      margin-top: 20px;
    }
  }

  .bpm-grid-checkbox {
    top: 1.6rem;
    left: 1.6rem;

    input[type=checkbox] + span,
    input[type=checkbox] + span:before {
      width: 2.4rem;
      height: 2.4rem;
      padding: 0;
      line-height: 2.4rem;
      font-size: 1.6rem;
    }
  }

  .bpm-mobile-toggle-cells {
    font-size: 14px;
    text-align: center;
    padding: 0;

    .bpm-show-cells-mobilebtn {
      font-weight: 600 !important;

      &:focus,
      &:hover {
        @include themify($themes) {
          color: themed('linkColor');
        }
      }

      i {
        font-size: 18px;
        transition: 0.3s;
      }
    }

    &.active {
      i {
        transform: rotate(180deg);
      }
    }
  }
}

.bpm-mobile-fixed-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1019;
  width: 100%;

  .mobile-detected .bpm-dropdown-menu.pull-right,
  .mobile-detected .bpm-hoverdown-menu.pull-right {
    top: -9.7rem;
    right: unset !important;
    left: -25vw !important;
    width: 20rem !important;
    min-width: 20rem !important;

    .input-group {
      width: 18rem;

      &::before {
        content: "Idź do strony";
        font-weight: bold;
      }

      .input-group-btn {
        padding-left: 1rem;
        padding-top: 1.9rem;

        .btn {
          height: 4rem;
          width: 5.2rem;
        }
      }
    }

  }
}

.bpm-mobile-transform-container {
  transform: translateY(-60px);
}

.mobile-detected.bpm-grid-pagination {
  width: 100%;
  background-color: $white-color;
  text-align: center;
  border-top: 1px solid $mobileIconsBorder;
  padding: 1rem 0 0.8rem;

  .pager li {
    margin: 0 0.3rem;
  }
}

.bpm-listview-mobile-controls .btn-mass-action,
.bpm-listview-mobile-controls .view-config {
  display: none;
}

.bpm-wizard .wizard-step-body {
  .bpm-mobile-actions-container {
    .btn-mass-action {
      display: flex;
    }
  }
}

.bpm-mobile-actions-container {
  display: none;
  padding: 0.8rem 0.8rem 0.8rem 1.6rem;
  @include fts10;
  @include themify($themes) {
    background-color: themed('btnDarkBg');
  }

  .btn-mass-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btn {
    padding: 0.8rem 4rem;
  }
}

.bpm-mobile-chosenRows-container .bpm-grid-mass-uncheck {
  font-weight: bold;
  text-decoration: underline;
}

.estate-props-container .bpm-collapse-content .mass-action-content {
  margin: 1.9rem 2.4rem 0 2.4rem;

  a {
    display: flex;
    align-items: center;
  }
}

.options-layer__container .bpm-dropdown-content .row a {
  display: flex;
  align-items: center;
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-tags-area {
  padding: 0.8rem 0;
  display: none;
}

.bpm-tag {
  background: none repeat scroll 0 0 $white-color;
  border: 1px solid $border-color;
  float: left;
  margin-right: 0.5rem;
  padding: 1rem 1.6rem 0.8rem;
  min-height: 4rem;

  @include themify($themes) {
    border-color: themed('formControlBorder');
  }

  &.bpm-tag-map-location {
    display: none;
    clear: both;
  }
}

.bpm-tag-links {
  display: block;
}

.bpm-tag-link {
  @include fts13;
  margin-left: 0.3rem;
  margin-right: 0.1rem;
  display: inline-block;
}

.bpm-tag-delete {
  margin-left: 0.3rem;
  margin-right: 0.1rem;
}

.bpm-calendar-event .bpm-tags-area {
  padding: 0.5rem 0;
  display: none;
}
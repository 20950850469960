@import "./resources/sass-test/build/sass/themes/_themes";
@import "./resources/sass-test/build/sass/utils/_mixins";

.badge-container {
  position: relative;
}

.badge {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -1rem;
  right: -1rem;
  height: 1.6rem;
  min-width: 1.6rem;
  border-radius: 4rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.03px;
  z-index: 1;
  padding: 0.3rem;

  @include themify($themes) {
    font-family: themed('mainFont'), Helvetica, Arial, sans-serif;
  }
}

span.badge {
  @include themify($themes) {
    color: themed('badgeColor') !important;
    background-color: themed('badgeBgColor');
  }
}

.badge-filters {
  display: inherit;
  right: unset;
}
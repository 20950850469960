@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-grid-investment {
  padding: 0 !important;
  height: auto !important;
  border-radius: 0.1rem !important;

  .bpm-grid-investment-content {
    padding: 2rem;
    position: relative;
  }

  h4 {
    font-size: 2.2rem;
    font-weight: 500;
    color: $investment-heading-color;
    margin-bottom: 1rem;

    a {
      color: $investment-heading-color;
    }

    .bpm-status {
      margin-left: 1.5rem;
      @include fts12;
    }
  }

  .investment-location {
    color: $prime-dark2-color;
    @include fts15;

    i {
      margin-right: 0.6rem;
      @include fts18;
    }
  }

  .investment-statistics {
    width: 60%;
  }

  .investment-picture-link {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    width: 100%;
  }

  .investment-picture {
    width: 100%;
  }

  .investment-user {
    position: absolute;
    right: 3rem;
    bottom: 2rem;

    i {
      color: $prime-primary-color;
      margin-right: 0.5rem;
    }

    span {
      color: $prime-primary-color;
    }
  }

  .investment-buttons {
    position: absolute;
    right: 3rem;
    top: 2rem;

    .investment-notification i {
      font-size: 2.6rem;
      position: relative;
    }

    .investment-action {
      width: 3rem;
      display: block;
      margin-left: 1rem;
      text-align: center;

      i {
        font-size: 3rem;
      }
    }
  }
}
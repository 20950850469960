@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-slider {
  margin-bottom: 0;
  border: none;
}

.slides, .flex-viewport {
  height: 100%;
}

.slides > li {
  height: 100%;
}

.flex-direction-nav {

  a {
    height: 4rem;
    font-size: 3rem;
    color: $slider-nav-color !important;

    &:before {
      font-size: 3.5rem !important;
      color: $slider-nav-color !important;
    }
  }

  .flex-prev {
    left: 1rem;
  }

  .flex-next {
    right: 1rem;
  }
}
.Zebra_DatePicker {
  z-index: 100;
  width: 200px;
}

.Zebra_DatePicker * {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
}

/* = GLOBALS
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker table {
  border-collapse: collapse;
  border-spacing: 0;
}

.Zebra_DatePicker td,
.Zebra_DatePicker th {
  text-align: center;
  padding: 2px 0;
}

.Zebra_DatePicker td {
  cursor: pointer;
}

.Zebra_DatePicker .dp_daypicker,
.Zebra_DatePicker .dp_monthpicker,
.Zebra_DatePicker .dp_yearpicker {
  margin-top: 3px;
}

.Zebra_DatePicker .dp_daypicker td,
.Zebra_DatePicker .dp_daypicker th,
.Zebra_DatePicker .dp_monthpicker td,
.Zebra_DatePicker .dp_yearpicker td {
  width: 30px;
}

.Zebra_DatePicker,
.Zebra_DatePicker .dp_header .dp_hover,
.Zebra_DatePicker td.dp_selected,
.Zebra_DatePicker .dp_footer .dp_hover,
.Zebra_DatePicker td.dp_hover {
}

/* = HEADER
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_header {
  width: 100% !important;
}

.Zebra_DatePicker .dp_header td {
}

.Zebra_DatePicker .dp_header .dp_previous,
.Zebra_DatePicker .dp_header .dp_next {
  width: 30px;
}

.Zebra_DatePicker .dp_header .dp_caption {
  font-weight: bold;
}

.Zebra_DatePicker .dp_header .dp_hover {
  background: #2B9FD9;
  color: #fff;
}

.Zebra_DatePicker .dp_header .dp_blocked {
  color: #DEDEDE;
  cursor: default;
}

/* = DATEPICKER
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_daypicker th {
  font-weight: bold;
}

.Zebra_DatePicker td.dp_not_in_month {
  color: #DEDEDE;
  cursor: default;
}

.Zebra_DatePicker td.dp_not_in_month_selectable {
}

.Zebra_DatePicker td.dp_weekend {
}

.Zebra_DatePicker td.dp_weekend_disabled {
  color: #DEDEDE;
  cursor: default;
}

.Zebra_DatePicker td.dp_selected {
  background: #54b854;
  color: #FFF;
}

.Zebra_DatePicker td.dp_week_number {
  cursor: text;
  font-weight: bold;
}

/* = MONTHPICKER
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_monthpicker {
  width: 100% !important;
}

.Zebra_DatePicker .dp_monthpicker td {
  width: 33%;
}

/* = YEARPICKER
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_yearpicker {
  width: 100% !important;
}

.Zebra_DatePicker .dp_yearpicker td {
  width: 33%;
}

/* = FOOTER
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_footer {
  width: 100% !important;
  margin-top: 3px;
}

/* = SOME MORE GLOBALS (MUST BE LAST IN ORDER TO OVERWRITE PREVIOUS PROPERTIES)
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker td.dp_current {
  background: #D8EDF8;
  color: #2B9FD9;
}

.Zebra_DatePicker td.dp_disabled_current {
  color: #3A87AD;
}

.Zebra_DatePicker td.dp_disabled {
  color: #DEDEDE;
  cursor: default
}

.Zebra_DatePicker td.dp_hover {
  background: #2B9FD9;
  color: #fff;
}

/* = ICON
----------------------------------------------------------------------------------------------------------------------*/
button.Zebra_DatePicker_Icon {
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  background: url('calendar.png') no-repeat left top;
  text-indent: -9000px;
  border: none;
  cursor: pointer;
  padding: 0;
  line-height: 0;
  vertical-align: top
}

button.Zebra_DatePicker_Icon_Disabled {
  background-image: url('calendar-disabled.png')
}

/* don't set vertical margins! */
button.Zebra_DatePicker_Icon {
  margin: 0 0 0 3px;
}

button.Zebra_DatePicker_Icon_Inside {
  margin: 0 3px 0 0;
}




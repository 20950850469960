@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-profile.bpm-profile-list.mobile-profile-list {
  .profile-list-header {

    margin-bottom: 0.8rem;

    h2, h3 {
      font-size: 1.9rem;
      font-weight: 500;
    }

    h3 {
      margin-top: 0;
    }

    .profile-list-header-search {
      display: flex;
      flex-direction: row;
      gap: 0.8rem;
      margin-bottom: 3.2rem;

      div.profile-list-header-input {
        flex-basis: 70%;
      }

      div.profile-list-header-reset {
        flex-basis: 30%;
      }

      button {
        height: 4rem;
        width: 100%;
        margin: 0;
      }
    }
  }

  .profile-list-element {
    display: flex;
    margin-bottom: 3.2rem;
    gap: 0.8rem;

    .profile-list-image {
      min-width: 11.4rem;
      min-height: 11.4rem;
      max-width: 11.4rem;
      max-height: 11.4rem;

      img {
        width: 100%;
        border-radius: 4px;
      }
    }

    .profile-list-description {
      overflow-x: hidden;
      flex-basis: 100%;

      .profile-list-name {

        h2 {
          white-space: nowrap;
          line-height: 3rem;
          font-size: 1.9rem;
          letter-spacing: 0.15px;
        }
      }

      .profile-list-contact {
        height: 4.4rem;
        font-size: 1.2rem;

        span {
          display: block;
          font-size: 1.2rem;
          font-weight: 500;
          line-height: 1.8rem;
        }
      }

      .profile-list-buttons {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-wrap: nowrap;
        gap: 0.8rem;
        height: 4rem;

        a, button {
          margin: 0;
          height: 4rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        button {
          width: 4rem;
        }

        a {
          flex-basis: 100%;
        }
      }
    }
  }
}
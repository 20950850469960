@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-chart {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  visibility: hidden;

  .chart-loading-info {
    @include chart-container;
  }
}

.bpm-high-charts {
  visibility: hidden;

  .chart-loading-info {
    @include chart-container;
  }
}

.bpm-chart-filter-form {
  width: 40rem;
}

.bpm-chart-filter-form-md {
  max-width: 40rem;


}

.bpm-chart-box .bpm-box .bpm-dropdown-menu {
  overflow: visible;
  position: relative;
}

.bpm-chart-box-header h3 {
  float: left;

  span {
    font-size: 0.7em;
  }
}

.mobile-content {
  .bpm-chart-box-header .buttons {
    .accordion-body {
      min-width: 90vw;
    }

    .accordion-collapse {
      max-height: 30rem;
      overflow-y: scroll;
    }
  }
}

.bpm-chart-box-header .buttons {
  position: absolute;
  top: 1.8rem;
  right: 1.2rem;
  z-index: 1000;

  .accordion-item {
    background: transparent;
  }

  .accordion-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }

  .accordion-collapse {
    background: transparent;
  }

  .bpm-dropdown-menu {
    right: 0 !important;
    left: auto !important;
  }

  .accordion-body {
    background: #fff;
    border-radius: 4px;
    border: 1px solid #f1f0f5;
  }
}

.bpm-desktop .bpm-chart-box-header .buttons {
  z-index: 1;
  right: 2.5rem;
  display: none;
}

.bpm-desktop-item:hover .bpm-chart-box-header .buttons {
  display: block;
}

table.google-visualization-orgchart-table {
  border-collapse: separate;
}

.graph-element estatetag {
  color: $white-color;
  display: block;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  padding: 0.5rem 1rem;
  @include themify($themes) {
    background-color: themed('warningBtnBg');
    border-color: themed('warningBtnBg');
  }
}

.graph-element transactiontag {
  display: block;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-width: 1px;
  border-style: solid;
  @include themify($themes) {
    color: themed('dangerBtnColor');
    background-color: themed('dangerBtnBg');
    border-color: themed('dangerBtnBg');
  }
}

.graph-element buyertag {
  display: block;
  cursor: pointer;
  color: $white-color;
  padding: 0.5rem 1rem;
  @include themify($themes) {
    background-color: themed('btnPrimaryBg');
    border-color: themed('btnPrimaryBg');
    color: themed('btnPrimaryColor');
  }
}

.graph-element offertag {
  display: block;
  cursor: pointer;
  color: $white-color;
  padding: 0.5rem 1rem;
  @include themify($themes) {
    background-color: themed('successColor');
    border-color: themed('successColor');
  }
}

.graph-element span {
  font-size: 0.9em;
  display: block;
  font-weight: normal;
}

.bpm-label-cell {
  white-space: nowrap;
  font-weight: bold;
}

.bpm-total-cell {
  font-weight: bold;
  background-color: $item-bg-color;
}

.theme-gethome {
  .bpm-high-charts .chart-loading-info {
    position: static;
    text-align: left;
  }
}

@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-estistore-slogan {
  @include fts16;
}

.bpm-estistore-details {

  .estistore-header {
    position: relative;
    height: 15.2rem;
    margin-bottom: 3rem;

    img {
      width: 22rem;
      float: left;
      display: block;
      margin-right: 1rem;
    }

    .back-btn {
      float: left;
      height: 15.2rem;
      line-height: 15.2rem;
      font-size: 3rem;
      width: 2.5rem;
      margin-right: 1rem;
    }

    h2 {
      margin-bottom: 0 !important;
      font-size: 2.2rem;
      font-weight: 300;
      @include themify($themes) {
        color: themed('linkColor');
      }
    }

    h3 {
      margin-top: 2.5rem !important;
      margin-bottom: 0 !important;
      @include fts15;
      color: $main-color;
      font-weight: 300;
    }

    h4 {
      @include fts13;
      color: $main-color;
      font-weight: 300;
      margin-top: 0.5rem !important;
    }
  }

  .estistore-description {
    position: absolute;
    top: 0;
    left: 27rem;
  }

  .estistore-buttons {
    position: absolute;
    bottom: 0;
    left: 27rem;
  }

  span.promo-firstprice {
    @include fts16;
  }

  span.promo-firstprice-desc {
    @include fts13;
    color: $gray-color;
  }

  span.promo-secondprice {
    @include fts13;
    text-decoration: line-through;
  }

  .estistore-content {
    margin: 3rem 0 0 3.5rem;
  }

  .bpm-grid .bpm-grid-cell {
    padding: 0.4rem 1rem;
  }

  .estistore-gallery {
    display: block;
    margin-bottom: 1.5rem;
    width: 100%;

    img {
      width: 100%;
    }
  }

  .estistore-full-description {
    text-align: justify;
    @include fts14;
  }
}

@media (max-width: 780px) {
  .bpm-estistore-details {
    .estistore-description,
    .estistore-buttons {
      position: static;
    }

    .estistore-content {
      margin: 14rem 0 0 0;
    }
  }
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.export-configuration-tip {
  width: 30rem;
  padding: 0.5rem;

  .portal-element {
    padding-bottom: 0.2rem;
    @include fts12;

    &.portal-no-perms {
      padding-bottom: 0.7rem;
    }

    .bpm-checkbox span {
      @include fts12;
    }
  }

  .allcheck {
    @include fts12;
    padding: 0.5rem 0;
  }
}

.note-tip {
  width: 30rem;
  padding: 0.5rem;

  .note-element {
    padding-bottom: 1rem;
    @include fts12;
  }

  .note-info {
    @include fts10;
  }
}

.note-tip-content {
  margin-bottom: 1rem;
  max-height: 20rem;
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-search-map-wrap {
  width: 60rem;
}

.bpm-dialog-wrap {
  display: none;
}

.bpm-overlay {
  background-color: $black-color;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: 5000000;
}

.bpm-dialog {
  visibility: hidden;
  position: absolute;
  left: -9999px;
  top: 0;
  z-index: 9999999;
  min-width: 60rem;
  max-width: 82rem;
  box-shadow: $dialog-shadow;

  @include themify($themes) {
    font-family: themed('mainFont'), Helvetica, Arial, sans-serif;
  }
}

.bpm-dialog-header {
  border-bottom: none;
  font-size: 2.2rem;
  border-top-left-radius: 0.4rem !important;
  border-top-right-radius: 0.4rem !important;

  @include themify($themes) {
    color: themed('dialogHeaderColor');
    padding: themed('dialogHeaderPadding');
    background: themed('dialogBgColor');
  }
}

.bpm-dialog-header-title {
  font-size: 2.2rem;
  line-height: 3.2rem;
  float: left;
  font-weight: 500;
}

.bpm-dialog-close {
  line-height: inherit;
  font-size: 2.2rem;
  float: right;

  &:hover {
    @include themify($themes) {
      color: themed('btnPrimaryBg');
    }
  }
}

.bpm-dialog-content {
  padding: 1rem 3.5rem 3.5rem;
  border-bottom-left-radius: 0.4rem !important;
  border-bottom-right-radius: 0.4rem !important;

  @include themify($themes) {
    background: themed('dialogBgColor');
  }

  h3 {
    @include fts18;
    margin-top: 1rem;
    margin-bottom: 0;
  }
}

.bpm-dialog-btns {
  padding-top: 2rem;

  .left-btns a,
  .left-btns button {
    margin-right: 0.5rem;
    float: left;
  }

  .right-btns a,
  .right-btns button {
    margin-left: 0.5rem;
    float: right;
  }
}

.bpm-dialog-blocked .disabled[disabled] {
  opacity: 1;
  background: none;
  border-width: 0 0 0.1rem 0;
  color: $black-color;
}

.bpm-dialog-blocked .bpm-dialog-btns {
  display: none;
}

.bpm-note-dialog {
  width: 60rem;

  textarea {
    height: 20rem;
  }
}

.bpm-confirm-dialog {
  text-align: center;
}

h3.confirm-dialog-title {
  font-size: 2.2rem;
  margin-bottom: 3.5rem;
}

.confirm-dialog-content {
  margin-bottom: 2.4rem;
}

.confirm-dialog-buttons .btn.btn-success {
  margin-right: 4.5rem;
}

.qtip-content {

  .btn-ok,
  .btn-no {
    display: inline-block !important;
    margin-left: 0.5rem;
  }

  p {
    @include fts12;
  }
}

.qtip-confirm {
  max-width: 30rem;
}

.bpm-news-dialog,
.bpm-rodo-dialog {
  text-align: justify;

  em {
    font-style: normal;
    @include themify($themes) {
      color: themed('successColor');
    }
  }
}

#bpm-application-details-dialog-wrap {

  .bpm-dialog-header {
    padding-bottom: 0;
  }

  .bpm-dialog-content {
    padding-top: 0.2rem;

    h3 {
      margin-top: 0;
      margin-bottom: 3rem;
      @include fts15;
      color: $prime-text-color;
    }
  }
}

.bpm-application-details-dialog {
  width: 60rem;
  min-height: 30rem;
}

.application-dialog-content {

  .image {
    margin: 0;
  }

  .btn-decision {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .decision-text {
    display: block;
    padding: 2rem;
    width: 100%;
    @include fts13;
    text-align: center;
  }

  .application-leads-exhausted {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: center;
    margin: 16px 0;
    padding: 16px;
    border-radius: 4px;
    //height: 56px;
    background-color: #FEF3F2;
    color: #F57461;
    line-height: 24px;
    @include fts13;
    font-weight: 500;

    span {
      font-weight: 700;
    }
  }

  .application-leads-exhausted-button {
    width: 100%;
    margin: 16px 0;
  }

  .application-dialog-content-manager {
    display: flex;
    align-items: center;
    justify-content: center;

    img.copy_to_clipboard {
      cursor: pointer;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 24px;
    }

    .application-dialog-content-name {
      span {
        color: #3F4246;
        @include fts19;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.15px;
      }
    }

    .application-dialog-content-details {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .application-dialog-content-manager-phone, .application-dialog-content-manager-email {
        display: flex;
        flex-direction: row;
        gap: 8px;
        min-height: 8px;

        span {
          color: #4A90E2;
          @include fts15;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: -0.1px;
          text-decoration-line: underline;
        }
      }
    }
  }

  .application-content {
    margin-top: 2rem;
  }

  .application-data {
    width: 100%;
    border-radius: 0.8rem;
    border: 1px solid $prime-border-input;
    padding: 1.3rem 2.5rem;
    color: $prime-dark2-color;
    @include fts13;
    margin-bottom: 1.5rem;

    i {
      @include fts16;
      margin-right: 1.5rem;
      color: $prime-medium-icon-color;
    }

    .covered {
      position: relative;
      height: 2rem;

      &:after {
        position: absolute;
        top: 0;
        -webkit-transform: translate(2px, -2px);
        -ms-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
        content: '';
        height: 2rem;
        width: 12rem;
        background-color: $prime-dialog-bg;
        border-radius: 0.3rem;
      }

      &.content-short {
        width: 100%;
        text-align: left;
        display: block;
        overflow: hidden;

        &:after {
          width: 100%;
        }
      }

    }

    .covered-line {
      height: 2rem;
      width: 100%;
      display: block;
      margin-top: 1rem;
      background-color: $prime-dialog-bg;
      border-radius: 0.3rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .investment-name {
    @include fts15;
    font-weight: bold;
  }

  .investment-location {
    @include fts13;
    margin-bottom: 1.5rem;
  }

  .offer-details {
    @include fts14;
  }

  .offer-params {
    @include fts13;
  }

  .image img {
    width: 100%;
  }

  .summary-option {
    background: none repeat scroll 0 0 $white-color;
    border: 1px solid $border-color;
    float: left;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.3rem 0.6rem;
    @include fts13;
  }
}

.theme-prime,
.theme-primepro,
.theme-gethome {

  .bpm-dialog-close {
    color: $investment-heading-color;
  }

  #bpm-publish-options-dialog-wrap {
    max-width: 98rem;
  }

  .bpm-publish-options-dialog {
    width: 91rem;
    min-height: 50rem;
  }

  .publish-options-content-list {
    height: 40rem;
  }

  .bpm-import-offers-dialog {
    min-height: 30rem;
  }

  .bpm-confirm-dialog {
    width: 54rem;
    min-height: 30rem;
  }

  #bpm-create-investment-dialog-wrap {
    max-width: 83rem;
  }

  .bpm-create-investment-dialog {
    width: 76rem;
    min-height: 46rem;
  }

  .prime-event-dialog-label {
    width: 100%;
  }

  .prime-event-dialog-label .contact-details,
  .prime-event-dialog-label .relation-details {
    display: inline;
    margin-left: 0.5rem;
  }

  .prime-event-dialog-label .control-label {
    @include fts11;
    color: $investment-icon-color;
    text-transform: uppercase;

  }

  .prime-event-dialog-label label {
    margin-bottom: 0.2rem;
  }

  .prime-event-dialog-field {
    width: 100%;
  }

  a.plupload_button.plupload_add {
    color: $white-color;
  }

  a.plupload_button.plupload_start {
    color: $prime-uploadbtn-color;
  }
}

.theme-gethome {
  .prime-event-dialog-label .control-label {
    color: $gethome-dark-textcolor;
  }
}

@media (max-width: 780px) {

  .bpm-dialog {
    width: 100%;
    max-width: 72rem;
    min-width: 0;
  }

  .bpm-note-dialog {
    width: 100%;
  }

  #check-existing-client-dialog {
    b {
      font-weight: 500;
    }

    .btn.btn-success {
      margin-top: 0.5rem;
    }

    .btn.btn-info {
      margin-top: 0.5rem;
    }

    h4 {
      @include fts13;
      font-weight: 500;
    }

    span.text-danger {
      font-size: 1rem;
      font-weight: 500;
    }

    .bpm-box-content {
      box-shadow: none;
    }
  }

  .theme-prime,
  .theme-primepro,
  .theme-gethome {

    .bpm-application-details-dialog {
      width: 100%;
      max-width: 72rem;
      min-width: 0;
    }

    .application-dialog-content .image img {
      margin-bottom: 1.5rem;
    }
  }
}

@media (max-width: 768px) {

  .theme-prime,
  .theme-primepro,
  .theme-gethome {
    .bpm-dialog-header-title {
      width: 90%;
    }
  }
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-scrollbar {
  position: relative;
  overflow: hidden
}

.bpm-scrollbar-horizontal {
  padding-top: 0.1rem;
}

.bpm-scrollbar-listview {
  padding-bottom: 35rem;

  &.status-fix {
    z-index: 4000;
  }
}

.bpm-vertical-track {
  padding-bottom: 0.4rem;
  top: 0.3rem;
  right: 0.3rem;
  width: 0.6rem;

  &:hover .bpm-vertical-handle,
  &dragging .bpm-vertical-handle {
    width: 0.5rem !important;
    @include themify($themes) {
      background-color: themed('primaryColor');
    }
  }
}

.bpm-vertical-handle {
  width: 0.4rem !important;
  background-color: $scrollbar-bg-opacity;
  right: 0;
  -webkit-transition-property: width, background-color;
  transition-property: width, background-color;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

.bpm-horizontal-track {
  height: 0;

  &:hover .bpm-horizontal-handle,
  &.dragging .bpm-horizontal-handle {
    height: 0.7rem;
    @include themify($themes) {
      background-color: themed('primaryColor');
    }
  }
}

.bpm-horizontal-handle {
  height: 0.6rem;
  background-color: $scrollbar-bg-opacity;
  bottom: 0.6rem;
}

.ps-scrollbar-x-rail {
  background-color: $scrollbar-bg !important;
  opacity: 0.6 !important;
  height: 0.7rem !important;
  @include border-radius-none;
}

#bpm-elements-listview .ps-scrollbar-x-rail {
  bottom: 5rem;
  position: fixed;
  left: 26rem !important;
  z-index: 5000 !important;
  height: 0.9rem !important;
}

.menu-hidden #bpm-elements-listview .ps-scrollbar-x-rail {
  left: 4rem !important;
}

.ps-container:hover > .ps-scrollbar-x-rail:hover {
  background-color: $border-color !important;
}

.ps-scrollbar-x {
  background-color: $scrollbar-bg-opacity !important;
  height: 0.7rem !important;
  @include border-radius-none;

  &:hover {
    height: 0.7rem !important;
    @include themify($themes) {
      background-color: themed('primaryColor') !important;
    }
  }
}

#bpm-elements-listview .ps-scrollbar-x,
#bpm-elements-listview .ps-scrollbar-x:hover {
  height: 0.9rem !important;
}

.ps-scrollbar-y-rail {
  @include border-radius-none;
  width: 1rem !important;
}

.ps-scrollbar-y {
  width: 1rem !important;
  background-color: $scrollbar-bg-opacity;
  right: 0;
  -webkit-transition-property: width, background-color;
  transition-property: width, background-color;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  @include border-radius-none;
}

.ps-container:hover.ps-in-scrolling.ps-x .ps-scrollbar-x {
  @include themify($themes) {
    background-color: themed('primaryColor') !important;
  }
}

.bpm-scrollbar-ondark.ps-container {

  .ps-scrollbar-y-rail {
    background-color: $scrollbar-y-rail-bg !important;
    width: 0.6rem !important;
  }

  .ps-scrollbar-y {
    @include themify($themes) {
      background-color: themed('primaryColor') !important;
    }
    width: 0.6rem !important;
  }

}

.bpm-dropdown-content .ps-scrollbar-x-rail {
  display: none !important;
}

.bpm-scrollbar-ondark + div .bpm-vertical-track .bpm-vertical-handle {
  @include themify($themes) {
    background-color: themed('primaryColor');
  }
}

.scroll-left-btn,
.scroll-right-btn {
  width: 1rem;
  height: 1rem;
  background: $black-color;
  position: absolute;
  top: -32rem;
  left: -1rem;
}

@media (max-width: 780px) {

  #bpm-elements-listview .ps-scrollbar-x-rail {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {

  #bpm-elements-listview .ps-scrollbar-x-rail {
    left: 22.5rem !important;
  }

  .menu-hidden #bpm-elements-listview .ps-scrollbar-x-rail {
    left: 3rem !important;
  }

}
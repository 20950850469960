/*
   Plupload
------------------------------------------------------------------- */

.plupload_button {
  display: -moz-inline-box; /* FF < 3*/
  display: inline-block;
  text-decoration: none;
  color: #FFFFFF;
  border: 0;
  padding: 2px 12px 3px 12px;
  margin-right: 4px;
  background: #409940;
  outline: 0;

}

.plupload_button:hover {
  text-decoration: none;
  opacity: 0.8;
  color: #FFFFFF;
}

.plupload_disabled, a.plupload_disabled:hover {
  color: #737373;
  border-color: #c5c5c5;
  background: #ededed;
  cursor: default;
}

.plupload_add {
  background-position: -181px center;
}

.plupload_wrapper {
  width: 100%;
}

.plupload_container {
  border: 1px solid #DDDDDD;
  box-shadow: 0 5px 10px -8px rgba(0, 0, 0, 0.2);
  margin-bottom: 40px;
}

.plupload_container input {
  border: 1px solid #DDD;
  width: 98%;
}

.plupload_header {
  background: #EEEEEE;
  display: none;
}

.plupload_header_content {
  min-height: 56px;
  padding-left: 10px;
}

.plupload_header_title {
  font-size: 18px;
  padding: 6px 0 3px;
}

.plupload_header_text {
  font-size: 12px;
}

.plupload_filelist {
  margin: 0;
  padding: 0;
  list-style: none;
}

.plupload_scroll .plupload_filelist {
  background: none repeat scroll 0 0 #F5F5F5;
  box-shadow: 0px 1px 10px -8px rgba(0, 0, 0, 0.7) inset;
  height: 185px;
  overflow-y: scroll;
  padding-right: 15px;
}

.plupload_filelist li {
  padding: 10px 8px;
  border-bottom: 1px solid #DDD;
}

.plupload_filelist li:last-child {
  border-bottom: 0;
}

.plupload_filelist_header, .plupload_filelist_footer {
  background: #FFF;
  padding: 8px 8px;
  color: #42454A;
}

.plupload_filelist_header {
  border-top: 1px solid #DDD;
  border-bottom: 1px solid #DDD;
}

.plupload_filelist_footer {
  border-top: 1px solid #ddd;
  vertical-align: middle;
}

.plupload_file_name {
  float: left;
  overflow: hidden
}

.plupload_file_status {
  color: #777;
}

.plupload_file_status span {
  color: #42454A;
}

.plupload_file_size, .plupload_file_status, .plupload_progress {
  float: right;
  width: 80px;
}

.plupload_file_size, .plupload_file_status, .plupload_file_action {
  text-align: right;
}

.plupload_filelist .plupload_file_name {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 60%;
}

.plupload_file_action {
  float: right;
  width: 16px;
  height: 16px;
  margin-left: 15px;
}

.plupload_file_action * {
  display: none;
  width: 16px;
  height: 16px;
}

li.plupload_uploading {
  background: #ECF3DC url('../gfx/plupload/backgrounds.gif') repeat-x 0 -238px;
}

li.plupload_done {
  color: #AAA
}

li.plupload_delete a {
  background: url('../gfx/plupload/delete.gif');
}

li.plupload_failed a {
  background: url('../gfx/plupload/error.gif');
  cursor: default;
}

li.plupload_done a {
  background: url('../gfx/plupload/done.gif');
  cursor: default;
}

.plupload_progress, .plupload_upload_status {
  display: none;
}

.plupload_progress_container {
  margin-top: 3px;
  border: 1px solid #CCC;
  background: #FFF;
  padding: 1px;
}

.plupload_progress_bar {
  width: 0px;
  height: 7px;
  background: #CDEB8B;
}

.plupload_scroll .plupload_filelist_header .plupload_file_action, .plupload_scroll .plupload_filelist_footer .plupload_file_action {
  margin-right: 17px;
}

/* Floats */

.plupload_clear, .plupload_clearer {
  clear: both;
}

.plupload_clearer, .plupload_progress_bar {
  display: block;
  font-size: 0;
  line-height: 0;
}

li.plupload_droptext {
  background: transparent;
  text-align: center;
  vertical-align: middle;
  border: 0;
  line-height: 165px;
}

@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-listview-controls {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-bottom: 1.6rem;
  position: relative;

  &.sticky {
    float: none;
    position: fixed;
    top: 5rem;
    z-index: 1019;
    padding: 1rem 0;
    background: url(../gfx/wizard-controls-bg-reverse.png) repeat-x left bottom;

    @include themify($themes) {
      top: themed('menuHeight');
    }

    .bpm-listview-filters {
      display: none;
    }
  }

  .bpm-controls-right > * {
    float: right;
    margin: 0 0 0 1rem;
  }

  .bpm-controls-left {

    > * {
      float: left;
      margin: 0 1rem 0 0;
    }

    .btn-mass-action > * {
      float: left;
      margin: 0 1rem 0 0;
    }

    .btn-multiadding-buttons > * {
      float: left;
      margin: 0 1rem 0 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  .btn-mass-action {
    display: none;
  }
}

.bpm-wizard .btn-mass-action {
  display: inline-block;
}


.bpm-wizard .wizard-step-body .btn-mass-action {
  display: none;
}


.btn-actions-button .bpm-dropdown-menu {
  width: auto;
}

.bpm-wizard .controls-edit-top {
  position: absolute;
  right: 0;
  top: 0.5rem;
  z-index: 999;
}

.exact-location-button {
  margin-top: 1rem;
}

.bpm-listview-filters {
  position: absolute;
  right: 0;
  top: -4.8rem;

  .ff {
    padding-bottom: 2rem;
  }
}


.bpm-object-navgation-btn.navigation-prev {
  padding: 0.5rem 0.8rem 0.4rem 0.6rem;
}

.bpm-object-navgation-btn.navigation-next {
  padding: 0.5rem 0.6rem 0.4rem 0.8rem;
}

.singlestep .bpm-object-navgation-btn {
  display: none;
}

.singlestep .bpm-listview-controls .btn:not(.manual-btn) {
  padding: 0.4rem 0.7rem;
  @include fts13;
  line-height: 1.5;
}


//.theme-prime .bpm-listing-offer-prime .bpm-listview-controls .btn:not(.manual-btn) {
//  padding: .3rem .8rem .4rem;
//  @include fts12;
//  line-height: 1.5;
//}

.bpm-object-navgation-btn i {
  font-size: 1.9rem;
}

a.bpm-object-navgation-btn.empty-href {
  background-color: $menu-text-color;
  border-color: $menu-text-color;
  cursor: default;
}

.bpm-view-change {
  float: left;
  width: 54rem;
  margin-bottom: 1rem;
  display: none;

  > span {
    border: 1px solid $border-color;
    float: left;
    margin: 0 0 0 2%;
    padding: 1.5rem;
    text-align: center;
    width: 23%;
    color: $border-color;
    cursor: pointer;
  }

  > .bpm-view-type:first-child {
    margin-left: 0;
  }

  > .bpm-view-type:hover {
    border: 1px solid $border-color;
    background-color: $table-bg;
    box-shadow: 0 0 1rem 0 $gmap-bg inset;
    @include themify($themes) {
      color: themed('linkColor');
    }
  }

  > .bpm-view-type.active {
    @include themify($themes) {
      color: themed('linkColor');
    }
  }
}

.bpm-listing-offer .bpm-view-change {
  display: block;
}

a.bpm-view-column-item {
  border: 1px solid $border-color;
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0.3rem;

  &:hover {
    border: 1px solid $primary-color;
  }
}

.columnSelector {
  width: 54rem;

  .sortable-placeholder {
    display: block;
    height: 3.2rem;
    width: 100%;
    margin-bottom: 0.3rem;
    border: 1px dashed $nav-active-color;
    position: relative;
  }
}

.bpm-view-control {

  .bpm-dropdown-content {
    max-height: 41rem;
  }

  .bpm-view-column-list {
    height: 30rem;
    padding-right: 1rem;
  }

  .bpm-view-column-list .empty-column {
    height: 20rem;
  }

}

.bpm-grid-actions .bpm-dropdown-menu {
  width: auto;
}

.bpm-dropdown-status .bpm-not-status {
  display: none !important;
}

.bpm-new-actions-dropdown .bpm-only-status {
  display: none !important;
}


.offer-actions-dropdown {
  width: 50rem;

  .section-title {
    display: block;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0;
    text-align: left;
    padding: 0.4rem 1rem;
    background: none;
    color: $main-color;
    border: none;
    @include fts14;
    vertical-align: middle;
    font-weight: bold;

    @include themify($themes) {
      color: themed('dropdownTitleColor');
      margin-bottom: themed('dropdownMarginBottom');
    }
  }

  .bpm-submit {
    @include themify($themes) {
      font-size: themed('dropdownSubmitFts');
    }
  }
}

.bpm-dropdown-status .offer-actions-dropdown {
  width: 25rem;
}

.bpm-dropdown-status .col-md-6 {
  width: 100% !important;
}

.btn-actions-icon .bpm-dropdown-content,
.btn-actions-button .bpm-dropdown-content {
  max-height: 46.5rem;
}


.bpm-sort-change {
  width: 20rem;
  margin-bottom: 1rem;
}

.bpm-listview-addons {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-bottom: 0.5rem;
  z-index: 50000;
}

.bpm-grid-mass {
  padding: 0.5rem 1rem;
  border: 1px solid $table-border-color;
  background: rgba(0, 0, 0, 0.02);
  margin-left: 0.5rem;
  display: none;
}

.bpm-grid-total {
  padding: 0.5rem 1rem;
  border: 1px solid $table-border-color;
  background: rgba(0, 0, 0, 0.02);
}

.bpm-listview-addons-bottom .bpm-grid-mass {
  background: $table-bg-hover;
}

.bpm-listview-addons-bottom .bpm-grid-total {
  background: $table-bg-hover;
}

.bpm-grid-total,
.bpm-grid-mass,
.bpm-listview-addons-bottom .bpm-grid-total,
.bpm-listview-addons-bottom .bpm-grid-mass {
  border: none;
  background: none;
  padding-left: 0;
  @include fts14;
  @include themify($themes) {
    color: themed('gridMassColor');
  }
}

.bpm-listview-addons-arrows {
  margin-bottom: 2rem;
  position: relative;
  display: none;

  @include themify($themes) {
    color: themed('linkColor');
    padding: themed('listviewArrowPadding');
    background: themed('gridCellBg');
    border: themed('listviewArrowBorder');
    font-size: themed('listviewArrowSiza');
  }

  i {
    @include fts17;
    position: absolute;
  }

  &:hover {
    @include themify($themes) {
      background-color: themed('pagerBg');
      border-color: themed('pagerBorderActive');
      color: themed('pagerActiveColor');
    }
  }
}

#bpm-listview-addons-arrows {
  margin-top: -4.6rem;
}

#bpm-listview-addons-left {
  padding-left: 1.8rem;

  i {
    left: 0.6rem;
    top: 0.4rem;
  }
}

#bpm-listview-addons-right {
  padding-right: 1.8rem;

  i {
    right: 0.6rem;
    top: 0.4rem;
  }
}

.bpm-grid-additional-addons {
  padding: 0.5rem 1rem 0.4rem;
  margin-right: 0.5rem;
}

.bpm-grid-empty {
  text-align: center;
  padding-top: 13rem;
  font-size: 2rem;
  font-weight: 300;

  .comment {
    @include fts14;
  }
}

.bpm-box-unlimited .bpm-grid-empty {
  padding-top: 1rem !important;
}

.btn-addons {
  margin-left: 0.5rem;
  display: none;
}

.bpm-listview-addons-bottom {
  visibility: hidden;
  position: fixed;
  bottom: 0;
  z-index: 4000;
  width: auto;
  padding-top: 0.5rem;
  padding-bottom: 0;
  background: url(../gfx/wizard-controls-bg.png) repeat-x left top;
}

.bpm-listview-addons .view-config {
  margin-left: 0.5rem;

  .btn-view-config {
    background: $white-color;
    line-height: 1.4;

    @include themify($themes) {
      padding: themed('pagerPadding');
      color: themed('pagerActiveColor');
      border: themed('pagerBorder');
      border-radius: themed('pagerRadius');
    }

    &:hover {
      background-color: $table-checkbox-displayed;

      @include themify($themes) {
        color: themed('btnPrimaryBg');
        border-color: themed('tableviewHoverBorder');
        background-color: themed('pagerBg');
      }
    }
  }
}

.bpm-watermark {
  display: inline-block;
}

.bpm-watermark-container {
  width: 50rem;
  height: 50rem;
  position: relative;
  overflow: hidden;
}

.mobile-content {
  .list-controls-mobile {
    position: relative;
  }

  .bpm-listview-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: calc(100% - 100px);

    h2, h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      white-space: nowrap;
      margin: 0 !important;
    }

    h3 {
      font-size: 14px;
    }

    .bpm-listview-header-text {
      .bpm-listview-filters {
        top: unset;
        right: unset;
      }

      h2, h3 {
        white-space: unset;
      }
    }

    .bpm-listview-controls {
      width: unset;
      margin-bottom: unset;
      margin-top: 0.8rem;
      flex-basis: 100%;
    }
  }

  .bpm-listview-header-button {
    flex-basis: 100%;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;

    .btn-group {
      width: 100%;

      a, button, input, .btn-multiadding-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 4rem;

        i {
          display: none;
        }
      }

      .btn-multiadding-buttons {
        gap: 0.8rem;
      }

      form {
        button {
          width: 100%;
          height: 4rem;
        }
      }
    }
  }

  .bpm-listview-filters {
    position: relative;
    top: 0;
  }

  .bpm-listview-filters-absolute {
    position: absolute;
    top: -2.8rem;
    right: 0;
  }

  .bpm-listview-controls {

    .bpm-controls-left,
    .bpm-controls-right {
      clear: both;
    }

    .bpm-controls-right > * {
      float: left;
      margin: 0 1rem 0 0;
    }

    &.sticky {
      position: relative !important;
      top: 0 !important;
      padding: 0 !important;
      background: none !important;
    }

  }

  .bpm-listview-addons-arrows,
  .bpm-listview-addons-bottom .bpm-grid-total,
  .bpm-listview-addons-bottom .bpm-grid-info,
  .bpm-listview-addons-bottom .bpm-grid-mass,
  .bpm-listview-addons-bottom .bpm-grid-pagination {
    display: none !important;
  }

}

@media (min-width: 992px) and (max-width: 1024px) {

  .bpm-listview-controls .bpm-controls-left > *,
  .bpm-listview-controls .bpm-controls-left .btn-mass-action > * {
    margin-right: 0.3rem;
  }

  .bpm-listview-controls .bpm-controls-right > * {
    margin-left: 0.3rem;
  }
}

.theme-prime,
.theme-primepro {

  .bpm-listview .bpm-grid.bpm-tableview {
    border-collapse: separate;
    border-spacing: 0 1.6rem;
  }

  .bpm-new-actions-dropdown .btn span,
  .bpm-new-actions-dropdown .btn i,
  .bpm-new-actions-dropdown .btn,
  .bpm-grid.bpm-tableview .bpm-grid-row .bpm-grid-actions-icons .bpm-dropdown-content a {
    color: $prime-primary-color;
    @include fts12;
  }

  .bpm-listview-addons .view-config .btn-view-config {
    margin-right: 0.5rem;
    float: left;

    i {
      display: none;
    }
  }

  a.bpm-view-column-item:hover {
    @include themify($themes) {
      color: themed('linkColor');
    }
  }

  .bpm-grid.bpm-tableview div:not(:hover).bpm-grid-row .bpm-grid-actions-icons a.btn-success,
  .bpm-grid.bpm-tableview .bpm-grid-row:hover div:not(:hover).bpm-grid-actions-icons a.btn-success {
    color: $white-color;
    background-color: $prime-primary-color !important;
  }

  .bpm-grid.bpm-tableview .bpm-grid-row .bpm-grid-actions-icons a.btn-success {
    text-transform: uppercase;
  }

  .mobile-content {
    nav.bpm-listview-controls {
      margin-top: 0.8rem;
      flex-basis: 100%;
    }
  }

}

.bpm-listview-addons-bottom .bpm-grid-pagination .pagination .bpm-dropdown-menu {
  top: -8.7rem;
  right: unset;
  left: -10vw;

  &.dropdown-paginationRows {
    top: -25rem;
  }
}

.theme-prime,
.theme-primepro,
.theme-gethome {

  .bpm-listview-addons-bottom .bpm-grid-pagination .pagination .bpm-dropdown-menu.dropdown-paginationRows {
    top: -23.5rem;
  }
}

.bpm-grid-pagination .pagination .bpm-dropdown-menu {

  .form-control {
    @include themify($themes) {
      border: 1px solid themed('formControlBorder');
    }
  }

  .input-group .input-group-btn {

    padding-left: 1rem;

    .btn {
      min-width: unset;
      height: 3.8rem;
      width: 4.2rem;
    }
  }

}


.theme-gethome {
  .bpm-listview-addons-bottom {
    background: $white-color;
    padding: 1.4rem 2.4rem;
    margin-bottom: 0;
    border: 1px solid $table-border-color;
    border-bottom: none;

    .bpm-listview-addons {
      margin-bottom: 0;
    }
  }
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.theme-prime,
.theme-primepro,
.theme-gethome {

  .btn-mass-action a.bpm-new-offers-import + .btn-actions-button {
    padding: 0.4rem 0 0.4rem 2rem;
  }

  .import-offers-content {

    h3 {
      font-family: 'Poppins';
      color: $prime-dark-color;
      @include fts15;

      i {
        margin-right: 1.2rem;
      }
    }

    .btn.btn-primary {
      margin-left: 1rem;
    }

    .import-offers-info-1,
    .import-offers-info-2 {
      color: $prime-light-color;
      font-weight: 500;
      line-height: 1.95rem;
      margin-top: 1.6rem;
      @include fts13;
    }
  }

  #bpm-import-offers-dialog-wrap {
    max-width: 56rem;
  }

  .import-offers-content .upload-file-container {
    margin-top: 1.8rem;

    label {
      @include fts10;
      color: $prime-primary-color;
      margin-bottom: 0.2rem;
      text-transform: uppercase;
    }

    .upload-file {
      border: 1px solid $prime-border-input;
      border-radius: 0.8rem;
      padding: 0 0.9rem;
      width: 100%;
      margin-bottom: 3.2rem;
    }
  }

  .download-file-container {
    color: $investment-span-color;
    @include fts13;

    i {
      margin-right: 1.5rem;
    }
  }

  .tooltip-info-success {
    position: relative;
    background-color: $prime-tooltip-bg;
    color: $investment-icon-color;
    @include fts10;
    padding: 1.6rem 2.8rem;
    margin: 2.2rem 1.5rem 4.8rem 4.5rem;
    border-radius: 0.4rem;

    &.triangle-left {
      margin-top: 4rem;
      margin-left: 1.5rem;

      &:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        top: 1.6rem;
        left: -1rem;
        border-top: 1rem solid transparent;
        border-right: 1rem solid $prime-tooltip-bg;
        border-bottom: 1rem solid transparent;
      }
    }

    &.triangle-up:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      top: -1rem;
      left: 2.8rem;
      border-left: 1rem solid transparent;
      border-right: 1rem solid transparent;
      border-bottom: 1rem solid $prime-tooltip-bg;
    }
  }

  .import-offers-content .tooltip-info-success {
    margin-bottom: 1.8rem;
  }

  #gallery-body {

    .form-prime-tipscontainer {
      max-width: 35rem;
      width: 40%;
      box-sizing: border-box;
    }

    .tooltip-info-success.triangle-up {
      margin: 0 0 2rem 0.5rem;
    }
  }

  .bold {
    font-weight: bold;
  }

  .tooltip-info-success {

    p {
      margin: 0;
    }

    i {
      @include fts15;
      position: absolute;
      top: 1rem;
      right: 1.2rem;
    }
  }

  .import-offers-details {
    background: $prime-step-bgcolor;
    color: $investment-icon-color;
    box-sizing: border-box;
    margin: 3.4rem 0.1rem;
    padding: 1.4rem 0;
    @include fts14;
  }

  .import-offers-property {
    padding: 0.7rem 0;
    text-align: right;
  }

  .import-offers-value {
    padding: 0.7rem 0 0.7rem 2.8rem;
    font-weight: bold;
  }

  .text-status-yellow {
    color: $prime-textstatus-yellow;
  }

  .import-offers-alert {
    margin: 2.8rem 0.1rem;
    padding: 2rem;
    color: $black-color;
    background-color: $prime-alert-bg;
    @include fts12;

    i {
      @include fts16;
      margin-right: 2rem;
    }
  }

  .text-status-alert {
    color: $portal-info-color;
  }

  .import-offers-message {
    width: 100%;
    text-align: center;

    i {
      font-size: 4.6rem;
    }

    p {
      width: 100%;
      margin-top: 3.2rem;
      @include fts18;
    }

    a {
      margin-top: 4.1rem;
    }

    h3 {
      font-size: 2.2rem;
      font-weight: normal;
      color: #2E384D !important;
      margin-top: 3.6rem;
    }
  }

  p.import-offers-info {
    @include fts12;
  }

  .return-link i {
    margin-right: 1.6rem;
  }

  .import-info-3-details {
    display: none;
  }

  .import-info-buttons {

    display: flex;
    justify-content: center;
    border: 1px solid $prime-border-input;
    border-radius: 4px;
    width: 60%;
    margin: 0 auto;

    label {
      color: $prime-dark2-color;
      padding: 0.8rem;
      margin: 0;
      flex-grow: 1;
      border-right: 1px solid $prime-border-input;
      @include fts15;

      &.checked {
        background-color: $prime-cell-border;
        color: $prime-primary-color;
      }

      &:last-child {
        border-right: none;
      }

      span {
        padding: 0;
        margin-bottom: 0;
      }

      span::before {
        visibility: hidden;
      }
    }
  }

  .bpm-offers-import-accept {
    width: auto;
  }
}

@media (max-width: 768px) {

  .theme-prime,
  .theme-primepro,
  .theme-gethome {
    .import-offers-content .btn.btn-danger {
      margin-top: 1rem;
      margin-right: 2.5rem;
    }

    .import-offers-alert div {
      display: flex;
    }

    .return-link {
      padding-left: 3rem;
    }

    .import-offers-content {

      .import-info-buttons {
        flex-direction: column;
      }

      label.bpm-radio {
        border-bottom: 1px solid $prime-border-input;
        border-right: none;

        &:last-child {
          border-bottom: none;
        }
      }

      .btn.btn-primary {
        margin-left: 0;
        margin-top: 0.8rem;
      }

    }
  }

}

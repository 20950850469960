@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-grid.bpm-ghosttable {
  border: none;

  .bpm-grid-row.bpm-grid-head {
    border: none;
    font-weight: 400;
  }

  .bpm-grid-cell {
    border: none;
  }

  .bpm-grid-row {
    background: none;
    border: none;
    border-bottom: 1px solid $ghosttable-border-color;

    &:nth-child(2n+1) {
      background: none;
    }
  }
}

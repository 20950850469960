@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.menu-mobile {
  display: none;

  a#menu-mobile-btn {
    @include themify($themes) {
      color: themed('mobileIconsColor');
    }
  }
}

body > header.mobile-header {
  @include header-size;
}

body > header .mobile-detected {
  @include header-size;
  @include flex-regular-container
}

.aside-mobile,
.aside-mobile-zone {
  top: -100vh;
  width: 100%
}

.aside-mobile {

  .menu-main {

    > dl > dt > a {
      padding: 1.6rem 2.4rem;
      @include themify($themes) {
        color: themed('asideMobileColor');
      }

      &.active i.menu-item-arrow {
        @include rotate90;
      }
    }

    .menu-item i.menu-item-arrow {
      @include themify($themes) {
        color: themed('asideMobileColor');
      }
    }

  }

  .menu-level-2 > dt > a {
    padding: 1.6rem 2.4rem 1.6rem 4rem;
    @include fts12;
    @include themify($themes) {
      color: themed('asideMobileColor');
    }
  }
}

.mobile-detected {

  .header-logo-container,
  .header-icons-container {
    display: flex;
    align-items: center;
  }

  .header-icons-container {
    padding-right: 0.5rem;
  }

  .header-icons-container.hide-view,
  #menu-mobile-btn.hide-view,
  .header-logo-container.hide-view,
  .close-button-mobilemenu.hide-view,
  .close-button-searcher.hide-view,
  .searcher-mobile-container.hide-view,
  .close-button-usermenu.hide-view,
  .close-button-actionsmenu.hide-view {
    display: none;
  }

  .searcher-mobile-container {
    padding-left: 1.6rem;
    width: 100%;

    .form-control {
      border-radius: 0.4rem;
    }
  }

  .menu-mobile {
    display: block;

    a i {
      padding: 2.1rem 0 2.1rem 1.8rem;
      font-size: 2rem;
      text-align: center;
      @include themify($themes) {
        color: themed('mobileIconsColor');
      }
    }
  }

  .header-icons-container a i,
  .close-button-mobilemenu a i,
  .close-button-searcher a i,
  .close-button-usermenu a i,
  .close-button-actionsmenu a i {
    font-size: 2rem;
    text-align: center;
    padding: 0 1.5rem;
    @include themify($themes) {
      color: themed('menumobileIconsColor');
    }
  }

  .header-icons-container a i.fa-ellipsis-v {
    padding-top: 0.1rem;
  }

  .header-icons-container img {
    height: 2rem;
    background-color: $menumobile-icon-color;
    color: $black-color;
    border-radius: 50%;
  }

  .bpm-dropdown-menu.pull-right .list-group-item a,
  .bpm-hoverdown-menu.pull-right .list-group-item a {
    @include fts14;
    background-color: $menu-bg-color;
    color: $menu-text-color;
    padding: 1.6rem 2.4rem;
  }

  .user-dropdown-content {
    position: absolute;
    right: 0 !important;
    left: 0 !important;
    width: 100% !important;
    top: 100vh;
    height: calc(100vh - 56px);
    @include themify($themes) {
      background-color: themed('menuMobileBgColor');
    }

    &.mobile-bottom-fix {
      padding-bottom: 200px;
    }

    .list-group-item {
      @include fts14;
      @include themify($themes) {
        background-color: themed('menuMobileBgColor');
        color: themed('menuTextColor');
      }
      padding: 1.6rem 2.4rem;

      a {
        @include themify($themes) {
          color: themed('mobilemenuDropdownColor');
        }
      }
    }

    ul.list-group {
      margin: 0;
      padding-bottom: 1.6rem;

    }

    .list-group .list-group-item.user-info {
      padding: 1.6rem 2.4rem;
      @include fts10;
      @include themify($themes) {
        color: themed('menuTextColor');
      }
    }
  }

  .list-group-item {
    @include themify($themes) {
      border: themed('menuLinkBorder');
    }
  }

  .more-dropdown-content {
    .bpm-collapse-menu {
      z-index: 0;
    }

    position: absolute;
    right: 0 !important;
    left: 0 !important;
    width: 100% !important;
    top: 100vh;
    height: calc(100vh - 56px);
    @include themify($themes) {
      background-color: themed('menuMobileBgColor');
    }

    &.mobile-bottom-fix {
      padding-bottom: 200px;
    }

    .bpm-scrollbar {
      height: inherit;
    }

    a {
      @include fts14;
      @include flex-regular-container;
      padding: 1.6rem 2.4rem;
      width: 100%;

      @include themify($themes) {
        color: themed('menuTextColor');
        border-bottom: themed('menuLinkBorder');
      }

      .email-mobilemenu-container {
        position: relative;

        .nav-item-badge {
          top: -0.8rem;
          left: 1rem;
          @include themify($themes) {
            color: themed('badgeColor');
            background-color: themed('badgeBgColor');
          }
        }
      }

      i {
        font-size: 2rem;
        padding-right: 1rem;

        &.menu-item-arrow {
          padding-right: 0;
          transition: .2s;
        }

        &.blue {
          @include fts16;
          padding-right: 0.5rem;
          padding-left: 0.5rem;
          color: $primary-color !important;
        }

        b {
          @include fts16;
          color: $white-color !important;
        }
      }

    }

    .list-group-item {
      padding: 1.6rem 2.4rem;
      border: none;
      @include themify($themes) {
        background-color: themed('menuMobileBgColor');
      }

      strong {
        font-weight: normal;
      }

      a {
        @include fts12;
        padding: 0;
        border-bottom: none;
        justify-content: flex-start;
      }

      .message {
        @include fts12;
        @include themify($themes) {
          color: themed('menuTextColor');
        }
      }
    }

    .list-group {
      margin: 0;
      padding: 0;
    }

    .tip-button {
      padding-left: 0.5rem;

      @include themify($themes) {
        color: themed('menuTextColor');
      }
    }

    .info-label {
      @include themify($themes) {
        color: themed('linkColor');
      }
    }
  }

  .notification-mobilemenu-container .notification-list label,
  .calendar-mobilemenu-container .list-group-item label {
    display: none;
  }

  .notification-mobilemenu-container .notification-list .list-group-item,
  .calendar-mobilemenu-container .list-group-item {
    padding: 1.6rem 2.4rem 0.8rem 2.4rem;
  }

  .notification-mobilemenu-container .notification-list .list-group-item a,
  .calendar-mobilemenu-container .list-group-item a {
    flex-direction: column;
    align-items: flex-start;
  }

  .bpm-dropdown-menu.pull-right,
  .bpm-hoverdown-menu.pull-right {
    right: 0 !important;
    left: 0 !important;
    width: 100% !important;
  }

  .search-button {
    display: none;
  }

}

nav.menu-main a i.menu-item-arrow {
  transition: 0.3s;
}

.mobile-detected .more-dropdown-content a.active i.menu-item-arrow,
nav.menu-main a.active i.menu-item-arrow {
  @include rotate90;
}

.theme-prime,
.theme-primepro,
.theme-gethome {

  aside.aside-mobile {
    left: 0;
    padding-top: 5rem;
  }

  .mobile-detected {

    .menu-mobile a {
      background-color: transparent;
      border-color: transparent;
    }

    .list-group-item {
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      border-left: none;
      border-right: none;
    }

    .user-dropdown-content .list-group-item {
      border-bottom: 1px solid $border-color;
    }

    .user-dropdown-content .list-group .list-group-item.user-info {
      background-color: $prime-hover-box;
    }

    .more-dropdown-content .list-group-item {

      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;

      a {
        color: $menu-bg-color;

        &.more {
          color: $prime-primary-color;
          font-weight: 600;
        }
      }
    }


  }
}

.theme-gethome {
  .mobile-detected {
    .user-dropdown-content .list-group .list-group-item.user-info {
      background-color: $gethome-cell-border;
    }

    .more-dropdown-content .list-group-item a.more {
      color: $gethome-primary-color;
    }
  }
}

@media (max-width: 1024px) {

  .theme-prime,
  .theme-primepro,
  .theme-gethome {

    .bpm-profile .profile-header {

      .profile-stat {
        @include fts12;
      }

      .profile-stat-item {
        margin-right: 0.5rem;
      }
    }

  }

}

@media (max-width: 360px) {
  .menu-mobile a {
    @include fts12;
    padding: 0.5rem;
  }

  .nav-top > .btn-group > a {
    padding: 0 1rem;
  }
}

@media (max-width: 780px) {
  .menu-mobile {
    display: block;
  }
}

@media (min-width: 780px) and (min-height: 1100px) {
  .mobile-detected .more-dropdown-content,
  .mobile-detected .user-dropdown-content {
    top: 70vh;
  }

  .aside-mobile {
    top: -110vh;
  }
}

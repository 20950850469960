/*!
 * Doka 6.10.1
 * Copyright 2019 PQINA Inc - All Rights Reserved
 * Please visit https://pqina.nl/doka/ for further information
 */
/* eslint-disable */

:root, // <- add variables to :root as well, this allows Post CSS Variables to generate fallback variables for IE11
.doka--root {

  --doka-effect--invert: 0%;
  --doka-value--alpha: calc(100% - var(--doka-effect--invert));
  --doka-value--beta: var(--doka-effect--invert);

  // primary colors
  --doka-color--alpha: #ffd843;
  --doka-color--alpha-dim: #ffc343;

  // defaults
  --doka-font-size: 16px;
  --doka-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';

  --doka-editor--max-width: 80em;
  --doka-editor--max-height: 60em;
  --doka-editor--spacing: 0;

  --doka-modal--width: 70vw;
  --doka-modal--height: 80vh;
  --doka-modal--max-width: 56rem;
  --doka-modal--max-height: 40rem;

  --doka-effect--frost: blur(10px);

  --doka-color--beta: hsl(0, 0%, var(--doka-value--alpha));
  --doka-color--gamma: hsl(0, 0%, var(--doka-value--beta));
  --doka-color--delta: hsla(0, 0%, var(--doka-value--beta), .85);
  --doka-color--epsilon: hsla(0, 0%, var(--doka-value--beta), .75);

  // styles
  --doka-editor--color: hsla(0, 0%, var(--doka-value--alpha), .9);
  --doka-editor--color-dim: hsla(0, 0%, var(--doka-value--alpha), .3);
  --doka-editor--background-color: hsla(0, 0%, var(--doka-value--beta), .99);
  --doka-editor--background-ellipse-inner-color: hsla(0, 0%, var(--doka-value--alpha), .15);
  --doka-editor--background-ellipse-outer-color: hsla(0, 0%, var(--doka-value--alpha), 0);
  --doka-editor-outline--color: hsl(0, 0%, var(--doka-value--alpha));

  --doka-modal--background: hsl(0, 0%, var(--doka-value--beta));
  --doka-modal--overlay: hsla(0, 0%, var(--doka-value--beta), .8);
  --doka-modal--shadow: 0 1em 4em rgba(0, 0, 0, .25), 0 .5em 2em rgba(0, 0, 0, .25);

  --doka-scrollbar-rail--color: var(--doka-color--delta);
  --doka-scrollbar-thumb--color: var(--doka-color--epsilon);

  --doka-scroller-rail--color: transparent;
  --doka-scroller-thumb--color: hsla(0, 0%, var(--doka-value--alpha), .2);

  --doka-component--color: var(--doka-editor--color);
  --doka-component--background: hsla(0, 0%, var(--doka-value--alpha), .15);
  --doka-component--background-dim: hsla(0, 0%, var(--doka-value--alpha), .1);
  --doka-component--background-dark: hsla(0, 0%, var(--doka-value--beta), .8);
  --doka-component--background-darker: hsla(0, 0%, var(--doka-value--beta), .9);

  --doka-component-alt--color: var(--doka-color--delta);
  --doka-component-alt--color-dim: hsla(0, 0%, var(--doka-value--beta), .75);
  --doka-component-alt--background: hsla(0, 0%, var(--doka-value--alpha), .85);
  --doka-component-alt-effect--background: hsla(0, 0%, var(--doka-value--alpha), .6);
  --doka-component-alt--edge: hsla(0, 0%, var(--doka-value--beta), .15);
  --doka-component-alt--shadow: 0 .75em 1em -.75em rgba(0, 0, 0, .4), 0 1em 2em 0 rgba(0, 0, 0, .05);

  --doka-button--color: var(--doka-component--color);
  --doka-button--background: var(--doka-component--background);
  --doka-button--background-dim: var(--doka-component--background-dim);
  --doka-button-hover--background: hsla(0, 0%, var(--doka-value--alpha), .25);

  --doka-button-alt--color: var(--doka-component-alt--color);
  --doka-button-alt--background: var(--doka-component-alt--background);
  --doka-button-alt-hover--background: hsla(0, 0%, var(--doka-value--alpha), .95);
  --doka-button-alt-effect--background: var(--doka-component-alt-effect--background);
  --doka-button-alt-effect-hover--background: hsla(0, 0%, var(--doka-value--alpha), .75);

  --doka-button-primary--color: var(--doka-color--gamma);
  --doka-button-primary--background: var(--doka-color--alpha);
  --doka-button-primary-hover--background: var(--doka-color--alpha-dim);

  --doka-button-main--radius: 9999em;
  --doka-button-tab--radius: 0;
  --doka-button-tool--radius: 0;

  --doka-dropdown-hover--background: hsla(0, 0%, var(--doka-value--beta), .1);
  --doka-dropdown-hover-active--background: hsla(0, 0%, var(--doka-value--beta), .15);
  --doka-dropdown-selected--color: var(--doka-editor--color);
  --doka-dropdown-selected--background: hsla(0, 0%, var(--doka-value--beta), .9);
  --doka-dropdown-selected-hover--background: hsla(0, 0%, var(--doka-value--beta), .8);
  --doka-dropdown-selected-active-hover--background: hsla(0, 0%, var(--doka-value--beta), .6);

  --doka-range-input--background: hsla(0, 0%, var(--doka-value--alpha), .2);
  --doka-range-input-recenter--background: var(--doka-range-input--background);
  --doka-range-input-centered--background: var(--doka-range-input--background);
  --doka-range-input-thumb--color: hsl(0, 0%, var(--doka-value--alpha));
  --doka-range-input-thumb-hover--color: hsla(0, 0%, var(--doka-value--alpha), .15);

  --doka-tab--border-color: hsla(0, 0%, 0%, .15);

  --doka-crop-focal-line--color: hsla(0, 0%, 100%, .5);
  --doka-crop-corner--color: var(--doka-color--beta);
  --doka-crop-corner-focus--color: var(--doka-color--beta);
  --doka-crop-corner--shadow: 0 .0625em .125em rgba(0, 0, 0, .2);
  --doka-crop-edge-focus--color: hsla(0, 0%, var(--doka-value--alpha), .5);

  --doka-image-outline--color: var(--doka-color--beta);
  --doka-image-focus--color: hsla(0, 0%, var(--doka-value--alpha), .25);

  --doka-status--background: hsla(0, 0%, var(--doka-value--alpha), .1);

  --doka-status-bubble--color: hsla(0, 0%, var(--doka-value--beta), .8);
  --doka-status-bubble--color-dim: hsla(0, 0%, var(--doka-value--beta), .2);
  --doka-status-bubble--background: hsla(0, 0%, var(--doka-value--alpha), .75);
  --doka-status-bubble-effect--background: hsla(0, 0%, var(--doka-value--alpha), .5);
  --doka-status-bubble--shadow: 0 .125em 1em rgba(0, 0, 0, .2);

  --doka-size-indicator--color: hsla(0, 0%, 0%, .85);
  --doka-size-indicator--background: hsla(0, 0%, 100%, .95);
  --doka-size-indicator--edge: hsla(0, 0%, 0%, .15);
  --doka-size-indicator--radius: .25em;

  --doka-button-zoom--color: hsla(0, 0%, 0%, .85);
  --doka-button-zoom--background: hsla(0, 0%, 100%, .85);
  --doka-button-zoom-hover--background: hsla(0, 0%, 100%, .95);
  --doka-button-zoom-effect--background: hsla(0, 0%, 100%, .6);
  --doka-button-zoom-effect-hover--background: hsla(0, 0%, 100%, .75);

  --doka-filter-tile--radius: 0;
  --doka-filter-tile-selected--color: var(--doka-color--alpha);

  --doka-markup-manipulator--fill: #2990ff;
  --doka-markup-manipulator--stroke: #fff;
  --doka-markup-manipulator--stroke-inverted: #000;
  --doka-markup-manipulator--line: rgba(255, 255, 255, .75);
  --doka-markup-manipulator--line-inverted: rgba(0, 0, 0, .75);

  --doka-instruction-bubble--color: #fff;
  --doka-instruction-bubble--background: radial-gradient(closest-side ellipse,
          rgba(0, 0, 0, .22) 20%,
          rgba(0, 0, 0, .21) 35%,
          rgba(0, 0, 0, .18) 50%,
          rgba(0, 0, 0, .1) 70%,
          rgba(0, 0, 0, .05) 80%,
          rgba(0, 0, 0, .01) 90%,
          transparent
  );
  --doka-instruction-bubble--color-shadow: 0 .125em .75em rgba(0, 0, 0, 1);

  --doka-menu--offset: 12em;
}

.doka--root {

  label,
  button,
  fieldset,
  legend,
  input {
    all: initial;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
  }

  * {
    box-sizing: inherit;

    &::after,
    &::before {
      box-sizing: inherit;
    }
  }

  button {
    box-sizing: border-box;
    text-align: center;
  }
}

.doka--root {

  .doka--view-stack {
    pointer-events: none;

    > * {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      outline: transparent;
    }

    [data-view-active='false'] * {
      pointer-events: none !important;
    }

    [data-view-active='true'] {
      z-index: 1;
    }

    [data-view-hidden='true'] {
      display: none !important;
    }

  }

}

.doka--root {

  .doka--button-app {

    // safeguards against overrides
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    font-family: inherit;
    font-size: 1em;
    outline: transparent;

    // color
    color: var(--doka-button--color);
    background-color: var(--doka-button--background);
    border-radius: var(--doka-button-main--radius);


    // custom styles
    display: flex;
    align-items: center;
    line-height: 2.5;
    padding: 0 1.5em;
    font-weight: 500;
    transition: transform .15s ease-out, background-color .25s ease-out, color .25s ease-out;

    // fix svg alignment in iOS 10 (Older versions of Safari do not support display flex on buttons)
    svg {
      margin: 0 auto;
      display: inline-block;
      width: auto;
      height: auto;
    }

    // if svg
    svg + span {
      margin-left: .5em;
    }

    span {
      font-size: .875em;
    }

    &:focus,
    &:hover {
      background-color: var(--doka-button-hover--background);
    }

    // tap effect
    transform: scale(1);

    &:active {
      transition-duration: .25s;
      transform: scale(.95);
    }
  }

  @supports (backdrop-filter: var(--doka-effect--frost)) {
    .doka--button-app {
      backdrop-filter: var(--doka-effect--frost);
    }
  }

  // make square
  .doka--button-app.doka--button-icon-only {
    width: 2.25em;
    height: 2.25em;
    padding: 0;
  }

  .doka--button-action-reset svg {
    max-width: 1.625em;
  }

  // different app buttons and their actions
  .doka--button-action-confirm {
    color: var(--doka-button-primary--color);
    background-color: var(--doka-button-primary--background);
    font-weight: 600;
    backdrop-filter: none;

    &:focus,
    &:hover {
      background-color: var(--doka-button-primary-hover--background);
    }
  }
}


// small viewport and multiple tools active
.doka--root[data-style-viewport~='x-cramped'][data-style-viewport~='multi-util'] {

  .doka--button-app {
    font-size: .85em;
    backdrop-filter: none;

    svg {
      width: 1.25em;
      height: 1.25em;
    }
  }

  .doka--button-action-confirm {
    font-size: .75em;
  }

  .doka--button-app:not(.doka--button-action-confirm) {
    color: var(--doka-button--color);
    background-color: transparent;
  }

  .doka--button-app.doka--button-icon-only {
    svg {
      width: 1.75em;
      height: 1.75em;
    }
  }

  .doka--button-app.doka--button-icon-fallback {
    width: 2.5em;
    height: 2.5em;
    padding: 0;

    span {
      position: absolute;
      opacity: 0;
      width: 1px;
      height: 1px;
      overflow: hidden;
    }
  }

}

.doka--root {

  .doka--button-icon-fallback {
    svg {
      display: none;
    }

    svg + span {
      margin-left: 0;
    }
  }

  &[data-style-viewport*='x-cramped'][data-style-viewport*='multi-util'] {

    .doka--button-icon-fallback {
      svg {
        display: block;
      }

      svg + span {
        margin-left: 0;
      }
    }

  }
}

.doka--root {

  .doka--button-icon-only {
    flex-shrink: 0;

    span {
      position: absolute;
      opacity: 0;
      width: 1px;
      height: 1px;
      overflow: hidden;
    }
  }

}

.doka--root {

  .doka--button-tab {
    // safeguards against overrides
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    font-family: inherit;
    font-size: 1em;
    outline: transparent;
    line-height: inherit;

    // custom styles
    color: var(--doka-button--color);
    border-radius: var(--doka-button-tab--radius);
    width: 5em;
    max-width: 5em;
    padding-top: .875em;
    padding-bottom: .75em;
    background-color: transparent;
    font-weight: 500;
    transition: transform .15s ease-out, background-color .25s ease-out, color .25s ease-out;

    svg {
      display: inline;
      margin-bottom: .325em;
      max-height: 1.5em; // fixes problem with missing button titles on IE11
    }

    span {
      display: block;
      font-size: .875em;
    }

    &:focus,
    &:hover {
      backdrop-filter: var(--doka-effect--frost);
      background-color: var(--doka-button--background);
    }

    &[data-active='true'] {
      background-color: var(--doka-button--background);

      &:focus,
      &:hover {
        background-color: var(--doka-button-hover--background);
      }
    }

    // tap effect
    transform: scale(1);

    &:active {
      transition-duration: .25s;
      transform: scale(.95);
    }

  }

  .doka--button-tab {
    .doka--icon-resize-arrow-ne,
    .doka--icon-resize-arrow-sw {
      transition: opacity .25s ease-out;
    }

    &[data-scale-direction='down'] {
      .doka--icon-resize-arrow-ne {
        opacity: 0;
      }

      .doka--icon-resize-arrow-sw {
        opacity: 1;
      }
    }

    &[data-scale-direction='up'] {
      .doka--icon-resize-arrow-ne {
        opacity: 1;
      }

      .doka--icon-resize-arrow-sw {
        opacity: 0;
      }
    }
  }

  @supports (backdrop-filter: var(--doka-effect--frost)) {
    .doka--button-tab[data-active='true'] {
      backdrop-filter: var(--doka-effect--frost);
    }
  }


  .doka--utils[data-layout='compact'] {
    .doka--button-tab {
      height: 4.6875em;

      span {
        display: none;
      }

      svg {
        margin-bottom: 0;
      }
    }
  }

  &[data-style-viewport*='x-cramped'] {

    .doka--utils {

      padding: 0 1px;

      &[data-util-count='5'] {
        .doka--button-tab {
          span {
            display: none;
          }

          width: 20%;
        }
      }

      &[data-util-count='4'] {
        .doka--button-tab {
          width: 25%;
        }
      }

      &[data-util-count='3'] {
        .doka--button-tab {
          width: 33.333%;
        }
      }

      &[data-util-count='2'] {
        .doka--button-tab {
          width: 50%;
        }
      }

      .doka--button-tab {

        min-width: 2em;
        max-width: 3.5em;

        height: 3.25em;
        padding: .325em 0 .325em;
        box-shadow: 0 0 0 1px var(--doka-tab--border-color);

        &:first-child::before,
        &:last-child::before {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          content: '';
          pointer-events: none;
        }

        &:first-child::before {
          box-shadow: -1px 0 0 0 var(--doka-tab--border-color);
        }

        &:last-child::before {
          box-shadow: 1px 0 0 0 var(--doka-tab--border-color);
        }

        svg {
          width: 1em;
          margin: 0 auto -.125em;
        }

        span {
          font-size: .5625em;
          margin-top: .5em;
        }

        &:active {
          transform: scale(1);
        }

        &[data-active='true'] {
          backdrop-filter: none;

          &:focus,
          &:hover {
            background-color: var(--doka-button--background);
          }
        }

      }
    }

  }

}

.doka--root {

  .doka--button-tool {

    // safeguards against overrides
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    border: none;
    font-family: inherit;
    font-size: 1em;
    font-weight: 400;
    outline: transparent;

    // styles
    border-radius: var(--doka-button-tool--radius);
    color: var(--doka-button--color);
    display: flex;
    padding: 0 .75em 0 .5em;
    align-items: center;
    background-color: transparent;
    transition: transform .15s ease-out, background-color .25s ease-out, color .25s ease-out;

    span {
      font-size: .875em;
      margin-left: .5em;
    }

    // keyboard and mouse interaction
    &:focus,
    &:hover {
      backdrop-filter: var(--doka-effect--frost);
      background-color: var(--doka-button--background);
    }

    // tap effect
    transform: scale(1);

    &:active {
      transition-duration: .25s;
      transform: scale(.95);
    }
  }

  [data-layout='compact'] .doka--button-tool {

    padding: 0 .5em;
    border-radius: .25em;
    background-color: var(--doka-button--background-dim);
    backdrop-filter: var(--doka-effect--frost);

    // fixes stretched buttons on Safari
    width: 2.6em;
    box-sizing: border-box;
    flex-grow: 0;
    // end fix

    // keyboard and mouse interaction
    &:focus,
    &:hover {
      background-color: var(--doka-button--background);
    }

    // hide text
    span {
      position: absolute;
      opacity: 0;
      width: 1px;
      height: 1px;
      overflow: hidden;
    }
  }

}


.doka--root {

  .doka--button-zoom {

    // safeguards against overrides
    position: static;
    margin: 0;
    padding: 0;
    border: none;
    font-family: inherit;
    font-size: 1em;
    outline: transparent;

    // custom styles
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 2.5;
    font-weight: 500;
    transition: transform .15s ease-out, background-color .25s ease-out, color .25s ease-out;
    border-radius: 9999em;
    height: 2.5em;
    width: 2.5em;

    color: var(--doka-button-zoom--color);
    background-color: var(--doka-button-zoom--background);

    span {
      position: absolute;
      opacity: 0;
      width: 1px;
      height: 1px;
      overflow: hidden;
    }

    &:focus,
    &:hover {
      background-color: var(--doka-button-zoom-hover--background);
    }

  }

  @supports ( backdrop-filter:var(--doka-effect--frost) ) {

    .doka--button-zoom {
      background-color: var(--doka-button-zoom-effect--background);
      backdrop-filter: var(--doka-effect--frost);

      &:focus,
      &:hover {
        background-color: var(--doka-button-zoom-effect-hover--background);
      }
    }
  }
}

.doka--root {

  .doka--instructions-bubble {

    color: var(--doka-instruction-bubble--color);
    background: var(--doka-instruction-bubble--background);
    filter: drop-shadow(var(--doka-instruction-bubble--color-shadow));

    position: absolute;
    left: 0;
    top: 0;
    max-width: 15em;
    line-height: 1.5;
    text-align: center;
    padding: 3em 4em 4em;
    font-size: .75em;
    pointer-events: none;

    display: flex;
    flex-direction: column;
    align-items: center;

    svg:first-child {
      margin-bottom: .5em;
    }
  }

}

//#if !_EXCLUDE_RESIZE_UTIL
.doka--root {

  .doka--checkable {

    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 2.15625em;
    line-height: 1.5;
    color: var(--doka-component--color);

    input {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: 0;
      opacity: 0;
      outline: transparent;
    }

    label {
      display: inline-block;
      cursor: pointer;
      transition: opacity .125s ease-out;
      font-size: .875em;
      margin: 0;
      color: inherit;
    }

    input:active + label,
    input:focus + label {
      background-color: var(--doka-component--background);
      border-radius: .5em;
    }

    svg {
      display: block;
      margin: 0;
    }

    path {
      transition: transform .1s ease-out;
    }

    // tap effect
    transform: scale(1);

    &:active {
      transition-duration: .25s;
      transform: scale(.925);
    }
  }
}

//#endif
//#if !_EXCLUDE_COLOR_UTIL
.doka--root {

  .doka--color {
    height: calc(100% - 3em);
    width: calc(100% - 2em);
    left: 1em;
    top: 1em;


    .doka--color-form {

      display: flex;
      align-items: flex-start;
      // justify-content: center;
      max-width: 45em;
      margin: 0 auto;
      padding: 1em 0 0 0;
      height: 6.5em;
      box-sizing: border-box;

      // this limits reflows to the subject container
      contain: layout size style;
    }

    .doka--range-input {

      pointer-events: all;
      margin: 0 .5em;
      // max-width: 10em;
      display: flex;
      flex-direction: column-reverse;
      align-items: stretch;
      flex: 1;

      label {
        color: inherit;
        margin-top: .25em;
        font-size: .75em;
        align-self: center;
        display: flex;
        align-items: center;
      }

      svg {
        display: inline-block;
        width: 1.25em;
        margin-right: .5em;
        max-height: 1.5em; // fix issue on IE11
      }

      [stroke-width] {
        stroke-width: 2.5;
      }

    }
  }

  &[data-style-viewport*='x-cramped'] {

    .doka--color {
      top: 2em;
      display: flex;
      flex-direction: column-reverse;
    }

    .doka--color-form {
      padding: .5em 0 0 0;
      height: 5em;
    }

    // wrap
    .doka--color-form {
      flex-wrap: wrap;
      margin: 0 -.5em;
      align-self: center;
      width: 100%;
      max-width: 25em;
      padding: 0;
    }

    .doka--range-input {
      width: calc(50% - 1em);
      max-width: none;
      flex: auto;
      flex-direction: row;
      margin: 0 .25em;

      label {
        margin: 0;
        width: 1.25em;
        overflow: hidden;

        svg {
          margin: 0;
        }

        span {
          display: none;
        }

      }
    }

    .doka--range-input:nth-child(even) {
      flex-direction: row-reverse;
    }

  }
}


@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .doka--color-form .doka--range-input {
    flex: none;
    width: 10em;
  }
}

//#endif
.doka--root {

  .doka--container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    position: relative;

    // allow manipulation
    touch-action: manipulation;

    // prevent content from leaking outside of the container
    &[data-limit-overflow='true'] {
      overflow: hidden;
    }
  }

  &[data-style-viewport*='x-cramped'] {

    .doka--container {
      flex-direction: column-reverse;
    }

  }
}

.doka--root {

  .doka--content {
    position: relative;
    flex: 1;

    > * {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .doka--view-stack {
    z-index: 2;
  }

  .doka--image {
    z-index: 1;
  }

}

//#if !_EXCLUDE_CROP_UTIL
.doka--root {

  .doka--crop-mask {
    position: absolute;
    left: 0;
    top: 0;
    will-change: transform;
    pointer-events: none;
  }

}

//#endif
//#if !_EXCLUDE_CROP_UTIL
.doka--root {

  $crop-button-size: 20px;
  $crop-button-size-quarter: $crop-button-size * .25;
  $crop-button-size-half: $crop-button-size * .5;
  $crop-button-size-double: $crop-button-size * 2;

  $crop-button-size-small: 16px;
  $crop-button-size-small-quarter: $crop-button-size-small * .25;
  $crop-button-size-small-half: $crop-button-size-small * .5;
  $crop-button-size-small-double: $crop-button-size-small * 2;

  .doka--crop-rect {
    z-index: 2;
    position: relative;
  }

  .doka--crop-rect-corner {
    color: transparent;
    position: absolute;
    pointer-events: all;
    z-index: 3;
    padding: 0;
    margin: 0;
    border: none;
    text-indent: 0;
    width: $crop-button-size;
    height: $crop-button-size;
    left: -$crop-button-size-half;
    top: -$crop-button-size-half;
    will-change: transform;

    // makes touch areas for buttons larger
    &::after {
      position: absolute;
      left: -1em;
      right: -1em;
      top: -1em;
      bottom: -1em;
      content: '';
    }

  }

  .doka--crop-rect-corner- {

    &ne,
    &sw {
      cursor: nesw-resize !important;
    }

    &nw,
    &se {
      cursor: nwse-resize !important;
    }

  }

  &[data-style-crop-corner='circle'] {
    .doka--crop-rect-corner {
      background: var(--doka-crop-corner--color);
      box-shadow: var(--doka-crop-corner--shadow);
      border-radius: 9999em;
      transition: background-color .25s ease-in-out;
    }
  }

  &[data-style-crop-corner='line'] {

    $color: var(--doka-crop-corner--color);
    $size: .25em;
    $sizeNeg: -1 * $size;
    $offset: .4375em;

    .doka--crop-rect-corner::before {
      position: absolute;
      width: 2.5em;
      height: 2.5em;
      pointer-events: none;
      transition: opacity .25s ease-out;
      content: '';
    }

    .doka--crop-rect-corner-nw::before {
      box-shadow: inset $size $size 0 0 $color;
      left: $offset;
      top: $offset;
    }

    .doka--crop-rect-corner-ne::before {
      box-shadow: inset $sizeNeg $size 0 0 $color;
      right: $offset;
      top: $offset;
    }

    .doka--crop-rect-corner-se::before {
      box-shadow: inset $sizeNeg $sizeNeg 0 0 $color;
      right: $offset;
      bottom: $offset;
    }

    .doka--crop-rect-corner-sw::before {
      box-shadow: inset $size $sizeNeg 0 0 $color;
      left: $offset;
      bottom: $offset;
    }
  }

  &[data-style-crop-corner='line'] {

    .doka--crop-rect[data-indicator-size='none'] {
      .doka--crop-rect-corner::before {
        opacity: 0;
      }
    }

  }


  .doka--crop-rect-focal-line {
    background: var(--doka-crop-focal-line--color);
    position: absolute;
    left: 0;
    top: 0;
    height: 100px;
    width: 100px;
    transform-origin: left top;
    z-index: 3;
    pointer-events: none;
    will-change: transform, opacity;
  }

  .doka--crop-rect-edge {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    border: 0;
    background: transparent;
    transform-origin: left top;
    z-index: 2;
    pointer-events: all;

    // added
    will-change: transform;

    // focus style
    &:focus {
      outline: transparent;

      &::after {
        position: absolute;
        content: '';
        background-color: var(--doka-crop-edge-focus--color);
      }
    }
  }

  .doka--crop-rect-edge- {

    &n,
    &s {
      width: 100px;
      top: -20px;
      height: 40px;
      cursor: ns-resize !important;

      &::after {
        left: 0;
        right: 0;
        top: 17px;
        width: 100%;
        height: 7px;
      }

    }

    &e,
    &w {
      cursor: ew-resize !important;
      height: 100px;
      left: -20px;
      width: 40px;

      &::after {
        top: 0;
        bottom: 0;
        left: 17px;
        height: 100%;
        width: 7px;
      }

    }

  }


  &[data-style-viewport*='touch'][data-style-crop-corner='circle'] {

    .doka--crop-rect-corner {

      // makes touch areas for buttons visible
      &::after {
        border-radius: 50%;
        opacity: 0;
        background: var(--doka-crop-corner-focus--color);
        box-shadow: 0 0 0 1em var(--doka-crop-corner-focus--color);
      }

      &:active::after {
        opacity: .25;
      }

    }

  }

  &[data-style-viewport*='touch'] {

    .doka--crop-rect-edge {

      // focus style
      &:active {
        outline: transparent;

        &::after {
          position: absolute;
          background-color: var(--doka-crop-edge-focus--color);
          content: '';
        }
      }

    }

  }


  &[data-style-viewport*='x-cramped'] {

    .doka--crop-rect-corner {
      width: $crop-button-size-small;
      height: $crop-button-size-small;
      left: -$crop-button-size-small-half;
      top: -$crop-button-size-small-half;
    }

  }

  &[data-style-viewport*='x-cramped'][data-style-crop-corner='line'] {

    $offset: .325em;

    .doka--crop-rect-corner-nw::before {
      left: $offset;
      top: $offset;
    }

    .doka--crop-rect-corner-ne::before {
      right: $offset;
      top: $offset;
    }

    .doka--crop-rect-corner-se::before {
      right: $offset;
      bottom: $offset;
    }

    .doka--crop-rect-corner-sw::before {
      left: $offset;
      bottom: $offset;
    }
  }
}

//#endif
//#if !_EXCLUDE_CROP_UTIL
.doka--root {

  .doka--crop-resizer {

    $size: 44px;

    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background: transparent;
    pointer-events: none;

    div {
      position: absolute;
      cursor: none;
      left: 0;
      top: 0;
      width: $size;
      height: $size;
      margin-left: $size * -.5;
      margin-top: $size * -.5;
      background: hsla(0, 0%, 100%, .85);
      border-radius: $size * .5;
      box-shadow: 0 2px 5px rgba(0, 0, 0, .5);
      pointer-events: all;
      backdrop-filter: var(--doka-effect--frost);
      opacity: 0;
      transition: opacity .25s ease-out, visibility .25s ease-out;
    }

    &[data-state='multi-touch'] {
      visibility: visible;
      cursor: none;
      pointer-events: all;

      div {
        opacity: 1;
      }
    }
  }
}

//#endif
//#if !_EXCLUDE_CROP_UTIL
.doka--root {

  .doka--crop-rotator {
    position: relative;
    height: 3.5em;
    margin-top: -.5em;
    margin-bottom: .5em;
    z-index: 2;
    pointer-events: all;
  }

  .doka--crop-rotator-center {

    left: 50%;
    position: absolute;
    overflow: hidden;
    width: 2em;
    top: 0.325em;
    height: 1.5em;
    font-size: .75em;
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0 0 0 -1em;
    background: transparent;
    opacity: .3;
    z-index: 2;
    color: inherit;

    // hide text
    span {
      color: transparent;
    }

    // render line
    &::after {
      position: absolute;
      left: 50%;
      height: .85em;
      border-radius: 9999em;
      width: 1.5px;
      color: inherit;
      background: currentColor;
      transition: background-color .25s ease-in-out;
      margin-left: -.75px;
      top: 50%;
      margin-top: -.55em;
      pointer-events: none;
      content: '';
    }

    &:focus {
      outline: transparent;
      opacity: .7;
    }

  }

  .doka--crop-rotator-bar {
    position: absolute;
    left: 1em;
    right: 1em;
    bottom: 0;
    top: 0;
    z-index: 1;
    cursor: ew-resize;
    user-select: none;
  }

  .doka--crop-rotator-line-mask {
    height: 100%;
    overflow: hidden;
    max-width: 30em;
    margin: 0 auto;
  }

  &[data-style-viewport*='x-cramped'] {

    .doka--crop-rotator {
      margin-top: -.5em;
    }

    .doka--crop-rotator-line-mask {
      max-width: 14em;
    }

  }

  .doka--crop-rotator-line {

    pointer-events: none;
    height: 100%; // IE11 needs this to render the line

    svg {
      position: absolute;
      left: 50%;
      margin-left: -500px;
      width: 1000px;
      max-width: none;
    }

    text {
      font-weight: 400;
      font-size: 2px;
      opacity: .35;
    }

    circle {
      opacity: .4;
    }

    circle[r='0.5'] {
      opacity: .9;
    }

  }

  .doka--crop-rotator:focus {
    outline: transparent;

    .doka--crop-rotator-line {

      text {
        fill: currentColor;
      }

      circle {
        fill: currentColor;
      }

    }

  }
}

// TODO: TEST WHY mask-image evaluates to true on Edge
@supports (mask-image: url('')) and (mix-blend-mode:screen) {

  .doka--root[data-style-viewport*='x-cramped'] .doka--crop-rotator-line-mask,
  .doka--root .doka--crop-rotator-line-mask {

    max-width: 40em;
    margin: 0 auto;

    mask: linear-gradient(
                    to right,
                    rgba(0, 0, 0, 0) 15%,
                    rgba(0, 0, 0, 1) 30%,
                    rgba(0, 0, 0, 1) 70%,
                    rgba(0, 0, 0, 0) 85%
    );

  }

}

//#endif
//#if !_EXCLUDE_CROP_UTIL
.doka--root {

  .doka--crop-size {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    overflow: hidden;

    // style
    color: var(--doka-size-indicator--color);
    background: var(--doka-size-indicator--background);
    backdrop-filter: var(--doka-effect--frost);
    border-radius: var(--doka-size-indicator--radius);
    line-height: 1;
    white-space: nowrap;
    transition: background-color .25s ease-in-out, color .25s ease-in-out;

    // layout
    transform-origin: center center;
    display: flex;
    align-items: center;

    // no interaction
    pointer-events: none;

    // font style
    font-variant-numeric: tabular-nums;
    font-feature-settings: 'tnum';

    // font that supports tabular nums on firefox
    font-family: Tahoma, Geneva, Verdana, sans-serif;
  }

  .doka--crop-size-info {
    font-size: .6875em;
    padding: .35em .625em;

    will-change: transform;
  }

  .doka--crop-resize-percentage {

    &:empty {
      display: none;
    }

    padding-right: .4375em;
    margin-right: -.25em;
    border-right: 1px solid var(--doka-size-indicator--edge);
  }

  .doka--crop-size-multiply {
    opacity: .75;
    margin: 0 .175em;
  }

  &[data-style-viewport*='x-cramped'] {
    .doka--crop-size-info {
      font-size: .5em;
      padding: .5em .875em;
    }

    .doka--crop-resize-percentage {
      padding-right: .4em;
    }
  }
}

//#endif
//#if !_EXCLUDE_CROP_UTIL
.doka--root {

  .doka--crop-subject {
    position: relative;
    flex: 1 0 auto; // instead of flex:1, fixed problems with overlay on IE11
    margin: .25em 1.5em 1.5em;

    // this limits reflows to the subject container
    contain: layout size style;
  }

  // make the side gutters just a little bit less wide,
  // but not too narrow as otherwise the back
  // interaction on iOS interferes
  &[data-style-viewport*='x-cramped'] {

    .doka--crop-subject {
      margin: 0 2em 1em;
    }

  }

  .doka--zoom-wrapper {
    position: relative;
    z-index: 2;
    pointer-events: all;
  }

  .doka--button-zoom {
    position: absolute;
    z-index: 2;
    left: -1.25em;
    top: -1.25em;
  }

}

//#endif
//#if !_EXCLUDE_CROP_UTIL
.doka--root {

  .doka--view-stack .doka--crop {
    display: flex;
    flex-direction: column;
  }

}

//#endif
.doka--root {

  .doka--dropdown {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > .doka--button {
      height: 100%;
    }
  }

  .doka--dropdown-list {

    position: absolute;
    z-index: 3;
    padding: 0;
    top: 100%;
    margin: -.125em 0 0 0;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: .1625em;
    background-color: var(--doka-component-alt--background);
    box-shadow: var(--doka-component-alt--shadow);

    .doka--button {

      // safeguards against overrides
      position: static;
      width: auto;
      height: auto;
      margin: 0;
      padding: 0;
      border: none;
      font-family: inherit;
      font-size: 1em;
      outline: transparent;
      pointer-events: inherit;

      // custom styles
      display: flex;
      align-items: center;
      white-space: nowrap;
      border: none;
      line-height: 2.5;
      margin: 3px;
      padding: 0 .75em 0 .5em;
      border-radius: .125em;
      color: var(--doka-component-alt--color);
      background-color: transparent;

      span {
        font-size: .875em;
        text-align: left;
        flex: 1;
      }

      svg + span {
        margin-left: .5em;
      }

      svg {
        display: inline-block;
        margin: 0;
      }

      &:active,
      &:focus,
      &:hover {
        background-color: var(--doka-dropdown-hover--background);
      }

      &:active:hover,
      &:focus:hover {
        background-color: var(--doka-dropdown-hover-active--background);
      }

      // add button click state
      transform: none;

      &:active {
        transform: none;
      }
    }

    .doka--button:first-child,
    .doka--button:last-child {
      border-radius: inherit;
    }

    .doka--button:first-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .doka--button:last-child {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    .doka--button + .doka--button {
      margin-top: -2px;
    }

    [aria-selected=true] {
      color: var(--doka-dropdown-selected--color);
      background-color: var(--doka-dropdown-selected--background);

      &:active,
      &:focus,
      &:hover {
        background-color: var(--doka-dropdown-selected-hover--background);
      }

      &:active:hover,
      &:focus:hover {
        background-color: var(--doka-dropdown-selected-active-hover--background);
      }
    }
  }

  @supports (backdrop-filter: var(--doka-effect--frost)) {
    .doka--dropdown-list {
      background-color: var(--doka-component-alt-effect--background);
      backdrop-filter: var(--doka-effect--frost);
    }
  }

  &[data-style-viewport*='x-cramped'] {

    .doka--dropdown {
      position: static;
    }

    .doka--dropdown-list {
      position: absolute;
      min-width: auto;
      top: 3em;
      left: 3em;
      right: 3em;
      max-width: 15em;
      margin: 0 auto;
    }
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .doka--root .doka--dropdown-list {
    min-width: 10em;
  }
}

.doka--root {

  .doka--edit-status {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;

    // this limits reflows to the subject container
    contain: strict;

    // center content
    display: flex;
    align-items: center;
    justify-content: center;

    // position all message bubbles in the center of the view
    .doka--status-bubble {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
    }

    // pointer events pass through by default
    pointer-events: none;
    opacity: 0;
    transition: opacity .25s ease-out;

    // style
    background: var(--doka-status--background);

    // if busy, blocks events
    &[data-view-status='busy'] {
      pointer-events: all;
      opacity: 1;
    }

    &:empty {
      display: none;
    }
  }

}

.doka--root {

  .doka--editor {

    // always scale to fit parent container size
    height: 100%;
    width: 100%;

    // grow to maximum horizontal size
    flex: 1 0 auto; // instead of flex:1, fixes problems with centering the editor on IE11
    max-width: var(--doka-editor--max-width);
    max-height: var(--doka-editor--max-height);

    // hidden by default unless overruled by inline styles
    // prevents flash of menu buttons
    opacity: 0;
  }

}

//#if !_EXCLUDE_FILTER_UTIL
.doka--root {

  .doka--filter-list {
    display: inline-flex;
    align-items: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: .25em .5em;
      padding: 0;
    }

    li:first-child {
      margin-left: 0;
    }

    li:last-child {
      margin-right: 0;
    }
  }

}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .doka--root .doka--filter-list li {
    flex: none;
    width: 4em;
  }
}

//#endif
//#if !_EXCLUDE_FILTER_UTIL
.doka--root {

  .doka--filter-tile.doka--filter-tile {

    position: relative;
    border-radius: var(--doka-filter-tile--radius);

    &::before {
      display: block;
      padding-top: 100%;
      content: '';
      height: 0;
    }

    &[style*='transform:translate3d(0px, 0px, 0)'] {
      transform: none !important;
    }

    label,
    div {
      transition: box-shadow .125s ease-out, color .125s ease-out;
    }

    label {
      position: absolute;
      color: var(--doka-component--color);
      text-align: center;
      font-size: .6875em;
      top: calc(100% + .5em);
      width: 100%;
      left: 0;
      padding: 0;
    }

    > div {
      margin-bottom: .5em;
      z-index: 2;
      border-radius: var(--doka-filter-tile--radius);
      overflow: hidden;
      box-shadow: 0 0 .75em rgba(0, 0, 0, .1);
      pointer-events: none;

      canvas {
        z-index: 1;
      }

      div {
        z-index: 2;
      }
    }

    canvas, input, div {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      min-width: auto;
      min-height: auto;
      font-size: 1em;
    }

    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      position: absolute;
      left: 0;
      top: 0;
      margin: 0;
      padding: 0;
      border-radius: 0;
      opacity: 0;
      outline: transparent;
      pointer-events: all;
    }

    input:checked {
      ~ label {
        color: var(--doka-filter-tile-selected--color);
      }

      ~ div {
        box-shadow: 0 .5px 0 1px rgba(0, 0, 0, .2),
        0 0 0 4px var(--doka-filter-tile-selected--color);
      }
    }

    input:focus {
      ~ label {
        color: var(--doka-filter-tile-selected--color);
      }
    }

  }
}

//#endif
//#if !_EXCLUDE_FILTER_UTIL
.doka--root {

  .doka--filter {
    height: calc(100% - 3em);
    width: calc(100% - 2em);
    left: 1em;
    top: 1em;

    .doka--filter-list {
      padding-bottom: 1.5em;
    }

    .doka--filter-scroller {
      margin-bottom: 1em;
    }

  }

  // scroll
  .doka--filter-scroller {
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-x: none;
    touch-action: pan-x;
    text-align: center;
    pointer-events: all;
    width: 40em;
    margin: 0 auto;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    // Firefox scrollbar styles, hidden by default
    scrollbar-width: none;
    scrollbar-color: var(--doka-scroller-thumb--color) transparent;
  }

  // on Edge the scrollbar is too ugly so we hide it, user can still drag with mouse
  @supports (-ms-ime-align: auto) {
    .doka--filter-scroller {
      overflow-x: hidden;
    }
  }

  .doka--filter-scroller[data-drag-state='dragging'] input {
    pointer-events: none !important;
  }

  .doka--filter-scroller::-webkit-scrollbar {
    height: .375em;
    background-color: var(--doka-scroller-rail--color);
  }

  .doka--filter-scroller::-webkit-scrollbar-track {
    background: transparent;
  }

  .doka--filter-scroller::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 9999rem;
    border: 3px solid var(--doka-scroller-rail--color);
    transition: background-color .25s ease-in-out;
  }

  @media (pointer: fine) and (hover: hover) {

    .doka--filter-scroller:hover {
      scrollbar-width: thin;
    }

    .doka--filter-scroller:hover::-webkit-scrollbar-thumb {
      background-color: var(--doka-scroller-thumb--color);
    }

  }

  .doka--filter-list {
    padding: 0 2em;
  }

  .doka--filter-tile {
    flex: none;
    width: 4em;
    scroll-snap-align: start;
  }


  &[data-style-viewport*='x-cramped'] {
    .doka--filter-tile {
      width: 2.875em;
    }
  }


  &[data-style-viewport*='x-cramped'] {

    .doka--filter {
      display: flex;
      flex-direction: column-reverse;
      top: 2em;

      .doka--filter-list {
        padding: 0 1em;
        height: 5em;
      }
    }

    .doka--filter-scroller {
      scroll-snap-type: proximity;
      scroll-snap-type: x proximity;
      scroll-padding: 2em;
      margin-bottom: 0;
    }

  }

  // IE
  .doka--filter-scroller {
    scrollbar-base-color: #111;
    scrollbar-face-color: #333;
    scrollbar-3dlight-color: #111;
    scrollbar-highlight-color: #111;
    scrollbar-track-color: #111;
    scrollbar-arrow-color: #111;
    scrollbar-shadow-color: #111;
    // -ms-overflow-style: -ms-autohiding-scrollbar;
  }

}

// TODO: TEST WHY mask-image evaluates to true on Edge
@supports (mask-image: url('')) and (mix-blend-mode:screen) {

  .doka--root .doka--filter-scroller {
    margin: 0 auto;
    mask: linear-gradient(
                    to right,
                    transparent 0,
                    #000 2em,
                    #000 calc(100% - 2em),
                    transparent 100%
    ),
    linear-gradient(to top, #000 8px, transparent 8px);
  }

  .doka--root[data-style-viewport*='x-cramped'] {
    .doka--filter-scroller {
      mask: linear-gradient(
                      to right,
                      transparent 0,
                      #000 1em,
                      #000 calc(100% - 1em),
                      transparent 100%
      );
    }
  }
}

//#endif
.doka--root {

  .doka--image-gl {
    user-select: none;

    canvas {
      position: absolute;
      left: 0;
      top: 0;
      color: var(--doka-editor-outline--color);
      background: var(--doka-editor--background-color);
      max-width: none;
    }

    &[tabindex='0'] canvas {
      pointer-events: all;
      cursor: move;
    }

    &::after {
      content: '';
      transition: opacity .15s ease-in-out;
      opacity: 0;
      position: absolute;
      left: calc(50% - 1.3em);
      top: calc(50% - 1.3em);
      width: 2.75em;
      height: 2.75em;
      pointer-events: none;
      border-radius: 9999em;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 2em;
      background-color: rgba(0, 0, 0, .5);
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='rgba(255,255,255,.9)' stroke-width='1.5'%3E%3Crect x='8.5' y='3.5' width='7' height='7' rx='2'/%3E%3Crect x='1.5' y='10.5' width='7' height='7' rx='2'/%3E%3Crect x='8.5' y='10.5' width='7' height='7' rx='2'/%3E%3Crect x='15.5' y='10.5' width='7' height='7' rx='2'/%3E%3C/g%3E%3C/svg%3E");
    }

    &[data-show-interaction-indicator='true']:focus::after {
      opacity: 1;
    }

  }

}

//#if !_EXCLUDE_MARKUP_UTIL
.doka--root {

  .doka--button-destroy-shape {

    // safeguards against overrides
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    margin: 0;
    border: none;
    font-family: inherit;
    outline: transparent;
    width: auto;
    height: auto;

    // custom styles
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 2.5;
    font-weight: 500;
    transition: opacity .15s ease-out, background-color .25s ease-out, color .25s ease-out;
    border-radius: 9999em;
    padding: 0 1em;
    font-size: .75em;
    opacity: 0;
    color: var(--doka-button-zoom--color);
    background-color: var(--doka-button-zoom--background);

    span {
      pointer-events: none;
    }

    &:focus,
    &:hover {
      background-color: var(--doka-button-zoom-hover--background);
    }

    &[data-active='true'] {
      opacity: 1 !important;
    }

    &[data-active='false'] {
      opacity: 0 !important;
      pointer-events: none;
    }
  }

  @supports ( backdrop-filter:var(--doka-effect--frost) ) {
    .doka--button-destroy-shape {
      background-color: var(--doka-button-zoom-effect--background);
      backdrop-filter: var(--doka-effect--frost);

      &:focus,
      &:hover {
        background-color: var(--doka-button-zoom-effect-hover--background);
      }
    }
  }


  .doka--image-markup,
  .doka--image-markup-clip {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  .doka--image-markup-clip {
    contain: strict;
  }

  .doka--image-markup {
    pointer-events: all;

    svg {
      display: block;
      contain: strict;
      height: 100%;
      width: 100%;
    }

    &[data-active='false'] {
      pointer-events: none;
    }

    &[data-util='draw'] svg {
      cursor: crosshair;
    }

    &[data-util='draw'] .doka--shape-group {
      pointer-events: none;
    }

    text {
      white-space: pre;
    }

    image {
      transition: opacity .25s ease-in-out;
    }

    input {
      background: transparent;
      position: absolute;
      left: 0;
      top: 0;
      line-height: 1 !important;
      text-align: left !important;
      text-indent: 0 !important;
      border: 0 !important;
      margin: 0 !important;
      padding: 0 !important;
      box-shadow: none !important;
      pointer-events: none;
      outline: none;
      color: #fff;
      z-index: 1;

      &::-ms-clear {
        display: none;
      }

      &:focus {
        pointer-events: all;
      }

      &[hidden] {
        display: none;
      }
    }

    &[data-active='true'] {
      pointer-events: all !important;
    }
  }

  .doka--manipulator-group {

    pointer-events: none;

    .doka--hitbox {
      pointer-events: all;
    }

    circle:not(.doka--hitbox) {
      fill: var(--doka-markup-manipulator--fill);
      stroke: var(--doka-markup-manipulator--stroke);
      stroke-width: 2;
      r: 6
    }

    path,
    rect {
      stroke-width: 1.5;
      stroke: var(--doka-markup-manipulator--line);
    }

    &[is-bright-color='true'] {
      path {
        stroke: var(--doka-markup-manipulator--line-inverted);
      }
    }
  }

}

//#endif
.doka--root {

  .doka--image {
    pointer-events: none;
  }

}

.doka--root {

  &[data-style-layout-mode~='fullscreen'] {

    // remove focus outline on Edge
    &:focus {
      outline: transparent;
    }

    // full screen
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2147483647; // max z
    opacity: 0;

    // overlay entire view
    .doka--edit-status {
      position: fixed;
    }

    // and we need to create a safe area
    &[data-style-fullscreen-safe-area="bottom"][data-style-fullscreen='true'] {
      padding-bottom: 40px;
      box-sizing: border-box;

      // match height of iPhoneX for extra padding
      @media (min-height: 635px) {
        padding-bottom: 80px;
      }
    }

  }
}

/* applied to <body> to kinda-hide scrollbars when in fullscreen mode */
.doka--parent {

  &::-webkit-scrollbar {
    background-color: var(--doka-scrollbar-rail--color);
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--doka-scrollbar-thumb--color);
    border-radius: 9999rem;
    border: 3px solid var(--doka-scrollbar-rail--color);
  }

}

.doka--root {

  &[data-style-layout-mode~='modal'] {

    // only works on big views
    @media (min-width: 56rem) and (min-height: 40rem) {

      background: var(--doka-modal--overlay);
      transition: background-color .25s ease-in-out;

      // disable glare overlay
      &::before {
        content: none;
      }

      .doka--editor {
        // set editor background to opaque
        position: relative;
        overflow: hidden;
        background: var(--doka-modal--background);
        box-shadow: var(--doka-modal--shadow);
        transition: background-color .25s ease-in-out;

        // set modal max size (is tinier than default editor max size)
        width: var(--doka-modal--width);
        height: var(--doka-modal--height);
        max-width: var(--doka-modal--max-width);
        max-height: var(--doka-modal--max-height);
      }

      // limit status to size of modal
      .doka--edit-status {
        position: absolute;
      }

    }

  }

}

.doka--root {

  &[data-style-layout-mode~='preview'] {

    background: transparent;

    &::before {
      content: none;
    }

    .doka--content {
      position: relative;
      z-index: 1;
    }

    .doka--editor {
      max-width: none;
      max-height: none;
    }

    .doka--image-outline {
      display: none;
    }

    .doka--utils {
      display: none;
    }

    .doka--menu {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      pointer-events: none !important;

      .doka--button {
        pointer-events: all;
      }
    }

    .doka--crop-rotator,
    .doka--button {
      backdrop-filter: var(--doka-effect--frost);
      box-shadow: 0 .125em .5em rgba(0, 0, 0, .325);
    }

    .doka--button-app:not(.doka--button-action-confirm) {
      background-color: var(--doka-component--background-dark);

      &:focus,
      &:hover {
        background-color: var(--doka-component--background-darker);
      }
    }

    .doka--processing-indicator p {
      color: var(--doka-editor--color);
      background-color: var(--doka-component--background-dark);
    }

    &[data-style-viewport*='x-cramped'] {
      .doka--processing-indicator {
        bottom: 0;
      }
    }

    .doka--toolbar {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 !important;
      z-index: 3;

      .doka--button-tool {
        background-color: var(--doka-component--background-dark);

        &:focus,
        &:hover {
          background-color: var(--doka-component--background-darker);
        }

        border-radius: .25em;
      }
    }

    .doka--crop-rect {
      mix-blend-mode: difference;
      height: 100%;
      pointer-events: none;
      mask: radial-gradient(
                      ellipse closest-side,
                      rgba(0, 0, 0, 1),
                      rgba(0, 0, 0, 0)
      );
    }

    .doka--crop-rotator {
      position: absolute;
      bottom: 0;
      left: 12rem;
      right: 12rem;
      border-radius: 9999rem;
      background-color: var(--doka-component--background-dark);
    }

    .doka--crop-size {
      transform: none !important;
      right: 1em;
      top: 1em;
      left: auto;
    }

    .doka--crop-rotator-line-mask {
      max-width: 100%;
    }

    .doka--crop-subject {
      margin: 0;
    }

    .doka--image-container[style*='opacity:1'] ~
    .doka--image-overlay {
      background-color: var(--doka-component--background-darker);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    &[data-style-viewport~='x-cramped'] {
      .doka--crop-rotator {
        display: none;
      }
    }

    &[data-style-viewport~='x-spacious'] {
      .doka--crop-rotator {
        left: 16rem;
        right: 16rem;
      }
    }

    .doka--crop-rect-edge,
    .doka--crop-rect-corner {
      display: none;
    }

  }
}

.doka--root {

  .doka--menu {

    display: flex;
    box-sizing: border-box;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;

    z-index: 2;
    position: relative;
    pointer-events: all !important; // should always be clickable even when animating
    transition: background-color .125s ease-in-out, color .125s ease-in-out;

    // limit layer size
    overflow: hidden;

    // pushes confirm button to right
    .doka--button-action-confirm {
      margin-left: auto;
    }
  }

  // as long as the menu is not positioned at the bottom
  &:not([data-style-viewport~='x-cramped']) {

    // set fixed height
    .doka--menu {
      height: 4.75em;
      padding: 0 1.25em;
    }

    // utils cover height of menu
    .doka--utils {
      position: absolute;
      top: 0;
      bottom: 0;

      // default offset creates space for reset button
      left: 5em;
      right: 5em;
    }

    // provide some white space between cancel and reset button
    .doka--button-action-cancel {
      margin-right: 1em;
    }

    // pushes reset button to left
    .doka--button-action-reset {
      margin-right: auto;
    }
  }

  // flow controls are available (either cancel or confirm enabled),
  // create additional spacing left and right
  &[data-style-viewport~='flow-controls']:not([data-style-viewport~='x-cramped']) {
    .doka--utils {
      left: var(--doka-menu--offset);
      right: var(--doka-menu--offset);
    }
  }

  // cramped viewport
  &[data-style-viewport~='x-cramped'] {

    .doka--menu {
      margin-top: -.75em;
      height: 3.25em;
    }

    // has utils
    &[data-style-viewport~='multi-util'] {

      .doka--utils {
        position: absolute;
        left: 3.25em;
        right: 3.25em;
        top: 0;
        bottom: 0;
      }

      .doka--menu {
        overflow: hidden;
        margin: 0 1em 1em;
        padding: 0 .6125em;
        border-radius: 9999em;
        background: var(--doka-component--background-dim);
        backdrop-filter: var(--doka-effect--frost);
      }

      .doka--button-action-reset {
        position: absolute;

        // needed for centering on Safari 10
        left: .75em;
        top: .6875em;
        width: 2.5em;
        height: 2.5em;
      }

    }

  }

  // menu has background color so image is nicely overlayed and buttons stay visible
  &[data-view='resize'] {
    .doka--menu {
      background: var(--doka-component--background-dark) !important;
    }
  }

  // has no utils and no controls
  &[data-style-viewport~='single-util'][data-style-viewport~='no-flow-controls'] {
    .doka--button-action-reset {
      position: absolute;
      top: .3125em;
      left: calc(50% - 1.125em);
    }
  }

}

//#if !_EXCLUDE_COLOR_UTIL
.doka--root {

  .doka--range-input {

    display: block;
    $height: 1.5625em;
    $size: .625em;
    // background:red;


    button {
      position: absolute;
      top: calc(50% + .5em);
      height: 1.325em;
      width: 2em;
      bottom: 100%;
      left: calc(50% - 1em);
      padding: 0;
      margin: 0;
      border: none;
      font-size: 1em;
      overflow: hidden;
      color: transparent;
      background: transparent;
      outline: transparent;

      &::after {
        content: '';
        pointer-events: none;
        position: absolute;
        top: calc(50% - .5em);
        left: calc(50% - .06125em);
        width: .125em;
        height: .5em;
        background: var(--doka-range-input-recenter--background);
        border-radius: .9999em;
      }

      &::after {
        bottom: 0;
      }
    }

    &[data-centered='true'] button::after {
      background: var(--doka-range-input-centered--background);
    }

    input {
      -webkit-appearance: none;
      display: block;
      font-size: 1em;
      width: 100%;
      min-width: auto;
      min-height: auto;
      background: transparent;
      position: absolute;
      left: .5em;
      width: calc(100% - 1em);
      margin: 0;
      padding: 0;
      line-height: 0;
      top: 0;
      height: 100%;
      background: transparent;
      border: none;
      border-radius: 0;

      &::-webkit-slider-runnable-track {
        background: transparent;
      }

      &::-webkit-slider-thumb {
        // reset
        -webkit-appearance: none;
        line-height: 0;
        padding: 0;
        margin: 0;
        padding: 0;
        border: none;

        // style
        width: $size;
        height: $size;
        border-radius: .9999em;
        transition: box-shadow .25s ease-in-out, background-color .25s ease-in-out;
        background: var(--doka-range-input-thumb--color);
      }

      &::-moz-range-thumb {

        // reset
        line-height: 0;
        padding: 0;
        margin: 0;
        padding: 0;
        border: none;

        // style
        width: $size;
        height: $size;
        border-radius: .9999em;
        transition: box-shadow .25s ease-in-out, background-color .25s ease-in-out;
        background: var(--doka-range-input-thumb--color);
      }

      &::-ms-thumb {
        // reset
        line-height: 0;
        padding: 0;
        margin: 0;
        padding: 0;
        border: none;

        // style
        width: $size;
        height: $size;
        border-radius: .9999em;
        transition: box-shadow .25s ease-in-out, background-color .25s ease-in-out;
        background: #fff; //var(--doka-range-input-thumb--color);
      }

      &::-ms-tooltip {
        display: none;
      }

      &::-ms-ticks-before {
        display: none;
      }

      &::-ms-ticks-after {
        display: none;
      }

      &::-ms-track {
        color: transparent;
        border: none;
        background: transparent;
      }

      &::-ms-fill-lower {
        background: transparent;
      }

      &::-ms-fill-upper {
        background: transparent;
      }

      &:focus {
        outline: transparent;

        &::-moz-range-thumb {
          box-shadow: 0 0 0 .5em var(--doka-range-input-thumb-hover--color);
        }

        &::-webkit-slider-thumb {
          box-shadow: 0 0 0 .5em var(--doka-range-input-thumb-hover--color);
        }
      }
    }

    span:hover {

      input::-moz-range-thumb {
        box-shadow: 0 0 0 .5em var(--doka-range-input-thumb-hover--color);
      }

      input::-webkit-slider-thumb {
        box-shadow: 0 0 0 .5em var(--doka-range-input-thumb-hover--color);
      }

    }
  }

  .doka--range-input-inner {

    display: block;
    position: relative;
    flex: 1;
    min-height: 2em;

    &::after {
      content: '';
      position: absolute;
      left: .5em;
      right: .5em;
      top: calc(50% - .125em);
      height: .25em;
      background-color: var(--doka-range-input--background);
      transition: background-color .25s ease-in-out;
      border-radius: .9999em;
    }
  }
}

//#endif
//#if !_EXCLUDE_RESIZE_UTIL
.doka--root {

  .doka--resize-form {

    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all;
    padding: 1em 0;
    margin: 0;
    height: 2.5em;

    legend,
    fieldset {
      border: none;
      padding: 0;
      margin: 0;
    }

    legend {
      position: absolute;
      width: 1px;
      height: 1px;
      opacity: 0;
    }

    fieldset {
      margin: 0;
      padding: 0;
      flex: 1 0 auto;
      max-width: 17em;
    }

    .doka--size-input {
      margin: 0;
      width: calc(50% - 1.125em);
    }

    .doka--checkable {
      margin: 0 .325em;
      vertical-align: bottom;

      label {
        padding: .3125em 0;
      }
    }

    button {
      margin-right: -3.25em; // width of button + left margin, so it doesn't take up any space
      margin-left: 1em;
      font-size: .875em;

      svg {
        width: 1.25em;
        height: 1.25em;
      }
    }

  }

  // background overlay so the fields are still readable when the image is behind the form
  &[data-view='resize'] {
    .doka--resize-form {
      background-color: var(--doka-component--background-dark);
    }
  }

  // push toolbar to location of main menu when the user cannot pick different utils
  &[data-style-viewport~='flow-controls'][data-style-viewport~='single-util']:not([data-style-viewport~='x-cramped']) {
    .doka--resize-form {
      margin-top: -4.5em;
      background: none;
    }
  }

  // hide the submit button on mobile devices as the keyboard will havea submit button instead
  &[data-style-viewport*='touch'] {
    .doka--resize-form button {
      position: absolute;
      opacity: 0;
      width: 1px;
      height: 1px;
      overflow: hidden;
    }
  }
}


//#endif
//#if !_EXCLUDE_RESIZE_UTIL
.doka--root {

  .doka--view-stack .doka--resize {
    height: calc(100% - 1em) !important;
  }

}

//#endif
.doka--root {

  // use content-box sizing by default
  box-sizing: content-box;

  // so we can position children correctly
  position: relative;

  // no action outside of the editor
  overflow: hidden;

  // no touch actions plz
  touch-action: none;

  // no selection of text
  user-select: none;

  // always scale to fit parent container size
  height: 100%;
  width: 100%;

  /* base line height */
  line-height: normal;

  // size is determined internally
  font-size: var(--doka-font-size);

  /* doka uses default system font family */
  font-family: var(--doka-font-family);

  /* will increase font weight a bit on Safari */
  font-weight: 450;

  // default text alignment
  text-align: left;

  // better text rendering on Safari
  text-rendering: optimizeLegibility;

  // text direction is ltr for now
  direction: ltr;

  // center editor
  display: flex;
  justify-content: center;
  align-items: center;

  // style
  color: var(--doka-editor--color);
  background: var(--doka-editor--background-color);

  // this limits reflows to the editor container
  contain: strict;

  // makes theme switch nicer
  transition: background-color .25s ease-in-out, color .25s ease-in-out;

  // glare
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: radial-gradient(
                    ellipse at center,
                    var(--doka-editor--background-ellipse-inner-color) 0%,
                    var(--doka-editor--background-ellipse-outer-color) 80%
    );
  }

  // clean up label styles
  label {
    margin: 0 !important;

    &::before,
    &::after {
      content: none !important;
    }
  }
}

//#if !_EXCLUDE_RESIZE_UTIL
.doka--root {

  .doka--size-input {

    position: relative;
    display: inline-flex;
    cursor: text;

    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }

    input::-ms-clear {
      display: none;
    }

    label, input {
      padding: .625em .5em;
      text-indent: .25em;
      font-size: .875em;
      font-weight: normal;
      line-height: inherit;
      cursor: inherit;
    }

    label {
      pointer-events: none;
      position: absolute;
      right: 0;
      cursor: text;
      flex: 1;
      white-space: nowrap;
      text-align: right;
      padding-left: 0;
      padding-right: .75em;
      color: var(--doka-editor--color);
    }

    input {
      -webkit-appearance: none;
      appearance: none;
      -moz-appearance: textfield;

      margin: 0;
      min-height: 0;
      min-width: 7em;
      height: auto;
      width: 100%;
      font-variant-numeric: tabular-nums;
      outline: transparent;
      border-radius: .5em;
      box-shadow: none;
      border: none;
      color: var(--doka-editor--color);
      background-color: var(--doka-component--background);
      transition: background-color .1s ease-in-out, color .1s ease-in-out;

      &:focus {
        transition: inherit;
        border: inherit;
        background-color: inherit;
        box-shadow: 0 0 0 2px currentColor;
      }

    }

    input::placeholder {
      color: var(--doka-editor--color-dim) !important;
    }

    input:-ms-input-placeholder {
      color: var(--doka-editor--color-dim) !important;
    }
  }

  @supports (backdrop-filter: var(--doka-effect--frost)) {
    .doka--size-input input {
      backdrop-filter: var(--doka-effect--frost);
    }
  }

}

//#endif
//#if !_EXCLUDE_MARKUP_UTIL
.doka--root {

  .doka--markup {
    display: flex;
    flex-direction: column;

    .doka--toolbar {
      display: inline-flex;
      margin: 0 auto;
      padding: 1.25em 0;
    }
  }

  .doka--markup-utils.doka--markup-utils {
    border: none;
    padding: 0;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
    }

    li {
      margin: 0 .75em;
    }

    input {
      display: none;
    }

    label {
      display: flex;
      align-items: center;
      pointer-events: all;
      padding: 0 .75em 0 .5em;
      line-height: inherit;
      margin: 0;
      height: 2.5em;
      color: var(--doka-button--color);
    }

    input:checked + label {
      color: var(--doka-filter-tile-selected--color);
    }
  }

  .doka--toolbar[data-layout='compact'] .doka--markup-utils {
    li {
      margin: 0 .3125em;
    }
  }

  .doka--markup-tools {
    box-sizing: content-box;
    display: flex;
    flex-wrap: wrap;
    margin: auto 1em 0;
    padding: .3125em 0 .75em 0;
    justify-content: center;
    align-items: flex-end;
    max-height: 3em;
    min-height: 2.0625em;

    &[data-active='true'] {
      pointer-events: all !important;
    }

    .doka--button span {
      text-align: left;
    }

    .doka--dropdown > .doka--button {
      color: var(--doka-button--color);
      background-color: var(--doka-button--background);
      border-radius: 9999em;
      line-height: 1.75em;
      margin: 0 .25em 0;
      font-size: .925em;

      > span {
        margin-left: .3125em;
      }
    }

    .doka--dropdown {
      margin-top: .5em;
    }

    [data-active='false'] {
      > .doka--button {
        display: none;
      }
    }

    .doka--markup-color {
      margin-top: -.875em;

      &[data-active='false'] {
        display: none;
      }
    }
  }

  .doka--markup-color.doka--markup-color {

    display: flex;
    justify-content: center;

    &, ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    ul {
      display: flex;
      justify-content: center;
    }

    li {
      padding: 0;
      margin: 0 .125em;
    }

    li input {
      display: none;
    }

    label {
      display: block;
      overflow: hidden;
      color: transparent;
      width: 1.5em;
      height: 1.5em;
      margin: 0;
      padding: 0;
      font-weight: normal;
      line-height: inherit;
      border-radius: 9999em;
      transition: box-shadow .125s ease-out;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .1);
      cursor: inherit;
    }

    .doka--color-input input[data-selected='true'] + label,
    input:checked + label {
      box-shadow: inset 0 0 0 2px rgba(0, 0, 0, .5),
      0 0 0 2px white;
    }

    .doka--color-input {
      margin: 0 .125em;
    }

  }

  .doka--color-input {

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 1.5em;
    height: 1.5em;
    border-radius: 9999em;
    color: var(--doka-button--color);
    background-color: var(--doka-button--background);

    label {
      overflow: hidden;
      background-image: radial-gradient(rgba(255, 255, 255, .3) 33%, rgba(255, 255, 255, 0) 36%),
      url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23FECD09' d='M12 0h12v24H12z'/%3E%3Cpath fill='%2366AF01' d='M0 12h24v12H0z'/%3E%3Cpath fill='%237300CD' d='M0 0h12v24H0z'/%3E%3Cpath fill='%23E12224' d='M0 0h12v12H0z'/%3E%3Cpath fill='%23FF7D00' d='M12 12L24 0H12z'/%3E%3Cpath fill='%23B400AA' d='M0 12V0l12 12z'/%3E%3Cpath fill='%23039695' d='M12 24h12L12 12z'/%3E%3Cpath fill='%23465FCD' d='M12 24H0l12-12z'/%3E%3C/g%3E%3C/svg%3E");
    }

    input {
      position: absolute;
      opacity: 0;
      -webkit-appearance: none;
      border: 0;
      padding: 0;
      margin: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      font-size: 1em;
      min-width: 0;
      min-height: 0;
    }

    span {
      position: absolute;
      pointer-events: none;
    }

    .doka--color-visualizer {
      opacity: 0;
      width: .375em;
      height: .375em;
      border-radius: 1em;
      box-shadow: 0 0 1px 1px #fff, 0 0 0 1.5px rgba(0, 0, 0, .7);
      // box-shadow: 0 0 1px 1px rgba(0,0,0,.7);
      z-index: 1;
    }

    .doka--color-visualizer[style] {
      opacity: 1;
    }

    .doka--color-brightness {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 9999em;
      background: #000;
      box-shadow: inset 0 0 .75em rgba(255, 255, 255, .25), 0 0 1px #000;
      opacity: 0;
    }

  }

  &[data-style-viewport~='x-cramped'] {
    .doka--markup-color {
      width: 100%;
    }
  }

}


//#endif
.doka--root {
  .doka--status-progress {
    display: none;
    background-color: var(--doka-status-bubble--color-dim);
    border-radius: 50%;
    pointer-events: none;
  }
}

.doka--root {

  .doka--status-bubble {
    pointer-events: none;
    will-change: transform;
    text-align: center;

    .doka--status-bubble-inner {
      position: relative;
      display: inline-block;
      color: var(--doka-status-bubble--color);
      background-color: var(--doka-status-bubble--background);
      box-shadow: var(--doka-status-bubble--shadow);
      transition: background-color .25s ease-in-out, color .25s ease-in-out;
      font-size: .875em;
      max-width: 80%;
      margin: 0 auto;
      border-radius: 1em;
      line-height: 1.5;
      text-align: center;
      padding: .1875em 1em .25em;
    }

    p {
      display: inline;
      margin: 0;
      padding: 0;
    }

    .doka--status-progress:not([data-value='0']) {
      position: absolute;
      display: block;
      right: .35em;
      top: .35em;
      width: 1.25em;
      height: 1.25em;

      & + p {
        padding-right: 1.5em;
      }
    }


    button + p {
      padding-right: 1.5em;
    }

    button {
      position: relative;
      z-index: 1;
      margin: 0;
      padding: 0;
      border: none;
      font-family: inherit;
      font-size: 1em;
      font-weight: 400;
      outline: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: all;
      border-radius: 9999em;
      width: 18px;
      height: 18px;
      color: var(--doka-status-bubble--background);
      background-color: var(--doka-status-bubble--color);

      position: absolute;
      right: .375em;
      top: .35em;

      svg {
        width: .875em;
        height: .875em;
      }

      &::after {
        content: '';
        position: absolute;
        left: -1em;
        right: -1em;
        top: -1em;
        bottom: -1em;
      }
    }
  }

  @supports (backdrop-filter: var(--doka-effect--frost)) {
    .doka--status-bubble-inner {
      backdrop-filter: var(--doka-effect--frost);
      background: var(--doka-status-bubble-effect--background);
    }
  }
}

.doka--root {

  .doka--toolbar {

    z-index: 3;

    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    padding: 1.25em 0;

    pointer-events: none;
    margin: 0 2em;

    > * {
      pointer-events: all;
      margin: 0 .75em;
      height: 2.5em;
    }

    svg {
      display: inline-block;
      flex-shrink: 0;
    }

    svg * {
      transition: opacity .25s ease-out, transform .5s ease-out;
    }

    .doka--button {
      white-space: nowrap;
      // fixes bug in IE11 where buttons are shrinked and thus
      // they never overflow the toolbar and therefor the toolbar
      // never enters compact mode
      flex-shrink: 0;
    }

  }


  // push toolbar to location of main menu if main menu is enabled
  &[data-style-viewport~='flow-controls'][data-style-viewport~='single-util']:not([data-style-viewport~='x-cramped']) {
    .doka--toolbar {
      margin: -4.875em 11em 0;
    }
  }

  .doka--toolbar[data-layout='compact'] {
    > * {
      margin: 0 .5em;
    }
  }

  &[data-style-viewport~='x-cramped'] {
    .doka--toolbar {
      padding: 1em 0;

      > * {
        margin: 0 .25em;
      }
    }
  }

}

.doka--root {

  .doka--utils {

    display: flex;
    justify-content: center;
    flex: 1;
    padding: 0;
    overflow: hidden;

    > * {
      pointer-events: all;
    }

    > * + * {
      margin-left: 1px;
    }
  }

}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-desktop {

  .sortable-placeholder {
    float: left;
    height: 38.4rem;
    padding-bottom: 0 !important;
    padding-left: 1rem;
    padding-right: 1rem;
    background: $table-row-bg;
    border: 1px dashed $nav-active-color;
    position: relative;
  }

  .bpm-desktop-item {
    height: 39rem;
  }

  .bpm-desktop-item-higher {
    height: 60rem !important;
  }

  .bpm-grid-empty {
    @include fts18;
    color: #666;
  }
}

.bpm-box {
  height: 30rem;
  background: $white-color;
  border: 1px solid $prime-border-box;
  padding: 0.1rem;

  .bpm-grid.bpm-minitable {
    border: none;
  }

  &.bpm-box-unlimited {
    height: auto;

    .bpm-box-part {
      height: auto;
    }
  }
}

.bpm-box-header,
.bpm-chart-box-header {
  height: 6rem !important;
  overflow: hidden;
}

.bpm-box-part {
  height: 29.6rem;
}

.bpm-box-header h2,
.bpm-box-header h3 {
  float: left;
}

.bpm-box-header .buttons,
.bpm-chart-box-wrapper .buttons {
  position: absolute;
  top: 1.8rem;
  right: 1.2rem;
  z-index: 1000;
}

.bpm-desktop .bpm-box-header .buttons,
.bpm-chart-box-wrapper .buttons {
  z-index: 1;
  right: 1.5rem;
  display: none;
}

.bpm-desktop-item:hover .bpm-box-header .buttons,
.bpm-chart-box-wrapper:hover .buttons {
  display: block;
}

.bpm-desktop-item-wrapper,
.bpm-desktop-item-wrapper.bpm-slider {
  padding: 0.5rem 1.5rem;
  border: 1px solid $prime-border-box;
  background-color: $white-color;
  margin-bottom: 2rem;

  .bpm-box {
    border: none;
  }

  &.tile-wrapper.bpm-slider {
    border: none;
  }
}

.desktop-sort .bpm-desktop-item-wrapper {
  box-shadow: 0 1px 15px 1px rgba(50, 50, 50, 0.3);
}

.theme-gethome .bpm-desktop-item-wrapper {
  box-shadow: none;
  border: 1px solid #EEE8F6;
  border-radius: 0 !important;
}

.bpm-box-header .legend {
  float: right;
  margin-top: 1.5rem;
}

.bpm-box-text {
  padding: 2rem;
  background: $table-bg;
  border: 1px solid $border-color;

  &.white-bg {
    background: $white-color;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    @include fts16;
  }
}

.bpm-box-content {
  padding: 2rem;
  background: $white-color;
  margin-bottom: 3rem;
  box-shadow: none;
  border: 1px solid $prime-border-box;

  .bpm-tabs-menu > a.active {
    background: $white-color;
  }
}

.bpm-box-no-results {
  text-align: center;
  display: block;
  margin: 0;
  left: 0;
  width: 100%;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.bpm-summary-item-wrapper {
  padding: 0.5rem 1.5rem;
  border: 1px solid $prime-border-box;
  background-color: $white-color;
  margin-bottom: 3rem;
  min-height: 36rem;

  .bpm-box {
    border: none;
  }

  .buttons {
    z-index: 1;
    right: 2.5rem;
  }

  .bpm-tabs-menu > a.active {
    background: $white-color;
  }
}

.bpm-box-padding {
  padding: 2rem;
}

.bpm-box-padding-horizontal {
  padding: 0 2rem;
}

.bpm-box-dynamic {
  height: auto;

  .bpm-grid {
    margin-bottom: 0;

    &.bpm-minitable .bpm-grid-row:last-child {
      border-bottom: none;
    }
  }
}

.bpm-desktop-tile {
  height: 17.5rem;
  width: 100%;
  display: block;
  border: 1px solid $prime-border-box;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: $white-color;

  .value,
  .caption {
    display: table;
    width: 100%;
  }

  .value {
    height: 55%;

    .inside {
      font-size: 3.2rem;
      font-weight: 300;
      display: table-cell;
      vertical-align: bottom;
      text-align: center;
      width: 100%;
      height: 100%;
    }
  }

  .caption {
    height: 41%;

    .inside {
      @include fts15;
      font-weight: 300;
      display: table-cell;
      vertical-align: top;
      text-align: center;
      width: 100%;
      height: 100%;
    }
  }

  .border {
    height: 4%;
    display: block;
    width: 100%;
  }
}

.tile-wrapper {
  background: none !important;
  padding: 0 !important;
  box-shadow: none !important;
  height: 38rem;
  margin-top: -1rem;

  .buttons {
    position: absolute;
    top: 1.8rem;
    z-index: 1;
    right: 2.5rem;
    display: none;
  }

  &:hover .buttons {
    display: block;
  }
}

.bpm-desktop-col-12 .tile-wrapper {
  margin-top: 0;
  margin-bottom: 2rem;
}


.bpm-desktop-col-4,
.bpm-desktop-col-3 {

  .bpm-desktop-tile .value .inside {
    font-size: 2.4rem;
  }

  .bpm-desktop-tile .caption .inside {
    @include fts12;
  }

  .bpm-desktop-item h3 {
    @include fts18;
  }

  .sortable-placeholder {
    height: 28.4rem;
    padding-bottom: 1rem !important;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .bpm-box-header .buttons {
    z-index: 1;
    right: 2rem;
  }

  .bpm-desktop-item {
    height: 29rem;
  }

  .bpm-desktop-item-wrapper {
    padding: 0.5rem 1rem;
  }

  .bpm-box {
    height: 20rem;
  }

  .bpm-grid-empty {
    padding-top: 7rem;
    @include fts16;
  }

  .bpm-grid-cell {
    @include fts13;
  }

  .offer-short {

    .offer-short-location {
      @include fts14;
    }

    .offer-short-area {
      @include fts12;
    }

    .offer-short-number,
    .offer-short-third,
    .offer-short-fourth {
      @include fts11;
    }

    .offer-short-price-old {
      @include fts12;
    }

  }

  .seeker-short {

    .seeker-short-name {
      @include fts14;
    }

    .seeker-short-type {
      @include fts12;
    }

    .seeker-short-status,
    .seeker-short-third,
    .seeker-short-fourth {
      @include fts11;
    }
  }

  .bpm-desktop-tile {
    height: 12.5rem;
    @include fts12;
  }

  .tile-wrapper {
    height: 28rem;
  }
}

.theme-prime,
.theme-primepro,
.theme-gethome {

  .bpm-desktop-tile {
    height: 12.5rem;
    width: 100%;
    display: block;
    box-shadow: none;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 1px solid $prime-event-icon;
    color: $investment-span-color;
  }

  .bpm-box-padding {
    padding: 2rem 3rem;
  }

  .bpm-box-padding-horizontal {
    padding: 0 3rem;
  }

  .bpm-summary-item-wrapper {
    border-radius: 8px;
    padding: 2rem 0 1rem 0;
  }

  .bpm-box-header h2,
  .bpm-box-header h3,
  .bpm-chart-box h3 {
    margin-left: 3rem;
    @include fts14;
    color: $prime-dark-color;
    letter-spacing: 1.12px;
    font-weight: bold;
  }
}

@media (max-width: 1100px) {

  .bpm-desktop-item h3 {
    @include fts18;
  }

  .bpm-desktop {

    .sortable-placeholder {
      height: 28.4rem;
      padding-bottom: 1rem !important;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .bpm-box-header .buttons {
      z-index: 1;
      right: 2rem;
    }

    .bpm-desktop-item {
      height: 27rem;
      margin-bottom: 1.6rem;
    }

    .bpm-box {
      height: 20rem;
    }

    .bpm-grid-empty {
      padding-top: 7rem;
      @include fts16;
    }

    .bpm-grid-cell {
      @include fts13;
    }

  }

  .bpm-desktop-item-wrapper {
    padding: 0.5rem 1rem;
  }

  .bpm-desktop-tile {
    height: 12.5rem;
    @include fts12;

    .value .inside {
      font-size: 2.4rem;
    }

    .caption .inside {
      @include fts12;
    }
  }

  .tile-wrapper {
    height: 28rem;
  }

  .theme-prime,
  .theme-primepro,
  .theme-gethome {

    .bpm-desktop-item h3 {
      @include fts18;
    }

    .bpm-desktop {

      .sortable-placeholder {
        height: 28.4rem;
        padding-bottom: 1rem !important;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }

      .bpm-box-header .buttons {
        z-index: 1;
        right: 2rem;
      }

      .bpm-desktop-item {
        height: 38rem;
      }

      .bpm-box {
        height: 30rem;
      }

      .bpm-grid-empty {
        padding-top: 7rem;
        @include fts16;
      }

      .bpm-grid-cell {
        @include fts13;
      }
    }

    .bpm-desktop-item-wrapper {
      padding: 0.5px 1rem;
    }

    .offer-short {

      .offer-short-location {
        @include fts14;
      }

      .offer-short-area {
        @include fts12;
      }

      .offer-short-number,
      .offer-short-third,
      .offer-short-fourth {
        @include fts11;
      }

      .offer-short-price-old {
        @include fts12;
      }
    }

    .seeker-short {

      .seeker-short-name {
        @include fts14;
      }

      .seeker-short-type {
        @include fts12;
      }

      .seeker-short-status,
      .seeker-short-third,
      .seeker-short-fourth {
        @include fts11;
      }
    }

    .bpm-desktop-tile {
      height: 12.5rem;
      @include fts12;

      .value .inside {
        font-size: 2.4rem;
      }

      .caption .inside {
        @include fts12;
      }
    }

    .tile-wrapper {
      height: 28rem;
    }
  }

}

@media (max-width: 780px) {

  .tile-wrapper {
    height: 100%;
  }

  .btn-group.desktop-customize-container {
    display: none;
  }
}

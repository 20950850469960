@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

h3.offerExport-title {
  font-size: 2.8rem;
}

.offerExport-subtitle {
  @include fts13;
}

.bpm-export-configuration-view-type {
  position: relative;
  margin-bottom: 1.3rem;

  i {
    margin-right: 1.2rem;
    color: $prime-medium-icon-color;
  }

  .inactive-icon i:after {
    content: "";
    position: absolute;
    width: 2.4rem;
    height: 0;
    top: 1rem;
    left: -0.5rem;
    border: 1px solid $searchbar-trigger-color;
    transform: rotate(-45deg);
  }
}

.bpm-grid .bpm-grid-portal-settings .bpm-grid-portal-settings-content {
  max-height: 9rem;
  display: grid;
  grid-template-columns: 5% 5fr 15% 15% 5%;
  grid-template-areas:
          "dot logo startButton toolsButton actionsButton";

  img {
    max-height: 4rem;
  }

  .portal-start-button {
    grid-area: startButton;
    display: flex;
    align-items: center;
  }

  .portal-tools-button {
    grid-area: toolsButton;
    display: flex;
    align-items: center;

    &.investment-view-publish a {
      display: flex;
      align-items: center;
    }
  }

  .portal-tools-button a:hover,
  .portal-start-button a:hover {
    text-decoration: none;
    color: $portal-link-hover !important;
  }

  .portal-actions-button {
    grid-area: actionsButton;
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      margin: 0 auto;
    }

  }

  i {
    margin-right: 1.2rem;
  }

  .portal-tools-settings-link {
    @include fts12;
    text-decoration: underline;
  }
}

.portal-actions-button.desktop-hidden {
  visibility: hidden;
}

.portal-logo-container {
  grid-area: logo;
  display: flex;
  align-items: center;

  p {
    margin: 0;
    @include fts16;
    color: $searchbar-trigger-color;
  }

  .portal-name-inactive {
    filter: grayscale(1);
    opacity: 0.7;
  }
}

.dot-portal-container {
  grid-area: dot;
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
}

.bpm-grid-portal-settings-content .dot-status,
.bpm-export-investment-view .dot-status {
  width: 1rem;
  height: 1rem;
  background-color: $dot-inactive-color;
  box-shadow: $dot-inactive-shadow;
  border-radius: 50%;

  &.dot-status-active {
    background-color: $dot-active-color;
    box-shadow: $dot-active-shadow;
  }
}

.bpm-grid .bpm-grid-row .inactive-tools-button,
.bpm-grid .bpm-grid-row .inactive-tools-button:hover {
  color: $prime-inactive-color !important;
}

.bpm-grid.bpm-tableview .bpm-grid-cell .portal-logo-container {
  display: flex;
  justify-content: space-between;

  img {
    max-height: 3.5rem;
    margin-right: 2rem;
  }

  a.investment-action {
    text-align: center;

    i {
      width: 1.5rem;
      font-size: 2.5rem;
    }
  }
}

.bpm-listview .bpm-grid.bpm-tableview.bpm-export-investment-view {
  border-spacing: 0;
  height: 6.5rem;
}

.bpm-grid.bpm-tableview.bpm-export-investment-view .bpm-grid-cell {
  border: 1px solid $prime-border-color;
  width: 20%;
}

.bpm-grid-cell .portal-investment-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.bpm-grid-cell .dot-hidden {
  visibility: hidden;
}

.bpm-grid.bpm-tableview .bpm-grid-cell .portal-logo-container a {
  padding-right: 0;
}

.bpm-listview .bpm-grid.bpm-tableview .bpm-grid-head .bpm-grid-cell.bmp-grid-investment-name {
  @include fts12;
  color: $prime-text-color;
  background-color: $white-color;
  border: 1px solid $prime-border-color;
}

.bpm-listview .bpm-grid.bpm-tableview .bpm-grid-head .bpm-grid-cell.bmp-grid-investment-name i {
  color: $prime-medium-icon-color;
}

.bmp-grid-investment-name .investment-name-container {
  display: flex;
  text-transform: none;

  p {
    padding-left: 2.2rem;
  }
}

.bpm-listview .bpm-grid.bpm-tableview.bpm-export-investment-view .bpm-grid-row {

  &.bpm-grid-head:hover .bpm-grid-cell.bmp-grid-investment-name {
    @include fts12;
    color: $prime-text-color;
    background-color: $white-color !important;
    border: 1px solid $prime-border-color;
  }

  &.bpm-grid-head .bpm-grid-cell:first-child {
    background-color: transparent;
  }
}

.bpm-grid-publication-settings .bpm-grid-portal-settings-content {
  max-height: 9rem;
  display: grid;
  grid-template-columns: 5% 2fr 30%;
  grid-template-areas:
          "dot logo toolsButton"
}

.bpm-grid-publication-settings.inactive-publication .bpm-grid-portal-settings-content {
  display: grid;
  grid-template-columns: 5% 2fr 40%;
  grid-template-areas: "dot logo toolsWarning"
}

.bpm-grid-portal-settings-content.bpm-grid-publication-settings .portal-tools-info {
  grid-area: toolsWarning;
}

.bpm-grid-row.bpm-grid-portal-settings.bpm-grid-publication-settings {
  margin-bottom: 1.5rem !important;
  border: 1px solid $prime-border-color;
}

.bpm-grid-portal-settings.bpm-grid-publication-settings .investment-view-publish {
  @include fts15;

  i {
    @include fts18;
  }
}

.bpm-grid-portal-settings-content .portal-tools-info {
  display: flex;
  align-items: center;
  @include fts12;
  justify-content: space-between;

  i {
    color: $portal-info-color;
  }
}

.bpm-grid-publication-settings .bpm-grid-portal-settings-content .dot-status {
  width: 1.4rem;
  height: 1.4rem;
}

.publish-options-content .publish-options-settings {
  display: flex;
  justify-content: space-between;
  margin: 2.8rem 0 1.8rem 0;
}

.publish-options-content-list .bpm-grid.bpm-minitable {
  border: 1px solid $prime-border-color;
  border-radius: 0.1rem;

  .bpm-grid-row.bpm-grid-head {
    border-bottom: none;
  }
}

.bpm-grid.bpm-minitable .bpm-grid-cell .disabled-grid-cell {
  color: $prime-disabled-cell;
}

.publish-options-settings {

  span.publish-label {
    @include publish-options-label($investment-icon-color);
  }

  span.contiguity-label {
    @include publish-options-label($investment-user-icon);
  }

  span.show-price-label {
    @include publish-options-label($investment-span-color);
  }
}

.row.submit-buttons-group {
  margin-top: 4.5rem;
}
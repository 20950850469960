@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

/* row = tile = listItem */

.bpm-grid.bpm-mapview {
  width: 100%;
  margin: 0;
  position: relative;
  height: 65rem;
  display: block;

  .bpm-gmap-body {
    height: 60rem;
  }

  .bpm-grid-row,
  .sortable-placeholder {
    height: 60rem;
    padding: 5rem 1rem 1rem 1rem;
    width: 30rem;
    background: $white-color;
    border: 1px solid $border-color;
    position: absolute;
    display: none;
    left: 0;
    top: 0;
  }

  .sortable-placeholder {
    background: $table-row-bg;
    border: 1px dashed $nav-active-color;
  }

  .bpm-grid-head .bpm-grid-cell i {
    display: inline-block;
    vertical-align: top;
    font-size: 1.7rem;
    padding-top: 0.1rem;
    color: transparent;
  }

  .bpm-grid-cell {
    display: block;
    vertical-align: top;
    padding: 0;
    transition: all 0.3s ease 0s;
  }

  .bpm-grid-checkbox {
    position: absolute;
    left: 1rem;
    top: 1rem;
  }

  .bpm-grid-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .bpm-grid-status {
    bottom: 1rem;
    left: 1rem;
    position: absolute;
  }

  .bpm-grid-image {
    display: none;
  }

  .bpm-status-temp {
    bottom: 3rem;
    right: 1rem;
    position: absolute;
    text-align: right;
  }

  .bpm-grid-actions {
    min-width: 18rem;
    text-align: left;
    width: 18rem;
    @include fts14;
    position: absolute;
    left: 5rem;
    top: 1rem;

    a {
      display: inline-block;
      padding: 0 0.4rem 0.2rem;
      vertical-align: middle;
      width: 2.1rem;
      margin-bottom: 0.3rem;

      &.bpm-submit {
        display: block;
        width: 100%;
        margin-bottom: 0;
        text-align: left;
        padding: 0.4rem 1rem;
        background: none;
        color: $main-color;
        border: none;
        @include fts14;

        &:hover {
          background-color: $table-bg-hover;
          color: $main-color;
        }
      }
    }

    div:not(:hover).bpm-grid-row .bpm-grid-actions a {
      background-color: transparent;
      color: $table-border-hover;
    }

    .bpm-grid-row:hover div:not(:hover).bpm-grid-actions a {
      background-color: transparent;
      color: $primary-color;
    }
  }

  .bpm-grid-head .bpm-grid-actions {
    text-align: left;
  }

  .bpm-grid-cell-label {
    margin-right: 1rem;
    width: 10rem;
    display: inline-block;
    font-weight: 700;
  }

  .bpm-grid-cell-value {
    display: inline-block;
    width: 16rem;
  }
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.theme-gethome {
  div.client-fields {
    * {
      margin: 0;
    }

    div.row.form-group {
      div.row {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        label {
          color: $gethome-dark-textcolor;
        }

        input {
          color: $gethome-dark2-color;
        }
      }
    }
  }

  .bpm-grid.bpm-tableview {
    .bpm-grid-row.bpm-grid-head, .bpm-grid-row.bpm-grid-head:hover {
      div.bpm-grid-cell {
        color: $gethome-gray-text;
        text-transform: none;
        font-weight: 600;
      }
    }

    .bpm-grid-row {
      div.bpm-grid-cell {
        padding: 2.4rem 1.8rem;

        h4.client-info {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin: 0;
          font-weight: 600;
          color: $gethome-dark-textcolor;
        }

        h5 {
          @include fts12;
          font-style: normal;
          font-weight: 500;
          color: $gethome-dark2-color;

          &.last-action {

          }

          &.last-action-date {
            color: $gethome-gray-text;
          }
        }

        .user-cell .user-name-row {
          span.user-name {
            @include fts14;
            font-weight: 400;
          }
        }
      }
    }
  }
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.options-layer__wrapper {
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(55, 71, 79, 0);
  z-index: 10000000;
  overflow: hidden;
  transform: translateY(100%);
  transition: transform 0ms linear 500ms, background 500ms linear 0ms;
}

.options-layer__wrapper--active {
  background-color: rgba(55, 71, 79, 0.5);
  transform: translateY(0);
  transition: transform 0ms linear 0ms, background 500ms linear 0ms;
}

.options-layer__container {
  overflow: hidden;
  position: fixed;
  width: 100vw;
  max-height: 100vh;
  bottom: 0;
  z-index: 10000001;
  background-color: $white-color;
  transform: translateY(100%);
  transition: transform 500ms ease-in-out;

  br {
    display: none;
  }
}

.options-layer__container--active {
  transform: translateY(0);
}

.options-layer__header {
  @include fts14;
  height: 2.4rem;
  margin: 1.6rem 1.6rem 0 1.6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  align-self: center;
  @include themify($themes) {
    color: themed("primaryColor");
  }
}

.options-layer__icon-close {
  @include themify($themes) {
    color: themed("mainColor");
  }
  font-size: 2.1rem !important;
}

div.ps-scrollbar-x-rail,
div.ps-scrollbar-y-rail {
  display: none;
}

.options-layer__content {
  overflow: scroll;
  @media (orientation: portrait) {
    max-height: calc((var(--vh, 1vh) * 100) - 4rem);
    min-height: calc((var(--vh, 1vh) * 75) - 4rem);
  }
  @media (orientation: landscape) {
    max-height: calc((var(--vw, 1vw) * 100) - 4rem);
    min-height: calc((var(--vw, 1vw) * 100) - 4rem);
  }
  font-family: "Poppins", sans-serif;

  * {
    margin: 0;
    padding: 0;
    font-size: 1.4rem;

    i,
    .ps-scrollbar-x-rail,
    .ps-scrollbar-y-rail,
    .ps-scrollbar-x,
    .ps-scrollbar-y {
      display: none;
    }
  }

  .investment-edit-data-tabs.investment-edit-data-tabs-mobile {
    display: flex;
    flex-direction: column;
    padding: 4rem 0;
    .investment-edit-data-tab:not(.dn) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 5.4rem;
      padding: 0;
      margin: 0;
      border: none;
      &.active-tab {
        @include themify($themes) {
          color: themed("primaryColor");
          background-color: themed("tableviewHoverBg");
        }
      }
    }
  }

  .btn {
    display: block;
    min-height: 4rem;
    width: 100%;

    i {
      display: none;
    }

    &.margin-t {
      margin-top: 0.8rem;
    }
  }

  .form-control {
    padding: 0 1.6rem;
  }

  .input-group-addon {
    padding: 0.6rem;
  }

  .bpm-dropdown-content.bpm-profile-links {
    padding: 4rem 0 4rem 0 !important;

    * {
      margin: 0;
      padding: 0;
      color: $main-color !important;
      font-size: 1.3rem;
    }

    a,
    input,
    button {
      display: flex;
      height: 5.4rem;
      width: 100%;
      align-items: center;
      justify-content: center;
      background: unset;
      border: unset;
      color: $main-color;

      i {
        display: none;
      }
    }

    div.btn-group {
      display: flex;
      width: 100%;

      form {
        width: 100%;
      }

      a,
      button {
        display: flex;
        width: 100%;
        height: 5.4rem;
        align-items: center;
        justify-content: center;
        color: $main-color;
      }
    }
  }

  .bpm-object-navgation-btn {
    display: none;
  }

  .btn-mass-action-mobile {
    padding: 4rem 1.6rem 4rem 1.6rem;
    display: flex !important;
    flex-direction: column;
    gap: 0.8rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .dropdown-mobile-paginationRows {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
  }

  .bpm-tabs {
    * {
      font-style: normal;
      font-weight: 400;
      font-size: 1.3rem;
      //color: $prime-dark2-color;
    }

    //background-color: transparent !important;
    .bpm-tabs-menu {
      display: flex;
      flex-direction: column;
      padding: 4rem 0 4rem 0;
      background: unset;
      border: unset;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5.4rem;
        border: none !important;
        background-color: transparent !important;
        box-shadow: unset;

        &.active {
          @include themify($themes) {
            background-color: themed("tableviewHoverBg") !important;
            color: themed("linkColor");
          }
        }
      }
    }
  }

  .wizard-nav {
    * {
      font-style: normal;
      font-weight: 400;
      font-size: 1.3rem;
    }

    display: flex;
    flex-direction: column;
    padding: 4rem 0 4rem 0;
    overflow-y: scroll;

    .wizard-nav-step {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 5.4rem;
    }

    span {
      color: $prime-dark2-color;
    }

    .step-active {
      @include themify($themes) {
        background-color: themed("tableviewHoverBg");
      }

      span {
        @include themify($themes) {
          color: themed("primaryColor");
        }
      }
    }

    i {
      display: none;
    }
  }

  .Zebra_DatePicker {
    * {
      margin: 0;
      padding: 0;
    }

    width: 100%;

    .dp_header {
      height: 3.2rem;
      margin-bottom: 2.4rem;

      .dp_hover {
        background-color: $white-color;
      }

      .dp_previous,
      .dp_next {
        width: 3.2rem;
        height: 3.2rem;
        border: 0.1rem solid $white-color;
        font-size: 2.1rem;
        @include themify($themes) {
          color: themed("primaryColor");
        }
      }

      .dp_caption {
        font: 1.4rem Poppins, sans-serif;
        font-weight: 600;
        line-height: 2.1rem;
        @include themify($themes) {
          color: themed("primaryColor");
        }
      }
    }

    .dp_daypicker,
    .dp_monthpicker,
    .dp_yearpicker {
      width: 100%;
      margin: 0 auto 3.1rem auto;

      tbody {
        width: 100%;
      }

      tr {
        height: 5rem;
      }

      th {
        font: 1.2rem Poppins, sans-serif;
        font-weight: 500;
        line-height: 1.8rem;
        color: rgba(0, 0, 0, 0.54);
      }

      td {
        font: 1.2rem Poppins, sans-serif;
        font-weight: 500;
        line-height: 1.8rem;
        color: rgba(0, 0, 0, 0.87);

        &.dp_current {
          color: $white-color;
          @include themify($themes) {
            background: themed("primaryColor");
          }
        }

        &.dp_not_in_month {
          color: rgba(0, 0, 0, 0.3);
        }
      }
    }

    .dp_footer {
      tr {
        display: flex;
        justify-content: space-between;
        gap: 8px;
      }

      td {
        display: flex;
        align-items: center;
        justify-content: center;
        @include themify($themes) {
          border: 1px solid themed("primaryColor");
        }
        border-radius: 4px;
        height: 4rem;
        font: 1.4rem Poppins, sans-serif;
        font-weight: 500;
        line-height: 2.4rem;
        @include themify($themes) {
          color: themed("primaryColor");
        }
      }
    }
  }

  .bpm-listview-controls {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  .bpm-accordion {
    * {
      margin: 0;
      padding: 0;
    }

    div.estate-props-container {
      display: flex;
      flex-direction: column;

      a.hidden {
        display: none !important;
      }

      .bpm-collapse {
        min-height: 4.3rem;
        border-radius: 2px;
        padding: 0.8rem 1rem 0.8rem 0.8rem;

        span {
          @include themify($themes) {
            color: themed("mainColor");
          }
        }
      }

      .bpm-collapse-menu {
        padding: 0 0 0 2.4rem;

        .bpm-collapse-content {
          display: flex;
          flex-direction: column;

          a,
          button,
          input,
          form {
            display: flex;
            align-items: center;
            min-height: 2.3rem;
            font-size: 1.5rem !important;
            font-weight: 400;
            vertical-align: middle;

            &:not(:last-child) {
              margin-bottom: 0.8rem !important;
            }
          }

          i {
            display: none;
          }
        }
      }
    }
  }

  .bpm-dropdown-content {
    overflow-y: scroll;
    padding: 4rem 1.6rem 4rem 1.6rem !important;

    .list-cell-action-buttons {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      button {
        i {
          display: none;
        }
      }
    }

    &.bpm-adherence-list {
      label {
        margin-top: 0.8rem;
      }

      button {
        margin-top: 0.8rem;
      }
    }

    * i {
      display: none;
    }

    label {
      min-height: 2.4rem;

      &:not(:last-child) {
        margin-bottom: 0.8rem !important;
      }
    }

    .bpm-radiolinks .bpm-radio input[type="radio"]:checked + span {
      background: $main-bg-color;
      color: $main-color;
    }
  }

  @media (orientation: portrait) {
    .bpm-dropdown-content,
    .wizard-nav {
      //max-height: calc((var(--vh, 1vh) * 100) - 4rem);
      min-height: calc((var(--vh, 1vh) * 75) - 4rem);
    }
  }

  @media (orientation: landscape) {
    .bpm-dropdown-content,
    .wizard-nav {
      //max-height: calc((var(--vw, 1vw) * 100) - 4rem);
      min-height: calc((var(--vw, 1vw) * 100) - 4rem);
    }
  }
}

.options-layer__list {
  div {
    height: 2.4rem;

    &:not(:last-child) {
      margin-bottom: 0.8rem !important;
    }
  }
}

.options-layer__buttons-list {
  * {
    padding: 0 !important;
    margin: 0 !important;
  }

  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-bottom: 0.8rem;

  .btn-mass-action-mobile {
    div:not(.bpm-dropdown-menu) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
    }
  }

  a.btn,
  button.btn {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: unset;
    height: 2.4rem;
    background-color: transparent !important;
    color: $main-color !important;
    border: none;

    i {
      display: none;
    }

    span.join-statuses {
      &:before {
        content: "\00a0 ";
      }

      color: $main-color;
      font-size: 1.4rem !important;
    }
  }
}

.options-layer__gradient {
  position: absolute;
  width: 100%;
  height: 4rem;
  padding: 0;
  margin: 0;
  z-index: 10000002;
}

.options-layer__gradient--top {
  background-image: linear-gradient(
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  top: 4rem;
}

.options-layer__gradient--bottom {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  bottom: 0;
}

.noScroll {
  position: relative;
  height: 100%;
  overflow: hidden;
}

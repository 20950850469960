@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.theme-prime,
.theme-primepro,
.theme-gethome {
  section.content.mobile-content {
    .bpm-grid-row {
      .bpm-grid-investment-mobile {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
        padding: 1.6rem;

        .bpm-grid-investment-header {
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;

          .bpm-grid-investment-title {
            display: flex;
            align-items: center;
            max-width: calc(100% - 72px);
            font-size: 1.3rem;
            font-weight: 500;
            color: $investment-heading-color;
            text-transform: capitalize;

            a {
              color: $investment-heading-color;
            }
          }

          .bpm-grid-investment-buttons {
            display: flex;
            align-items: flex-start;
          }
        }

        .bpm-grid-investment-img {
          .bpm-grid-investment-img-link {
            height: 200px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
            width: 100%;
          }

        }

        .bpm-grid-investment-info {
          font-size: 1.3rem;
          font-weight: 500;
          color: $investment-heading-color;
          text-transform: capitalize;

          a {
            color: $investment-heading-color;
          }
        }

        .bpm-grid-investment-controls {
          * {
            margin: 0;
            padding: 0;
          }

          display: flex;
          flex-direction: row;
          gap: 0.8rem;

          .btn-group {
            flex-basis: 50%;
            position: relative;

            .btn {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 28px;
              width: 100%;

              &.bpm-status {
                border-radius: 4px !important;
              }
            }
          }
        }

        .bpm-grid-investment-user {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.wizard-add {
  padding-bottom: 5rem;

  .wizard-header {
    display: none;
  }

  .wizard-nav {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    margin-bottom: 1.5rem;
    border-top-width: 2px;
    border-top-style: solid;
    @include themify($themes) {
      border-top-color: themed('btnPrimaryBg');
    }
  }

  .wizard-nav-step {
    background: none repeat scroll 0 0 $white-color;
    border: none;
    display: table-cell;
    height: 6rem;
    text-align: center;
    vertical-align: middle;
    font-size: 18px;
    font-weight: 300;
    position: relative;
    overflow: visible;

    &.step-active {
      color: $white-color;
      @include themify($themes) {
        background-color: themed('btnPrimaryBg');
        border-color: themed('btnPrimaryBg');
      }

      .fa {
        @include themify($themes) {
          color: themed('btnPrimaryBg');
        }
      }

    }

    &.step-next {
      background-color: none;
      border-color: none;
      @include themify($themes) {
        color: themed('btnPrimaryBg');
      }
    }

    &.step-prev {
      background-color: $table-cell-border;
      border-color: none;
      color: $autocomplete-color;

      .fa {
        color: $table-cell-border;
      }
    }

    .fa {
      @include fts15;
      position: absolute;
      right: -0.6rem;
      top: 2.3rem;
      z-index: 1000;
    }

    &:last-child .fa {
      display: none;
    }

    > span {
      background: transparent;
      display: table;
      height: 100%;
      vertical-align: middle;
      width: 100%;
      transition: .4s;

      > span {
        display: table-cell;
        height: 100%;
        vertical-align: middle;
        width: 100%;
      }
    }

    &:hover > span {
      background: $notifications-bg;
    }
  }

  .wizard-step-body {
    padding-bottom: 10rem;
    position: relative;
  }

  .wizard-controls {
    display: none;
    position: fixed;
    right: 4rem;
    bottom: 0;
    padding: 2rem 3.5rem;
    z-index: 4000;
    background: url("../gfx/wizard-controls-bg.png") repeat-x left top;
  }
}

.wizard-client .client-search,
.wizard-client .client-search-hr {
  display: none;
}

hr.client-search-hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.quick-add-trigger i {
  display: none;
}

.quick-add-trigger-active {
  filter: brightness(125%);

  i {
    display: inline;
  }
}

.fixed-controls {
  position: fixed;
  right: 4rem;
  bottom: 0;
  padding: 1rem 3.5rem;
  z-index: 4000;
  background: url("../gfx/wizard-controls-bg.png") repeat-x left top;
}

.back-header {
  position: relative;

  .back-btn {
    float: left;
    height: 5rem;
    line-height: 5rem;
    font-size: 3rem;
    margin-right: 1rem;
  }

  h2 {
    margin-bottom: 0 !important;
    font-size: 2.2rem;
    font-weight: 300;
  }

  h3 {
    margin-top: 0.5rem !important;
    margin-bottom: 1rem !important;
    font-size: 1.5rem;
    color: $main-color;
    font-weight: 300;
  }
}

.wizard-edit .wizard-header {
  position: relative;

  .back-navigate {
    position: absolute;
    left: 3.1rem;
    top: -2rem;
  }

  .back-btn {
    float: left;
    height: 5rem;
    line-height: 5rem;
    font-size: 3rem;
    margin-right: 1rem;
  }

  h2 {
    margin-bottom: 0 !important;
    font-size: 2.2rem;
    font-weight: 300;
  }

  h3 {
    margin-top: 0.8rem !important;
    margin-bottom: 1rem !important;
    font-size: 1.5rem;
    color: $main-color;
    font-weight: 300;
  }

  .bpm-object-infos-icons {
    @include fts15;
    margin-left: 0.5rem;
  }
}

.wizard-edit {

  position: relative;

  .wizard-step-body {
    padding-bottom: 10rem;
    position: relative;

    .bpm-listview-filters {
      top: -4rem;
    }

    .bpm-listview {
      padding-top: 1.5rem;
    }
  }

  .wizard-header-lease-details {
    padding-right: 40rem;
  }

  .bpm-listview-filters {
    top: -5.5rem;
  }

  .wizard-nav {
    background: $wizard-gradient;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#0a000000', GradientType=0);
    width: 100%;
    vertical-align: top;
    margin-bottom: 1.5rem;
  }

  .wizard-nav-step {
    padding: 0;
    vertical-align: top;
    position: relative;
    top: 0.2rem;
    border: 1px solid transparent;
    border-top-width: 0.2rem;
    color: $main-color;
    text-align: center;
    @include fts15;
    font-weight: 300;
    background: $wizard-step-bg;
    display: table-cell;
    border-bottom: 2px solid $border-color;

    .fa {
      display: none;
    }

    &.step-active {
      background: $main-bg-color;
      border-style: solid;
      border-width: 2px 0 0;
      box-shadow: $tabs-shadow;
      @include themify($themes) {
        color: themed('wizardActiveColor');
        border-color: themed('wizardActiveBorder');
      }
    }

    > span {
      display: table;
      height: 100%;
      padding: 0.8rem 1.2rem;
      transition: all 0.4s ease 0s;
      vertical-align: middle;
      width: 100%;

      > span {
        display: table-cell;
        height: 100%;
        vertical-align: middle;
        width: 100%;
      }
    }

    &.step-active > span {
      background: $wizard-gradient-active;
      border-color: $border-color;
      border-image: none;
      border-style: solid;
      border-width: 0 1px;
      display: table;
      height: 100%;
      padding: 0.8rem 1.2rem;
      transition: all 0.4s ease 0s;
      vertical-align: middle;
      width: 100%;
    }

    &:hover {
      @include themify($themes) {
        color: themed('wizardActiveColor');
      }
    }
  }

  .wizard-controls {
    display: none;
    position: fixed;
    right: 4rem;
    bottom: 0;
    padding: 2rem 3.5rem;
    z-index: 4000;
    background: url("../gfx/wizard-controls-bg.png") repeat-x left top;
  }

  .mobile-trigger {
    .wizard-controls {
      width: 100%;
      bottom: 6rem;
      right: 0;
      left: 0;
      margin: 0;
      padding: 1.8rem 0;

      .form-edit-controls {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;
        padding: 0 2.4rem;

        button, a {
          margin: 0;
        }
      }
    }
  }

  .client-search,
  .client-search-hr {
    display: none;
  }

  .client-search .control-label {
    padding-top: 1rem;
  }

  .notification-content {
    padding: 3rem 0 0 4rem;
  }

  .help-content {
    padding: 3rem 0 0 4rem;
    text-align: justify;
  }

  .help-related {
    padding: 3rem 0 0 4rem;
  }
}

.wizard-header-transaction {
  padding-right: 30rem;
}

.wizard-scrollbar {
  margin-bottom: 2.5rem;
}

.wizard-display-blocked {

  .disabled[disabled] {
    opacity: 1;
    background: none;
    border-width: 0 0 1px 0;
    color: $black-color;

    + .input-group-addon {
      display: none;
    }
  }

  .bpm-grid .disabled[disabled] {
    border-width: 0;
    color: $main-color;
    box-shadow: none;
  }

  .form-edit-controls {
    display: none;
  }
}

.readonly {
  opacity: 1;
  background: none !important;
  border-width: 0 0 1px 0 !important;
  color: $black-color;
}

.wizard-display-normal .go-to-edit {
  display: none;
}

.go-to-edit {
  display: none;
}

.wizard-summary {

  .profileimagediv .image-format {
    text-align: center;
    @include fts11;
    padding-top: 0.5rem;
  }

  .profileimage {
    width: 100%;
    border-radius: 0.5rem;
  }

  .imageprivate {
    display: none;
  }

  .imageprivatediv {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    display: block;
    padding-top: 100%;
    margin-bottom: 1rem;
  }

  .bpm-gmap-body {
    min-height: 20rem;
  }

  .profileimagesmall {
    width: 100%;
    margin-top: 1rem;
    border-radius: 0.5rem;
  }

  .profileimagesmalldiv {
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    .col-sm-6 {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .btn-more-photo,
  .btn-less-photo {
    margin-top: 1rem;
  }

  .summary-header {
    margin-bottom: 1rem;

    .summary-description {
      margin-bottom: 2rem;
      @include fts13;
      text-align: justify;
      word-break: break-word;
    }

    .btn {
      margin-bottom: 0.5rem;
    }

    h3 {
      margin-top: 0;
    }
  }

  .details-information {
    display: none;

    h4 {
      @include fts14;
    }
  }

  .profileimagesmallhalfhidden {
    display: none;
  }

  .summary-tags-area {
    padding: 0 0 2rem 0;
    clear: both;
  }

  .summary-tag {
    background: none repeat scroll 0 0 $white-color;
    border: 1px solid $border-color;
    float: left;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.2rem 0.5rem;
    @include fts13;
  }

  .summary-estate h3 {
    margin-top: 0;
    margin-bottom: 0.8rem;
  }

  .summary-estate h4 {
    margin-top: 0;
    margin-bottom: 2rem;
    @include fts16;
    font-weight: 400;
  }

  .summary-options-area {
    padding: 0 0 2rem 0;
    clear: both;
  }

  .summary-option {
    background: none repeat scroll 0 0 $white-color;
    border: 1px solid $border-color;
    float: left;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.3rem 0.6rem;
    @include fts13;
  }

  .summary-price {
    @include themify($themes) {
      color: themed('successColor');
    }
  }

  .summary-price-old {
    color: $main-color;
    text-decoration: line-through;
    @include fts13;
  }

  .order-item {
    margin-bottom: 0.7rem;

    a {
      color: $main-color;
      @include fts13;

      &:hover {
        @include themify($themes) {
          color: themed('linkColor');
        }
      }
    }
  }
}

#btn-less-options, #btn-less-photo {
  display: none;
}

.summary-estate .bpm-box-content {
  min-height: 40rem;
}

.bpm-help-dialog {
  text-align: justify;

  em {
    font-style: normal;
    @include themify($themes) {
      color: themed('successColor');
    }
  }

  .help-example {
    font-style: italic;
  }
}

.bpm-help-index a {
  display: block;
  line-height: 2.5rem;
  color: $input-color;
}

.help-dialog-more a {
  display: block;
  line-height: 2.5rem;
}

.bpm-wizard.wizard-edit.wizard-invoice-creator {
  .bpm-listview-filters {
    top: -5rem;
    left: 20rem;
  }
}

.bpm-page-role-edit .allcheck {
  @include fts12;
}

@media (max-width: 780px) {

  .summary-estate {

    padding-top: 1rem;

    .bpm-box-content {
      min-height: 0;
    }
  }

  .wizard-edit {

    .bpm-listview-filters {
      position: relative;
      top: 0;
    }

    .wizard-header {
      margin-bottom: 2rem;
    }
  }

  .profileimagesmallhalf,
  .btn-more-photo {
    display: none;
  }

  .wizard-localization {

    h2 {
      @include fts16;
    }

    .icons-localization-legend {
      display: flex;
      @include fts12;
      font-weight: 500;
      margin-bottom: 2rem;
    }
  }
}

.theme-prime,
.theme-primepro,
.theme-gethome {

  .wizard-edit {

    .wizard-header {
      margin-bottom: 2rem;

      h2 {
        font-size: 2.8rem;
        margin-bottom: 1rem !important;
      }

      h3 {
        @include fts14;
        margin-bottom: 2.5rem !important;

        i {
          @include fts17;
          color: $prime-medium-icon-color;
          margin-right: 0.8rem;
        }

        .contact-item {
          margin-right: 2.5rem;
        }
      }

      .wizard-header-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        margin-bottom: 1rem;

        h2 {
          font-size: 2.8rem;
          margin-bottom: 0 !important;
        }

        .btn-bulb {
          .bpm-dropdown {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background-color: #EBFF00;
            cursor: pointer;
            border-radius: 50%;
            margin-left: 10px;


            img {
              height: 32px;
            }
          }

          .bpm-dropdown-menu {
            border-radius: 2px;
            left: 6px;
            top: calc(100% + 27px);
            width: 509px;

            .bpm-dropdown-content {
              border-radius: 2px;
              margin: 0;
              padding: 10px;
              color: #fff;
              font-size: 15px;
              font-weight: normal;
              line-height: normal;
              background-color: #3F4246;

              p {
                margin: 0;
              }

              .tooltip-header {
                font-weight: 700;
              }

              .tooltip-text {
                font-weight: 400;
                line-height: 24px;

                span {
                  font-weight: 500;
                  letter-spacing: 0.15px;
                  text-decoration-line: underline;
                  cursor: pointer;
                }
              }

              &::before {
                content: "";
                position: absolute;
                top: -12px;
                left: 24px;
                transform: translateX(-50%);
                border-width: 0 7px 12px;
                border-style: solid;
                border-color: transparent transparent #3F4246;
              }
            }
          }
        }
      }

      .back-link {
        font-weight: 500;
        @include fts15;
        color: #3F4246;

        i {
          @include fts17;
          color: #7B7D7F;
          margin-right: 0.8rem;
        }
      }

      .bpm-listview-controls {
        position: absolute;
        top: 0;
        padding-top: 2rem;
      }
    }

    .wizard-nav {
      background: $white-color;
      filter: none;
    }

    .wizard-nav-step {
      border: none;
      color: $prime-dark2-color;
      background: $white-color;
      top: 0;
      border-radius: 0 !important;

      &.step-active {
        border-radius: 0 !important;
      }
    }

    .bpm-listview-filters,
    .wizard-step-body .bpm-listview-filters {
      top: 0;
    }

    .bpm-listview-controls {
      position: absolute;
      right: 0;
      top: 0;
    }

    .bpm-listview .back-link {
      font-weight: 500;
      @include fts13;
      color: $investment-span-color;

      i {
        @include fts15;
        color: $investment-span-color;
        margin-right: 1.2rem;
      }
    }

    .wizard-step-body .bpm-listview {
      padding-top: 4rem;

      &.bpm-listing-client-application {
        padding-top: 0;
      }
    }

    .wizard-step-body .bpm-controls-left .btn-group.btn-actions-button i.dropdown-list-icon {
      right: -2rem !important;
      top: 0 !important;
    }

    .wizard-nav-step {

      @include fts12;

      > span {
        padding: 1.1rem 1.6rem;
      }

      &.step-active {
        border: none;
        box-shadow: none;
        color: $prime-primary-color;
        background-color: $prime-hover-box;

        > span {
          background: none;
          border: none;
          border-image: none;
          padding: 1.1rem 1.6rem;
        }
      }

      &:hover {
        color: $prime-primary-color;
        background-color: $prime-hover-box;
      }
    }
  }

  .bpm-wizard.wizard-edit.wizard-invoice-creator {
    .bpm-listview-filters {
      top: 0;
      left: unset;
      right: 0;
    }
  }
}

.theme-gethome {

  .wizard-edit .wizard-nav-step {

    &.step-active {
      color: $gethome-primary-color;
      background-color: $gethome-cell-border;
    }

    &:hover {
      color: $gethome-primary-color;
      background-color: $gethome-cell-border;
    }
  }
}

@media (max-width: 780px) {
  .theme-prime,
  .theme-primepro,
  .theme-gethome {
    .wizard-edit .wizard-header .bpm-listview-controls {
      position: static;
    }
  }
}

.mobile-content {

  .input-lg.client-search {
    padding: 0.8rem 1.2rem 0.8rem 1rem;
  }

  .client-search .col-sm-6.hint {
    padding: 0 2rem;
  }
}
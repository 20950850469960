@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.form-control {
  border-radius: 4px;
}

.btn, .btn-link, .btn-lg, .btn-sm, .fc-header .fc-button {
  border-radius: 4px !important;
}

.btn.bpm-status {
  border-radius: 50px !important;
}

.btn-xs,
.bpm-checkbox input[type="checkbox"] ~ span:before,
.bpm-label,
.bpm-grid.bpm-tableview .bpm-grid-actions a {
  border-radius: 4px;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 4px;
}

.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child > .btn, .input-group-btn:last-child > .dropdown-toggle, .input-group-btn:first-child > .btn:not(:first-child) {
  border-radius: 4px;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child > .btn, .input-group-btn:first-child > .dropdown-toggle, .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

section.content-login article .login-box,
.bpm-desktop-item-wrapper,
.bpm-box-content,
.bpm-summary-item-wrapper,
.bpm-desktop-tile,
.bpm-box,
.fc-event-vert,
.fc-event-hori, cc
.bpm-tag,
.wizard-add .wizard-nav,
.bpm-grid.bpm-tileview .bpm-grid-row.bpm-gallery-item {
  border-radius: 4px !important;
}

.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 4px;
}

.wizard-summary .summary-tag,
.wizard-summary .summary-option,
.bpm-grid-presentation .summary-option {
  border-radius: 5px;
}

.bpm-dialog-header,
.wizard-edit .wizard-nav-step.step-active,
.bpm-summary-item-wrapper .bpm-tabs-menu > a.active,
.bpm-box-content .bpm-tabs-menu > a.active,
.bpm-tabs-menu > a.active {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}


.bpm-dialog-content,
.bpm-dropdown-menu, .bpm-hoverdown-menu,
.bpm-dropdown-menu-foot {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.bpm-selectbox {

  .form-control {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .input-group-addon {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.no-radius-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.no-radius-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.theme-gethome {
  .input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child > .btn, .input-group-btn:last-child > .dropdown-toggle, .input-group-btn:first-child > .btn:not(:first-child), .input-group-addon:not(:first-child):not(:last-child),
  .input-group-btn:not(:first-child):not(:last-child),
  .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 4px;
  }
}

@media (max-width: 780px) {

  section.content-login article .login-box,
  .bpm-desktop-item-wrapper,
  .bpm-box-content,
  .bpm-summary-item-wrapper,
  .bpm-desktop-tile, .bpm-box,
  .fc-event-vert, .fc-event-hori,
  .bpm-tag, .wizard-add .wizard-nav,
  .bpm-grid.bpm-tileview .bpm-grid-row.bpm-gallery-item {
    border-radius: 4px !important;
  }

}

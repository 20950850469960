@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-grid-row {
  label.bpm-checkbox-mobile {
    input[type="checkbox"] {
      display: none;
    }

    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    background-color: white;
    border: 2px solid #A7A6A7;
    border-radius: 4px;
  }

  &.bpm-selected {
    label.bpm-checkbox-mobile {
      position: relative;
      color: $white-color;
      background-color: #309FD6;
      border-color: #309FD6;

      &:before {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: FontAwesome;
        font-size: 18px;
        content: '\f00c';
        width: 100%;
        height: 100%;
      }
    }
  }
}

.bpm-checkbox {
  cursor: pointer;
  margin-right: 5px;

  input[type="checkbox"],
  input[type="radio"] {
    display: none;

    ~ span:before {
      background-color: $white-color;
      border: 1px solid #7B7D7F;
      color: $white-color;
      content: "\f00c";
      display: inline-block;
      font-family: FontAwesome;
      @include fts13;
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      position: absolute;
      left: 0;
      padding: 0.2rem;
      width: 1.7rem;
      height: 1.7rem;
    }

    &:checked ~ span:before {
      @include themify($themes) {
        background-color: themed('checkboxBgColor');
        color: themed('radioCheckedColor');
      }
      border: 0;
    }
  }

  span {
    display: block;
    min-height: 1.4rem;
    min-width: 0.1rem;
    padding: 0 0 0 2.3rem;
    position: relative;
    margin-bottom: 0.2rem;
  }

  &.bpm-radio span:before {
    border-radius: 100%;
  }

  &.bpm-checkbox-disabled {
    color: $disabled-color;

    input[type="checkbox"] + span:before,
    input[type="radio"] + span:before {
      background-color: $item-bg-color;
      color: $item-bg-color;
    }

    input[type="checkbox"]:checked + span:before,
    input[type="radio"]:checked + span:before {
      background-color: $menu-text-color;
    }
  }

  &.bpm-superradio span:before {
    border-radius: 100%;
  }
}

.checkbox-unavailble .bpm-checkbox {
  color: $disabled-color;

  input[type="checkbox"] + span:before,
  input[type="radio"] + span:before {
    background-color: $item-bg-color;
    color: $item-bg-color;
  }

  input[type="checkbox"]:checked + span:before,
  input[type="radio"]:checked + span:before {
    background-color: $menu-text-color;
  }

}

.checkbox-invisible {
  display: none;
}

.bpm-dropdown-content .bpm-checkbox {
  display: block;
}

.bpm-dropdown-content hr {
  margin: 0.5rem 0;
}

.bpm-radiolinks .bpm-radio {
  display: block;
  width: 100%;
  margin-bottom: 0;
  text-align: left;

  input[type="radio"] + span:before {
    display: none;
  }

  span {
    display: block;
    width: 100%;
    margin-bottom: 0;
    text-align: left;
    padding: 0.4rem 1rem;

    &:hover {
      background-color: $item-bg-color;
    }
  }

  input[type="radio"]:checked + span {

    @include themify($themes) {
      background-color: themed('radioCheckedBg');
      color: themed('radioCheckedColor');
    }
  }
}

.bpm-submit {
  display: block;
  width: 100%;
  margin-bottom: 0;
  text-align: left;
  padding: 0.4rem 1rem;
  background: none;
  color: $main-color;
  border: none;
  @include fts14;

  @include themify($themes) {
    background: none;
    color: $main-color;
    border: none;
  }

  &:hover {
    background-color: $item-bg-color;
    color: $main-color;
  }
}

.bpm-submit-sending {
  background-color: $disabled-color !important;
  border-color: $disabled-color !important;
}

.bpm-supercheckbox {
  cursor: pointer;
  margin-bottom: 0;

  input[type="checkbox"],
  input[type="radio"] {
    display: none;

    + span:before {
      background-color: $white-color;
      border: 1px solid $border-color;
      color: $white-color;
      content: "\f00c";
      display: inline-block;
      font-family: FontAwesome;
      @include fts13;
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      margin-right: 0.3rem;
      padding: 0.2rem;
      width: 1.7rem;
      height: 1.7rem;
    }
  }

  input[type="checkbox"]:checked + span:before,
  input[type="radio"]:checked + span:before {
    @include themify($themes) {
      background-color: themed('checkboxBgColor');
      color: themed('radioCheckedColor');
    }
    border: 0;
  }
}

.ie8 .fa-checkbox input[type="checkbox"],
.ie8 .fa-checkbox input[type="radio"] {
  display: inline-block;
}

.theme-prime,
.theme-primepro,
.theme-gethome {

  .bpm-checkbox span {

    padding: 0 0 0 2.6rem;

    span {
      padding: 0;
      color: $prime-primary-color

    }
  }

  .bpm-checkbox input[type="checkbox"] + span:before, .bpm-checkbox input[type="radio"] + span:before {
    top: 50%;
    transform: translateY(-50%);
  }

  .remember-me-checkbox .bpm-checkbox input[type="checkbox"] + span:before, .bpm-checkbox input[type="radio"] + span:before {
    top: 50%;
    transform: translateY(-50%);
  }

  .fc-status-checkbox {
    display: none;
  }

  .event-subtype .bpm-checkbox {
    margin-right: 2.5rem;
  }

  .bpm-page-investment-details {
    .btn-primary.bpm-submit {
      background-color: $prime-primary-color;
      border-color: $prime-primary-color;
      color: $white-color;
    }
  }

  .checkbox-unavailble {
    display: none;
  }

  .bpm-submit {
    width: unset;
  }
}

.theme-prime .prime-register-form {
  .checkbox-unavailble {
    display: block;

    .bpm-checkbox {
      cursor: auto;
    }
  }

  .bpm-checkbox.first-register-checkbox {
    display: flex;
    flex-direction: column-reverse;
  }
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";
@import "./resources/sass-test/build/sass/base/_core";
@import "./resources/sass-test/build/sass/themes/_themes";

header {
  position: fixed;
  //background: $menu-bg-color url(../gfx/bg.jpg) repeat 0 0;
  width: 100%;
  z-index: 5000000;

  @include themify($themes) {
    background: themed('headerBgColor');
    border-bottom: themed('headerBorderBottom');
    height: themed('menuHeight');
    font-family: themed('mainFont'), Helvetica, Arial, sans-serif;
  }

  .logo {
    display: inline-block;
    @include themify($themes) {
      text-align: themed('logoTextAlign');
      height: themed('logoHeight');
      width: themed('logoWidth');
      line-height: themed('logoHeight');
      margin: themed('logoMargin');
    }
  }
}

.theme-gethome header .logo img {
  height: 4rem;
}

.search-bar-top {
  display: block;
  position: absolute;
  width: 48rem;
  left: 23rem;
  @include themify($themes) {
    height: themed('searchBarHeight');
    padding-left: themed('searchBarPaddingLeft');
    line-height: themed('searchBarHeight');
    top: themed('searchBarTop');
  }

  .bpm-dropdown-menu-foot {
    @include themify($themes) {
      line-height: themed('searchBarDropdown');
    }
  }

  .search-input {
    display: block;
    float: left;
    @include themify($themes) {
      width: themed('searchInputWidth');
    }
  }

  .search-button {
    width: 3rem;
    display: block;
    float: left;
    margin-left: 1rem;
    padding-top: 0.3rem;

    a i {
      @include fts18;
      @include themify($themes) {
        color: themed('menuTextColor');
        text-shadow: none;
      }
    }

    a span {
      @include fts13;
      color: $white-color;
      padding-left: 0.5rem;
      @include themify($themes) {
        color: themed('navTopColor');
        text-shadow: none;
      }
    }

    .bpm-dropdown-menu {
      width: 20rem;
      top: 4.2rem;
    }

  }

  .input-group {
    box-shadow: none;
  }

  .form-control {

    @include fts14;
    padding: 0.3rem 1rem;
    background-color: $white-color;

    @include themify($themes) {
      border: themed('searchBarTopBorder');
      height: themed('searchBarTopHeight');
      color: themed('searchBarColor');
      background-color: themed('searchBarTopBg');
    }

    &::-webkit-input-placeholder {
      @include themify($themes) {
        color: themed('searchBarTopPlaceholderColor')
      }
    }

    &.placeholder-active::-webkit-input-placeholder {
      @include themify($themes) {
        color: themed('searchBarTopPlaceholderActiveColor')
      }
    }

    &::-webkit-input-placeholder {
      color: $gray-color;
    }

  }

  .bpm-autocomplete .bpm-dropdown-menu {
    width: 50rem;
  }

}

.client-search .form-control::-webkit-input-placeholder {
  color: $placeholder-color;
}

.nav-top {
  height: 100%;

  > .btn-group {
    float: left;
    height: 100%;

    > a {
      float: left;
      height: 100%;
      padding: 0 15px;
      @include themify($themes) {
        color: themed('menuTextColor');
        text-shadow: none;
      }

      i {
        @include themify($themes) {
          color: themed('menuTextColor');
          font-size: themed('navTopIconsSize');
          line-height: themed('navTopIconsLineHeight');
          text-shadow: none;
        }
      }
    }

    > a.labeled i {
      @include fts16;
    }

    &:hover > a {
      background-color: $nav-bg-hover;

      i {
        @include themify($themes) {
          color: themed('infoTextColor');
          text-shadow: none;
        }
      }
    }

    > a.account {
      padding: 0 1rem;
    }

    > a.help-icon i {
      @include themify($themes) {
        color: themed('infoTextColor');
        text-shadow: none;

      }
      font-size: 2.2rem;
    }

    > a.nav-active i {
      color: $nav-active-color;

    }

    span {
      padding-left: 0.5rem;
      @include fts13;
      @include themify($themes) {
        color: themed('navTopColor');
      }

      span {
        padding-left: 0;
      }
    }

    .bpm-checkbox span {
      @include themify($themes) {
        text-shadow: none;
      }
    }

    .list-group-item span.info-label {
      color: $input-color;
    }

  }

  img.avatar {
    border-radius: 50%;
    overflow: hidden;

    @include themify($themes) {
      width: themed('avatarWidth');
      margin-top: themed('avatarMarginTop');
    }
  }

}

.theme-prime,
.theme-primepro,
.theme-gethome {
  .nav-top {
    padding-right: 4rem;

    > .btn-group {

      > a.account {
        padding-right: 0;
      }

      > a i {
        color: $prime-primary-color;
      }

      > a:hover i {
        color: $investment-span-color;
      }
    }
  }
}

.theme-gethome .nav-top > .btn-group {
  > a i {
    color: $gethome-primary-color;
  }

  > a:hover i {
    color: $gethome-icons-color;
  }
}

.nav-item-badge {
  position: absolute;
  top: 0.5rem;
  left: 50%;
  height: 1.8rem;
  min-width: 1.4rem;
  border-radius: 1.4rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  text-align: center;
  padding: 0 0.4rem;
  z-index: 1;
}

.nav-top > .btn-group span.nav-item-badge {
  @include themify($themes) {
    color: themed('badgeColor');
    background-color: themed('badgeBgColor');
  }
}

.empty-badge {
  display: none;
}

.nav-top .list-group,
.search-bar-top .list-group {
  @include reset;
}

.nav-top .list-group-item {
  @include reset;
  border: none;
  border-bottom: 1px solid $border-color;

  &.notification-not-read a {
    font-weight: bold;
    background-color: $light-color;
  }

  a {
    display: block;
    @include regular-padding;
    @include fts11;
    color: $main-color;

    &.more {
      font-weight: bold;
      @include themify($themes) {
        color: themed('linkColor');
      }
      @include fts11;
    }

    &:hover {
      background: $item-bg-color;
    }

    span.date {
      display: block;
      padding: 0.3rem 0 0 0;
      font-size: 1rem;
      @include themify($themes) {
        color: themed('linkColor');
        text-shadow: none;
      }
    }

    span.from {
      display: block;
      padding: 0.3rem 0 0 0;
      font-size: 1rem;
      @include themify($themes) {
        color: themed('successColor');
      }
    }

  }

  span.message {
    display: block;
    @include regular-padding;
    @include fts11;
    color: $main-color;
    text-shadow: none;
  }

  .bpm-status-temp {
    @include fts11;
    @include themify($themes) {
      color: themed('alertColor');
    }
    padding: 0;
  }

}

.nav-top .nav-item .list-group-item {
  position: relative;
}

.nav-top .nav-dropdown .list-group-item a,
.nav-top .nav-dropdown .list-group-item input[type="submit"],
.search-bar-top .nav-dropdown .list-group-item input[type="submit"] {
  @include regular-padding;
  display: block;
  color: $input-color;
  @include fts13;
}

.nav-top .bpm-calendar-dropdown .list-group-item .bpm-checkbox,
.nav-top .bpm-notification-dropdown .list-group-item .bpm-checkbox {
  position: absolute;
  left: 1rem;
  top: 1rem;
}

.nav-top .bpm-calendar-dropdown .list-group-item a,
.nav-top .bpm-notification-dropdown .list-group-item a {
  padding-left: 3.5rem;
}

.search-bar-top .list-group-item {
  @include reset;
  border: none;
  border-bottom: 1px solid $border-color;

  span.message {
    @include regular-padding;
    @include fts11;
    display: block;
    color: $main-color;
  }
}

.search-button .bpm-dropdown-content {
  width: 30rem;
}

.search-bar-top .search-button .bpm-hoverdown-content,
.search-bar-top .search-button .bpm-dropdown-content {
  width: 30rem;
  @include reset;
}

.search-bar-top .nav-dropdown .list-group-item.button-selected input[type="submit"] {
  background-color: $selected-bg-color;
}

.qtip {
  z-index: 99999991 !important;

  .qtip-content {
    line-height: 1.5rem;
    @include fts12;
  }
}

.qtip-helptip {
  max-width: 45rem;

  .qtip-content {
    @include fts11;
    padding: 1rem;
    line-height: 1.3rem;
  }

  h3 {
    margin-top: 0;
    @include fts16;
    margin-bottom: 1rem;
  }

  h4 {
    margin-top: 0;
    @include fts15;
    margin-bottom: 1rem;
  }

  ul {
    @include reset;
    list-style: none;

    li {
      margin-bottom: 0.5rem;
    }
  }

  .maptip {
    position: relative;
    width: 38rem;
    height: 12rem;

    img {
      width: 12rem;
    }

    h3 {
      @include fts13;
      margin-top: 0;
      margin-bottom: 0.5rem;
    }

    h4 {
      @include fts13;
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }

  .mapcontent {
    position: absolute;
    left: 13rem;
    top: 0;
  }
}

#qtip-overlay {
  display: block !important;
  visibility: hidden;

  div {
    opacity: 1;
  }
}

.theme-prime,
.theme-primepro,
.theme-gethome {

  header {
    border-bottom: 1px solid #F1F0F5;
  }

  .qtip {
    background-color: $prime-qtip-bg;
    border-color: $prime-qtip-border;
    color: $prime-qtip-color;
    box-shadow: 0 0 2rem $bg-hover-opacity;
    border-radius: 3px;
    padding: 0.5rem;

    .qtip-titlebar {
      background-color: $prime-qtip-bg;
    }

    .qtip-content {
      @include fts13;
      line-height: 1.8rem;
    }
  }

  .nav-item-badge {
    position: absolute;
    top: 1rem;
    left: 50%;
    height: 2rem;
    min-width: 1.8rem;
    border: 2px solid $white-color;
    background-color: $dot-inactive-color;
    color: $white-color !important;
    border-radius: 1.2rem;
    @include fts12;
    font-weight: 400;
    line-height: 1.8rem;
    text-align: center;
    padding: 0 0.5rem !important;
    z-index: 1;
  }

  .btn-item-badge {
    position: absolute;
    top: -0.5rem;
    right: 80%;
    height: 1.8rem;
    min-width: 1.4rem;
    background-color: $alert-secondary-color;
    color: $white-color;
    border-radius: 1.4rem;
    @include fts12;
    font-weight: 400;
    line-height: 1.8rem;
    text-align: center;
    padding: 0 0.4rem;
    z-index: 1;
  }

  .investment-buttons .btn-item-badge {
    right: 60%;
  }
}

.helptip-icon {
  font-size: 1.4rem;
  display: none;
}

.bpm-helptip-show .helptip-icon {
  display: inline;
}

.helptip-icon.helptip-message {
  display: inline;

  &.helptip-absolute {
    position: absolute;
    right: 0;
    top: 0.7px;
  }
}

@media (max-width: 780px) {

  .nav-top {
    padding-right: 3rem;
  }

  .nav-top > .btn-group span {
    display: none;
  }

  .nav-top > .btn-group > a {
    padding: 0 1rem !important;
  }

  //.search-bar-top {
  //  left: 0;
  //  padding: 0 3rem;
  //  width: 100%;
  //  top: 5rem;
  //  height: 5rem;
  //  background: url(../gfx/bg.jpg) repeat 0 0 $menu-bg-color;
  //
  //  .form-control {
  //    height: 3.2rem;
  //    padding: 0.6rem 1rem;
  //    @include fts12;
  //    line-height: 1.428571429;
  //    color: $placeholder-color;
  //    vertical-align: middle;
  //    background-color: $white-color;
  //    background-image: none;
  //    border: 1px solid $border-color;
  //    box-shadow: 0 1px 0 $searchbar-trigger-bg;
  //  }
  //
  //  .search-input {
  //    width: 70%;
  //    float: right;
  //  }
  //}

  .theme-prime,
  .theme-primepro,
  .theme-gethome {

    body > header {
      height: 12rem;
    }

    header.login {
      height: 10rem;
    }

    section.content.content-login {
      height: calc(100% - 10rem);

      .input-group.form-group .form-control {
        height: 4rem;
        line-height: 4rem;
      }

      .btn {
        height: 4rem;
      }
    }

    .nav-top {
      padding-right: 3rem;

      > .btn-group span {
        display: none;
      }

      > .btn-group > a {
        padding: 0 1rem !important;
      }
    }

    #emails-dropdown-group {
      display: none;
    }

    header .logo {
      width: 15rem;
      text-align: left;
      padding-left: 3rem;
    }

    .search-bar-top {
      left: 0;
      padding: 0 3rem;
      width: 100%;
      top: 5rem;
      height: 5rem;
      background: none;
      box-shadow: none;

      .search-button {
        display: none;
      }

      .form-control {
        height: 3.2rem;
        padding: 0.6rem 1rem;
        @include fts12;
        line-height: 1.428571429;
        color: $prime-radiobtn-color;
        vertical-align: middle;
        background-color: $white-color;
        background-image: none;
        border: 1px solid $border-color;
        box-shadow: 0 1px 0 $searchbar-trigger-bg;
      }

      .search-input {
        width: 70%;
        float: right;
      }
    }

    .menu-mobile {
      float: left;
      width: 20%;
      display: block;

      a {
        display: inline-block;
        color: $white-color !important;
        border-radius: 7px !important;
        font-weight: normal;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        padding: 0.5rem 1.5rem;
        @include fts14;
        line-height: 2.4rem;
        width: 100%;
      }
    }

  }

}

@media (min-width: 992px) and (max-width: 1024px) {

  .nav-top > .btn-group > a {
    padding: 0 0.5rem;
  }

  .nav-top > .btn-group > a i {
    @include fts15;
  }

  .nav-top > .btn-group span {
    @include fts12;
  }

  .search-bar-top .search-button a i {
    @include fts15;
  }

  .search-bar-top .search-button a span {
    @include fts12;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .search-bar-top {
    left: 20.5rem;
  }
}

@media (min-width: 780px) {
  .theme-604 a.logo {
    margin: 0 3.5rem;
  }
}

// IOS header start

header.ios, div.offcanvas-header {
  @include themify($themes) {
    background: themed('headerBgColor');
    min-height: 5.6rem;
    height: 5.6rem;
    font-family: themed('mainFont'), Helvetica, Arial, sans-serif;
    padding: 0;
    nav {
      padding: 0 1.6rem;
      width: 100vw;

      .nav__elements {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 28px;
        width: 100%;

        &.collapse {
          &:not(.show) {
            display: none;
          }
        }

        &.collapsing {
          transition: none;
          display: none;

          &.collapse-horizontal {
            transition: none;
            display: none;
          }
        }

        .nav__icons {
          display: flex;
          align-items: center;
          height: 28px;

          &.nav__icons--left {
            .navbar-brand {
              margin-left: 16px;
            }

            &.nav__icons--search {

              width: 100%;

              .searcher-mobile-container {
                width: 100%;
                height: 40px;

                .form-control {
                  height: 40px;
                  padding: 0 16px;
                  margin: 0;
                  box-shadow: none;
                  border: 1px solid themed('formControlBorder');
                  border-radius: 4px;
                }

                .bpm-dropdown-menu {
                  width: 100% !important;
                  left: 0 !important;
                  right: 0 !important;
                  margin-top: 4px;
                  border-radius: 4px;
                }

                .search-button {
                  display: none;
                }
              }
            }
          }

          &.nav__icons--right {
            .nav__icon:not(:first-child) {
              margin-left: 24px;
            }

            &.nav__icons--search {
              .nav__icon {
                margin-left: 16px;
                //color: #3F4246;
              }
            }
          }

          .nav__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 28px;
            width: 28px;
            border: none;
            margin: 0;
            padding: 0;
            background-color: transparent;
            color: themed('menumobileIconsColor');

            i {
              font-size: 21px;
            }

            img {
              border-radius: 50%;
              width: 21px;
              height: 21px;
            }

          }
        }
      }
    }
    .offcanvas {
      --bs-offcanvas-height: 100vh;
      --bs-offcanvas-border-color: 0;
      z-index: 100000000000;

      .offcanvas-body {
        padding: 0;
        background-color: themed('headerBgColor');

        .notification-list {

          label {
            display: none;
          }

        }

        .list-group {
          margin: 0;
          padding: 1px;
          background-color: themed('headerBgColor');
          border-radius: 0;
          font-size: 1.2rem;

          .list-group-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            min-height: 56px;
            height: 56px;
            padding: 0;
            background-color: themed('headerBgColor');
            color: themed('menuTextColor');
            border: none;

            span.message {
              padding: 0 24px;
            }

            &:not(:last-of-type) {
              margin-bottom: 1px;
            }

            a {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              color: themed('menuTextColor');
              font-size: 15px;
              width: 100%;
              height: 100%;
              padding: 0 24px;
            }


            &.user-info {
              height: unset;
              margin: 0;
              border: none;
              background-color: #37474F;
              padding: 16px 24px;
            }
          }
        }

        nav {
          padding: 0;
          background: themed('headerBgColor');

          .accordion {

            .accordion-item {
              &:not(:last-of-type) {
                margin-bottom: 1px;
              }

              //margin-bottom: 1px;
              border: none;

              .notification-list.list-group {

                .list-group-item {
                  &.info {
                    min-height: 43px;
                    height: 43px;
                  }

                  padding: 1.6rem 2.4rem .8rem 4rem;
                  min-height: unset;
                  height: unset;

                  a {
                    display: block;
                    white-space: unset;
                    padding: 0;
                    min-height: unset;
                    height: unset;
                  }

                  span.message {
                    height: 43px;
                    min-height: 43px;
                    padding: 0;
                  }

                  a.more {
                    display: flex;
                  }
                }
              }

              button, a {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                min-height: 5.6rem;
                padding: 1.6rem 2.4rem;
                background: themed('headerBgColor');
                border-radius: 0;
                text-decoration: none;
                box-shadow: none;

                &::after {
                  display: none;
                }

                &:focus {
                  box-shadow: none;
                }

                span.email-mobilemenu-container {
                  position: relative;
                }

                span {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  height: 2.3rem;
                  font-size: 1.5rem;
                  font-weight: 400;
                  color: themed('menuTextColor');

                  &.nav-item-badge {
                    position: absolute;
                    top: -0.6rem;
                    left: 1rem;
                    height: 1.8rem;
                    min-width: 1.4rem;
                    border-radius: 1.4rem;
                    font-size: 1.2rem;
                    font-weight: 400;
                    line-height: 1.8rem;
                    text-align: center;
                    padding: 0 0.4rem;
                    z-index: 1;
                    background-color: $alert-secondary-color;
                    color: $white-color;

                    &.empty-badge {
                      display: none;
                    }
                  }
                }

                i.fa-chevron-down {
                  color: themed('menuTextColor');
                  font-size: 1.2rem;
                  transition: 0.2s;
                  margin-left: auto;
                }

                &.collapsed {
                  i.fa-chevron-down {
                    transform: rotate(-90deg);
                    transition: 0.2s;
                    margin-left: auto;
                  }
                }

                i:not(.fa-chevron-down) {
                  font-size: 20px;
                  margin-right: 10px;
                  color: themed('menuTextColor');

                  i.blue {
                    margin-left: 10px;
                  }
                }
              }
            }

            .accordion-body {
              padding: 0;
              background: themed('headerBgColor');

              .list-group {
                padding: 0;

                .list-group-item {
                  min-height: 43px;
                  height: 43px;
                  margin: 0;

                  //a {
                  //  font-size: 13px;
                  //  padding: 0 40px;
                  //}
                }
              }

              span.more, span.message {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 0 2rem 0 4rem;
                min-height: 4.3rem;
                height: 4.3rem;
                font-size: 1.3rem;
                white-space: nowrap;
              }

              button, a {
                padding: 0 2rem 0 4rem;
                min-height: 4.3rem;
                height: 4.3rem;
                font-size: 1.3rem;
                white-space: nowrap;

                span {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  font-size: 1.3rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

.theme-primepro header.ios .offcanvas .offcanvas-body .list-group .list-group-item.user-info {
  background-color: $prime-hover-box;
}

.theme-primepro header.ios .offcanvas .offcanvas-body nav .accordion .accordion-item a.active-url {
  background-color: $prime-hover-box;
  color: $prime-primary-color;

  i, span {
    color: $prime-primary-color;
  }
}

// IOS header end
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.offer-box {
  margin-bottom: 3rem;
  position: relative;
  height: 20rem;

  .profileimage {
    width: 20rem;
  }

  .offer-box-estate {
    position: absolute;
    top: 0;
    left: 22rem;

    h3 {
      margin-top: 0;
      margin-bottom: 0.8rem;
    }

    h4 {
      margin-top: 0;
      margin-bottom: 2rem;
      @include fts16;
      font-weight: 400;
    }
  }

  .offer-box-description {
    margin-bottom: 2rem;
    @include fts13;
    text-align: justify;
  }

  .offer-box-tags-area {
    padding: 0 0 2rem 0;
    clear: both;
  }

  .offer-box-tag {
    background: none repeat scroll 0 0 $white-color;
    border: 1px solid $border-color;
    float: left;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.2rem 0.5rem;
    @include fts13;
  }

  .offer-box-buttons {
    position: absolute;
    bottom: 0;
    left: 22rem;
  }

  h3 {
    margin-top: 0;
  }

  .offer-box-options-area {
    padding: 0 0 2rem 0;
    clear: both;
  }

  .offer-box-option {
    background: none repeat scroll 0 0 $white-color;
    border: 1px solid $border-color;
    float: left;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.3rem 0.6rem;
    @include fts13;
  }

  .offer-box-price {
    @include themify($themes) {
      color: themed('successColor');
    }
  }

  .offer-box-price-old {
    color: $main-color;
    text-decoration: line-through;
    @include fts13;
  }

  .opinion-dropdown .bpm-dropdown-content {
    width: 40rem !important;
  }
}

.bpm-offer-list h3 {
  margin-top: 0;
}

/*  Investment
========================================================================== */

.investment-field-error {
  @include themify($themes) {
    color: themed('alertColor');
  }
}

.investment-field-ok {
  @include themify($themes) {
    color: themed('successColor');
  }
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-tabs-carousel > .bpm-tabs-menu + .bpm-tabs-panels {
  font-size: 0;
  letter-spacing: -.31em;
  word-spacing: -.43em;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  padding-bottom: 4rem;

  > .bpm-tabs-panel {
    width: 100%;
    position: relative;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    @include fts13;
    letter-spacing: normal;
    vertical-align: top;
    word-spacing: normal;
    white-space: normal;
  }
}

.bpm-tabs-panels {
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 100%;
}

.bpm-tabs-panel {
  width: 100%;
  position: relative;
  z-index: 1;
  display: none;
  padding: 2rem 0.5rem;
}

.bpm-tabs-trigger-panel {
  width: 100%;
  position: relative;
  z-index: 1;
  display: inline-block;
  @include themify($themes) {
    padding: themed('tabsPadding');
  }
}

.bpm-tabs-menu {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.02) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#0a000000', GradientType=0);
  border-bottom: 1px solid $border-color;
  display: block;
  width: 100%;
  @include themify($themes) {
    background: themed('tabsBg');
  }
}

.bpm-tabs-menu > a {
  display: inline-block;
  padding: 0.8rem 1.2rem;
  vertical-align: top;
  position: relative;
  top: 0.1rem;
  border: 1px solid transparent;
  border-top-width: 0.2rem;
}

.bpm-tabs-menu > a.active {
  background: $white-color;
  border-style: solid;
  border-width: 2px 1px 1px;
  box-shadow: $tabs-shadow;
  @include themify($themes) {
    border-color: themed('tabsBorderColor');
  }

  &:hover {
    @include themify($themes) {
      color: themed('tabsBorderColor');
    }
  }
}

.bpm-tabs-menu > a:not(.active) {
  color: inherit;
}

.bpm-tabs-disabled .bpm-tabs-menu > a:not(.active) {
  display: none;
}

.bpm-dialog-body .bpm-tabs-menu > a.active {
  background: $main-bg-color;
}

.tabs-list {
  background: $wizard-gradient;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#0a000000', GradientType=0);
  border-bottom: 1px solid $border-color;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin-bottom: 2rem;

  .tab {
    float: left;
    padding: 0;
    vertical-align: top;
    position: relative;
    top: 0.2rem;
    border: 1px solid transparent;
    border-top-width: 0.2rem;
    color: $main-color;
    text-align: center;
    @include fts16;
    font-weight: 300;
    background: $wizard-step-bg;

    &.tab-active {
      background: $main-bg-color;
      border-color: $status-active $tabs-border transparent $border-color;
      border-style: solid;
      border-width: 0.2rem 0 0;
      box-shadow: $tabs-shadow;
      color: $status-active;

      > span {
        background: $wizard-gradient-active;
        border-color: $border-color;
        border-image: none;
        border-style: solid;
        border-width: 0 1px;
        @include tabs-span;
      }
    }

    > span {
      @include tabs-span;

      > span {
        display: table-cell;
        height: 100%;
        vertical-align: middle;
        width: 100%;
      }
    }

    &:hover {
      color: $status-active;
    }
  }
}

@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-grid.bpm-tileview {

  .bpm-grid-row {

    &.bpm-gallery-item {
      width: 23rem;
      height: 25rem;
      padding: 1rem;
    }

    &.bpm-document-item {
      width: 23rem;
      height: 25rem;
      padding: 1rem;
      text-align: center;
      border-radius: 0.7rem;

      i {
        font-size: 9rem;
        margin: 1rem 0;

        &.file {
          font-size: 7rem;
          margin: 2rem 0;
        }
      }

      span {
        display: block;
        text-align: center;
      }
    }

    &.bpm-estistore-item {
      width: 29.2rem;
      height: 26rem;
      padding: 0;
      margin: 1rem;
      text-align: center;
      color: $gray-color;

      .promo-image {
        width: 100%;
        height: 20rem;
        text-align: center;

        span {
          opacity: 0;
          display: block;
          width: 100%;
          text-align: center;
          padding: 1.5rem 0.5rem;
          background: $white-color;
          position: absolute;
          top: 7.5rem;
          left: 0;
          @include fts16;
        }
      }

      &:hover .promo-image {
        @include multimedia-hover(60, 0.6);

        span {
          @include multimedia-hover(100, 1);
        }
      }

      .promo-info {
        width: 100%;
        text-align: center;
        padding: 0.8rem;
        border-top: 1px solid $border-color;
      }

      .promo-header {
        @include fts16;
        color: $gray-color;
        padding-top: 1rem;
        text-align: center;
      }

      .promo-name {
        width: 100%;
        float: left;
        text-align: left;

        span {
          display: block;
          text-align: left;
          width: 100%;

          &.promo-title {
            @include fts16;
            color: $promo-title-color;
          }

          &.promo-description {
            @include fts13;
            color: $autocomplete-color;
          }
        }
      }

      .promo-name-short {
        width: 20rem;
      }

      .promo-price {
        width: 7rem;
        float: right;

        span {
          display: block;
          text-align: right;
          width: 100%;

          &.promo-firstprice {
            @include fts16;
          }

          &.promo-firstprice-desc {
            @include fts14;
          }

          &.promo-secondprice {
            @include fts13;
            text-decoration: line-through;
          }

        }
      }
    }

    &.bpm-estithemes-item {
      width: 20rem;
      height: 18rem;
      padding: 0;
      margin: 1rem;
      text-align: center;
      color: $gray-color;

      .promo-image {
        width: 100%;
        height: 14rem;
        text-align: center;

        span {
          opacity: 0;
          display: block;
          width: 15rem;
          text-align: center;
          padding: 1.5rem 0.5rem;
          background: $white-color;
          position: absolute;
          top: 4.5rem;
          left: 2.5rem;
          @include fts16;
        }
      }

      &:hover .promo-image {
        @include multimedia-hover(90, 0.9);


        span {
          @include multimedia-hover(90, 0.9);
        }
      }

      .promo-info {
        width: 100%;
        text-align: center;
        padding: 0.9rem 0.2rem;
      }

      .promo-header {
        @include fts15;
        color: $main-color;
        text-align: center;
      }
    }
  }

  &.simplify-order .bpm-grid-row.bpm-gallery-item {
    width: 14.5rem !important;
    height: 14.5rem !important;
    margin: 0.5rem !important;
  }

  &.bpm-document .sortable-placeholder {
    width: 23rem;
    height: 25rem;
  }

  &.bpm-estistore .sortable-placeholder {
    width: 29rem;
    height: 30rem;
  }

  .btn-group {
    position: absolute;
    bottom: 0;
    width: 7.5rem;
    left: 0;

    a {
      width: 2.2rem;
      display: block;
      padding: 0.2rem 0.4rem 0.2rem;
      text-align: center;
      vertical-align: middle;
      margin-right: 0.3rem;
      @include fts12;
    }

    &.btn-actions-icon {
      position: relative;
      bottom: 0;
      width: 2.2rem;
      left: 0;
    }
  }
}

.wizard-multimedia {

  .bpm-grid.bpm-tileview .sortable-placeholder {
    width: 23rem;
    height: 25rem;
  }

  .bpm-grid.bpm-tileview.simplify-order .sortable-placeholder {
    width: 14.5rem !important;
    height: 14.5rem !important;
    margin: 0.5rem !important;
  }

  .gallery-info {
    position: absolute;
    top: 0;
    right: 1rem;
    display: none;
    @include fts16;
    @include themify($themes) {
      color: themed('alertColor');
    }
  }

}

#simplify-order-off {
  display: none;
}

.bpm-grid-cell {

  &.checks-cell {
    float: right;
    width: 7.5rem;
    height: 12.5rem;
    position: relative;

    .bpm-checkbox {
      @include fts12;
    }
  }

  &.photo-cell {
    float: left;
    width: 12.5rem;

    a {
      background-position: center center;
      background-size: cover;
      display: inline-block;
      height: 12.5rem;
      width: 12.5rem;
      cursor: move;
      border-radius: 0.5rem;
    }

    img {
      width: 100%;
      max-width: 100%;
      visibility: hidden;
      opacity: 0;
      position: absolute;
    }
  }

  .photo-dimension {
    @include fts11;
    text-align: left;
    padding-left: 1rem;
    margin-top: 0.5rem;
  }

  &.name-cell {
    margin-top: 0.8rem;
    width: 100%;

    .select-wraper {
      margin-bottom: 0.5rem;
    }
  }
}

.sortable-placeholder {
  background: $table-row-bg;
  border: 1px dashed $nav-active-color;
}

.bpm-upload {
  height: 30rem;
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.side-bar-search {
  position: fixed;
  padding-top: 0;
  height: 100%;
  z-index: 5000001;
  visibility: hidden;
  right: 0;
  background: $white-color;
  box-shadow: $dialog-shadow;
  width: 30rem;

  @include themify($themes) {
    font-family: themed("mainFont"), Helvetica, Arial, sans-serif;
  }
}

.side-bar-search-content {
  height: inherit;
  padding: 1rem 0 1rem;
  position: relative;

  .bpm-box {
    height: 15rem;
    background: $white-color;
    border: 1px solid $border-color;
    padding: 0.1rem;
  }

  .bpm-box-header {
    height: 3rem !important;
    overflow: hidden;
    position: relative;
    padding: 0 0.5rem;
  }

  h3 {
    @include fts16;
    margin: 0.6rem 0;
  }

  .control-label label {
    text-transform: uppercase;
    @include fts13;
  }

  .form-group {
    margin-bottom: 1rem;
  }
}

.side-bar-search-header {
  padding: 0 2rem 1rem;
}

.side-bar-search-footer {
  padding: 2rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 14000;
  background: $white-color;
  opacity: 0.9;

  button {
    width: 100%;
  }
}

.side-bar-search-buttons {
  position: absolute;
  top: 2rem;
  font-size: 2rem;
  right: 2rem;

  a {
    color: $investment-heading-color;
  }
}

.side-bar-search-form {
  padding-top: 0;
  padding-bottom: 6rem;
}

.side-bar-search-content .bpm-collapse-content,
.side-bar-search-section {
  padding: 1rem 2rem;
}

a.side-bar-search-section-trigger {
  display: block;
  padding: 1.5rem 2rem;
  color: $searchbar-trigger-color;
  background: $searchbar-trigger-bg;
  border-bottom: 1px solid $searchbar-trigger-border;
  position: relative;

  &.active {
    border-bottom: 1px solid $white-color;
    background: $white-color;
  }
}

.side-bar-search-section-trigger i {
  color: $searchbar-trigger-color;
  line-height: inherit;
  font-size: 3rem;
  font-weight: bold;
  position: absolute;
  top: 0.5rem;
  right: 3rem;

  &.fa-angle-down {
    display: inline;
  }

  &.fa-angle-up {
    display: none;
  }

  &.active i.fa-angle-down {
    display: none;
  }

  &.active i.fa-angle-up {
    display: inline;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .search-bar-top {
    left: 20.5rem;
  }
}

@media (max-width: 780px) {
  .side-bar-search .input-margin-bottom {
    margin-bottom: 2rem;
  }

  .side-bar-search {
    width: 100%;

    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

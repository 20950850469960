@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

@media (max-width: 780px) {

  .smaller-input-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    &.bpm-house-show {
      display: flex !important;
    }

    &.flex-start-container {
      justify-content: flex-start;
    }

    .control-label {
      width: 100%;
      padding-bottom: 0;

      &.small-width-input {
        width: 15%;
      }

      &.smallest-width-input {
        width: 4%;
        padding: 0.9rem 0;
      }
    }
  }

  .half-width-input {
    width: 46%;
    padding: 0;
  }

  .legal-status-btn {
    margin-top: 1.9rem;
  }

  .contiguity-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .col-sm-5 {
      flex-grow: 3;

      &.wrap-text {
        flex-wrap: wrap;
        width: 50%;
      }
    }

    span {
      align-self: center;
    }

    .small-width-input {
      width: 22%;
    }
  }

  .large-width-input {
    width: 100%;
    padding: 0;
    margin-bottom: 2rem;

    #building_roofmaterial-autocomplete {
      width: 100%;
      padding: 0;
    }
  }

  .medium-width-input {
    width: 75%;
    padding-right: 0;
    padding-left: 0;
  }

  .small-width-input {
    width: 15%;
    padding-left: 0;
    padding-right: 0;
  }

  .mid-width-input {
    width: 38%;
    padding-left: 1.6rem;
  }

  .apartment-heats-checkbox label.bpm-checkbox {
    display: block;
    margin-bottom: 2.2rem;
    @include fts14;
    font-weight: normal;
  }

  .apartment-heats-title {
    display: block;
    margin-bottom: 1rem;
    padding: 0;
  }

  .input-group-addon:not(:first-child):not(:last-child),
  .input-group-btn:not(:first-child):not(:last-child),
  .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0.4rem;
  }

  .wizard-saleAgreement span.control-label label {
    margin-bottom: 0.9rem;
  }

  .legal-status-container {
    margin: 0;
    width: 100%;

    .control-label {
      padding-left: 0;
      padding-bottom: 0.9rem;
    }

    .col-sm-7 {
      padding: 0;
    }

    .col-sm-5,
    .col-sm-2 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .cost-container .form-group {
    padding: 0;
  }

  .estate-props-container {

    .helptip-icon.helptip-message.helptip-absolute {
      right: -0.5rem;
      top: -0.2rem;
    }

    .col-sm-3 {
      padding-right: 0;
    }

    .mce-toolbar {
      display: none;
    }
  }

  .estate-props-container .control-label.currency-span,
  .singlestep .control-label.currency-span {
    display: block;
    width: 100%;
    text-align: right;
    padding-right: 0.2rem;
    padding-bottom: 0;
  }

  .cost-container .form-control {
    padding-bottom: 0;
  }

  .description-container .row.form-group {
    margin-bottom: 0;
  }

  .none-margin-bottom {
    margin-bottom: 0;
  }

  .wizard-step-body .control-label.none-padding,
  span.none-padding,
  .form-group.none-padding,
  .estate-props-content .row.none-padding .apartment-heats-checkbox,
  .singlestep .none-padding {
    padding: 0;
  }

  .singlestep .checkbox-margin label {
    margin-bottom: 2rem;
  }

  .form-group.none-padding .row .apartment-heats-checkbox {
    padding-left: 0;
  }

  .form-group.none-padding .row .none-padding {
    padding: 0;
  }

  .wizard-add .bpm-validation label.error {
    left: 0;
  }

  .row.none-margin {
    margin: 0;
  }

  #price_currency-autocomplete .bpm-dropdown-menu,
  #cooperation_provision_type-autocomplete .bpm-dropdown-menu,
  #office_provision_type-autocomplete .bpm-dropdown-menu {
    min-width: auto !important;
  }

  .provision-container .col-sm-6 {
    padding: 0;
  }

  .description-container .bpm-tabs-panel {
    padding: 2rem 0;
  }

  .description-container .bpm-tabs-menu {
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    border: none;
  }

  .description-container .bpm-tabs-menu > a {
    border: 1px solid $border-color;
    background-color: $white-color;
  }

  .description-container .bpm-tabs-menu > a.active {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border: 1px solid $wizard-step-color;
    color: $white-color;
    @include themify($themes) {
      background-color: themed('wizardActiveColor');
      border-color: themed('wizardActiveColor');
    }
  }

  .description-container .tinymce-copy-content {
    border: 1px solid $wizard-step-color;
    @include themify($themes) {
      background-color: themed('wizardActiveColor');
      border-color: themed('wizardActiveColor');
    }
  }

  .bpm-grid-row.bpm-grid-head.hide-in-mobile {
    display: none;
  }

  .wizard-add .bpm-validation .contact-container label.error,
  .wizard-add .bpm-validation .localization-container label.error {
    left: 1rem;
  }

  .bpm-dropdown-menu.pull-right, .bpm-hoverdown-menu.pull-right {
    right: 0 !important;
    left: 0 !important;
    width: 100% !important;
  }
}

.mobile-detected {

  .estate-props-container {
    width: 100%;
    margin-bottom: 1.9rem;

    .row {
      margin-right: 0;
      margin-left: 0;
      width: 100%;
    }

    span.control-label {
      padding-left: 0;

      label {
        margin-bottom: 0.9rem;
      }
    }

    .form-group {
      padding-bottom: 0;
    }
  }

  .estate-props-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem;
    @include fts18;
    background-color: $white-color;
    border-top: 2px solid $wizard-step-color;

    @include themify($themes) {
      border-color: themed('wizardActiveColor');
    }

    i {
      font-size: 2.2rem;
    }
  }

  .estate-props-content {
    margin-top: 1.9rem;
  }

  .bpm-grid.bpm-tableview.bpm-room-items,
  .bpm-grid.bpm-tableview.bpm-contiguity-items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 1.9rem 0;

    .bpm-grid-cell {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      border: none;
      position: relative;

      .fl.ws-nw {
        display: block;
        width: 100%;
      }

      .form-control {
        display: block;
        width: 100%;
      }
    }

    .bpm-grid-row {
      width: 100%;
      margin-bottom: 1.9rem;
      box-shadow: 0 0.2rem 2rem $bg-hover-opacity;
      background-color: $white-color;
      border-radius: 0.8rem !important;
    }
  }

  .bpm-grid.bpm-tableview.bpm-contiguity-items {

    .bpm-grid-row {
      width: 100%;
    }
  }

  .bpm-grid.bpm-tableview .bpm-grid-row:hover .bpm-grid-cell,
  .bpm-grid.bpm-tableview .bpm-grid-row:hover .bpm-grid-cell.bpm-sorted,
  .bpm-grid.bpm-tableview .bpm-grid-row .bpm-grid-cell.hover-column,
  .bpm-grid.bpm-tableview .bpm-grid-row .bpm-grid-cell.hover-column.bpm-sorted {
    background-color: transparent;
  }

  .bpm-grid.bpm-tableview.bpm-room-items .btn,
  .bpm-grid.bpm-tableview.bpm-contiguity-items .btn,
  #btn-room-grid-next-row,
  #btn-contiguity-grid-next-row {
    display: block;
    width: 100%;
  }

  .wizard-scrollbar {
    margin-bottom: 0;
  }
}

.wizard-add .mobile-detected {

  .wizard-nav {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-top: none;
    margin-bottom: 1.9rem;
  }

  .wizard-nav-step {
    height: auto;
    @include fts14;

    &.step-next,
    &.step-prev {
      background-color: transparent;
      @include themify($themes) {
        color: themed('wizardActiveColor');
      }
    }

    &.step-active {
      background-color: transparent;
      border-color: $white-color;
      font-weight: 600;
      @include fts14;
      @include themify($themes) {
        color: themed('wizardActiveColor');
      }
    }

    .fa {
      font-size: 0.8rem;
      top: 0.8rem;
      right: 0.3rem;
      @include themify($themes) {
        color: themed('wizardActiveColor');
      }
    }

    &.step-prev .fa {
      @include themify($themes) {
        color: themed('wizardActiveColor');
      }
    }

    &.step-active .fa {
      @include themify($themes) {
        color: themed('wizardActiveColor');
      }
    }

    > span > span {
      padding: 0.3rem 1.4rem 0.3rem 0;
    }
  }

  .input-lg {
    padding: 0 1rem;
  }
}

.theme-prime,
.theme-primepro,
.theme-gethome {

  .compass {
    width: 2rem;
    height: 2rem;
    border: 1px solid $prime-medium-icon-color;
  }

  .compass-west {
    border-left-width: 5px;
  }

  .compass-east {
    border-right-width: 5px;
  }

  .compass-south {
    border-bottom-width: 5px;
  }

  .compass-north {
    border-top-width: 5px;
  }

  .offer-price-value,
  .offer-price-permeter-value {
    position: relative;

    i {
      display: none;
    }
  }

  .bpm-grid-cell:hover .offer-price-value:not(.edit-temporary-hidden) i.offer-price-value-trigger,
  .bpm-grid-cell:hover .offer-price-permeter-value:not(.edit-temporary-hidden) i.offer-price-permeter-value-trigger {
    display: block;
    @include offer-price-value(2);
  }

  .bpm-grid-cell:hover .offer-price-value.edit-shifted i.offer-price-value-trigger,
  .bpm-grid-cell:hover .offer-price-permeter-value.edit-shifted i.offer-price-permeter-value-trigger {
    left: -4rem;
  }

  .offer-price-value i.offer-price-value-success,
  .offer-price-permeter-value i.offer-price-permeter-value-success {
    @include offer-price-value(1);
    color: $success-prime-color;
  }

  .offer-price-value i.offer-price-value-refresh,
  .offer-price-permeter-value i.offer-price-permeter-value-refresh {
    @include offer-price-value(1);
    color: $investment-span-color;
  }

  .offer-price-input,
  .offer-price-permeter-input {
    display: none;
  }

  .offer-price-input input,
  .offer-price-permeter-input input {
    width: 10rem;
    color: $investment-icon-color;
    margin-left: -1.1rem;
    @include fts15;
  }

  .offer-price-value-global-trigger,
  .offer-price-permeter-value-global-trigger {
    color: $investment-span-color;
    cursor: pointer;
    margin-left: 0.5rem;
  }
}

.mobile-content {

  #quick-add-form {

    h2 {
      font-size: 2rem;
      font-weight: 500;
      color: $gethome-dark-textcolor;
    }

    h3 {
      color: $turquise-dark;
      font-weight: bold;
      @include fts15;
    }

    h4 {
      font-size: 1.9rem;
      font-weight: 500;
      color: $gethome-dark-textcolor;
    }

    .control-label {
      color: $gethome-dark-textcolor;
    }

    span.control-label {
      padding-left: 0;
    }

    .form-edit-controls {
      .btn.btn-success {
        width: 100%;
        background-color: $primary-color;
        border-color: $primary-color;
      }
    }

    .quickadd-mobile-price {
      display: flex;
      width: 100%;
      padding-bottom: 1.5rem;

      .col-sm-3 {
        width: 45%;
        padding: 0;
      }

      .col-sm-5 {
        padding-left: 0;
        width: 55%;
      }

      .col-sm-1 {
        width: 10%;
        padding-right: 0;
      }

    }
  }

  .quick-add-mobile-container {
    border-bottom: 1px solid $turquise-dark;
    border-radius: 4px;

    .col-sm-2 {
      padding: 0;

      &:first-child .quick-add-trigger.btn-primary {
        border-top-left-radius: 4px !important;
        border-top-right-radius: 4px !important;
      }

      &:last-child .quick-add-trigger.btn-primary {
        border-bottom-left-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
      }
    }
  }

  .quick-add-trigger.btn-primary {
    background-color: $white-color;
    color: $turquise-dark;
    border-color: $turquise-dark;
    margin-bottom: 0;
    border-radius: 0 !important;
    font-weight: 400;
    border-bottom: none;
    @include fts14;

    &.quick-add-trigger-active {
      background-color: $turquise-dark;
      color: $white-color;
      border-color: $turquise-dark;
      font-weight: 400;

      i {
        font-size: 1.6rem;
      }
    }
  }

}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

.click_counter {
  cursor: pointer;
}

.popup-bottom {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 12px;
  width: calc(100% - 24px);
  z-index: 1000000;
  padding: 24px 48px;
  background-color: #9bd7ff;
  margin: 16px 12px;
  border-radius: 8px;
  box-shadow: 0 16px 40px 0 rgba(174, 177, 180, 0.2);
  text-align: center;
  overflow: hidden;

  .popup-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1342px;
    gap: 48px;

    img.popup-logo {
      height: fit-content;
      max-width: 275px;
      max-height: 100px;
    }

    .popup-text {
      min-width: 675px;
      text-align: left;

      h4 {
        font-family: "Playfair Display", serif;
        font-size: 20px;
        line-height: 24px;
        margin: 0;
        color: #23232d;

        a {
          color: #23232d;
          text-decoration: underline;
        }
      }

      p {
        color: #4f4f57;
        font-size: 14px;
        line-height: 20px;
        margin-top: 12px;
        margin-bottom: 0;
      }
    }

    .popup-button {
      width: 200px;
      padding: 16px;
      border: none;
      border-radius: 180px;
      background: #ebff00;
      color: #23232d;
      text-transform: uppercase;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.28px;
      text-align: center;
      cursor: pointer;

      &:hover {
        font-size: 15.75px;
        box-shadow: 0 4px 10px 0 rgba(174, 177, 180, 0.3);
      }
    }
  }

  .popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.info-module {
  .info-module-card {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 30rem;

    .card-img {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    .card-description {
      flex-grow: 1;
    }

    .card-description,
    .card-title {
      word-wrap: break-word;
    }
  }
}

@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-dropdown-menu,
.bpm-hoverdown-menu {
  visibility: hidden;
  display: none;
  background-color: $white-color;
  border: 0;
  box-shadow: $dropdown-shadow;
  float: left;
  min-width: 20rem;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 15000;
}

.dropdown-menu {
  min-width: 6rem;

  .bpm-adherence-dropdown {
    width: 20rem;
  }

  &.bpm-adherence-list-dropdown {
    width: 40rem;
  }
}

.bpm-adherence-dropdown-button {
  min-width: 16rem;
}

.bpm-dropdown-menu.pull-right,
.bpm-hoverdown-menu.pull-right {
  right: 0;
  left: auto;
}

.bpm-dropdown-content,
.bpm-hoverdown-content {
  margin-bottom: 1rem;
  max-height: 33rem;
  padding: 1rem 1rem 0;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &.overflow-show {
    overflow-y: visible;
  }
  &.full-height {
    max-height: 80vh;
    overflow-y: scroll;
  }
  &.overflow-y-visible {
    overflow-y: visible;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  .row::before {
    display: none;
  }

  .btn.bpm-submit {
    @include fts12;
  }
}

.bpm-dropdown-menu-foot {
  background: none repeat scroll 0 0 $dropdown-foot-bg;
  border-top: 1px solid $dropdown-foot-border;
  padding: 0.5rem 0.8rem;
}

.bpm-dropdown-clear {
  float: right;
  margin-top: 0.2rem;
}

.bpm-hoverdown-content {
  @include fts12;
}

.nav-top .bpm-hoverdown-content,
.nav-top .bpm-dropdown-content {
  width: 36rem;
  @include reset;
}

.bpm-dropdown-menu-desktop {
  width: 30rem !important;
}

.add-button-nav .bpm-dropdown-content {
  height: 100%;
  max-height: 100%;
}

.nav-top .nav-dropdown {
  width: 20rem;
}

.nav-top .nav-dropdown.bpm-hoverdown-menu,
.nav-top .nav-dropdown.bpm-dropdown-menu {
  width: 36rem;
}

.hoverdown-content input.form-control {
  min-width: 5.5rem;
}

@media (max-width: 780px) {
  #emails-dropdown-group {
    display: none;
  }

  .pull-right {
    float: right !important;
  }

  .bpm-dropdown-menu {
    width: 90% !important;
    left: 5% !important;
    right: 5% !important;
    min-width: 30rem !important;
  }

  .theme-prime,
  .theme-primepro,
  .theme-gethome {
    .bpm-dropdown-menu.pull-right,
    .bpm-hoverdown-menu.pull-right {
      right: 0 !important;
      left: auto !important;
    }
  }
}
.dp-pull-right + div {
  right: 0 !important;
  left: auto !important;
}

.theme-prime, .theme-primepro {
  .mobile-content {
    .bpm-page-estate-details {
      .bpm-autocomplete {
        position: relative;
      }

      .bpm-dropdown-menu {
        left: 0 !important;
        width: 100% !important;
      }
    }

    .form-transaction-client .col-xs-4 .bpm-dropdown-menu {
      min-width: unset !important;
    }
  }
}

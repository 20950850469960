@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-timepicker + .bpm-dropdown-menu {
  min-width: 7.5rem;
  max-width: 9.5rem;
  width: 100%;
}

.bpm-timepicker-times span {
  display: block;
  cursor: pointer;
  padding: 0.1rem 0.3rem;

  &:hover {
    @include themify($themes) {
      background-color: themed('primaryColor') !important;
    }
    color: $white-color;
  }
}

.bpm-datepicker {
  background: $white-color url(../gfx/datepicker.png) no-repeat calc(100% - 0.7rem) center;
}

.bpm-timepicker {
  background: $white-color url(../gfx/timepicker.png) no-repeat calc(100% - 0.7rem) center;
}

.bpm-calendar-event .contact-relation-presentation-info {
  @include fts11;
}

.theme-prime,
.theme-primepro {
  .bpm-datepicker {
    background: #fff url(../gfx/datepicker.png) no-repeat calc(1rem) center;
    padding-left: 3rem;
  }

  .bpm-timepicker {
    background: #fff url(../gfx/timepicker.png) no-repeat calc(1rem) center;
    padding-left: 3rem;
  }
}

.theme-gethome {
  .bpm-datepicker {
    background: $white-color url(../gfx/datepickerGH.png) no-repeat calc(100% - 0.7rem) center;
  }
}

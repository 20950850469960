@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.theme-primepro {

  #bpm-application-details-dialog-wrap {

    .bpm-dialog-header-title {
      font-size: 1.9rem;
      letter-spacing: 0.15px;
      font-weight: bold;
      color: $prime-dark-color;
    }

    .application-content-wrapper {
      display: flex;
      gap: 16px;

      &:first-child {
        align-items: center;
      }

      .application-type-details,
      .application-client-data {
        flex: 0.75;
      }

      .application-estate-details,
      .application-client-preferences {
        flex: 1;
      }

      .application-type-details {
        .blue {
          font-size: 15px;
        }

        p {
          margin: 0;
        }
      }

      .application-estate-details {
        display: flex;
        gap: 16px;
        align-items: center;

        .estate-tags {
          .tag {
            color: #6D396E;
            border: 1px solid #D87FDA;
            background-color: #E8B2E9;
            border-radius: 4px;
            padding: 0 8px;
            width: min-content;
          }
        }

        .image {
          width: 125px;
          height: 78px;
          background-color: $prime-border-box;
          border-radius: 4px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .blue {
          font-size: 15px;
        }
      }

    }

    h4 {
      color: $prime-dark-color;
      margin: 16px 0 8px 0;
      font-size: 13px;
      font-weight: bold;
    }

    .application-data {
      display: flex;
      gap: 8px;
      align-items: center;
      padding: 9.5px 16px 9.5px 8px;
      border: 1px solid $prime-border-box;
      border-radius: 4px;
      color: $prime-dark-color;
      margin-bottom: 8px;

      i {
        margin: 0;
        color: $prime-medium-icon-color;
      }
    }

    .application-actions {
      .decision-text {
        font-size: 13px;
        font-weight: bold;
        padding: 8px;
        color: $prime-primary-color;
        background-color: $prime-hover-box;
        border-radius: 4px;
        margin: 8px 0 16px 0;

      }

      .decision-buttons {
        display: flex;
        gap: 8px;
        align-items: center;
        .btn {
          flex: 1;
          font-size: 15px;
        }
        .btn.dlg-cancel-btn {
          border: 1px solid $prime-primary-color;
        }
      }
    }

    .application-info-text {
      padding: 8px;
      margin-bottom: 16px;
      font-size: 13px;
      font-weight: bold;
      text-align: center;
      color: #7BCF5D;
      border-radius: 4px;
      background: rgba(143, 216, 117, 0.20);
    }

    .application-client-show-wrapper {
      display: flex;
      gap: 8px;

      .application-client-data-show {
        flex: 1;
      }

      .application-client-show-actions {
        flex: 0.75;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .btn {
          font-size: 15px;
          min-height: 43.45px;
        }

        .btn.outline-btn {
          color: $prime-primary-color;
          border: 1px solid $prime-primary-color;
          background-color: $white-color;
        }

      }

    }

    .application-estate-details {
      .estate-number {
        color: $prime-dark2-color;
        margin-bottom: 6px;
      }
      .estate-tags {
        display: flex;
        gap: 8px;
        justify-content: flex-start;

        .tag {
          color: $prime-medium-icon-color;
          border: 1px solid $prime-medium-icon-color;
          border-radius: 4px;
          padding: 0 8px;
        }
      }
    }
  }
}

@media (max-width: 780px)  {
  .theme-primepro #bpm-application-details-dialog-wrap {
    .application-content-wrapper {
      flex-direction: column;
      gap: 8px;

      &:first-child {
        align-items: flex-start;
      }
    }

    .application-actions .decision-buttons {
      flex-direction: column;
    }

    h4 {
      margin: 8px 0;
    }

    .application-client-show-wrapper {
      flex-direction: column;
    }
  }
}
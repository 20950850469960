@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.theme-prime,
.theme-primepro,
.theme-gethome {
  .formpage-content {
    display: flex;
  }

  .form-prime-investment-data {
    width: 100%;
    background-color: $white-color;
    border: 1px solid $prime-border-color;

    .form-prime-container {
      border: none;
    }
  }

  .form-prime-container {
    width: 55%;
    background-color: $white-color;
    margin-top: 2rem;
    border: 1px solid $prime-border-color;
    padding: 4rem 6rem;

    h2 img {
      max-height: 3.5rem;
    }

    &.basic-prime {
      width: 100%;

      &.reference-container {
        display: inline-block;
      }
    }
  }

  .double-label-container {
    display: flex;
    justify-content: space-between;
  }

  .order-www {
    @include fts10;
    margin: 0;
    align-self: end;
    padding-bottom: 0.5rem;

    a {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .form-prime-tipscontainer {
    width: 30%;
    max-width: 30rem;

    .btn.btn-danger {
      border: 1px solid $investment-span-color;
    }
  }

  div.form-prime-container h2:first-child {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 0;
    font-size: 2.8rem;
  }

  .prime-grey-info {
    display: flex;
    @include fts13;
    color: $prime-gray-color;
    margin: 3rem 0;

    i {
      margin-right: 1rem;
      padding-top: 0.3rem;
    }
  }

  .form-group-prime {
    padding-bottom: 2rem;
    color: $investment-icon-color;
    position: relative;

    label.error {
      top: 6.7rem !important;
    }

    label.control-label {
      font-weight: bold;
      font-size: 1rem;
      letter-spacing: 1.12px;
    }

    label.bpm-radio {
      font-weight: normal;
      letter-spacing: normal;
    }

    label[disabled="disabled"] {
      opacity: .5;
    }

    input[disabled="disabled"] {
      cursor: auto;
    }

    &.short-inputs {
      display: flex;

      .form-input-long {
        width: 80%;
        padding-right: 2rem;
      }

      .form-input-short {
        width: 20%;
      }

      .form-input-mid:last-child {
        padding-right: 0;
      }
    }

    .bpm-checkbox.bpm-radio {
      @include fts13;
      text-transform: none;
      color: $prime-radiobtn-color;
      padding-right: 4rem;

      span::before {
        border: 1px solid $investment-user-icon;
      }
    }
  }

  .bpm-create-investment-fields .form-group-prime label.error {
    top: 3.8rem !important;
  }

  .bpm-create-investment-fields .form-group-prime.investment-name-field label.error {
    top: 6.7rem !important;
  }


  .short-inputs {

    .form-input-mid,
    .form-input-joined {
      width: 50%;
      padding-right: 2rem;
    }

    .form-input-triple {
      width: 33.33%;
      padding-right: 2rem;
    }

    .form-input-triple.small-triple {
      width: 15%;
    }

    .form-input-triple.mid-triple {
      width: 70%;
    }

    .form-input-triple.large-triple {
      width: 85%;
    }

    .form-input-triple.long-triple {
      width: 75%;
    }

    .form-input-triple.short-triple {
      width: 25%;
    }

    .form-input-triple:last-child {
      padding-right: 0;
    }

  }

  .joined-inputs-container {
    display: flex;

    .form-control.joined-right {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right: none;
    }

    .form-control.joined-left {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  .form-input-joined input {
    width: 50%;
  }

  #more-export-options-btn,
  #less-export-options-btn {
    padding-left: 0;
    @include fts13;
  }

  #more-export-options-btn i,
  #less-export-options-btn i {
    padding-right: 1.5rem;
  }

  .btn-group-portalPrime {
    margin-top: 7.6rem;
  }

  .more-export-options a#more-export-options-btn,
  .more-export-options a#less-export-options-btn {
    margin-bottom: 2.5rem;
  }

  .form-edit-controls .btn.btn-success {
    margin-right: 5rem;
  }
}

@media (max-width: 768px) {

  .theme-prime,
  .theme-primepro,
  .theme-gethome {

    .form-prime-container {
      width: 100%;
      padding: 2rem;
      box-sizing: border-box;
      margin-top: 0;
    }

    .form-prime-tipscontainer {
      width: 100%;
      max-width: none;
    }
  }
}

@media (min-width: 1600px) {

  .theme-prime,
  .theme-primepro,
  .theme-gethome {
    .form-prime-container {
      max-width: 87rem;
    }
  }
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-notifications {
  @include fts13;
  position: fixed;
  left: 50%;
  margin-left: -17.5rem;
  top: 0;
  width: 35rem;
  padding: 0;
  cursor: pointer;
  z-index: 99999999;
}

.bpm-notification {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0.4rem 0.5rem 0.2rem;
}

.bpm-notification-title {
  display: none;
  background: none repeat scroll 0 0 $notifications-bg;
  padding: 1rem;
}

.bpm-notification-content {
  margin: 0 0 1rem 0;
  padding: 1rem;
}

@media (max-width: 780px) {
  .bpm-notifications {
    margin-left: 0;
    left: 5%;
    width: 90%;
    right: 5%;
    top: 10rem;
  }
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-grid.bpm-tableview.bpm-minitable {
  border: 0px solid $border-color;

  .bpm-grid-row {
    border-bottom: 1px solid $border-color;

    &.bpm-grid-head {
      border-bottom-width: 0.3rem;
      font-weight: 400;
    }
  }

  .bpm-grid-cell {
    border: none;
  }
}

.theme-prime,
.theme-primepro,
.theme-gethome {

  .bpm-grid.bpm-tableview.bpm-minitable .bpm-grid-row.bpm-grid-head {
    border-bottom: 1px solid #EEF2FF;
    font-weight: 400;
  }

  .bpm-grid.bpm-tableview.bpm-minitable .bpm-grid-row {
    border-bottom: 1px solid #EEF2FF;
  }

  .bpm-grid.bpm-tableview.bpm-minitable .bpm-grid-cell {
    padding: 1.2rem 1rem;
    color: #61798F;
    @include fts13;
  }

  .bpm-grid.bpm-tableview.bpm-minitable .bpm-grid-head .bpm-grid-cell {
    color: #BFC5D2;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1.12px;
  }

  .bpm-grid.bpm-tableview.bpm-minitable .bpm-grid-cell:first-child {
    padding-left: 30px;
  }
}

@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-dropdown-status-list {
  min-width: 20rem;
}

.bpm-status:hover,
.bpm-status:focus {
  color: $white-color;
  cursor: pointer;
}

.bpm-status {
  padding: 0.1rem 0.7rem 0.1rem;
}

.bpm-grid-status .bpm-status {
  padding: 0.4rem 1.5rem;
  height: auto;
  @include fts11;
}

.btn.bpm-status {
  height: auto;
}

.bpm-status-paid {
  color: rgba(11, 130, 37, 1);
  background-color: rgba(192, 248, 204, 1);
}

.bpm-status-ordered, .bpm-status-payment_initiated {
  color: rgba(73, 33, 8, 1);
  background-color: rgba(252, 215, 192, 1);
}

.bpm-status-canceled, .bpm-status-rejected {
  color: rgba(50, 56, 62, 1);
  background-color: rgba(240, 244, 248, 1);
}

.bpm-status0, .bpm-button-status0:before {
  color: $white-color;
  @include status($disabled-color);
  @include themify($themes) {
    background-color: $disabled-color;
    color: $white-color;
  }
}

.bpm-status1, .bpm-button-status1:before {
  background-color: $white-color;
  @include themify($themes) {
    color: themed('status1color');
    border-color: themed('status1color');
    background-color: $white-color;
  }
}

.bpm-status1:hover {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    border-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status52, .bpm-button-status52:before {
  color: $white-color;
  @include themify($themes) {
    background-color: $gray-color;
    color: $white-color;
  }
}

.bpm-status81, .bpm-button-status81:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status81color');
    color: $white-color;
  }
}

.bpm-status99, .bpm-button-status99:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status99color');
    color: $white-color;
  }
}

.bpm-status2, .bpm-button-status2:before {
  color: $white-color;
  @include status($status-secondary-color);
  @include themify($themes) {
    background-color: $status-secondary-color;
    color: $white-color;
  }
}

.bpm-status3, .bpm-button-status3:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status3color');
    color: $white-color;
  }
}

.bpm-status4, .bpm-button-status4:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status81color');
    color: $white-color;
  }
}

.bpm-status5, .bpm-button-status5:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status81color');
    color: $white-color;
  }
}

.bpm-status6, .bpm-button-status6:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status6color');
    color: themed('btnPrimaryColor');
  }
}

.bpm-status7, .bpm-button-status7:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status6color');
    color: themed('btnPrimaryColor');
  }
}

.bpm-status8, .bpm-button-status8:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status9, .bpm-button-status9:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.text-status9 {
  color: $alert-color;
}

.bpm-status10 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status11 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status3color');
    color: $white-color;
  }
}

.bpm-status12 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status13 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status6color');
    color: themed('btnPrimaryColor');
  }
}

.bpm-status14 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status3color');
    color: $white-color;
  }
}

.bpm-status15, .bpm-button-status15:before {
  color: $white-color;
  @include status($disabled-color);
  @include themify($themes) {
    background-color: $disabled-color;
    color: $white-color;
  }
}

.bpm-status16, .bpm-button-status16:before {
  background-color: $white-color;
  @include themify($themes) {
    color: themed('status1color');
    border-color: themed('status1color');
    background-color: $white-color;
  }
}

.bpm-status16:hover {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    border-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status17, .bpm-button-status17:before {
  filter: brightness(120%);
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status3color');
    filter: brightness(120%);
    color: $white-color;
  }
}

.bpm-status18, .bpm-button-status18:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status3color');
    color: $white-color;
  }
}

.bpm-status19, .bpm-button-status19:before {
  filter: opacity(80%);
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status3color');
    filter: opacity(80%);
    color: $white-color;
  }
}

.bpm-status20, .bpm-button-status20:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status21, .bpm-button-status21:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status6color');
    color: themed('btnPrimaryColor');
  }
}

.bpm-status22, .bpm-button-status22:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status3color');
    color: $white-color;
  }
}

.bpm-status23 {
  color: $white-color;
  @include status($disabled-color);
  @include themify($themes) {
    background-color: $disabled-color;
    color: $white-color;
  }
}

.bpm-status24 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status25 {
  color: $white-color;
  @include status($disabled-color);
  @include themify($themes) {
    background-color: $disabled-color;
    color: $white-color;
  }
}

.bpm-status26 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status3color');
    color: $white-color;
  }
}

.bpm-status53 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status6color');
    color: themed('btnPrimaryColor');
  }
}

.bpm-status29 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status81color');
    color: $white-color;
  }
}

.bpm-status30 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status3color');
    color: $white-color;
  }
}

.bpm-status31 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status38, .bpm-button-status38:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status6color');
    color: themed('btnPrimaryColor');
  }
}

.bpm-status39, .bpm-button-status39:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status40 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status41 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status3color');
    color: $white-color;
  }
}

.bpm-status42 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status43, .bpm-button-status43:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status3color');
    color: $white-color;
  }
}

.bpm-status44, .bpm-button-status44:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status45, .bpm-button-status45:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status6color');
    color: themed('btnPrimaryColor');
  }
}

.bpm-status46, .bpm-button-status46:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status81color');
    color: $white-color;
  }
}

.bpm-status47, .bpm-button-status47:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status81color');
    color: $white-color;
  }
}

.bpm-status48, .bpm-button-status48:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status6color');
    color: themed('btnPrimaryColor');
  }
}

.bpm-status64 {
  color: $white-color;
  @include themify($themes) {
    background-color: $disabled-color;
    color: $white-color;
  }
}

.bpm-status65 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status6color');
    color: themed('btnPrimaryColor');
  }
}

.bpm-status66 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status3color');
    color: $white-color;
  }
}

.bpm-status71 {
  color: $white-color;
  @include status($disabled-color);
  @include themify($themes) {
    background-color: $disabled-color;
    color: $white-color;
  }
}

.bpm-status72 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status3color');
    color: $white-color;
  }
}

.bpm-status73 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status81color');
    color: $white-color;
  }
}

.bpm-status74 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status75 {
  color: $white-color;
  @include status($disabled-color);
  @include themify($themes) {
    background-color: $disabled-color;
    color: $white-color;
  }
}

.bpm-status76 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status77 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status77color');
    color: $white-color;
  }
}

.bpm-status78 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status3color');
    color: $white-color;
  }
}

.bpm-status79 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status81color');
    color: $white-color;
  }
}

.bpm-status168 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status81color');
    color: $white-color;
  }
}

.bpm-status80 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status6color');
    color: themed('btnPrimaryColor');
  }
}

.bpm-status119, .bpm-button-status119:before, .bpm-status125, .bpm-button-status125:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status81color');
    color: $white-color;
  }
}

.bpm-status120, .bpm-button-status120:before, .bpm-status121, .bpm-button-status121:before, .bpm-status122, .bpm-button-status122:before, .bpm-status123, .bpm-button-status123:before, .bpm-status124, .bpm-button-status124:before, .bpm-status126, .bpm-button-status126:before, .bpm-status127, .bpm-button-status127:before, .bpm-status128, .bpm-button-status128:before, .bpm-status129, .bpm-button-status129:before, .bpm-status130, .bpm-button-status130:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status6color');
    color: $white-color;
  }
}

.bpm-status82 {
  color: $white-color;
  @include status($disabled-color);
  @include themify($themes) {
    background-color: $disabled-color;
    color: $white-color;
  }
}

.bpm-status83 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status3color');
    color: $white-color;
  }
}

.bpm-status84 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status6color');
    color: themed('btnPrimaryColor');
  }
}

.bpm-status85 {
  color: $white-color;
  @include status($gray-color);
  @include themify($themes) {
    background-color: $gray-color;
    color: $white-color;
  }
}

.bpm-status86 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status87 {
  color: $white-color;
  @include status($disabled-color);
  @include themify($themes) {
    background-color: $disabled-color;
    color: $white-color;
  }
}

.bpm-status88 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status3color');
    color: $white-color;
  }
}

.bpm-status89 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status90 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status91 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status3color');
    color: $white-color;
  }
}

.bpm-status92 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status99:hover {
  color: $white-color !important;
  -webkit-filter: brightness(95%);
  filter: brightness(95%);
}

.bpm-status105 {
  color: $white-color;
  @include status($gray-color);
  @include themify($themes) {
    background-color: $gray-color;
    color: $white-color;
  }
}

.bpm-status106 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status3color');
    color: $white-color;
  }
}

.bpm-status107 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status6color');
    color: $white-color;
  }
}

.bpm-status108 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status109 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status110 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status6color');
    color: $white-color;
  }
}

.bpm-status112 {
  color: $white-color;
  @include status($gray-color);
  @include themify($themes) {
    color: $white-color;
  }
}

.bpm-status113 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status99color');
    color: $white-color;
  }
}

.bpm-status114 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status3color');
    color: $white-color;
  }
}

.bpm-status115 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status6color');
    color: $white-color;
  }
}

.bpm-status116 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status81color');
    color: $white-color;
  }
}

.bpm-status117 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status-temp {
  display: block;
  margin-top: 0.3rem;
  @include fts11;
  @include themify($themes) {
    color: themed('status1color');
  }
}

.bpm-status131 {
  color: $white-color;
  @include status($gray-color);
  @include themify($themes) {
    background-color: $gray-color;
    color: $white-color;
  }
}

.bpm-status132 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status132color');
    color: $white-color;
  }
}

.bpm-status133 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status133color');
    color: $white-color;
  }
}

.bpm-status134 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status3color');
    color: $white-color;
  }
}

.bpm-status135 {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status135color');
    color: $white-color;
  }
}

.bpm-status143 {
  color: $white-color;
  @include status($gray-color);
  @include themify($themes) {
    background-color: $gray-color;
    color: $white-color;
  }
}

.bpm-status144 {
  color: $white-color;
  @include status($primary-color);
  @include themify($themes) {
    background-color: $primary-color;
    color: $white-color;
  }
}

.bpm-status145 {
  color: $white-color;
  @include status($warning-color);
  @include themify($themes) {
    background-color: $warning-color;
    color: $white-color;
  }
}

.bpm-status146 {
  color: $white-color;
  @include status($status-secondary-color);
  @include themify($themes) {
    background-color: $status-secondary-color;
    color: $white-color;
  }
}

.bpm-status147 {
  color: $white-color;
  @include status($alert-color);
  @include themify($themes) {
    background-color: $alert-color;
    color: $white-color;
  }
}

.bpm-status148 {
  color: $white-color;
  @include status($alert-color);
  @include themify($themes) {
    background-color: $alert-color;
    color: $white-color;
  }
}

.bpm-status150, .bpm-button-status150:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status6color');
    color: themed('btnPrimaryColor');
  }
}

.bpm-status151, .bpm-button-status151:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status6color');
    color: themed('btnPrimaryColor');
  }
}

.bpm-status152, .bpm-button-status152:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status6color');
    color: themed('btnPrimaryColor');
  }
}

.bpm-status153, .bpm-button-status153:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.bpm-status154, .bpm-button-status154:before {
  color: $white-color;
  @include themify($themes) {
    background-color: themed('status1color');
    color: $white-color;
  }
}

.text-status153,
.text-status154 {
  color: $alert-color;
}

.text-status75 {
  color: $disabled-color;
  @include themify($themes) {
    color: $disabled-color;
  }
}

.text-status76 {
  @include themify($themes) {
    color: themed('status1color');
  }
}

.text-status77 {
  color: $status-secondary-color;
}

.text-status78 {
  @include themify($themes) {
    color: themed('status3color');
  }
}

.text-status79 {
  @include themify($themes) {
    color: themed('status81color');
  }
}

.text-status80 {
  @include themify($themes) {
    color: themed('status6color');
  }
}

.text-status3 {
  color: $status-active;
}

.text-status0 {
  color: $placeholder-color !important;
}

.text-status0.btn-link {
  color: $placeholder-color !important;
}

.border-success {
  border-color: $status-active !important;
}

.border-error {
  border-color: $alert-color !important;
}

.call-type210 {
  @include themify($themes) {
    color: themed('status3color') !important;
  }
}

.call-type211 {
  @include themify($themes) {
    color: themed('status6color') !important;
  }
}

.call-type219 {
  @include themify($themes) {
    color: themed('status1color') !important;
  }
}

.call-type221 {
  @include themify($themes) {
    color: themed('status81color') !important;
  }
}

.call-type220 {
  color: $gray-color !important;
}

.theme-prime,
.theme-primepro,
.theme-gethome {

  .bpm-status {
    padding: 0.3rem 1rem;
    border-radius: 5rem !important;
  }

  .bpm-grid-status .bpm-status {
    padding: 0.3rem 1rem;
    @include fts12;
    border-radius: 5rem !important;
  }

  .bpm-button-status:before {
    background: none;
  }

  .btn-mass-action .btn-group.btn-actions-button i.dropdown-list-icon {
    font-size: 3rem;
    width: 3rem;
    color: $prime-primary-color;
    cursor: pointer;
    text-align: center;
  }

  .bpm-page-investment-details .btn-mass-action .btn-group.btn-actions-button i.dropdown-list-icon {
    padding-left: 0;
  }
}

.theme-gethome {
  .bpm-status64 {
    background-color: #5A9DE6;
    border-color: #5A9DE6;
  }
}

@media (max-width: 768px) {

  .theme-prime,
  .theme-primepro,
  .theme-gethome {
    .btn-mass-action .btn-group.btn-actions-button i.dropdown-list-icon {
      top: -13.5rem;
    }
  }
}

.theme-prime {
  .bpm-status.bpm-status3,
  .bpm-button-status.bpm-status3 {
    color: #366227;
  }

  .bpm-status.bpm-status4,
  .bpm-button-status.bpm-status4 {
    color: #645121;
  }

  .bpm-status.bpm-status5,
  .bpm-button-status.bpm-status5 {
    color: #6D396E;
    background-color: #E8B2E9;
  }

  .bpm-status.bpm-status7,
  .bpm-button-status.bpm-status7 {
    color: #2B5C69;
  }
}

$main-color: #444;
$primary-color: #2b9fd9;
$primary-dark: #1f80b0;
$turquise-dark: #0071A4;
$primary-active-color: #18bc9b;
$light-color: #F5FCFF;
$white-color: #fff;
$black-color: #000;
$gray-color: #666666;

$prime-primary-color: #4A90E2;

$alert-color: #e54f49;
$alert-prime-color: #d45151;
$alert-secondary-color: #ff2c33;
$success-color: #54b854;
$success-prime-color: #33AC2E;
$status-active: #43a143;
$status-secondary-color: #65d365;
$status-additional-color: #30beb2;
$warning-color: #f2a73d;
$disabled-color: #aaa;

$placeholder-color: #777;
$item-bg-color: #eee;
$border-color: #ddd;
$border-menu-color: #262626;

$main-bg-color: #f9f9f9;
$menu-bg-color: #14191f;
$menu-link-color: #bbb;
$menu-text-color: #ccc;

$stars-color: #fde16d;
$rating-color-hover: #843534;

$promo-title-color: #222;
$nav-bg-hover: rgba(255, 255, 255, .1);
$nav-icon-color: #58bddb;
$menumobile-icon-color: #4CBFF8;
$main-icon-color: #aaaaaa;
$nav-active-color: #F2A200;

$bg-hover-opacity: rgba(0, 0, 0, 0.2);

$input-color: #333;
$selected-bg-color: #e3f5fc;

$dropdown-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
$prime-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
$dropdown-foot-bg: #F6F6F6;
$dropdown-foot-border: #e8e8e8;

$scrollbar-bg: #f4f4f4;
$scrollbar-y-rail-bg: #888;
$scrollbar-bg-opacity: rgba(0, 0, 0, 0.15);

$tabs-border: #dcdcdc;
$tabs-shadow: 5px -4px 6px -3px rgba(0, 0, 0, 0.04);
$prime-calendar-tabs: #a0afbc;

$dialog-shadow: 0 2px 15px 2px rgba(44, 44, 44, 0.2);
$kit-shadow: 0 1px 10px 1px rgba(50, 50, 50, 0.3);

$autocomplete-bg: #eaeaea;
$autocomplete-border: #eee;
$autocomplete-color: #999;


$fc-content-bg: #fbfbfb;
$fc-btn-active: #2187ba;
$fc-contact-bg: #58bddb;
$fc-acquisition-bg: #be62ff;
$fc-event-color: #e2f6ff;
$fc-state-active-bg: #d5dce3;

$notifications-bg: rgba(255, 255, 255, 0.4);

$gmap-shadow: 0.4rem 0.4rem 2rem -0.5rem rgba(0, 0, 0, 0.13);
$gmap-bg: rgba(0, 0, 0, 0.05);

$table-bg: #fafafa;
$table-border-color: #F1F0F5;
$table-bg-hover: #eee;
$table-border-hover: #ccc;
$table-cell-icon: #0080c3;
$table-row-bg: #fff1dd;
$table-border-bottom: #c8e9fa;
$table-cell-selected: #f1f8fb;
$table-cell-sorted: #f4fcff;
$table-cell-sorted-hover: #f0faff;
$table-cell-sorted-border: #c8e9fa;
$table-cell-border: #e5e5e5;
$table-checkbox-bg: #2B9FD9;
$table-checkbox-displayed: #E5F6FF;
$table-cell-border-lastchild: #f2f2f2;

$ghosttable-border-color: #f0f0f0;

$investment-heading-color: #2E384D;
$investment-icon-color: #61798F;
$investment-user-icon: #8798AD;
$investment-span-color: #5D87B7;

$application-client-bg: #FDEDED;

$slider-nav-color: rgba(50, 50, 50, 0.7);
$application-price-border: rgba(46, 91, 255, 0.08);

$box-shadow: 0 1px 15px 1px rgba(113, 106, 202, 0.2);

$tutorial-shadow: 3px 3px 5px #000000;

$prime-text-color: #2E384D;
$prime-text-secondary: #7B7D7F;
$prime-text-text-secondary: #475467;
$prime-border-color: rgba(46, 91, 255, 0.08);
$prime-border-box: #F1F0F5;
$prime-border-input: #DBE1EF;
$prime-hover-box: #E4EEFA;
$prime-dark-color: #3F4246;
$prime-dark2-color: #5E6064;
$prime-light-color: #bbbbbb;
$prime-medium-icon-color: #A7A6A7;
$prime-inactive-color: rgba(196, 196, 196, 0.7);
$prime-disabled-cell: rgba(97, 121, 143, 0.3);
$prime-gallery-link: rgba(13, 13, 13, 0.59);
$prime-cell-border: #e4eefa;
$prime-event-icon: #b3c1d0;
$prime-event-date: #BFC5D2;
$prime-step-bgcolor: #eff3f8;
$prime-dialog-bg: #ebebeb;
$prime-qtip-border: #E4EEFA;
$prime-qtip-color: #4A90E2;
$prime-qtip-bg: #E4EEFA;
$prime-tooltip-bg: #ebf7ea;
$prime-textstatus-yellow: #F7C137;
$prime-alert-bg: rgba(232, 74, 80, 0.1);
$prime-gray-color: #647181;
$prime-neutral-color: #98A2B3;
$prime-neutral-color2: #E4E7EC;
$prime-radiobtn-color: #555555;
$prime-uploadbtn-color: #737373;
$prime-delayed-application-bg: #FEECEA;
$prime-delayed-application-border: #F9ACA0;
$portal-link-hover: #435690;
$portal-info-color: #FF5A5F;
$dot-inactive-color: #E84A50;
$dot-inactive-shadow: 0 0 6px rgba(232, 74, 80, 0.75);
$dot-active-color: #A0D468;
$dot-active-shadow: 0 0 6px rgba(160, 212, 104, 0.75);


$searchbar-trigger-color: #8097B1;
$searchbar-trigger-bg: #f7f9fb;
$searchbar-trigger-border: #e5eaf0;
$wizard-step-color: #3f9bc9;
$wizard-step-bg: rgba(0, 0, 0, 0.02);

$board-text-color: #37474F;
$board-text-color-light: #536168;
$board-primary-color: #5A9DE6;
$board-btn-bg: #309FD6;
$board-post-border: #E1E8F0;
$board-success-color: #45BC83;
$board-danger-color: #E81D31;


$wizard-gradient: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.02) 100%);
$wizard-gradient-active: linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
$ukraine-gradient: linear-gradient(to bottom, #005BBB 50%, #FFD500 50%);

$zone-suggestions: #E2001A;
$zone-primary-border: #1e7aa9;
$zone-success-border: #3d923d;
$zone-danger-bg: #e02c25;
$zone-danger-border: #d3251e;
$zone-info-bg: #36b0d4;
$zone-info-border: #2ca6ca;
$zone-link-hover: #1c719c;

$main-font: 'Poppins';
$mobileIconsBorder: #E1E8F0;
$mobileCalendarFilterButtons: #B1B7BD;
$mobileCalendarSearch: #b1b7bd4d;

$gethome-primary-color: #9069C0;
$gethome-secondary-color: #787993;
$gethome-icons-color: #787993;
$gethome-cell-border: #EEE8F6;
$gethome-gray-text: #B1B7BD;
$gethome-gray3-color: #BBB;
$gethome-dark-color: #4632B3;
$gethome-dark2-color: #536168;
$gethome-dark-textcolor: #37474F;
$gethome-accent-color: #5A9DE6;
$gethome-turquise-primary: #009688;

$dark2: #5E6064;




@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.mobile-btn-group {
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.8rem;
  font-size: 1.6rem !important;
  font-weight: 400 !important;

  &.mobile-btn-absolute {
    position: absolute;
    right: 1rem;
    top: 2.3rem;
  }

  &.mobile-btn-absolute-list {
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
  }


  .mobile-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid $mobileCalendarFilterButtons;
    border-radius: 2px;
    min-width: 2.8rem;
    min-height: 2.8rem;
    font-size: 1.6rem;
    color: $mobileCalendarFilterButtons;

    span.mobile-btn-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      height: 1.4rem;
      min-width: 1.4rem;
      font-size: 1rem;
      background-color: $alert-secondary-color;
      color: $white-color;
      border-radius: 100%;
      padding: 2px;
      font-weight: 400;
      z-index: 1;
    }

    &:has(i.fa-ellipsis-v) {
      border-color: transparent;
    }

    &:has(i.fa-bell) {
      border-color: transparent;
    }
  }
}


section.content h2.mobilecalendar-title {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  margin-bottom: 1.2rem
}

.mobilecalendar-title .blue {
  display: block;
}

.mobilecalendar-controls .bpm-calendar-event-new {
  width: 100%;
  font-weight: 500;
  margin-bottom: 0;
}

.mobilecalendar-filterbuttons {
  position: absolute;
  top: -3.6rem;
  right: 0;
  @include fts16;

  a {
    color: $mobileCalendarFilterButtons;
  }

  .bpm-dropdown-menu {
    min-width: 200px !important;
    left: -150px !important;
    font-size: 14px;
    line-height: 21px;

    .mobilecalendar-action-item {
      padding: 6px 17px;
    }

    div a {
      color: $black-color;
    }
  }

  .bpm-collapse-menu {
    padding: 0 17px;
  }
}

.mobilecalendar-search-btn {
  border: 1px solid $mobileCalendarFilterButtons;
  padding: 0.2rem 0.6rem;
  margin-right: 0.5rem;
}

.mobilecalendar-actions-btn {
  padding: 0.2rem 1rem;
}

.mobilecalendar-event {
  margin-bottom: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
}

.mobilecalendar-event-content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid $mobileIconsBorder;
  border-left: none;
}

.mobilecalendar-event-type {
  width: 0.8rem;
}

.mobilecalendar-events-container .avatar {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
}

.mobilecalendar-no-events {
  display: flex;
  height: 30vh;
  justify-content: center;
  align-items: end;
}

.mobilecalendar-event-time {
  @include fts12;
  font-weight: 500;
  padding-bottom: 0.6rem;
}

.mobilecalendar-event-title {
  @include fts14;
  font-weight: 600;
  margin-bottom: 1.1rem;
}

.mobilecalendar-event-prop {
  @include fts10;
  font-weight: 500;
  padding: 0.4rem;
  border: 1px solid $mobileIconsBorder;
  margin-bottom: 0.4rem;
}

.mobilecalendar-content-container {
  height: 3.2rem;

  .mobilecalendar-content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    margin-bottom: 1rem;
    @include fts14;
  }

  .mobilecalendar-content-actions {
    display: flex;

    .mobilecalendar-calendar-btn {
      margin-right: 1.2rem;
    }

    .mobilecalendar-nav-prevbtn {
      margin-right: 0.5rem;
    }

    .mobilecalendar-nav-prevbtn, .mobilecalendar-nav-nextbtn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.2rem;
      height: 3.2rem;
      border: 1px solid $mobileIconsBorder;
      border-radius: 0.4rem;
      @include fts18;
    }

    i.bpm-btn-mobile-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.2rem;
      height: 3.2rem;
      padding: 0.4rem;
      border: 1px solid $mobileIconsBorder;
      border-radius: 0.4rem;
      position: relative;
    }

    label.bpm-btn-mobile-label {
      display: block;
      position: absolute;
      height: 3.2rem;
      width: 3.2rem;
      top: 0;
      left: 0;
    }
  }
}
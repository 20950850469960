@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.offer-short {

  .offer-short-location {
    @include fts15;
    display: block;
  }

  .offer-short-price {
    font-weight: bold;
  }

  .offer-short-area {
    @include fts13;
  }

  .offer-short-number,
  .offer-short-third,
  .offer-short-fourth {
    @include fts12;
  }

  .offer-short-price-old {
    text-decoration: line-through;
    @include fts13;
  }
}

.seeker-short {

  .seeker-short-name {
    @include fts15;
    display: block;
  }

  .seeker-short-type {
    @include fts13;
  }

  .seeker-short-status,
  .seeker-short-third,
  .seeker-short-fourth {
    @include fts12;
  }
}

@media (max-width: 1100px) {
  .offer-short {

    .offer-short-location {
      @include fts14;
    }

    .offer-short-area {
      @include fts12;
    }

    .offer-short-number,
    .offer-short-third,
    .offer-short-fourth {
      @include fts11;
    }

    .offer-short-price-old {
      @include fts12;
    }

  }

  .seeker-short {

    .seeker-short-name {
      @include fts14;
    }

    .seeker-short-type {
      @include fts12;
    }

    .seeker-short-status,
    .seeker-short-third,
    .seeker-short-fourth {
      @include fts11;
    }

  }

}
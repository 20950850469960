@import "./resources/sass-test/build/sass/themes/_themes";

@mixin reset {
  margin: 0;
  padding: 0;
}

@mixin regular-padding {
  padding: 1rem 1.5rem;
}

@mixin fts10 {
  font-size: 10px;
}

@mixin fts11 {
  font-size: 11px;
}

@mixin fts12 {
  font-size: 12px;
}

@mixin fts13 {
  font-size: 13px;
}

@mixin fts14 {
  font-size: 14px;
}

@mixin fts15 {
  font-size: 15px;
}

@mixin fts16 {
  font-size: 16px;
}

@mixin fts17 {
  font-size: 17px;
}

@mixin fts18 {
  font-size: 18px;
}

@mixin fts19 {
  font-size: 19px;
}

@mixin rotate90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

@mixin header-size {
  height: 56px;
  width: 100%;
}

@mixin flex-regular-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin border-radius-none {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  border-radius: 0 !important;
}

@mixin chart-container {
  text-align: center;
  display: block;
  margin: 0;
  left: 0;
  width: 100%;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin graph-element($text-color, $bg-color) {
  display: block;
  cursor: pointer;
  color: $text-color;
  border: 1px solid $bg-color;
  background-color: $bg-color;
  padding: 0.5rem 1rem;
}

@mixin grid-content($opacity, $height) {
  background: url("../gfx/bg-white-transparent.png") repeat;
  opacity: $opacity;
  transition: opacity 0.4s;
  height: $height;
  width: 100%;
  position: absolute;
  left: 0;
}

@mixin multimedia-hover($filter-value, $opacity-value) {
  zoom: 1;
  filter: alpha(opacity=$filter-value);
  opacity: $opacity-value;
  -webkit-transition: opacity .15s ease-in-out;
  -moz-transition: opacity .15s ease-in-out;
  -ms-transition: opacity .15s ease-in-out;
  -o-transition: opacity .15s ease-in-out;
  transition: opacity .15s ease-in-out;
}

@mixin tutorial-arrows($background-url) {
  background: url($background-url) no-repeat center center;
  width: 70px;
  height: 34px;
}

@mixin tutorial-higher-arrows($background-url) {
  background: url($background-url) no-repeat center center;
  width: 34px;
  height: 70px;
}

@mixin status($bg-color, $color: #fff) {
  background-color: $bg-color;
  color: $color;
}

@mixin status-with-border($bg-color, $border-color, $color: #fff) {
  background-color: $bg-color;
  border-color: $border-color;
  color: $color;
}

@mixin tabs-span {
  display: table;
  height: 100%;
  padding: 0.8rem 1.2rem;
  transition: all 0.4s ease 0s;
  vertical-align: middle;
  width: 100%;
}

@mixin center-position {
  top: 50%;
  left: 50%;
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

@mixin publish-options-label($color) {
  font-size: 13px;
  color: $color;
}

@mixin offer-price-value($z-index) {
  position: absolute;
  z-index: ($z-index);
  left: -2rem;
  top: 0.2rem;
  width: 2rem;
  height: 1.5rem;
  cursor: pointer;
}

@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {

    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }

      @content;
      $theme-map: null !global;
    }

  }

}

@function themed($key) {
  @return map-get($theme-map, $key);
}
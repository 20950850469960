@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-validation {

  .form-control.error {
    position: relative;
    border-width: 1px;
    border-style: solid;
    @include themify($themes) {
      border-color: themed('alertColor');
    }
  }

  .form-legend {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 100;

    a {
      @include themify($themes) {
        color: themed('alertColor');
      }
    }
  }

  label.error {
    position: static;
    left: 0.5rem;
    top: 0;
    width: 100%;
    @include fts11;
    @include themify($themes) {
      color: themed('alertColor');
    }
  }

  .user-email-inputs label.error {
    position: static;
  }

  label.required-label {
    font-weight: bold;

    &::after {
      content: "*";
    }
  }

  .required-activate-input {
    @include themify($themes) {
      border-bottom: themed('requiredInputBorder');
    }
  }

  .upload-file-container label.error {
    top: 6.2rem !important;
    left: 1.2rem;
  }

  .change-password-container {
    .form-group.row {
      margin-bottom: 4rem;
    }

    label.error {
      left: 1rem;
    }
  }

}

.start-screen.theme-gethome .login-box {
  label.error[for="password"] {
    top: 9.5rem !important;
  }

  label.error[for="password_confirmation"] {
    display: none !important;
  }
}

.bpm-validation .change-password-container label.error {
  position: static;
}
.bpm-collapse,
.bpm-dropdown {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}

.bpm-slidedown {
  display: none;
}

.bpm-collapse-menu {
  height: 0;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &.opened {
    height: auto;
    overflow: visible;
  }

  &.behind {
    z-index: 0;
  }
}

.bpm-collapse-content {
  width: 100%;
}

.bpm-desktop-collapse-more {
  position: absolute;
  right: 0;
  top: -2.7rem;
}

@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.theme-prime,
.theme-primepro,
.theme-gethome {

  .params-with-icon {
    padding-left: 3rem;
    margin-bottom: 2rem;
    position: relative;

    i {
      position: absolute;
      left: 0;
      color: $investment-user-icon;
    }

    span,
    a {
      color: $investment-icon-color;
    }
  }

  .summary-plan {
    width: 100%;
    display: block;
    margin-bottom: 1.5rem;

    img {
      width: 100%;
      border: 1px solid $autocomplete-border;
    }
  }

  .prime-hidden {
    display: none;
  }

  .on-input-button {
    position: absolute;
    top: 1rem;
    right: 3rem;
    z-index: 10;

    i {
      @include fts16;
    }
  }

  // CALC PRICE

  .display-column {
    display: flex;
    flex-direction: column;
  }

  .search {
    position: relative;
    display: inline-block;
  }

  .search .fa-exchange {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
  }

  .search .fa-exchange {
    left: auto;
    right: 10px;
  }

  /* INPUT COUNTER */

  .form-group-prime {
    .input-counter__wrapper {
      display: flex;
      flex-direction: column;
    }

    .input-counter__row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 0.6rem;
      align-items: center;
      padding-bottom: 2rem;
    }

    .input-counter__row > * {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.6rem;
      min-width: 1.6rem;
      line-height: 1.6rem;
      padding: 0;
      margin: 0;
    }

    .input-counter__label {
      justify-content: flex-start;
      min-width: 8rem;
      text-transform: capitalize;
    }

    .input-counter__controls {
      border-radius: 0.4rem;
      font-size: 0.8rem;
      cursor: pointer;
      background-color: #7B7D7F;
      color: #fff;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .input-counter__inputs {
      justify-content: flex-start;
      height: 3.8rem;
      width: 100%;
      gap: 0.55rem;

      * {
        padding: 0;
        margin: 0;
      }

      input.form-control {
        padding: 0 1.6rem;
      }
    }

    .input-counter__inputs > input {
      width: 3.8rem;
    }

    .input-counter__inputs > input:first-child {
      margin-left: 4rem;
    }

    .input-counter__inputs > input:not(:last-child) {
      margin-right: 2rem;
    }

    .input-counter__number {
      //line-height: 1.6rem;
    }

    .input-counter__controls--disabled {
      background-color: #F1F0F5;
    }

  }

  .bpm-page-estate-details,
  .bpm-listing-offer-prime {

    .bpm-dropdown-status {

      .bpm-dropdown-content {
        margin-bottom: 0.6rem;
      }

      .bpm-dropdown.bpm-status {
        min-width: 13.4rem;
      }

      .bpm-dropdown-menu {
        min-width: 13.4rem;
        top: 110%;
      }

      .bpm-button-status {
        width: 100%;
        margin-bottom: 0.4rem;
        padding-left: 0.8rem !important;
        text-align: center;
      }

      .row {
        margin-left: -0.4rem;
        margin-right: -0.4rem;
      }
    }
  }

}

@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-image-editor {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: 50000000;

  .image-precontainer {
    background: none;
    margin: 0 auto;
    width: 100%;
    padding: 3rem 0 2rem;
    background: #222;
  }

  .image-container {
    background: none;
    margin: 0 auto;
    width: 60rem;
    height: 60rem;

    > img {
      max-width: 100%;
    }
  }

  .bpm-image-editor-footer {
    color: #fff;
    margin: 4rem auto 1rem;
    font-size: 16px;
    text-align: center;
    font-style: italic;
  }

  .button-container {
    background-color: #000;
    text-align: center;
    width: 100%;
    padding: 2rem;
    height: auto;
  }

  .image-editor-button {
    width: 8rem;
    height: 8rem;
    border: 1px solid #eee;
    color: #fff;
    background: #000;
    margin: 0.5rem;
    border-radius: 0.5rem;

    &:hover {
      color: #000;
      background: #fff;
    }

    i {
      text-align: center;
      display: block;
      font-size: 2.2rem;
      padding: 1rem 0 0.5rem;
    }

    span {
      text-align: center;
      display: block;
      font-size: 12px;
      padding: 0.5rem 0 1rem;
      margin: 0;
    }
  }

  .preview-bg {
    width: 60rem;
    height: 60rem;
  }

  .canvas-bg {
    width: 60rem;
    height: 60rem;
    display: none;
  }

  .image-editor-option {
    display: none;
  }

  .image-editor-caption {
    color: #000;
    background: #fff;

    i {
      visibility: hidden;
    }

    span {
      font-weight: bold;
    }
  }

  .cropper-bg {
    background-image: none !important;
    background-color: #333 !important;
  }
}

#image-editor-object {
  display: none;
}

@media (max-width: 768px) {
  .bpm-image-editor {

    .image-precontainer {
      padding-bottom: 3rem;
    }

    .image-container {
      width: 95%;
    }

    .preview-bg {
      width: 100%;
    }

    .canvas-bg {
      width: 100%;
    }
  }
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.theme-gethome {

  section.content .bpm-page-desktop h2 {
    line-height: 3.6rem;
    margin-bottom: 0;
    font-weight: 500;
    color: $gethome-dark-textcolor;
  }

  .GetHomeStaticsChart {

    &.bpm-desktop-item {
      width: 100%;
      height: auto;
    }

    .subtitle-gh {
      font-size: 1.4rem;
      line-height: 2.1rem;
      font-weight: 600;
      color: $gethome-dark2-color;
      margin: 0 0 2.1rem 0;
    }

    .bpm-desktop-item-wrapper {
      background-color: transparent;
      border: none;
      padding: 0;

      .chart-buttons-container {
        position: absolute;
        top: -3.6rem;
        right: 1.2rem;

        .btn {
          border-radius: 0 !important;
          border: 1px solid $gethome-cell-border;
          background-color: $white-color;
          color: $gethome-dark2-color;
          font-size: 1.4rem;
          line-height: 2.4rem;
          height: 4rem;
          display: flex;
          justify-content: center;
          align-items: center;

          &.active-btn {
            background-color: $gethome-cell-border;
            color: $gethome-primary-color;
          }

        }
      }
    }

    .bpm-box {
      background: none;
      height: auto;
      padding: 0;

      &.col-lg-4 {
        padding: 0 4.8rem 0 0;

        &:last-child {
          padding: 0;
        }
      }

      h3 {
        margin-left: 0;
        margin-bottom: 1.2rem;
        font-size: 1.2rem;
        line-height: 1.8rem;
        font-weight: 600;
        color: $gethome-dark2-color;
      }

      .highcharts-container {
        border: 1px solid $gethome-cell-border;
      }
    }

  }

  .mobile-content {

    .GetHomeStaticsChart {

      .bpm-desktop-item-wrapper .chart-buttons-container {
        position: static;
      }

      .bpm-box {
        padding: 0;
      }
    }
  }
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-profile {

  .profile-header {
    position: relative;
    height: 15rem;

    img {
      width: 15rem;
      float: left;
      display: block;
      margin-right: 1rem;
    }

    .back-btn {
      float: left;
      height: 15rem;
      line-height: 15rem;
      font-size: 3rem;
      width: 2.5rem;
      margin-right: 1rem;
    }

    h2 {
      margin-bottom: 0;
      font-size: 2.2rem;
      font-weight: 300;
      @include themify($themes) {
        color: themed('linkColor');
      }
    }

    h3 {
      margin-top: 1.2rem;
      margin-bottom: 0;
      @include fts15;
      color: $main-color;
      font-weight: 300;
    }

    h4 {
      @include fts13;
      color: $main-color;
      font-weight: 300;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .profile-specializations {
      @include fts12;
      color: $main-color;
      font-weight: 300;
      height: 1.6rem;
      overflow: hidden;
    }

    .profile-stat {
      margin-top: 1rem !important;
      margin-bottom: 0 !important;
      @include fts13;
      color: $main-color;
      font-weight: 300;
    }

    .profile-stat-item {
      display: inline-block;
      margin-right: 1.5rem;
    }
  }

  &.bpm-profile-list .profile-header {
    margin-bottom: 3rem;
  }

  &.bpm-profile-list .profile-description {
    left: 16.5rem;
  }

  &.bpm-profile-list .profile-buttons {
    left: 16.5rem;
  }

  .bpm-listview-filters {
    top: -5.5rem;
  }

  .profile-description {
    position: absolute;
    top: 0;
    left: 20rem;
  }

  .profile-buttons {
    position: absolute;
    bottom: 0;
    left: 20rem;
  }

  .profile-content {
    margin: 3rem 0 0 3.5rem;
  }

  .bpm-grid .bpm-grid-cell {
    padding: 0.4rem 1rem;
  }
}

.bpm-user {
  position: relative;
  height: 10.5rem;

  img {
    width: 7.5rem;
  }

  .bpm-user-name {
    @include fts16;
    display: block;
    margin-bottom: -0.2rem;
  }

  .bpm-user-company {
    @include fts11;
    color: $main-color;
    margin-bottom: 0.3rem;
  }

  .bpm-user-contact {
    @include fts13;
    display: block;
  }

  .bpm-user-description {
    position: absolute;
    top: 0;
    left: 8.5rem;

    &.bpm-user-description-no-photo {
      left: 0;
    }
  }

  .bpm-user-buttons {
    position: absolute;
    bottom: 0;
    left: 0;

    .btn {
      margin: 0;
    }
  }

  .bpm-dropdown-menu {
    width: 40rem;

  }
}

.bpm-activity {
  margin-bottom: 1rem;
  height: 100rem;
  padding-right: 1.5rem;

  &.bpm-activity-all {
    height: auto;
    padding-right: 0;
  }

  .bpm-activity-row {
    background: $white-color;
    border: 1px solid $border-color;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .bpm-activity-header {
    position: relative;

    h3 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      @include fts15;
      font-weight: 300;
    }

    .desc {
      margin-top: 0.5rem;
      @include fts13;
    }

    .date {
      color: $autocomplete-color;
      font-weight: 300;
      @include fts13;
      position: absolute;
      top: 0;
      right: 0;
    }

    .category {
      font-weight: 300;
      @include fts13;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .bpm-activity-user {
    position: relative;
    height: 2.5rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;

    img {
      width: 2.5rem;
      float: left;
      display: block;
      margin-right: 1rem;
    }

    a {
      color: $main-color;
      @include fts12;
    }
  }

  .bpm-activity-object {
    margin-top: 1.5rem;
    background-color: $main-bg-color;
    border: 1px solid $border-color;
    padding: 1rem;

    .object-first-row,
    .object-first-row a {
      @include fts13;
      @include themify($themes) {
        color: themed('successColor');
      }
    }

    .object-second-row {
      @include fts13;
    }
  }
}

.buttons.bpm-activity-all {
  margin-top: 0;
}

.bpm-profile-list .buttons.bpm-activity-all {
  float: left;
}

section.content .bpm-profile-list h2:first-child {
  padding-right: 1rem;
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  .bpm-profile .profile-header .profile-stat {
    @include fts12;
  }
  .bpm-profile .profile-header .profile-stat-item {
    margin-right: 0.5rem;
  }
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.theme-prime {
  .bpm-validation .email-inputs-container label.error {
    position: static;
  }

  .register-form-checkbox .control-label {
    padding-top: 0;
  }
}

.start-screen.theme-prime.prime-first-register {

  .login-right {
    display: none;
  }

  .login-left {
    width: 100%;
    text-align: left;
  }

  .vertical-center {
    top: 0;
    transform: translateX(-50%);
  }

  .prime-register-form {

    .login-box {
      max-width: 51rem;
      width: unset;
      padding: 2.4rem 4.8rem;

      .bpm-checkbox {

        margin-bottom: 1.2rem;

        span {
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }

        input[type=checkbox]~span:before {
            top: 0.5rem;
            transform: none;
        }
      }

      a {
        color: unset;
        text-decoration: underline;
      }

      .info-prime-text {
        color: $prime-text-text-secondary;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.4px;
      }

      .prime-register-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $prime-text-text-secondary;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.4px;
        padding-top: 3.2rem;
        border-top: 1px solid $prime-neutral-color2;

        p {
          margin-bottom: 0;

          a {
            text-decoration: none;
          }
        }
      }

      .btn-primary {
        margin: 3.2rem 0;
      }
    }

    .input-group.form-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 1.2rem;

      label {
        color: $prime-dark-color;
        font-size: 13px;
        font-style: normal;
        font-weight: bold;
        line-height: 120%;

        &.error {
          position: static;
          color: $alert-prime-color;
        }
      }

      .form-control {
        color: $prime-dark-color;
        border-color: $prime-border-box;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      textarea {
        min-height: 7.6rem;

      }

      .info-prime-form {
        color: $prime-neutral-color;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}

@media (max-width: 780px) {
  .start-screen.theme-prime.prime-first-register {
    .vertical-center {
      transform: translateY(0) translateX(0);
    }
  }
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-listview .bpm-checkbox, .bpm-minitable .bpm-checkbox {
  margin: 0;
}

.bpm-grid {
  margin-bottom: 1rem;

  .hiddenColumn {
    display: none !important;
  }

  .nowrapColumn {
    white-space: nowrap;
  }

  .bpm-grid-checkbox {
    text-align: center;
  }

  .bpm-important {
    margin-top: 0.8rem;
    @include themify($themes) {
      color: themed('alertColor');
    }
  }

  &.bpm-tableview {
    border-spacing: 0 0.8rem;
    display: table;
    width: 100%;

    .bpm-grid-head {
      display: table-row;
      text-align: left;
      vertical-align: middle;
      background: none !important;

      .bpm-grid-cell {
        border: none !important;
        background: none !important;
        vertical-align: top;
      }

      .bpm-grid-cell,
      &.bpm-grid-row:hover .bpm-grid-cell {
        text-transform: none;
        vertical-align: top;
        @include fts12;

        @include themify($themes) {
          color: themed('gridCellColor');
          background-color: themed('gridCellBg');
          border-color: themed('gridCellBorder');
          font-weight: themed('gridCellFontWeight');
        }

        &:hover {
          color: #7B7D7F;
        }
      }

      .bpm-grid-cell.hover-column {
        background-color: $table-bg-hover;
        border-color: $table-border-hover;
      }

      .bpm-grid-cell.hover-column-before {
        border-right-color: $table-border-hover;
      }

      .bpm-grid-cell a {
        color: $main-color;
        display: block;
      }

      .bpm-grid-cell.required-label {
        font-weight: bold;
      }

      .bpm-grid-cell i {
        display: inline-block;
        font-size: 1.7rem;
        padding-top: 0.1rem;
        color: transparent;
      }

      .bpm-grid-cell.sort-column {
        cursor: pointer;
      }

      .bpm-grid-cell.bpm-sorted i {
        @include themify($themes) {
          color: themed('tableviewSortedIconColor');
        }
      }

      .bpm-grid-actions {
        text-align: center;
      }

    }

    &.bpm-minitable .bpm-grid-head {
      text-align: left;
    }

    .bpm-grid-row.package-row {
      display: none;
    }

    .bpm-grid-row.package-row-style {
      background-color: $table-row-bg !important;
    }

    .bpm-grid-cell.break-word {
      //-ms-word-break: break-all;
      //word-break: break-all;

      /* Non standard for webkit */
      word-break: break-word;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }

    .bpm-grid-cell {

      .zone-icon img {
        height: 1.9rem;
        padding: 0 2px;
      }
      img {
        height: 4.5rem;
        vertical-align: top;

        &.square {
          width: 4.5rem;
          object-fit: cover;
        }
      }
    }

    .bpm-grid-row {
      display: table-row;
      background-color: $white-color;

      &:nth-child(2n+1) {
        background-color: $white-color;
      }

      &.hover-before .bpm-grid-cell {
        border-bottom-color: $table-border-bottom;
      }

      &.bpm-selected .bpm-grid-cell,
      &.bpm-selected .bpm-grid-cell.bpm-sorted {
        background-color: $table-cell-selected;
      }

      .bpm-grid-cell.bpm-sorted {
        @include themify($themes) {
          background-color: themed('tableviewSortedBg');
        }

        &:hover {
          @include themify($themes) {
            background-color: themed('tableviewSortedBg');
          }
        }
      }

      &:hover .bpm-grid-cell,
      &:hover .bpm-grid-cell.bpm-sorted,
      .bpm-grid-cell.hover-column,
      .bpm-grid-cell.hover-column.bpm-sorted {
        @include themify($themes) {
          background-color: themed('tableviewHoverBg');
          border-color: themed('tableviewHoverBorder');
        }
      }

      &:hover .bpm-checkbox input[type="checkbox"]:checked + span:before,
      &:hover .bpm-checkbox input[type="radio"]:checked + span:before {
        @include themify($themes) {
          background-color: themed('primaryColor');
        }
      }

      &:hover div:not(:hover).bpm-grid-actions-icons a {
        background-color: transparent;
        color: inherit;
      }

      &:hover div:not(:hover).bpm-grid-actions-icons .bpm-dropdown-content a {
        background-color: transparent;
        color: $main-color;
      }

      &.bpm-grid-missing-contact .bpm-grid-checkbox {
        @include themify($themes) {
          background-color: themed('alertColor') !important;
        }
      }

    }

    .bpm-grid-cell {
      display: table-cell;
      border-left: none;
      border-right: none;
      transition: .3s;
      vertical-align: middle;

      .bpm-grid-accounts-info {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        gap: 4px;

        span {
          white-space: nowrap;
        }

        img {
          width: 18px;
          height: 18px;
        }

      }

      @include themify($themes) {
        padding: themed('tableviewCellPadding');
        color: themed('tableviewCellColor');
        border-top: themed('tableviewCellBorder');
        border-bottom: themed('tableviewCellBorder');
      }

      &.hover-column-before {
        @include themify($themes) {
          border-right-color: themed('tableviewHoverBorder');
        }
      }
    }

    .bpm-grid-actions {
      min-width: 9rem;
      text-align: center;
      width: 9rem;

      a {
        display: inline-block;
        padding: 0.2rem 0.4rem 0.2rem;
        vertical-align: middle;
        font-size: 13px;
        width: 2.1rem;
        margin-bottom: 0.3rem;

        @include themify($themes) {
          padding: themed('gridActionsPadding');
          margin-bottom: themed('gridActionsMargin');
          width: themed('gridActionsWidth');
        }

        &.bpm-submit {
          display: block;
          width: 100%;
          margin-bottom: 0;
          text-align: left;
          padding: 0.4rem 1rem;
          background: none;
          color: $main-color;
          border: none;
          @include fts12;

          &:hover {
            background-color: $table-bg-hover;
            color: $main-color;
          }
        }
      }
    }

    div:not(:hover).bpm-grid-row .bpm-grid-actions-icons a {
      background-color: transparent !important;
      color: $table-border-hover;
    }

    div:not(:hover).bpm-grid-row .bpm-grid-actions-icons .bpm-dropdown-content a {
      background-color: transparent;
      color: $main-color;
    }

    .bpm-grid-infos {
      min-width: 7rem;
      text-align: center;
      width: 7rem;
      @include fts14;

      a {
        display: inline-block;
        padding: 0.2rem 0.2rem 0;
        vertical-align: middle;
        width: 1.7rem;
        margin-bottom: 0.1rem;

        &.info-export {
          display: inline-block;
          vertical-align: middle;
          width: auto;
          @include fts10;
          margin-bottom: 0.3rem;

          &:hover {
            color: $white-color;
            opacity: 0.8;
          }
        }
      }
    }

    .bpm-grid-image {
      min-width: 5.5rem;
      text-align: center;
      width: 5.5rem;

      span {
        @include fts10;
      }
    }

    .bpm-grid-cell-label {
      display: none;
    }
  }
}

.bpm-minitable .bpm-grid-checkbox {
  width: 1.5rem;
  padding: 0;
}

.bpm-box.bpm-box-nohead .bpm-grid.bpm-tableview.bpm-minitable .bpm-grid-cell {
  background-color: $white-color !important;
}

.bpm-listview .bpm-grid.bpm-tableview .bpm-grid-row .bpm-grid-cell {

  &:first-child {
    border-left: 1px solid $table-border-color;
    width: 15px;
  }

  &:last-child {
    border-right: 1px solid $table-cell-border-lastchild;
  }

  .form-control {
    display: inline-block;
  }
}

#btn-room-grid-next-row,
#btn-contiguity-grid-next-row {
  margin-right: 2rem;
}

.bpm-grid-editable-column {
  cursor: pointer;
}

.bpm-grid-editable-input {
  display: none;
  width: 100%;
}

.bpm-grid-presentation {
  padding: 1.5rem 1rem 1rem 5rem !important;
  height: auto !important;

  .bpm-grid-checkbox {
    top: 1.7rem !important;
    left: 1.5rem !important;
  }

  .presentation-wrapper {
    width: 100%;
    max-width: 120rem;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    @include fts16;
  }

  h4 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    @include fts14;
  }

  .presentation-details h4 {
    font-weight: bold;
    margin-bottom: 2rem;
  }

  .data-list {
    max-width: 45rem;
    width: 100%;
  }

  .order-data {
    margin-bottom: 1rem;
    color: $autocomplete-color;
    @include fts13;
  }

  .summary-option {
    background: none repeat scroll 0 0 $white-color;
    border: 1px solid $border-color;
    float: left;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.2rem 0.4rem;
    @include fts12;
  }

  .summary-image img {
    width: 100%;
    max-width: 30rem;
  }

  .buttons {
    padding: 2rem 0;
  }

  .presentation-activity {
    max-width: 80rem;
    width: 100%;
  }

  .presentation-activity-row {
    border-bottom: 1px solid $table-bg-hover;
    padding: 0.6rem 0;
  }

  .presentation-estimation-form {
    max-width: 50rem;
    width: 100%;
  }

  .presentation-estimation-list {
    max-width: 45rem;
    width: 100%;
  }

  .presentation-details {
    display: none;
    padding-bottom: 1.5rem;
    padding-top: 2rem;
  }
}

.bpm-grid.bpm-tableview.bpm-minitable {
  border-spacing: 0;
}

.theme-prime,
.theme-primepro {

  .bpm-grid.bpm-tableview .bpm-grid-row.bpm-grid-head:nth-child(2n+1) {
    background: none;
  }

  .bpm-listview .bpm-grid.bpm-tableview .bpm-grid-row.bpm-grid-head .bpm-grid-cell:first-child {
    border: none;
  }

  .bpm-listview .bpm-grid.bpm-tableview .bpm-grid-row .bpm-grid-cell:first-child {
    border-left: 1px solid $prime-cell-border;
  }

  .bpm-grid.bpm-minitable .bpm-grid-head.bpm-grid-row:hover .bpm-grid-cell {
    @include fts12;
  }

  .bpm-grid.bpm-tableview .bpm-grid-row .bpm-grid-cell .grid-text {
    max-width: 30rem;
    width: auto;
    @include fts12;
  }

  .bpm-grid.bpm-tableview .bpm-grid-head {
    color: $investment-user-icon;

    &.bpm-grid-row:hover .bpm-grid-cell {
      @include fts12;
    }
  }

  .bpm-grid.bpm-tableview .bpm-grid-head.bpm-grid-row .bpm-grid-cell.bpm-sorted,
  .bpm-grid.bpm-tableview .bpm-grid-head.bpm-grid-row:hover .bpm-grid-cell:hover {
    background: none !important;
  }

  .bpm-grid.bpm-tableview .bpm-grid-row:hover .bpm-grid-cell:hover {
    background-color: $prime-cell-border !important;
  }

  .bpm-grid.bpm-tableview .bpm-grid-row.bpm-grid-head:hover .bpm-grid-cell:hover {
    border: none;
  }

  .bpm-grid.bpm-tableview .bpm-grid-checkbox {
    vertical-align: middle;
  }

  .bpm-listing-application .bpm-grid.bpm-tableview .bpm-grid-actions {
    min-width: 12rem;
    width: 12rem;
  }

  .bpm-listing-application .bpm-grid.bpm-tableview .bpm-grid-row .bpm-grid-cell:first-child {
    width: 2rem;
    padding: 0;
  }

  .bpm-listview .bpm-grid.bpm-tableview .btn-actions-icon > a > i {
    font-size: 2.4rem;
    font-weight: 300;
    color: $prime-primary-color;
  }

  .bpm-grid.bpm-tableview .bpm-grid-cell .options-area {
    padding: 1rem 0 0 0;
    clear: both;
  }

  .bpm-grid.bpm-tableview .bpm-grid-cell .option {
    background: none repeat scroll 0 0 $white-color;
    border: 1px solid #e0e0e0;
    float: left;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 3px 6px;
    font-size: 13px;
    border-radius: 4px;
  }

  .bpm-grid.bpm-tableview .bpm-grid-actions input.bpm-submit,
  .bpm-grid.bpm-tableview .bpm-grid-actions button.bpm-submit,
  .bpm-grid.bpm-tableview .bpm-grid-actions a.bpm-submit {
    padding: 0.5rem 1rem;
    border-radius: 0;
  }

  .summary-offer-price-change,
  .summary-offer-price-permeter-change {
    position: relative;

    i.offer-price-value-refresh {
      position: absolute;
      z-index: 1;
      right: -2rem;
      display: none;
      top: 0.8rem;
      width: 2rem;
      height: 1.5rem;
      cursor: pointer;
      color: $investment-span-color;
      font-size: 2rem;
    }
  }

  .bpm-grid.bpm-tableview .bpm-grid-cell {

    .user-cell {
      width: 100%;
      text-align: left;
    }

    .user-name {
      display: inline-block;
      width: auto;
    }

    .user-details {
      div.user-name-row, div.user-email-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        min-height: 20px;
        gap: 3px;

        div.user-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 20px;
          min-height: 20px;
        }

        i {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
        }

        span.user-email {
          color: $prime-primary-color
        }
      }
    }

    img.avatar {
      width: 3.4rem;
      border-radius: 50%;
      overflow: hidden;
      margin: 0.5rem 1rem 0.5rem 0;
      height: 3.4rem;
      vertical-align: middle;
    }

    h4 {
      font-weight: 500;
      margin-top: 0;

      &.primary {
        color: $prime-primary-color;
        font-weight: normal;
      }
    }

    h5 {
      font-weight: 500;
      @include fts15;
      margin-top: 0;
      margin-bottom: 0.5rem;
    }

    .icons-cell-container {
      color: $prime-primary-color;
      font-size: 2rem;
      display: flex;
      justify-content: center;
      transition: 0.2s;

      .material-icons:after {
        content: attr(data-icon);
      }

      span {
        font-size: 3rem;
        margin-left: 0.5rem;
      }

      .add-plan-icon {
        color: transparent;
      }

      &:hover .add-plan-icon {
        color: $prime-primary-color;
      }
    }

  }

  .bpm-grid.bpm-tileview .bpm-grid-cell-table .bpm-grid-cell-value .icons-cell-container {
    color: $prime-primary-color;
    font-size: 2rem;
    display: flex;
    justify-content: center;

    .material-icons-round {
      font-size: 2.8rem;
      margin-left: 0.5rem;
    }
  }

  .bpm-listing-application .application-data {
    @include fts15;
    margin-bottom: 1rem;

    i {
      @include fts18;
      margin-right: 1rem;
    }
  }

}

@media (max-width: 780px) {

  .bpm-grid.bpm-tableview {

    .bpm-grid-actions {

      min-width: 12rem;
      width: 12rem;

      a {
        width: 3.1rem;
        padding: 0.5rem 0.7rem 0.5rem;
      }
    }

    .bpm-grid-cell.dropdown-container {
      padding-bottom: 0;
      margin-bottom: 1rem;
    }
  }

  .theme-prime,
  .theme-primepro {

    .bpm-grid.bpm-tableview .bpm-grid-actions {
      min-width: 6rem;
      width: 6rem;
    }
  }
}

.theme-prime .bpm-page-application .bpm-listing-application .bpm-grid-row.delayed-application-desktop {
  background-color: $prime-delayed-application-bg;

  .bpm-grid-cell {
    border-top: 1px solid $prime-delayed-application-border;
    border-bottom: 1px solid $prime-delayed-application-border;

    &:first-child {
      border-left: 1px solid $prime-delayed-application-border;
    }

    &:nth-last-child(3){
      border-right: 1px solid $prime-delayed-application-border;
    }
  }
}

.theme-prime .bpm-page-application .bpm-listing-application .bpm-tileview-list.bpm-mobileview-list .bpm-grid-row.delayed-application-mobile {
  background-color: $prime-delayed-application-bg;
  border: 1px solid $prime-delayed-application-border;

  .bpm-grid-content-center {
    background: $prime-delayed-application-bg;
  }
}




@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.theme-prime,
.theme-primepro,
.theme-gethome {

  .bpm-create-investment-dialog-container {
    display: flex;
    margin-top: 4rem;

    #location-map {
      width: 45%;
    }

    .bpm-gmap-body {
      min-height: 36rem;
    }
  }

  .bpm-create-investment-fields {
    margin-right: 2.6rem;
    width: 55%;

    &:first-child {
      margin-top: -2.5rem;
    }
  }

  .bpm-create-investment-dialog .btn.btn-success {
    margin-top: -0.6rem;
  }

  #bpm-create-investment-dialog-wrap .bpm-dialog-content {
    padding-bottom: 0;
  }

  #bpm-create-investment-result-dialog-wrap .bpm-dialog-header-title {
    visibility: hidden;
  }

  .investment-edit-data-tabs {
    padding: 3.4rem 5.5rem 0;

    a {
      margin-right: 2rem;
      padding-bottom: 0.5rem;
      color: $prime-dark2-color;
      transition: .3s;

      &.active-tab {
        color: $prime-primary-color;
        border-bottom: 3px solid $prime-primary-color;
      }

      &:hover {
        color: $prime-primary-color;
        border-bottom: 3px solid $prime-primary-color;
      }
    }
  }

  .investment-edit-data-content {
    display: flex;

    .form-prime-tipscontainer .tooltip-info-success.triangle-left:first-child {
      margin-top: 24.5rem;
    }

    .bpm-grid-toggler-title.active + .bpm-collapse-menu.opened {
      height: auto !important;
    }
  }

  .investment-edit-data-container {
    width: 100%;
    padding: 4.7rem 5.5rem 0;

    .bpm-edit-form-controls {
      text-align: right;
    }

    .bpm-grid-toggler-content {
      padding: 2rem 8rem 1rem 4rem;
    }

    .bpm-grid.bpm-rowview .bpm-grid-row {
      border: 1px solid $prime-border-color;
    }

    .bpm-grid.bpm-rowview.bpm-optionsview .bpm-grid-row {
      border: 1px solid $investment-user-icon;
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 5rem;
    }
  }

  .form-section-prime {
    margin-bottom: 2rem;
  }

  .form-section-prime-title {
    @include fts14;
    color: $prime-dark-color !important;
    margin-bottom: 2.9rem;
    font-weight: bold;
  }

  h4.building-name {
    font-weight: bold;
  }

  .inside-form-button,
  .prime-form-button {
    @include fts13;
  }

  .prime-form-button {
    margin-top: 3.3rem;
  }

  .inside-form-button.btn-success {
    margin-left: 4.8rem;
  }

  .bpm-collapse-content .inside-form-button {
    margin-bottom: 2.3rem;
  }

  .bpm-grid-toggler-title .additional-info.standard-subtitle {
    position: relative;
    top: 1rem;
  }

  .bpm-grid-row .building-details {
    text-transform: uppercase;
    @include fts13;
    color: $investment-user-icon;
    letter-spacing: 1.12px;
    margin: 2.3rem 4.8rem 3.3rem;
  }

  .investment-price-switch {
    margin-bottom: 3.4rem;
  }

  .investment-contiguity-switch {
    margin-bottom: 1rem;
  }

  .contiguity-section-prime {
    margin-bottom: 3.4rem;

    + .form-edit-controls .prime-form-button {
      margin-top: 0;
    }
  }

  .prime_add_plan {
    color: $investment-span-color;
    @include fts13;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-top: 1.4rem;
    margin-bottom: 3rem;

    .btn {
      padding: 0;
      display: flex;
      align-items: center;
      font-weight: bold;

      &.active,
      &:active {
        box-shadow: none;
      }
    }

    a.btn i {
      font-size: 2.6rem;
      margin-right: 2rem;
    }

    &.agent_photo {
      margin-bottom: 0.6rem;
      align-items: flex-end;
      text-transform: none;
    }
  }

  .bpm-grid.bpm-tileview .bpm-grid-row.bpm-gallery-item.prime-gallery-item {
    border-radius: 0 !important;
    width: 34.6rem;
    height: 21rem;
    padding: 0;
    margin: 1rem 0.5rem;
    filter: drop-shadow($prime-box-shadow);
  }

  .prime-gallery-item .bpm-grid-cell.photo-cell a {
    width: 34.6rem;
    height: 21rem;
    border-radius: 0;
  }

  .bpm-grid.bpm-tileview .prime-gallery-item {

    .btn-group {
      bottom: unset;
      left: unset;
      right: 1rem;
      top: 1rem;

      a {
        float: right;
        background-color: $prime-gallery-link;
        border-radius: 50% !important;
        border-color: transparent;
        width: 2.8rem;
        min-width: 2.8rem;
        height: 2.8rem;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          color: $white-color;
          @include fts14;
        }
      }
    }
  }

  .gallery-section-prime-title {
    font-size: 2.2rem;
    color: $prime-text-color;
    font-weight: 300;
    margin-bottom: 2rem;
  }

  .gallery-section-action-buttons {
    margin: 3rem 0.3rem;
    display: flex;
  }

  .gallery-section-investment-logo {
    text-align: right;

    img {
      width: auto;
      height: 15rem;
    }

    .btn {
      margin-right: 1.8rem;

      &.btn-danger.active,
      &.btn-danger:active {
        box-shadow: none;
      }
    }
  }

  #offer-gallery.bpm-grid.bpm-tileview {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 -0.5rem;
  }

  .input-copy-options {
    position: relative;
  }

  .prime-options-btn {
    text-transform: none;
    padding: 0.5rem 0;
  }

  .btn-dialog-footer {
    text-align: right;

    .btn-danger {
      margin-right: 2rem;
    }
  }

  .bpm-grid.bpm-tableview .bpm-grid-cell.dn {
    display: none;
  }

  .bpm-grid-cell.checks-cell.gallery-prime-checks {
    display: block;
    position: static;
    float: none;
    width: 17.5rem;
  }

  .bpm-grid.bpm-tileview .bpm-grid-row.bpm-gallery-item.prime-gallery-item {
    margin: 2.5rem 0.5rem;
  }
}

@media (max-width: 768px) {

  .investment-edit-data-content {

    .form-prime-tipscontainer {
      display: none;
    }

    .bpm-autocomplete {
      position: relative;
    }

    .bpm-dropdown-menu {
      left: 0 !important;
    }
  }

  #gallery-body .form-prime-tipscontainer {
    display: block;
    width: 95%;
  }

  .investment-edit-data-tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 2rem 2rem 0 2rem;

    a {
      margin-bottom: 0.8rem;
    }
  }

  .investment-edit-data-container {
    padding: 2rem;

    .bpm-grid-toggler-content {
      padding: 2rem 2rem 1rem 2rem;
    }

    .bpm-edit-form-controls {
      text-align: left;
    }

    .form-group-prime {
      padding-bottom: 0;
    }

    .short-inputs .form-input-mid {
      width: 100%;
      padding-right: 0;
    }
  }

  .short-inputs .form-input-triple,
  .basic-prime .short-inputs .form-input-mid,
  .short-inputs .form-input-joined {
    width: 100%;
    padding-right: 0;
  }

  .form-section-prime-title {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .bpm-grid.bpm-tileview .bpm-grid-row.bpm-gallery-item.prime-gallery-item,
  .prime-gallery-item .bpm-grid-cell.photo-cell a {
    width: 26.8rem;
  }

  .short-inputs .form-input-triple.short-triple,
  .short-inputs .form-input-triple.small-triple,
  .short-inputs .form-input-triple.mid-triple,
  .short-inputs .form-input-triple.large-triple,
  .short-inputs .form-input-triple.long-triple {
    width: 100%;
  }

  .inside-form-button.btn.btn-success {
    margin: 2rem 0 1rem 1rem;
  }

  .investment-edit-data-container .form-edit-controls {
    text-align: center;
    margin-top: 4rem;
  }

  .investment-price-switch {
    margin-bottom: 1rem;
  }

  .prime_add_plan .btn {
    @include fts12;
  }

  .prime_add_plan a.btn i {
    font-size: 16px;
    margin-right: 0.3rem;
  }

  .prime-options-btn {
    right: 1rem;
    @include fts12;
  }

  .form-prime-tipscontainer {

    .tooltip-info-success {
      margin: 2rem 0;

      &.triangle-left:after {
        display: none;
      }

      &.triangle-left:last-child {
        display: none;
        margin-top: 0;
      }
    }
  }

  .form-group-prime.short-inputs {
    display: block;

    .form-input-long,
    .form-input-short {
      width: 100%;
    }

    .form-input-long {
      padding-right: 0;
      padding-bottom: 2rem;
    }
  }

  div.form-prime-container h2:first-child {
    flex-direction: column;
    justify-content: flex-start;
    font-size: 2.4rem;
  }

  .form-prime-container h2 img {
    width: 35%;
    margin-top: 2rem;
  }

  .formpage-content {
    flex-direction: column-reverse;
  }

  .bpm-create-investment-dialog-container {
    flex-direction: column;

    #location-map {
      width: 40%;
    }
  }
  .bpm-create-investment-fields {
    width: 40%;
  }

  #bpm-create-investment-dialog-wrap .bpm-dialog-content {
    padding-bottom: 2rem;
  }

  .bpm-create-investment-dialog .btn.btn-success {
    margin-top: -2rem;
  }

  .form-group-prime .bpm-checkbox.bpm-radio {
    padding-right: 2rem;
  }

  .bpm-create-investment-dialog-container .form-group-prime.short-inputs.half-width {
    display: flex;
  }

  .bpm-create-investment-dialog-container .form-group-prime.short-inputs .form-input-short:first-child {
    padding-right: 2rem;
  }
}

@media (min-width: 1600px) {
  .investment-edit-data-container {
    max-width: 140rem;
  }
}

@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

#send-new-mail {
  width: 72rem;
}

.bpm-mail-thread {
  padding-bottom: 10rem;

  pre,
  code {
    white-space: normal;
    @include fts13;
    @include themify($themes) {
      font-family: themed('mainFont'), Helvetica, Arial, sans-serif;
    }
  }

  .bpm-mail-thread-row {
    background: $white-color;
    box-shadow: $box-shadow;
    padding: 2rem;
    margin-bottom: 1rem;
    cursor: pointer;
    border-radius: 0.7rem;

    &.row-expanded {
      cursor: default;
    }
  }

  .bpm-mail-thread-header {
    position: relative;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $border-color;
    margin-bottom: 1.5rem;

    h3 {
      margin-top: 0;
      margin-bottom: 0;
      @include fts13;
      font-weight: 300;
    }

    .desc {
      margin-top: 0.5rem;
      @include fts13;
    }

    .dw {
      margin-top: 0.5rem;
      @include fts12;
    }

    .date {
      color: $autocomplete-color;
      font-weight: 300;
      @include fts13;
      position: absolute;
      top: 0;
      right: 0;
    }

    .actions {
      position: absolute;
      top: 2.6rem;
      right: 0;

      i {
        @include fts16;
      }

    }
  }

  .bpm-mail-thread-summary {
    @include fts13;
    display: block;
  }

  .bpm-mail-thread-content {
    @include fts13;
    display: none;

    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }

    a {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
    }

    button {
      background-color: transparent;
      color: inherit;
      border-width: 0;
      padding: 0;
      cursor: pointer;
    }

    figure {
      margin: 0;
    }

    input::-moz-focus-inner {
      border: 0;
      @include reset;
    }

    ul,
    ol,
    dd {
      @include reset;
      list-style: none;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      font-size: inherit;
      font-weight: inherit;
    }

    p {
      margin: 0;
    }

    div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
      @include reset;
      border: 0;
      vertical-align: baseline;
    }

    table {
      border-collapse: separate;
      border-spacing: 0.2rem;
    }

    .show-message-images,
    .loading-message-images {
      margin-bottom: 1.6rem;
    }

    .show-images-container {
      text-align: right;
    }
  }

  .bpm-mail-thread-attachments {
    display: none;
    margin-top: 1.5rem;
    border-top: 1px solid $border-color;
    padding-top: 1.5rem;
  }

  .bpm-mail-thread-attachment {
    display: inline-block;
    margin: 0.5rem 1rem 0.5rem 0;
  }

  .row-expanded .bpm-mail-thread-summary {
    display: none;
  }

  .bpm-mail-thread-content {
    display: block;
    max-height: 50rem;
  }

  .bpm-mail-thread-attachments {
    display: block;
  }

  .row-hidden {
    display: none;
  }
}

.bpm-mail-contact {
  min-height: 11.5rem;
}

.bpm-mail-no-contact {
  margin-bottom: 2rem;
}

.thread-controls {
  display: none;
  position: fixed;
  right: 4rem;
  bottom: 0;
  padding: 2rem 3.5rem;
  z-index: 4000;
  background: url("../gfx/wizard-controls-bg.png") repeat-x left top;
}

.loading-message-images {
  height: 10rem;
}

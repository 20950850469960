@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-leaderboard {
  margin-bottom: 3rem;

  .bpm-leaderboard-row {
    background: $white-color;
    border: 1px solid $border-color;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    position: relative;
  }

  .bpm-leaderboard-user {
    position: relative;
    height: 3.5rem;
    line-height: 3.5rem;
    font-weight: 300;

    .leaderboard-number {
      width: 2rem;
      float: left;
      display: block;
      text-align: center;
      margin-right: 1rem;
      @include fts15;
    }

    img {
      width: 3.5rem;
      float: left;
      display: block;
      margin-right: 1rem;
    }

    a {
      color: $main-color;
      @include fts14;
    }
  }

  .points {
    font-weight: 300;
    @include fts15;
    position: absolute;
    top: 0;
    right: 0;

    .target {
      font-weight: 300;
      @include fts12;
    }
  }

  .number1,
  .number1 a {
    @include themify($themes) {
      color: themed('alertColor');
    }
    font-weight: 700;
  }

  .number2,
  .number2 a {
    @include themify($themes) {
      color: themed('textWarningColor');
    }
    font-weight: 400;
  }

  .number3,
  .number3 a {
    @include themify($themes) {
      color: themed('textWarningColor');
    }
  }

  .bpm-leaderboard-row.row-total .bpm-leaderboard-user,
  .bpm-leaderboard-row.row-total .points {
    font-weight: bold;
  }
}

.bpm-leaderboard-box {
  height: 62rem !important;
  padding-right: 2rem;
}

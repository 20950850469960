@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

/* row = tile = listItem */

.bpm-grid.bpm-tileview {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin: 0 -1.5rem;

  .bpm-grid-head.bpm-grid-row {
    /* ukryj header w kaflach */
    display: none !important;
  }

  .bpm-grid-row,
  .sortable-placeholder {
    float: left;
    height: 30rem;
    margin: 1.5rem;
    padding: 5rem 1rem 1rem 1rem;
    width: 30rem;
    background: $white-color;
    border: 1px solid $border-color;
    position: relative;
  }

  .bpm-grid-row {
    padding: 0;

    &:hover div:not(:hover).bpm-grid-actions a {
      background-color: transparent;
      color: $primary-color;
    }
  }

  .sortable-placeholder {
    background: $table-row-bg;
    border: 1px dashed $nav-active-color;
  }

  .bpm-grid-head .bpm-grid-cell i {
    display: inline-block;
    vertical-align: top;
    font-size: 1.7rem;
    padding-top: 0.1rem;
    color: transparent;
  }

  .bpm-grid-cell {
    display: block;
    vertical-align: top;
    padding: 0;
    transition: all 0.3s ease 0s;
  }

  .bpm-grid-cell-table {
    display: table;

    .bpm-grid-cell {
      vertical-align: top;
      transition: all 0.3s ease 0s;
      display: table-row;
    }

    .bpm-grid-cell-label {
      margin-right: 1rem;
      width: 10rem;
      display: table-cell;
      font-weight: 700;
      padding: 0.4rem 1rem 0.4rem 0;
      border-bottom: 1px solid $border-color;
      @include fts12;
    }

    .bpm-grid-cell-value {
      display: table-cell;
      width: 16rem;
      padding: 0.4rem 0;
      border-bottom: 1px solid $border-color;
      @include fts12;
    }
  }

  .bpm-grid-checkbox {
    position: absolute;
    left: 1rem;
    top: 1rem;
  }

  .bpm-grid-status {
    top: 1rem;
    right: 1rem;
    position: absolute;
  }

  .bpm-grid-image {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .bpm-status-temp {
    bottom: 3rem;
    right: 1rem;
    position: absolute;
    text-align: right;
  }

  .bpm-grid-actions {
    min-width: 18rem;
    text-align: right;
    width: 18rem;
    @include fts14;
    position: absolute;
    right: 1rem;
    top: 1rem;

    a {
      display: inline-block;
      padding: 0 0.4rem 0.2rem;
      vertical-align: middle;
      width: 2.1rem;
      margin-bottom: 0.3rem;

      &.bpm-submit {
        display: block;
        width: 100%;
        margin-bottom: 0;
        text-align: left;
        padding: 0.4rem 1rem;
        background: none;
        color: $main-color;
        border: none;
        @include fts14;

        &:hover {
          background-color: $table-bg-hover;
          color: $main-color;
        }
      }
    }
  }

  .bpm-grid-head .bpm-grid-actions {
    text-align: left;
  }

  div:not(:hover).bpm-grid-row .bpm-grid-actions a {
    background-color: transparent;
    color: $table-border-hover;
  }
}

.bpm-grid.bpm-tileview-list {

  .bpm-grid-row {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &:hover {

      .bpm-grid-content-top {
        opacity: 1;
      }

      .bpm-grid-content-center {
        opacity: 1;
      }

      .bpm-grid-content-bottom {
        opacity: 0;
      }

      .bpm-grid-status {
        opacity: 0;
      }

    }
  }

  .bpm-grid-content-top {
    @include grid-content(0, 4rem);
    top: 0;
  }

  .bpm-grid-content-center {
    @include grid-content(0, 25.8rem);
    bottom: 0;
    padding: 0.5rem;
  }

  .bpm-grid-content-bottom {
    @include grid-content(1, 10rem);
    bottom: 0;
    padding: 1rem 1rem 0 1rem;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    @include fts14;
  }

  h4 {
    margin-top: 0;
    margin-bottom: 1rem;
    @include fts13;
  }

  .params {
    @include fts12
  }

  .info-export {
    @include fts11;
  }

}

.bpm-grid.bpm-tileview .bpm-grid-cell-table .tar,
.bpm-grid.bpm-tileview .bpm-grid-cell-table .tac {
  text-align: left !important;
}

.theme-prime,
.theme-primepro,
.theme-gethome {
  .bpm-status-temp {
    color: $alert-prime-color;
  }

}
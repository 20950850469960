@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-autocomplete {
  min-width: 7rem;
  position: relative;

  input {
    background: url('../gfx/load3.gif') no-repeat scroll 98% -180% $white-color;
    transition: background .3s;

    &.loader {
      background-position: 98% center;
    }
  }

  .bpm-autocomplete-arrow {
    cursor: pointer;

    i {
      transition: .2s;
    }
  }

  .bpm-dropdown-menu {
    width: 100%;
  }

  .bpm-dropdown-content {
    max-height: 20rem;
    min-height: 20rem;
    position: relative;

    > ul {
      position: static !important;
      display: block !important;
      width: 100% !important;
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }

  .bpm-autocomplete-suggestion {
    display: inline-block;
    width: 100%;
    padding: 0 0.3rem;
    cursor: pointer;

    &.phrase-suggestion {
      @include fts14;
      padding: 0.5rem 0.3rem 0.7rem;
      margin-bottom: 0.7rem;
      border-bottom: 1px solid $autocomplete-border;
    }

    &:hover,
    &.selected {
      background-color: $autocomplete-bg;
    }

    &.richrow {
      padding: 0.5rem 0.3rem;
      position: relative;

      span.autocomplete-label {
        display: block;
        padding-left: 5rem;
        min-height: 5rem;
        line-height: 2.2rem;
      }

    }

    strong {
      @include themify($themes) {
        color: themed('autocompleteSuggestionColor');
      }
    }

    span.image {
      position: absolute;
      width: 4rem;
      height: 4rem;
      top: 0;
      left: 0;

      img {
        width: 100%;
        margin: 0.5rem 0;
      }
    }

    span.autocomplete-label {
      line-height: 2.2rem;
      display: block;
    }

    span.desc {
      @include fts12;
      display: block;
      line-height: 1.8rem;
      color: $autocomplete-color;
      margin-bottom: 0.5rem;
    }

    span.labelinfo {
      @include fts12;
      line-height: 1.8rem;
      color: $autocomplete-color;
    }
  }
}

.bpm-autocomplete-infonum {
  @include themify($themes) {
    color: themed('linkColor');
  }
}

.bpm-combobox .bpm-dropdown-content {
  min-height: 0;
}

.bpm-transaction-form .bpm-autocomplete .bpm-dropdown-menu {
  width: 100%;
}

.mobile-content #search-container {

  .bpm-autocomplete {
    margin-bottom: 1.5rem;
  }

  #search-status_search-autocomplete.bpm-autocomplete {
    margin-bottom: 0;
  }

  .row.form-group .btn.btn-success {
    margin-top: 1.5rem;
  }
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

aside {
  position: fixed;
  //background: $menu-bg-color url(../gfx/bg.jpg) repeat 0 0;
  padding-top: 5rem;
  width: 23rem;
  height: 100%;
  z-index: 100000;

  @include themify($themes) {
    background: themed('menuBgColor');
    font-family: themed('mainFont'), Helvetica, Arial, sans-serif;
  }

  &.menu-hidden {
    visibility: hidden;
  }
}

.menu-main {
  height: inherit;
  position: relative;

  @include themify($themes) {
    padding: themed('menuMainPadding');
  }

  > dl > dt {

    @include themify($themes) {
      border-bottom: themed('menuLinkBorder');
    }

    > a {
      display: block;
      font-weight: 300;
      transition: .4s;
      @include fts14;
      @include themify($themes) {
        color: themed('asideTextColor');
        padding: themed('menuLinkPadding');
        text-shadow: none;
      }

      &:hover {
        @include themify($themes) {
          background-color: themed('menuHoverBg');
        }
      }

      &:hover i.menu-item-arrow,
      &.active i.menu-item-arrow {
        @include rotate90;
      }

      > i {
        @include fts14;
        margin-right: 0.7rem;
        text-align: center;
        width: 1.5rem;
        @include themify($themes) {
          color: themed('asideTextColor');
          margin-right: themed('menuIconMargin');
        }
      }

      &.active,
      &.active-url {
        @include themify($themes) {
          background-color: themed('menuLinkActive');
          color: themed('menuLinkActiveColor');
          text-shadow: none;
        }
      }
    }
  }

  .menu-item i.menu-item-arrow {
    float: right;
    color: $main-color;
    line-height: inherit;
    font-size: 1.7rem;
    margin: 0;
  }

  .menu-item.sortable-dragging {
    height: 4.2rem !important;
    overflow: hidden;
  }

  .sortable-placeholder {
    height: 4.2rem;
  }

  .menu-item-hidden {
    opacity: 0.3 !important;
  }

  .menu-item-testing {
    border-bottom: 1px solid $main-color;

    span {
      font-weight: bold;
    }
  }
}

.menu-trigger {
  position: absolute;
  right: -2rem;
  top: 5rem;
  background: url("../gfx/menu-txt.png") no-repeat top center $black-color;
  color: $white-color;
  width: 2rem;
  padding: 0.3rem 0.3rem 0.3rem 0.8rem;
  display: block;
  opacity: 0.3;
}

.menu-hide {
  position: absolute;
  right: -1.8rem;
  //top: 5rem;
  color: $white-color;
  background: url("../gfx/menu-hide.png") no-repeat left center $black-color;
  width: 1.8rem;
  height: 2.6rem;
  padding: 0.3rem 0.3rem 0.3rem 0.4rem;
  display: block;
  opacity: 0.2;

  @include themify($themes) {
    height: themed('menuHeight');
  }

  &:hover {
    color: $white-color;
  }
}

.menu-show {
  position: absolute;
  right: -1.8rem;
  top: 5rem;
  color: $white-color;
  background: url("../gfx/menu-show.png") no-repeat left center $black-color;
  width: 1.8rem;
  height: 2.6rem;
  padding: 0.3rem 0.3rem 0.3rem 0.6rem;
  display: none;
  opacity: 0.2;

  &:hover {
    color: $white-color;
  }
}

.menu-level-2 {
  background: none repeat scroll 0 0 $bg-hover-opacity;
  margin: 0;
  padding: 0.5rem 1rem 1.5rem 1rem;
  display: block;

  @include themify($themes) {
    background: themed('menu2Bg');
    padding: themed('menu2Padding');
  }

  > dt > a {
    display: block;
    font-weight: 300;
    @include themify($themes) {
      color: themed('menuLinkColor');
      padding: themed('menu2PaddingLink');
      font-size: themed('menu2Fts');
    }

    &:hover {
      @include themify($themes) {
        background-color: themed('menuHoverBg');
        filter: brightness(110%);
        text-shadow: none;
      }
    }

    &.active-url {
      @include themify($themes) {
        color: themed('linkColor');
      }
    }
  }

  > dt > .separator {
    display: block;
    padding: 0 0 0 2.3rem;

    hr {
      margin: 0.8rem 0 0.8rem;

      @include themify($themes) {
        border-top: themed('menuSeparatorBorder');
      }
    }
  }
}

.theme-prime,
.theme-primepro,
.theme-gethome {

  aside {
    border: 1px solid #F1F0F5;
  }
}

.theme-prime,
.theme-primepro,
.theme-gethome {
  .menu-main > dl > dt > a {

    @include fts12;

    > i {
      @include fts18;
    }

    &.active > i,
    &.active-url > i,
    &.active > span,
    &.active-url > span {
      color: $prime-primary-color;
    }
  }
}

.theme-gethome {
  .menu-main > dl > dt > a {
    &.active > i,
    &.active-url > i,
    &.active > span,
    &.active-url > span {
      color: $gethome-primary-color;
    }
  }
}

@media (max-width: 780px) {

  .menu-trigger {
    display: none !important;
  }

  aside.menu-hidden {
    visibility: visible;
  }

  .theme-prime,
  .theme-primepro,
  .theme-gethome {

    aside {
      left: -23rem;
      padding-top: 10rem;
    }

  }
}

@media (min-width: 992px) and (max-width: 1199px) {

  aside {
    width: 20.5rem;
  }

  .menu-main > dl > dt > a {
    @include fts12;
    color: $main-icon-color;
    padding: 0.8rem 1rem;

    > i {
      @include fts13;
      width: 1.4rem;
    }
  }

  .menu-main .menu-item i.menu-item-arrow {
    @include fts15;
  }

  .menu-level-2 > dt > a {
    @include fts12;
    padding: 0.2rem 1rem 0.2rem 2rem;
  }


  .theme-prime,
  .theme-primepro,
  .theme-gethome {

    aside {
      width: 20.5rem;
    }

    section.content {
      margin-left: 20.5rem;
      padding: 8rem 3rem;
    }

    section.menu-hidden {
      margin-left: 1rem;
    }

    .search-bar-top {
      left: 20.5rem;
    }

    .menu-main > dl > dt > a {
      @include fts12;
      padding: 0.8rem 1rem;

      > i {
        @include fts13;
        width: 1.4rem;
      }
    }

    .menu-main .menu-item i.menu-item-arrow {
      @include fts15;
    }

    .menu-level-2 > dt > a {
      @include fts12;
      padding: 0.2rem 1rem 0.2rem 2rem;
    }

    #bpm-elements-listview .ps-scrollbar-x-rail {
      left: 22.5rem !important;
    }

    .menu-hidden #bpm-elements-listview .ps-scrollbar-x-rail {
      left: 3rem !important;
    }

  }
}
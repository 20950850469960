@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-grid-portal-settings {
  padding: 0 !important;
  height: auto !important;
  border-radius: 0.1rem !important;
}

.bpm-grid-row.bpm-grid-portal-settings {
  margin: 0 1.5rem !important;
  border-top-width: 0 !important;

  &:first-child {
    border-top-width: 0.1rem !important;
  }

}

.bpm-grid-portal-settings-content {
  padding: 2rem;
  position: relative;
}

@media(max-width: 767px) {
  .bpm-grid-publication-settings .bpm-grid-portal-settings-content .dot-portal-container {
    margin-left: 0;
  }

  .offerExport-subtitle {
    margin-bottom: 2.7rem;
  }

  .bpm-grid-portal-settings-content {
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-areas:
          "dot logo actionsButton";

    .portal-tools-button.investment-view-publish {
      display: block;
    }

    .portal-tools-info {
      flex-direction: column;
      align-items: flex-start;

      .portal-tools-settings-link {
        margin-top: 1.9rem;
      }
    }

    .portal-start-button,
    .portal-tools-button {
      display: none;
    }
  }

  .portal-tools-button.investment-view-publish .investment-view-publish-text {
    display: none;
  }

  .bpm-grid-publication-settings .bpm-grid-portal-settings-content {
    grid-template-columns: 15% 2fr 15%;
  }

  .inactive-publication .dot-portal-container {
    align-items: unset;
    margin-top: 1rem;
  }

  .inactive-publication .portal-logo-container {
    margin-bottom: 2rem;
  }

  .bpm-grid-publication-settings.inactive-publication .bpm-grid-portal-settings-content {
    grid-template-columns: 15% 2fr;
    grid-template-areas: "dot logo" "dot toolsWarning";
    max-height: none;
  }

  .portal-logo-container p {
    @include fts14;
  }

  .portal-actions-button.desktop-hidden {
    visibility: visible;
  }

  .publish-options-settings .publish-options-offers {
    display: none;
  }

  .publish-options-content .row.submit-buttons-group {
    margin-left: 0;
  }
}

@media(max-width: 960px) {


  .bpm-grid-portal-settings-content {
    grid-template-columns: 10% 5fr 25% 25% 10%;
  }

  .bpm-grid-publication-settings .bpm-grid-portal-settings-content {
    grid-template-columns: 10% 2fr 40%;
  }

  .bpm-grid-publication-settings.inactive-publication .bpm-grid-portal-settings-content {
    grid-template-columns: 10% 2fr 40%;
  }
}

@media(max-width: 1098px) {
  .bpm-grid-portal-settings-content {
    grid-template-columns: 5% 5fr 20% 20% 5%;
  }
}
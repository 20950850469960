@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.pagination {
  display: inline-block;
}

.pager {
  display: inline-block;
  margin: 0;

  > li {
    float: left;

    > a,
    > span {
      background: $white-color;
      float: left;
      margin: 0 0.5rem 0 0;
      @include fts12;
      @include themify($themes) {
        padding: themed('pagerPadding');
        color: themed('pagerColor');
        border: themed('pagerBorder');
        border-radius: themed('pagerRadius');
      }
    }

    &.active > span {
      @include themify($themes) {
        color: themed('linkColor');
        background-color: themed('pagerBg');
        border-color: themed('pagerBorderActive');
      }
    }

    a:hover,
    a.active {

      @include themify($themes) {
        background-color: themed('pagerBg');
        border-color: themed('pagerBorderActive');
        color: themed('pagerActiveColor');
      }
    }
  }

  .bpm-dropdown-menu a {
    display: block;
  }
}

@media (max-width: 780px) {

  .bpm-grid-pagination {
    padding-top: 10px;
    float: none !important;
    clear: both;
  }
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

#bpm-subscription-loyalty {
    .loyalty-templates {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 15px;
    }

    .btn-template {
        flex: 1 0 calc(30% - 15px);
        min-height: 120px;
        text-align: left;
        border: 2px solid #ddd;
        background-color: #f8f9fa;
        transition: all 0.3s;
        padding: 15px;
        position: relative;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
    }

    .template-name {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .template-desc {
        font-size: 12px;
        color: #666;
        margin-bottom: 10px;
        word-wrap: break-word;
        white-space: normal;
    }


    .btn-template:hover {
        border-color: #007bff;
        background-color: #e9f0ff;
    }

    .btn-template.active {
        border-color: #007bff;
        background-color: #d4e4ff;
    }

    .btn-template-empty {
        background-color: #f2f2f2;
        border: 2px dashed #aaa;
    }

    .btn-template-empty:hover, .btn-template-empty.active {
        background-color: #e6e6e6;
        border-color: #666;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
}
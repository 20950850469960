@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.theme-prime,
.theme-primepro {

  #create-investment-dialog.mobile-create-investment-dialog .bpm-create-investment-dialog {
    width: 100%;

    .btn-dialog-footer .col-sm-12 {
      display: flex;
      justify-content: center;
      padding-bottom: 3rem;

      .btn.btn-success {
        margin-top: 0;
      }
    }
  }

  .bpm-create-investment-fields.mobile-create-investment-fields {
    width: 100%;
  }

  .bpm-create-investment-dialog-container.mobile-create-investment-dialog-container {
    max-width: 100%;

    #location-map {
      width: 100%;
    }

    .form-group-prime.short-inputs {
      padding-bottom: 0;
    }

  }

  .content.mobile-content {
    .mobile-sub-tabs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 4rem;
      margin-bottom: 9px;
      padding: 0.8rem;
      background-color: $white-color;
      border-top: 2px solid $prime-primary-color;
      border-top-right-radius: 2px;
      border-top-left-radius: 2px;

      span {
        font-size: 15px;
        font-weight: 500;
        white-space: nowrap;
      }
    }

    .investment-edit-data-tabs {
      padding: 3.4rem 1.8rem 0
    }

    .form-prime-container.basic-prime {

      padding: 1.6rem;

      .form-group-prime.mobile-nopadding-bottom {
        padding-bottom: 0;
      }

      .form-group-prime.short-inputs {
        flex-direction: column;

        .form-input-mid.mobile-padding-bottom {
          padding-bottom: 2rem;
        }

        .form-input-long {
          width: 100%;
          padding-right: 0;
        }

        .form-input-short {
          width: 100%;
        }

      }
    }

    .investment-edit-data-container {
      padding: 1.6rem;

      .form-input-mid.padding-mobile-bottom {
        padding-bottom: 2rem;
      }

      .form-group-prime.short-inputs {

        .form-input-mid.mobile-padding-bottom,
        .form-input-joined.mobile-padding-bottom {
          padding-bottom: 2rem;
        }

      }

      .form-group-prime {
        padding-bottom: 2rem;
      }

      .form-group-prime.short-inputs {
        flex-direction: column;
      }

      .short-inputs .form-input-triple {
        width: 100%;
        padding-right: 0;
      }

      #gallery-body {

        .form-prime-tipscontainer {
          width: 98%;
          display: none;

          .tooltip-info-success.triangle-up {
            margin: 0;
          }
        }

        .prime-gallery-item {
          width: 100%;
          margin: 2.5rem 0 2.5rem 0.5rem;

          .bpm-grid-cell.photo-cell {
            width: 100%;

            .bpm-lightbox {
              width: 100%;
            }
          }
        }
      }

      .short-inputs .form-input-joined {
        width: 100%;
        padding-right: 0;
      }

      .contiguity-section-prime {

        .form-input-triple {
          padding-bottom: 2rem;
        }
      }

      .bpm-grid-application-title,
      .bpm-grid-toggler-title {
        padding: 1.6rem 4rem 1.6rem 1.6rem;

        i {
          right: 2rem;
        }
      }

      .bpm-grid-toggler-content {
        padding: 1.6rem;
      }

      h4.building-name {
        font-size: 1.9rem;
        color: $prime-dark-color;
      }

      .bpm-grid-toggler-title .additional-info {
        @include fts13;
        color: $prime-dark2-color;
        font-weight: 500;
      }

    }
  }
}
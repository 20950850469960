@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.rating-loading {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0;
  color: $white-color;
  background: transparent url('../img/loading.gif') top left no-repeat;
  border: none;
}

.rating-container {

  .rating-stars {
    position: relative;
    cursor: pointer;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }

  .rating-input {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 0.1rem;
    bottom: 0;
    left: 0;
    font-size: 0.1rem;
    border: none;
    background: none;
    opacity: 0;
    padding: 0;
    margin: 0;
  }

  &.is-display-only .rating-input,
  &.is-display-only .rating-stars {
    cursor: default;
  }

  .star {
    display: inline-block;
    margin: 0 0.2rem;
    text-align: center;
    @include fts18;
  }

  .empty-stars {
    color: $disabled-color;
  }

  .filled-stars {
    position: absolute;
    left: 0;
    top: 0;
    margin: auto;
    color: $stars-color;
    white-space: nowrap;
    overflow: hidden;
    -webkit-text-stroke: 1px $placeholder-color;
    text-shadow: 1px 1px $autocomplete-color;
  }

  .clear-rating {
    color: $disabled-color;
    cursor: not-allowed;
    display: inline-block;
    vertical-align: middle;
    font-size: 60%;
    padding-right: 0.5rem;
  }

  .caption {
    color: $autocomplete-color;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    margin-left: 0.5rem;
    margin-right: 0;

    .label {
      display: inline-block;
      padding: .25em .4em;
      line-height: 1;
      text-align: center;
      vertical-align: baseline;
      border-radius: .25rem;
    }
  }
}

.rating-disabled .rating-input,
.rating-disabled .rating-stars {
  cursor: not-allowed;
}

.presentation-wrapper .rating-container {
  padding-top: 0.3rem;
}

.rating-rtl {
  float: right;

  .filled-stars {
    left: auto;
    right: 0;
    transition: none;
    -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  &.is-star .filled-stars {
    right: 0.06em;
  }

  &.is-heart .empty-stars {
    margin-right: 0.07em;
  }

  .caption {
    margin-right: 0.5rem;
    margin-left: 0;
  }
}

.rating-animate .filled-stars {
  transition: width 0.25s ease;
}

.clear-rating-active {
  cursor: pointer !important;

  &:hover {
    color: $rating-color-hover;
  }
}

@media print {
  .rating-container .clear-rating {
    display: none;
  }
}

.rating-xl .caption {
  font-size: 2rem;
}

.rating-lg .caption {
  @include fts18;
}

.rating-md .caption {
  @include fts16;
}

.rating-sm .caption {
  @include fts14;
}

.rating-xs .caption {
  @include fts12;
}

.bpm-rating {
  display: none;
}

.fa.zone-estimation-icon {
  font-size: 24px;
  &.fa-thumbs-up {
    @include themify($themes) {
      color: themed('successColor');
    }
  }
  &.fa-thumbs-down {
    @include themify($themes) {
      color: themed('alertColor');
    }
  }
}

.zone-estimation-container {
  display: flex;
  align-items: center;

  span {
    padding-left: 1rem;
  }
}

@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.gmap {
  width: 100%;
  max-width: 100%;
  min-height: 40rem;
  background-color: $gmap-bg;
  margin-bottom: 2rem;
}

.bpm-gmap {
  width: 100%;
  display: none;

  &.bpm-manual {
    width: 100%;
    display: block;
  }

  .marker-labels {
    background-color: $white-color;
    font-size: 0.9rem;
    text-align: center;
    color: $gray-color;
    width: 5rem;
    white-space: nowrap;
    border-radius: 0.8rem;
    padding: 0.1rem;
    box-shadow: 0 0.1rem 0.1rem $scrollbar-y-rail-bg;
  }
}

.bpm-gmap-body {
  width: 100%;
  min-height: 46rem;
  margin-bottom: 4rem;
  box-shadow: $gmap-shadow;
}

.bpm-gmap-controls-info {
  padding-bottom: 2rem;
  text-align: center;
  @include fts14;
}

.bpm-gmap-input-not-ok {
  color: $warning-color !important;
}

.bpm-gmap-input-ok {
  color: $success-color !important;
}

.bpm-gmap-controls {
  width: 100%;
  display: inline-block;
  margin-bottom: 1rem;

  input[type=text] {
    width: 60%;
    float: left;
  }

  button {
    width: 30%;
    float: right;
  }

}

.location-marker-button,
.bpm-gmap-controls-info {
  display: none;
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-tutorial-arrow {
  display: block;
  position: absolute;
  z-index: 50000001;
  opacity: 0;
}

.bpm-tutorial-arrow-up-right {
  @include tutorial-higher-arrows('../gfx/tutorial/arrow-up-right.png');
}

.bpm-tutorial-arrow-up-left {
  @include tutorial-higher-arrows('../gfx/tutorial/arrow-up-left.png');
}

.bpm-tutorial-arrow-down-right {
  @include tutorial-higher-arrows('../gfx/tutorial/arrow-down-right.png');
}

.bpm-tutorial-arrow-down-left {
  @include tutorial-higher-arrows('../gfx/tutorial/arrow-down-left.png');
}

.bpm-tutorial-arrow-right-up {
  @include tutorial-arrows('../gfx/tutorial/arrow-right-up.png');
}

.bpm-tutorial-arrow-right-down {
  @include tutorial-arrows('../gfx/tutorial/arrow-right-down.png');
}

.bpm-tutorial-arrow-left-up {
  @include tutorial-arrows('../gfx/tutorial/arrow-left-up.png');
}

.bpm-tutorial-arrow-left-down {
  @include tutorial-arrows('../gfx/tutorial/arrow-left-down.png');
}


.bpm-tutorial-highlight {
  background: none;
  border: 2px solid $white-color;
  display: block;
  position: absolute;
  opacity: 0;
  z-index: 50000001;
}

.bpm-tutorial-info {
  @include fts15;
  color: $white-color;
  max-width: 25rem;
  text-shadow: $tutorial-shadow;
  display: block;
  opacity: 0;
  position: absolute;
  z-index: 50000001;
}

.bpm-tutorial-step {
  @include fts18;
  color: $white-color;
  padding: 1rem 2rem;
  background: $black-color;
  opacity: 0;
  margin-top: 0.1rem;
  text-shadow: $tutorial-shadow;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  cursor: pointer;
  z-index: 50000000;
}

.bpm-tutorial-close {
  @include fts13;
  color: $white-color;
  padding: 0.5rem 2rem;
  background: $black-color;
  opacity: 0;
  margin-top: 0.1rem;
  text-shadow: $tutorial-shadow;
  display: block;
  position: fixed;
  left: 50%;
  margin-left: -12.5rem;
  top: 0;
  cursor: pointer;
  text-align: center;
  width: 25rem;
  border: 1px solid $alert-color;
  z-index: 50000001;
}

.bpm-tutorial-close span {
  @include fts12;
}
@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";


.theme-prime,
.theme-primepro,
.theme-gethome {
  .desktop-news {
    margin-top: 2rem;
    padding-left: 6rem;
    color: $investment-icon-color;
    @include fts13;
    position: relative;

    i {
      position: absolute;
      left: 2rem;
      top: 0.5rem;
      font-size: 2.6rem;
      color: $prime-event-icon;
    }

    .news-date {
      @include fts14;
      color: $prime-event-date;
      letter-spacing: 1.2px;
      font-weight: 500;
    }

    h3 {
      font-size: 2rem;
      font-weight: 500;
      color: $investment-icon-color;
      margin-top: 0.5rem;
    }

    .news-more {
      text-decoration: underline;
      color: $investment-icon-color;
    }

    .news-summary {
      margin-bottom: 2rem;
      line-height: 160%;
    }

    .event-summary {
      margin-bottom: 2rem;
      line-height: 160%;

      a {
        text-decoration: underline;
        color: $investment-span-color !important;
      }
    }
  }

  .desktop-event {
    padding-left: 6rem;
    color: $investment-icon-color;
    @include fts13;
    position: relative;
    margin-top: 2rem;

    i {
      position: absolute;
      left: 2rem;
      top: 0.5rem;
      font-size: 2.6rem;
      color: $prime-event-icon;
    }

    .event-date {
      @include fts14;
      color: $prime-event-date;
      letter-spacing: 1.2px;
      font-weight: 500;
    }

    h3 {
      font-size: 2rem;
      font-weight: 500;
      color: $investment-icon-color;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
  }
}



@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/themes/_themes";
@import "./resources/sass-test/build/sass/utils/_mixins";

.theme-prime {
  #bpm-helptips-slider-content {
    background-color: #3D2E66;
  }

  div.general-header {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    line-height: 16px;
    color: #B8A6E9;
    width: 84%;
    margin: 0 auto;
    padding-top: 16px;

    i {
      font-size: 20px;
    }
  }

  .bpm-carousel.carousel {
    margin: 0 auto;
    padding-bottom: 16px;

    .carousel-inner {
      width: 84%;
      margin: 0 auto;
    }

    h3 {
      font-size: 19px;
      line-height: 24px;
      margin: 8px 0;
      color: $white-color;
    }

    p {
      font-size: 15px;
      line-height: 22.5px;
      margin: 0;
      color: $white-color;
    }

    .carousel-control-next, .carousel-control-prev {
      width: 8%;
    }

  }
}

@import "./resources/sass-test/build/sass/utils/_variables";
@import "./resources/sass-test/build/sass/utils/_mixins";

.bpm-select + .bpm-dropdown-menu {
  width: 100%;

  label {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

.bpm-multiselect {
  cursor: pointer;
}